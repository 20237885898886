@use "sass:math";
@import "../scss/mixins";

.sidebar {
  $padding-y: map-get($spacers, 4);
  $padding-item-y: math.div(map-get($spacers, 1), 2);
  $padding-item-x: map-get($spacers, 2);
  $padding-link-y: map-get($spacers, 2);
  $padding-link-x: map-get($spacers, 2) + map-get($spacers, 1);

  display: flex;
  position: fixed;
  z-index: $zindex-fixed;
  top: $page-top-height;
  right: 0;
  bottom: 0;
  flex-direction: column;
  width: 100%;
  max-width: calc(215px + var(--safe-area-inset-right));
  padding-top: $padding-y;
  padding-right: var(--safe-area-inset-right);
  padding-bottom: calc(#{$padding-y} + var(--safe-area-inset-bottom));
  overflow: auto;
  transform: translate3d(100%, 0, 0);
  transition: $sidebar-transition;
  background-color: $sidebar-bg;

  &.sidebar--active {
    transform: translate3d(0, 0, 0);
  }

  @include media-breakpoint-up($sidebar-static-breakpoint) {
    left: 0;
    width: $sidebar-width;
    padding-right: 0;
    padding-left: var(--safe-area-inset-left);
    overflow: visible;
    transform: translate3d(-100%, 0, 0);
  }

  &__backdrop {
    position: fixed;
    z-index: $zindex-fixed - 1;
    top: $page-top-height;
    transition: opacity .3s ease-in-out;
    opacity: .6;
    background-color: $dark;

    @include inset(0);

    @include media-breakpoint-up($sidebar-static-breakpoint) {
      display: none;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    @include media-breakpoint-up($sidebar-static-breakpoint) {
      overflow: auto;
    }
  }

  &__list {
    margin: 0;
    padding: 0;

    &--bottom {
      padding: 0 $padding-item-x + $padding-link-x;

      .sidebar__item {
        padding: 0;
      }
    }
  }

  &__item {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    padding: $padding-item-y $padding-item-x;

    &--inner {
      &::before {
        $size: 2px;

        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: $padding-item-x + $padding-link-x + rem(math.div($sidebar-item-icon-size, 2) - math.div($size, 2));
        width: $size;
        background: $var-surface-5;
      }
    }
  }

  &__link {
    display: flex;
    position: relative;
    flex-grow: 1;
    align-items: center;
    min-height: 40px;
    padding: $padding-link-y $padding-link-x;
    transition: $sidebar-item-transition;
    border-radius: $sidebar-item-border-radius;
    background-color: $sidebar-item-bg-color;
    color: $sidebar-item-color;
    font-weight: $font-weight-medium;
    cursor: pointer;

    &:hover {
      background-color: $sidebar-item-bg-color-hover;
      color: $sidebar-item-color-hover;

      .sidebar__icon {
        color: $sidebar-item-icon-color-hover;
      }
    }

    &.caret-down {
      &::after {
        width: 12px;
        height: 12px;
        mask-size: 100%;
        transition: $transition;
        transition-property: transform, color;
        background-color: $gray-400;
      }
    }

    &:not(.collapsed) {
      color: $sidebar-item-color-active;

      &.caret-down {
        &::after {
          background-color: $sidebar-item-color-active;
        }
      }
    }

    &.active {
      background-color: $sidebar-item-bg-color-active;
      color: $sidebar-item-color-active;
      pointer-events: none;

      .sidebar__icon {
        color: $sidebar-item-icon-color-active;
      }

      &.caret-down {
        &::after {
          background-color: $sidebar-item-color-active;
        }
      }
    }

    &.disabled {
      opacity: .5;
    }

    &--container {
      &.active {
        background-color: $sidebar-item-bg-color;
        pointer-events: auto;
      }
    }

    &--inner {
      margin-left: rem($sidebar-item-icon-size) + $sidebar-item-icon-offset;
    }

    &--auth {
      padding-top: 0;
      padding-bottom: 0;
    }

    &--bottom {
      min-height: 20px;
      padding: 0;
      color: $sidebar-item-bottom-color;
      font-size: $small-font-size;
      font-weight: $font-weight-normal;

      &:hover,
      &.active {
        background-color: transparent;

        .sidebar__icon--bottom {
          color: $var-primary-theme;
        }
      }
    }
  }

  &__icon,
  &__text {
    vertical-align: middle;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $sidebar-item-icon-size;
    height: $sidebar-item-icon-size;
    margin-right: $sidebar-item-icon-offset;
    transition: inherit;
    color: $sidebar-item-icon-color;
    text-align: center;

    &--bottom {
      margin-right: 0;

      &, svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 18px;
    height: 18px;
    padding: .25rem .25rem .25rem 0.2rem;
    border-radius: 10rem;
    background-color: $danger;
    color: $white;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }

  &__divider {
    width: 100%;
    height: 1px;
    margin: map-get($spacers, 3) 0;
    background-color: $var-surface-5;
  }
}

main {
  @include media-breakpoint-up(md) {
    .sidebar--active & {
      margin-left: $sidebar-width;
    }
  }
}

.main--inner {
  .sidebar--active & {
    padding-left: $main-offset;

    @include media-breakpoint-up(md) {
      padding-left: $main-offset-md;
    }
  }
}
