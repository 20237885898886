@use 'sass:map';
@use 'sass:math';

$host: '.modal-base';

.modal-base {
  $padding: map.get($spacers, 4);
  $padding-not-full: map.get($spacers, 5);
  $padding-md: map.get($spacers, 7);
  $border-radius-sl: 2rem;
  $border-radius-xs: 1.5rem;

  // $border-radius-default: 1.5rem;
  $border-radius-default: 2rem;

  border: none;

  &--not-full {
    --#{$prefix}modal-border-radius: #{$border-radius-xs} !important;
  }

  &, &:not(&--not-full) {
    @include media-breakpoint-up(md) {
      --#{$prefix}modal-border-radius: #{$border-radius-default} !important;
    }

    @include media-breakpoint-up(sl) {
      --#{$prefix}modal-border-radius: #{$border-radius-sl} !important;
    }
  }

  &:not(.modal-xl, .modal-lg, .modal-md, .modal-sm) {
    --#{$prefix}modal-width: 536px;
  }

  &:not(&--not-full) {
    @include media-breakpoint-down(md) {
      --#{$prefix}modal-border-radius: 0 !important;
      --#{$prefix}modal-width: 100% !important;

      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0;

      .modal-content {
        flex-grow: 1;
        margin-top: calc(var(--safe-area-inset-top) * -1);
        margin-bottom: calc(var(--safe-area-inset-bottom) * -1);
        padding-top: var(--safe-area-inset-top);
        padding-bottom: var(--safe-area-inset-bottom);

        .close {
          margin-top: var(--safe-area-inset-top);
        }
      }
    }
  }

  .modal-content {
    border: none;
  }

  .close {
    $offset: map.get($spacers, 3) + map.get($spacers, 1);

    top: $offset;
    right: $offset;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: $padding;

    #{$host}--not-full & {
      padding: $padding-not-full;
    }

    @include media-breakpoint-up(md) {
      &, #{$host}--not-full & {
        padding: $padding-md;
      }
    }
  }

  &__content {
    flex-grow: 1;
    align-self: center;
    margin: $spacer * 1.25;

    @include media-breakpoint-up(md) {
      margin: map.get($spacers, 4);
    }

    @include media-breakpoint-up(xl) {
      margin: $spacer * 3;
    }
  }

  &__header {
    margin: 0 $padding map.get($spacers, 2);
    margin-bottom: map.get($spacers, 2);
    font-family: $font-family-headline;
    font-size: 1.25rem;
    font-weight: $font-weight-normal;
    line-height: 1.75rem;

    @include media-breakpoint-up(md) {
      margin: 0 $padding-md map.get($spacers, 2);
    }

    &--no-border {
      margin-bottom: 0;
      border-bottom: 0;
    }

    .modal-desc {
      margin-top: map.get($spacers, 2);
      color: $var-grey;
      font-family: $font-family-base;
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      line-height: 1.25rem;
    }
  }
}
