@use "sass:map";
@use "sass:math";

.modal-share-generator {
  $preview-size: 64px;

  &__btn-download {
    position: absolute;
    right: map.get($spacers, 2_5);
    bottom: map.get($spacers, 2_5);
  }

  &__label {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    margin-bottom: map.get($spacers, 2_5);
  }

  &__tabs,
  &__previews {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -#{map.get($spacers, 0_5)};
  }

  &__tabs-item {
    position: relative;
    display: block;
    margin: map.get($spacers, 0_5);
    padding: map.get($spacers, 2) map.get($spacers, 3);
    color: $body-color;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    border: $border-width solid $border-color;
    transition: opacity $transition;

    @include border-radius($border-radius);

    &::before {
      $border-width: $border-width * 1.5;

      content: '';
      position: absolute;
      border: $border-width solid $var-primary-theme;
      pointer-events: none;
      opacity: 0;
      transition: opacity $transition;

      @include inset(-$border-width);
      @include border-radius(inherit);
    }

    &:disabled {
      opacity: .5;
    }

    &.active {
      &::before {
        opacity: 1;
      }
    }
  }

  &__previews {
    overflow: auto;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, minmax($preview-size, 1fr) );
    max-height: rem($preview-size * 2) + map.get($spacers, 0_5) * 4;
  }

  &__previews-item {
    position: relative;
    display: block;
    margin: map.get($spacers, 0_5);
    width: $preview-size;
    height: $preview-size;
    font-size: 0;

    @include border-radius($border-radius);

    &::before {
      $border-width: $border-width * 3;

      content: '';
      position: absolute;
      border: $border-width solid $var-primary-theme;
      pointer-events: none;
      opacity: 0;
      transition: opacity $transition;

      @include inset(-#{math.div($border-width, 2)});
      @include border-radius(inherit);
    }

    &.active {
      &::before {
        opacity: 1;
      }
    }

    &--with-border {
      border: $border-width solid $border-color;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include border-radius(inherit);
    }
  }

  &__previews--achievements &__previews-item {
    img {
      width: 80%;
      height: 80%;
    }
  }
}
