@use "sass:math";

$ngx-slider: '.ngx-slider';
$pointer-size: 20px;
$tick-width: 1px;
$tick-height: 4px;
$tick-height-bold: 6px;
$ngx-slider-bar-height: 8px;
$pointer-lh: 1.25rem;
$pointer-caret-size: 0.375rem;
$pointer-offset: 4px;
$pointer-padding-x: 0.625rem;
$pointer-padding-y: 0.375rem;
$pointer-top-total: calc(#{math.div($ngx-slider-bar-height, 2) + math.div($pointer-size, 2) + $pointer-offset} + #{$pointer-caret-size});
$margin-bottom: calc(#{$pointer-top-total} + #{$pointer-padding-y * 2 + $pointer-lh} - #{$ngx-slider-bar-height});
$ruler-padding: math.div($pointer-size, 2) - $tick-width;
