@use 'sass:map';

.leaderboard {
  $select-width: 140px;
  $table-bg: $var-aside-bg;
  $table-bg-light: $aside-bg-light;
  $table-bg-dark: $aside-bg-dark;
  $gold-color: #FFE46A;
  $silver-color: #BEBEBB;
  $bronze-color: #FCC3A4;

  --rank-gradient-opacity: 0.4;

  @include light-theme-content {
    --rank-gradient-opacity: 1;
  }

  &__table-header {
    @include media-breakpoint-down(lg) {
      gap: 1rem;
    }
  }

  &__actions-container {
    display: flex;
    justify-content: center;
    min-width: 85vw;
    min-height: 40px;

    @include media-breakpoint-up(sm) {
      min-width: unset;
    }
  }

  &__header-actions {
    position: relative;
    min-height: 40px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__select {
    width: $select-width;
    font-weight: $font-weight-medium;

    .twa & {
      border-color: $aside-bg;
      background-color: $aside-bg;
    }
  }

  &__avatar {
    .avatar {
      width: 35px;
      height: 35px;

      @include media-breakpoint-up(sm) {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__user-avatar,
  &__nft-avatar,
  &__level-avatar {
    display: flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: $border-radius;
    background-color: mix($grey, $table-bg-dark, 10%);
    color: rgba($grey, 0.4);

    @include light-theme-content {
      background-color: mix($grey, $table-bg-light, 10%);
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      object-fit: cover;

      @include inset(0);
    }

    &--with-app-item {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }

  &__nft-avatar {
    &-badge {
      $border-width: 3px;
      $offset-top: 2px;
      $offset-left: 2px;

      display: flex;
      position: absolute;
      top: -#{$border-width + $offset-top};
      left: -#{$border-width + $offset-left};
      align-items: center;
      justify-content: center;
      min-width: 16px;
      height: 16px;
      border: $border-width solid $table-bg;
      border-radius: 10rem;
      background-color: $body-bg;
    }
  }

  &__level-avatar {
    img {
      transform: scale(2);
      object-fit: contain;
    }

    &-badge {
      $border-width: 3px;
      $offset-top: 2px;
      $offset-left: 2px;

      display: flex;
      position: absolute;
      top: -#{$border-width + $offset-top};
      left: -#{$border-width + $offset-left};
      align-items: center;
      justify-content: center;
      min-width: 16px;
      height: 16px;
      border: $border-width solid $table-bg;
      border-radius: 10rem;
      background-color: $primary;
      color: color-contrast($primary);
      font-size: $extra-small-font-size;
      font-weight: $font-weight-semibold;
    }
  }

  &__table-value {
    display: flex;
    align-items: center;
    font-size: $font-size-base;

    svg {
      width: 20px;
      height: 20px;
    }

    &--with-app-item,
    &--with-app-item-reverse {
      font-size: $small-font-size;
      font-weight: $font-weight-medium;
      text-align: right;
    }

    &--with-app-item-reverse {
      flex-direction: row-reverse;
    }
  }

  &__currency-value {
    display: inline-flex;
    align-items: center;
    padding: map.get($spacers, 1) map.get($spacers, 2);
    border-radius: 10px;
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;

    &--btc {
      background-color: rgba(#F7931A, .2);
    }

    &--usd {
      background-color: rgba($green, .2);
    }
  }

  &__table {
    .table {
      border-spacing: 0 map.get($spacers, 1);
      border-collapse: separate;

      tbody tr {
        --table-row-ranked-color: transparent;
        --table-row-bg: #{$table-bg};
        --table-row-ranked-bg: var(--table-row-bg) linear-gradient(90deg, var(--table-row-ranked-color), transparent) left center / 105px 100% no-repeat;
        --table-row-ranked-first-td-bg: var(--table-row-bg) linear-gradient(90deg, var(--table-row-ranked-color), var(--table-row-ranked-color)) left center / 100% 100% no-repeat;

        background: var(--table-row-ranked-bg);

        .safari &,
        .firefox & {
          background-color: transparent;
          background-image: none;

          &>td,
          &.rank-me>td {
            background-color: var(--table-row-bg);

            &:first-child {
              // background-color: var(--table-row-ranked-color);
              background: var(--table-row-ranked-first-td-bg);
            }

            &:nth-child(2) {
              background: var(--table-row-ranked-bg);
            }
          }
        }

        &#rank-1 {
          --table-row-ranked-color: #{rgba($gold-color, var(--rank-gradient-opacity, 1))};
        }

        &#rank-2 {
          --table-row-ranked-color: #{rgba($silver-color, var(--rank-gradient-opacity, 1))};
        }

        &#rank-3 {
          --table-row-ranked-color: #{rgba($bronze-color, var(--rank-gradient-opacity, 1))};
        }

        &.rank-me {
          --table-row-ranked-color: #{rgba($primary, .24)};
          --table-row-bg: #{rgba($primary, .08)};
        }

        &.rank-me-outside {
          --table-row-bg: transparent;

          td {
            $border: $border-width solid $var-surface-10;

            border-top: $border;
            border-bottom: $border;

            &:first-child {
              border-left: $border;
            }

            &:last-child {
              border-right: $border;
            }
          }
        }

        td {
          border: none;

          &:first-child {
            @include border-start-radius($border-radius-lg);
          }

          &:last-child {
            @include border-end-radius($border-radius-lg);
          }
        }
      }

      tr th {
        padding: map.get($spacers, 1) map.get($spacers, 2);
      }

      tr td {
        padding: map.get($spacers, 2_5) map.get($spacers, 2);
      }

      tr th,
      tr td {
        &:first-child {
          padding-left: $border-radius-lg !important;
        }

        &:last-child {
          padding-right: $border-radius-lg !important;
        }
      }
    }

    .smart-table__sticky-header {
      border: none;
    }
  }

  &__app-item {
    $padding: map.get($spacers, 3);

    overflow: hidden;
    background-color: $table-bg;

    @include border-radius($border-radius-lg);

    .smart-table-app-item__header {
      margin-bottom: -#{$border-width};
      padding: $padding;
      background-repeat: no-repeat;
      background-size: 105px 100%;
    }

    .smart-table-app-item__body-inner {
      position: relative;
      padding: $padding;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: $padding;
        left: $padding;
        height: $border-width;
        background-color: $var-surface-10;
      }
    }

    & + & {
      margin-top: map.get($spacers, 1);
    }

    &#rank-1 .smart-table-app-item__header {
      background-image: linear-gradient(90deg, rgba($gold-color, var(--rank-gradient-opacity, 1)), transparent);
    }

    &#rank-2 .smart-table-app-item__header {
      background-image: linear-gradient(90deg, rgba($silver-color, var(--rank-gradient-opacity, 1)), transparent);
    }

    &#rank-3 .smart-table-app-item__header {
      background-image: linear-gradient(90deg, rgba($bronze-color, var(--rank-gradient-opacity, 1)), transparent);
    }

    &.rank-me .smart-table-app-item__header {
      background-color: rgba($primary, .08);
      background-image: linear-gradient(90deg, rgba($primary, .24), transparent);
    }

    &.rank-me-outside {
      border: $border-width solid $var-surface-10;
      background-color: transparent;
    }
  }

  &__app-item-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + & {
      margin-top: map.get($spacers, 1_5);
    }
  }

  &__app-item-label {
    margin-right: map.get($spacers, 1);
    color: $grey;
    font-size: $small-font-size;
  }

  &__app-item-value {
    font-size: $small-font-size;
    font-weight: $font-weight-medium;
  }

  &__rank {
    color: $grey;
    font-size: $small-font-size;
    font-weight: $font-weight-medium;

    &--1,
    &--2 {
      font-size: $font-size-sl;
    }

    &--3 {
      font-size: $font-size-lg;
    }

    &--4 {
      font-size: $font-size-base;
    }

    #rank-1 &,
    #rank-2 &,
    #rank-3 &,
    .rank-me & {
      color: $body-color;
    }
  }

  &__badge-image {
    width: 32px;
    height: 32px;

    & + & {
      margin-left: map.get($spacers, 0_5);
    }

    &--with-app-item {
      width: 24px;
      height: 24px;
    }
  }

  &__badge-count {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    min-height: 24px;
    margin-left: map.get($spacers, 0_5);
    border: $border-width solid $var-surface-10;
    border-radius: 10rem;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;

    &--with-app-item {
      min-width: 20px;
      min-height: 20px;
      font-size: $font-size-xxs;
    }
  }
}
