$host: '.app-nav-tabs';

.app-nav-tabs {
  --nav-tabs-bg-color: #{$var-surface-accent};
  --nav-tab-item-bg-color: #{$var-subface-200};
  --nav-tab-item-color: var(--grey);
  --nav-tab-item-hover-color: #{$var-surface};
  --nav-tab-item-active-color: #{$var-surface};
  --nav-tab-item-padding-x: #{$app-nav-tabs-item-padding-x};
  --nav-tab-item-padding-x-extreme: #{$app-nav-tabs-item-padding-x-extreme};
  --nav-tab-item-padding-y: #{$app-nav-tabs-item-padding-y};
  --nav-tabs-padding: #{$app-nav-tabs-item-offset};
  --nav-tabs-border-radius: #{$border-radius};
  --nav-tabs-border-width: 0px;
  --nav-tabs-border-color: var(--nav-tab-item-bg-color);
  --nav-tab-item-border-radius: calc(#{$border-radius} - var(--nav-tabs-padding));

  display: flex;
  position: relative;
  max-width: 100%;
  min-height: 38px;
  padding: calc(var(--nav-tabs-padding) - var(--nav-tabs-border-width)) 0;
  overflow: auto;
  border: var(--nav-tabs-border-width) solid var(--nav-tabs-border-color);
  border-radius: var(--nav-tabs-border-radius);
  background-color: var(--nav-tabs-bg-color);
  scroll-snap-type: x mandatory;

  &--transparent {
    --nav-tabs-bg-color: transparent;
    --nav-tabs-border-width: 1px;
    --nav-tabs-border-color: #{$var-surface-10};
  }

  @include media-breakpoint-up(sm) {
    display: inline-flex;
  }

  &::-webkit-scrollbar { /* chrome based */
    display: none;
  }

  &--loading {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: skeleton-progress 1s ease-in-out infinite;
      background-image: linear-gradient(90deg, rgba($white, 0), rgba($white, 0.3), rgba($white, 0));
    }

    @keyframes skeleton-progress {
      from {
        transform: translate3d(-100%, 0, 0);
      }

      to {
        transform: translate3d(100%, 0, 0);
      }
    }
  }

  &__item {
    display: flex;
    position: relative;
    z-index: 0;
    flex: 0 1 100%;
    align-items: center;
    justify-content: center;
    padding: var(--nav-tab-item-padding-y) var(--nav-tab-item-padding-x);
    transition: $transition;
    transition-property: color, opacity;
    color: var(--nav-tab-item-color);
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    text-align: center;
    white-space: nowrap;
    scroll-snap-align: start;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      transition: opacity $transition;
      border-radius: var(--nav-tab-item-border-radius);
      opacity: 0;
      background-color: var(--nav-tab-item-bg-color);
      box-shadow: 0 3px 8px rgba(#2E2E2E, 0.08);

      @include inset(0);
    }

    &:first-child {
      padding-left: var(--nav-tab-item-padding-x-extreme);

      &::before {
        left: calc(var(--nav-tabs-padding) - var(--nav-tabs-border-width));
      }
    }

    &:last-child {
      padding-right: var(--nav-tab-item-padding-x-extreme);

      &::before {
        right: calc(var(--nav-tabs-padding) - var(--nav-tabs-border-width));
      }
    }

    &:hover,
    &:focus {
      color: var(--nav-tab-item-hover-color);
    }

    &.active {
      color: var(--nav-tab-item-active-color);
      pointer-events: none;

      &::before {
        opacity: 1;
      }
    }

    #{$host}--loading & {
      opacity: 0;
    }
  }
}
