@use "sass:map";
@use "sass:math";

// TODO Проработать темизацию через css переменные
$home-aside-bg: $dark;
$home-info-block-bg-dark: #3A3B47;
$home-info-block-bg-light: $aside-bg;

.home {
  $home-header-height: rem(40px) + map.get($spacers, 2) * 2;
  $home-radius: $border-radius-xl;
  $home-card-padding: $main-offset;

  padding-right: var(--safe-area-inset-right);
  padding-left: var(--safe-area-inset-left);
  background-color: $home-aside-bg;

  .main--inner & {
    margin: main-inner-offset('top', false, -1) main-inner-offset('right', false, -1) main-inner-offset('bottom', false, -1) main-inner-offset('left', false, -1);

    @include media-breakpoint-up(md) {
      margin: main-inner-offset('top', true, -1) main-inner-offset('right', true, -1) main-inner-offset('bottom', true, -1) main-inner-offset('left', true, -1);
    }
  }

  .app-tab-bar--active .main--inner & {
    margin-bottom: -$main-offset;

    @include media-breakpoint-up(md) {
      margin-bottom: -$main-offset-md;
    }
  }

  &__sidebar {
    .sidebar-redesign {
      $host: '.sidebar-redesign';

      --sidebar-max-width: 80%;

      top: 0;
      right: auto;
      left: 0;
      max-width: calc(var(--sidebar-max-width) + var(--safe-area-inset-left));
      padding-top: var(--safe-area-inset-top);
      padding-right: 0;
      padding-bottom: var(--safe-area-inset-bottom);
      transform: translate3d(-100%, 0, 0);

      @media (width >= 400px) {
        --sidebar-max-width: 312px;
      }

      &__container {
        margin-left: 0;
        margin-bottom: 0;
      }

      &__list {
        padding: 0;
        border-radius: 0;
        background: none;
      }

      &__item {
        padding: 0;
      }

      &__link {
        padding: map.get($spacers, 2_5) 0 !important;
        font-weight: $font-weight-medium;
        border-bottom: none !important;
      }

      &__icon {
        color: inherit;
      }
    }
  }

  // TODO Check actual
  &__header,
  &__panel {
    @extend %dark-theme;

    --skeleton-bg: #3e3e42;

    color: $var-surface;
  }

  &__header {
    $padding-y: map.get($spacers, 2);

    position: sticky;
    z-index: $zindex-sticky;
    top: 0;
    height: calc($home-header-height + var(--safe-area-inset-top));
    padding: $padding-y $main-offset;
    padding-top: calc($padding-y + var(--safe-area-inset-top));
    background-color: inherit;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      height: $home-radius;
      box-shadow: -$home-radius 0 $home-aside-bg, $home-radius 0 $home-aside-bg;
      pointer-events: none;

      @include border-top-radius($home-radius);
    }
  }

  &__panel {
    position: sticky;
    z-index: 0;
    top: calc($home-header-height + var(--safe-area-inset-top));
    padding: map.get($spacers, 3) $main-offset map.get($spacers, 4);
  }

  &__content {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    transform: translate3d(0, 0, 0); // Safari hack
    background-color: $aside-bg;

    @include border-top-radius($home-radius);
  }

  &__row {
    margin: -#{map.get($spacers, 1)} 0;
  }

  &__col {
    margin: map.get($spacers, 1) 0;
  }

  &__col:last-child &__card {
    @include border-bottom-radius(0);
  }

  &__card {
    display: block;
    padding: $home-card-padding;
    border-radius: $home-radius;
    background-color: $card-bg;

    &:empty {
      display: none;
    }
  }

  &__btn {
    $padding-y: map.get($spacers, 2) - rem($border-width);
    $padding-x: map.get($spacers, 2) + map.get($spacers, 1) - rem($border-width);

    display: inline-flex;
    align-items: center;
    padding: $padding-y $padding-x;
    border: $border-width solid $var-surface-5;
    border-radius: 10rem;
    color: $body-color;
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;
    line-height: 1;
  }

  &__info-block {
    display: block;
    padding: map.get($spacers, 3) map.get($spacers, 2) + map.get($spacers, 1);
    border-radius: $home-radius;
    background-color: $home-info-block-bg-dark;
    text-align: start;

    @include dir-mark('.light') {
      background-color: $home-info-block-bg-light;
    }
  }

  &__changes-value {
    display: inline-flex;
    align-items: center;
    font-size: $extra-small-font-size;
    font-weight: $font-weight-semibold;
  }

  &__ticker-changes {
    display: none;

    @media (width >= 370px) {
      display: inline-flex;
    }
  }

  &__leaderboards-carousel {
    display: flex;
    margin: 0 -#{$home-card-padding};
    overflow: auto;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar { /* chrome based */
      display: none;
    }
  }

  &__leaderboards-carousel-item {
    flex-shrink: 0;
    scroll-snap-align: start;
    padding: 0 $main-offset;

    &:not(:last-child) {
      margin-right: -#{rem($main-offset * 2) - map.get($spacers, 2)};
    }
  }

  &__leaderboard-info-block {
    display: flex;
    align-items: center;
    padding: map.get($spacers, 2_5);
    font-weight: $font-weight-medium;

    &,
    &:hover,
    &:focus {
      color: $body-color;
    }

    & + & {
      margin-top: map.get($spacers, 1_5);
    }
  }

  &__leaderboard-info-block-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: map.get($spacers, 2);
    background-color: $var-surface-5;
    border-radius: $border-radius;
  }
}

body {
  @include dir-mark('.home-theme') {
    background-color: $home-aside-bg;
  }
}

//<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
//<path d="M11 21C16.5 21 21 16.5 21 11C21 5.5 16.5 1 11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21Z" stroke="#0E0F1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//<path d="M10.9973 15.5L10.9973 10.5" stroke="#0E0F1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//<path d="M11.0031 7.5L10.9941 7.5" stroke="#0E0F1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
//</svg>
//
//<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
//<path d="M6 16.9629L9.28557 19.3962C9.71251 19.8047 10.6592 20 11.3089 20H15.3369C16.6178 20 17.9914 19.0943 18.307 17.8687L20.85 10.4621C21.3883 9.04123 20.4231 7.81572 18.8267 7.81572H14.5759C13.9448 7.81572 13.4064 7.30052 13.5178 6.59007L14.0561 3.33981C14.2603 2.43398 13.6292 1.4038 12.6825 1.10186C11.8286 0.799916 10.7706 1.20847 10.3436 1.81236L6 8.01107" stroke="#0E0F1F" stroke-width="1.5" stroke-miterlimit="10"/>
//<path d="M1 17.0386V6.96159C1 5.52201 1.59649 5 3 5H4C5.40351 5 6 5.52201 6 6.96159V17.0386C6 18.4782 5.40351 19 4 19H3C1.59649 19 1 18.4962 1 17.0386Z" stroke="#0E0F1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//</svg>
//
