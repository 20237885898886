@use 'sass:map';

.modal-with-circle {
  --circle-size: var(--modal-circle-size, 64px);
  --circle-border-size: var(--modal-circle-border-size, 6px);
  --circle-margin: calc((var(--circle-size) + var(--circle-border-size)) / -2) auto 0;
  --#{$prefix}modal-margin: calc((var(--circle-size) + var(--circle-border-size)) / 1.5);
  --#{$prefix}modal-padding: #{map.get($spacers, 3)};

  @include media-breakpoint-up(md) {
    --#{$prefix}modal-padding: #{map.get($spacers, 7)};
  }

  @include media-breakpoint-up(lg) {
    --circle-size: var(--modal-circle-size-xl, 128px);
  }

  &.modal-base {
    @include media-breakpoint-down(md) {
      --circle-border-size: 0;
      --#{$prefix}modal-margin: 0;
      --circle-margin: #{map.get($spacers, 4)} auto 0;
    }
  }

  &__circle {
    display: flex;
    box-sizing: content-box;
    align-items: center;
    justify-content: center;
    width: var(--circle-size);
    height: var(--circle-size);
    margin: var(--circle-margin);
    overflow: hidden;
    border: var(--circle-border-size) solid var(--#{$prefix}modal-bg);
    border-radius: 50%;
    background-color: var(--aside-bg);
  }

  &__body {
    margin-top: calc(var(--circle-border-size) * -1);
    padding-top: #{map.get($spacers, 3)};
  }
}