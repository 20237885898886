img {
  $transition: opacity .3s ease-in;

  &.ng-lazyloading {
    opacity: 0;
  }

  &.ng-failed-lazyloaded,
  &.ng-lazyloaded {
    opacity: 1;
    transition: $transition;
  }
}
