.logo {
  $width: 137px;
  $height: 28px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $width;
  height: $height;

  &--adaptive {
    @include media-breakpoint-down(sm) {
      width: $height;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
