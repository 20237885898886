.promo-card {
  .info-card {
    background-repeat: no-repeat;
    background-position: center right;

    &--static {
      min-height: 190px;
    }

    &--width {
      width: 320px;
    }

    &--unavailable {
      background-image: url("../../../assets/images/promo/task-lock.png");
      background-size: 136px;
    }

    &--bonus-miner {
      background-image: url("../../../assets/images/promo/promo-lightning-bg.png");
      background-size: auto 162px;
    }

    &--gominers {
      background-image: url("../../../assets/images/promo/promo-key-bg.png");
      background-size: auto 162px;
    }

    &--referral, &--liquidity {
      background-image: url("../../../assets/images/promo/promo-gomining-bg.png");
      background-size: auto 162px;
    }

    &--expired, &--unavailable {
      opacity: .6;
    }

    &--expired {
      filter: grayscale(1);
    }
  }

  &__addon {
    background: $blue;
    padding: map-get($spacers, 1_5) map-get($spacers, 2_5);
    text-align: center;
    font-size: $font-size-sm;

    &--unavailable {
      opacity: .6;
    }
  }

  &__category {
    line-height: 2.1;
  }

  &__reward-icon {
    width: $icon-box-size-xs;
    height: $icon-box-size-xs;
  }

  &--basic {
    .info-card {
      background-color: var(--secondary-theme-button-background);
      border: none;
      padding: map-get($spacers, 3);

      &.info-card--expired {
        background: transparent;
        border: 1px dashed $var-surface-10;
      }

      &.info-card--finished {
        background: transparent;
        border: 1px dashed $success;
      }
    }

    .promo-card__addon {
      border: none;
      text-transform: uppercase;
      color: $white;
    }

    .info-card__divider {
      box-shadow: none;
      background: $var-surface-5;
    }
  }
}
