@use "sass:math";
@import "../../vendors/bootstrap/card";

%card-body {
  @extend %card-body-vendor;

  & + .card-body,
  & + .card-footer {
    margin-top: calc(-1 * var(--#{$prefix}card-spacer-y));
    padding-top: var(--#{$prefix}card-spacer-y);
  }

  display: flex;
  flex-direction: column;
  color: $card-body-color;
}

.card {
  height: 100%;
  border-width: 0;
  box-shadow: $card-box-shadow;

  @include input-legend-bg($card-bg);

  @include media-breakpoint-up(md) {
    --#{$prefix}card-cap-padding-y: #{ $card-cap-padding-y-md };
    --#{$prefix}card-cap-padding-x: #{ $card-cap-padding-x-md };
    --#{$prefix}card-spacer-y: #{ $card-spacer-y-md };
    --#{$prefix}card-spacer-x: #{ $card-spacer-x-md };
  }

  .twa & {
    border-width: $card-border-width;
  }

  .smart-table {
    background-color: $card-bg;
  }

  .alert {
    padding: $alert-card-padding-y $alert-card-padding-x;
  }
}

.card-flat {
  &,
  .card-header,
  .card-body,
  .card-footer {
    border: none !important;;
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.card-img-top {
  display: block;
  width: auto;
  margin: -#{$card-border-width};
  margin-bottom: 0;
}

.card-header,
.card-body,
.card-footer {
  border: none;
}

.card-header {
  & + .card-header,
  & + .card-body,
  & + .card-footer {
    margin-top: calc(-1 * var(--#{$prefix}card-spacer-y));
    padding-top: var(--#{$prefix}card-spacer-y);
  }
}

.card-body {
  @extend %card-body;
}

.card-title {
  color: $card-title-color;
  font-size: $card-title-font-size;
  font-weight: $card-title-font-weight;
}

.card-preloader {
  .preloader {
    --preloader-overlay-bg: #{$card-bg};

    border-radius: $card-border-radius;
  }

  .preloader--overlay::before {
    opacity: .9;
  }
}

.card-overlay {
  display: flex;
  position: absolute;
  z-index: 100;
  flex-direction: column;

  @include inset(0);

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    opacity: 1;
    background-color: $card-bg;

    @include inset(0);
    @include border-radius($card-inner-border-radius);
  }
}

.card-main {
  width: 100%;
  max-width: 585px;
  margin: 0 auto;
}

.card-group {
  display: flex;
  flex-wrap: nowrap;
  box-shadow: $card-box-shadow;

  @include border-radius($card-border-radius);

  .card-group-item {
    flex: 1 0 0;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    &:not(:first-child) {
      .card {
        @include border-start-radius(0);

        border-left: math.div($border-width, 2) solid $var-surface-5;
      }
    }

    &:not(:last-child) {
      .card {
        @include border-end-radius(0);

        border-right: math.div($border-width, 2) solid $var-surface-5;
      }
    }
  }

  .card {
    box-shadow: none;
  }
}

.card-tabs {
  margin-bottom: map-get($spacers, 4);

  @include media-breakpoint-up(sm) {
    display: flex;
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    overflow: auto;
    border: 0 solid $card-border-color;
    border-bottom: none;
    background-clip: padding-box;
    background-color: $var-surface-3;
    box-shadow: $card-box-shadow;

    .twa & {
      border-width: $card-border-width;
    }

    @include border-top-radius($card-border-radius);
  }
}

.card-tab {
  display: block;
  position: relative;
  padding: $card-spacer-y $card-spacer-x;
  overflow: hidden;
  border: $card-border-width solid $card-border-color;
  border-radius: $card-border-radius;
  background-color: $card-bg;
  box-shadow: $card-box-shadow;
  color: rgba(var(--surface-rgb), .4);
  font-size: $font-size-lg;
  font-weight: $font-weight-medium;

  & + & {
    margin-top: map-get($spacers, 2);
  }

  &:hover,
  &:focus,
  &.active {
    color: var(--surface);
  }

  &.active {
    border-color: $primary;

    .card-tab-mark {
      display: flex;
    }
  }

  @include media-breakpoint-up(sm) {
    flex: 1;
    overflow: visible;
    border: none;
    text-align: center;

    @include border-bottom-radius(0);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: $border-width;
      opacity: 0;
      background-color: $border-color;
    }

    & + & {
      margin-top: 0;
    }

    &:not(.active) {
      background-color: transparent;
      box-shadow: none;

      & + & {
        &::before {
          opacity: 1;
        }
      }
    }

    &.active {
      border-color: transparent;

      .card-tab-mark {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding: map-get($spacers, 3) $card-spacer-x-md;
  }
}

.card-tab-mark {
  $size: 48px;

  display: none;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;

  //border-top-right-radius: $border-radius;
  overflow: hidden;
  color: $white;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: $size;
    height: $size;
    transform: rotate(45deg) scaleX(1.5) translateY(-50%);
    background-color: $primary;
    box-shadow: $card-box-shadow;
  }

  & > * {
    display: inline-flex;
    position: relative;
    transform: translate(50%, -50%);
  }
}

.card-with-tabs {
  @include media-breakpoint-up(sm) {
    .card,
    .card-preloader .preloader,
    .card-overlay::before {
      border-top: none;

      @include border-top-radius(0);
    }
  }
}

.card--rounded-small {
  border-radius: $spacer;
}

.card--gray {
  background-color: $aside-bg;
}

.card--small {
  @include media-breakpoint-up(md) {
    --#{$prefix}card-spacer-y: #{ calc(map-get($spacers, 1) * 5) };
    --#{$prefix}card-spacer-x: #{ $spacer };
  }
}
