@import "../../vendors/bootstrap/button";

%btn-square___btn {
  min-width: $input-height;
  min-height: $input-height;
}

%btn-square___btn-xs {
  min-width: $input-height-xs;
  min-height: $input-height-xs;
}

%btn-square___btn-sm {
  min-width: $input-height-sm;
  min-height: $input-height-sm;
}

%btn-square___btn-lg {
  min-width: $input-height-lg;
  min-height: $input-height-lg;
}

%btn-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  &.btn {
    @extend %btn-square___btn;
  }

  &.btn-xs {
    @extend %btn-square___btn-xs;
  }

  &.btn-sm {
    @extend %btn-square___btn-sm;
  }

  &.btn-lg {
    @extend %btn-square___btn-lg;
  }
}

%btn-form-plain {
  padding: $input-btn-padding-y $input-btn-padding-x;

  &.btn-square {
    padding: 0;
  }

  &.btn-lg {
    padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
  }
}

%btn {
  @extend %btn-vendor;

  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-clip: border-box;

  &.btn-secondary-theme {
    background-clip: padding-box;
  }
}

input[ngbbutton] {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
}

.btn {
  @extend %btn;
}

.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-border-radius-xs);
}

.btn-block {
  display: flex;
}

.btn-square {
  @extend %btn-square;
}

.btn-circle {
  @extend %btn-square;

  border-radius: 50%;
}

.btn-inline {
  padding: 0;
  border: none;
  border-radius: 0;
  vertical-align: inherit;
}

.btn-form-plain {
  @extend %btn-form-plain;
}

.btn-form {
  @extend %btn-form-plain;
  // @extend .form-rounded;

  --#{$prefix}btn-border-width: #{$input-border-width};
  --#{$prefix}btn-border-color: #{$input-border-color};
  --#{$prefix}btn-hover-border-color: #{$var-surface-20};
  --#{$prefix}btn-active-border-color: #{$var-surface-20};

  padding: $input-btn-padding-y $input-btn-padding-x;

  // ??? применение %btn-form-plain не сгенерило селектор .btn-form.btn-circle
  &.btn-circle {
    padding: 0
  }

  &.active {
    --#{$prefix}btn-active-border-color: #{$input-focus-border-color};
  }
}

@each $color, $value in $button-themes {
  .btn-#{$color}, %btn-#{$color} {
    @include button-variant(
      var(--#{$color}-button-background),
      var(--#{$color}-button-border),
      var(--#{$color}-button-color),
      var(--#{$color}-button-hover-background),
      var(--#{$color}-button-hover-border),
      var(--#{$color}-button-hover-color),
      var(--#{$color}-button-active-background),
      var(--#{$color}-button-active-border),
      var(--#{$color}-button-active-color),
      var(--#{$color}-button-disabled-background),
      var(--#{$color}-button-disabled-border),
      var(--#{$color}-button-disabled-color),
    );
  }
}

@each $color, $value in $button-themes-opacity {
  .btn-#{$color}-opacity {
    @include button-variant-opacity(
      var(--#{$color}-button-opacity-color),
      var(--#{$color}-button-opacity-background),
      var(--#{$color}-button-opacity-hover-background),
      var(--#{$color}-button-opacity-active-background),
    );
  }
}

@each $color, $value in $button-themes {
  .btn-outline-#{$color} {
    @include button-outline-variant(
        $value,
        var(--#{$color}-button-color),
        var(--#{$color}-button-active-background),
        var(--#{$color}-button-active-border),
        var(--#{$color}-button-active-color),
        var(--#{$color}-rgb)
    );
  }
}
