@mixin colored-links($color, $hover-color: shift-color($color, $link-shade-percentage)) {
  color: $color !important;

  @if $link-shade-percentage != 0 {
    &:hover,
    &:focus {
      color: $hover-color !important;
    }
  }
}
