.modal-auth-starter {
  --#{$prefix}modal-width: 600px;

  &__step {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
    align-items: center;
    text-align: center;
    max-width: 110px;

    @include media-breakpoint-up(sm) {
      max-width: 140px;
    }
  }

  &__step-decor {
    overflow: hidden;
    flex-shrink: 0;
    flex-grow: 0;
    width: 72px;
    height: 72px;
    background: linear-gradient(135deg, #7540EF 4.11%, #FFFFFF 100%);
    border-radius: $border-radius-lg;

    @include media-breakpoint-up(sm) {
      width: 120px;
      height: 120px;
    }
  }

  &__step-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: screen;
  }

  &__divider {
    display: none;
    align-items: center;
    justify-content: center;
    color: rgba($primary, .4);

    @include media-breakpoint-up(sm) {
      display: flex;
      height: 120px;
    }
  }
}
