@import "index";

.app-divider {
  $gap: 4px;
  $offset: 20px;
  $size: 4px;

  position: relative;
  z-index: -2;
  height: ($offset - $gap) * 2 + $size;
  margin: 0 main-inner-offset('right', false, -1) 0 main-inner-offset('left', false, -1);
  background-color: $var-aside-bg;

  @include media-breakpoint-up(md) {
    margin: 0 main-inner-offset('right', true, -1) 0 main-inner-offset('left', true, -1);
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    width: 100%;
    height: $offset;
    background-color: $var-body-bg;
  }

  &::before {
    top: 0;
    margin-top: -#{$gap};
    border-radius: 0 0 $offset $offset;
  }

  &::after {
    bottom: 0;
    margin-bottom: -#{$gap};
    border-radius: $offset $offset 0 0;
  }

  &--overlay-sticky {
    z-index: $zindex-sticky + 1;

    &::before,
    &::after {
      z-index: $zindex-sticky + 2;
    }
  }
}