@use "sass:map";
@use "sass:math";

@import "../../vendors/bootstrap/toasts";

$toast-icon-size: 24px;
$toast-icon-size-real: 20px;
$toast-icon-offset: map.get($spacers, 2) + map.get($spacers, 1);
$toast-progress-bar-size: 4px;

.toasts-container {
  $padding: 0.5em;

  position: fixed;
  z-index: 1200;
  top: 0;
  right: 0;
  max-width: 100%;
  padding: calc(#{$padding} + var(--safe-area-inset-top)) calc(#{$padding} + var(--safe-area-inset-right)) calc(#{$padding} + var(--safe-area-inset-bottom)) calc(#{$padding} + var(--safe-area-inset-left));
}

%toast-with-icon {
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: calc($toast-padding-x - map.get($spacers, 1));
    top: 50%;
    width: $toast-icon-size;
    height: $toast-icon-size;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  &::after {
    transform: translateY(-50%) scale(math.div($toast-icon-size-real, $toast-icon-size));
  }

  .toast-header,
  .toast-body {
    padding-left: calc($toast-padding-x + rem($toast-icon-size) + $toast-icon-offset);
  }
}

.toast {
  $toast-colors: (
    'success': 'success',
    'error': 'danger',
    'warning': 'warning',
  );

  @each $color, $value in $toast-colors {
    $color-value: map.get($theme-colors, $value);

    &.toast-#{$color} {
      @extend %toast-with-icon;

      &::before {
        background-color: $color-value;
        mask-size: $toast-icon-size;
        mask-image: url('../../img/alert/#{$color}.svg');
      }

      &::after {
        box-shadow: 0 0 0 6px rgba($color-value, .12);
      }

      .toast-progress-bar {
        display: block;
        background-color: $color-value;
      }
    }
  }

  & + & {
    margin-top: map-get($spacers, 2);
  }
}

.toast-header {
  justify-content: space-between;
  padding-bottom: map.get($spacers, 1);

  strong {
    font-weight: $font-weight-semibold;
  }

  .close {
    margin-bottom: 0 !important;
    margin-left: map-get($spacers, 3) !important;
    font-size: 1.25rem;
    line-height: 1;
  }

  & + .toast-body {
    padding-top: 0;
  }
}

.toast-body {
  color: $var-surface-80;
  font-size: $small-font-size;
  padding-top: calc($toast-padding-y + $toast-progress-bar-size);
  padding-bottom: calc($toast-padding-y + $toast-progress-bar-size);
}

.toast-progress-bar {
  --animation-duration: 0ms;

  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: $toast-progress-bar-size;
  border-radius: 10rem;
  animation: toastProgressBar var(--animation-duration) linear forwards;

  @keyframes toastProgressBar {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
}
