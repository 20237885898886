@include game-style {
  $breakpoints: map-merge($grid-breakpoints, (
  xs: 0,
  sm: 370px,
));

%description-game {
  font-family: $font-family-description-game;
  font-weight: 500;
}

%text-shadow {
  text-shadow: 1px 2px 0 $black, -1px 2px 0 $black, $text-shadow-base;
}

.miner-wars-onboarding, .miner-wars, .modal-controller, .font-game {
  font-family: $font-family-base-game;
  font-weight: 400;
}

.description-game {
  @extend %description-game;
}

.font-size-base-em {
  font-size: em($font-size-base);
}

.hint-game {
  @extend %description-game;
  opacity: 0.5;
}

.text-shadow {
  @extend %text-shadow;
  //-webkit-text-stroke-width: 1px;
  //-webkit-text-stroke-color: $black;
  //
  //@supports (paint-order: stroke) {
  //  text-shadow: 1px 2px 0 $black, -1px 2px 0 $black;
  //  -webkit-text-stroke-width: 2px;
  //  paint-order: stroke;
  //  text-rendering: geometricPrecision;
  //}
}

.circle-text-divider {
  width: 3px;
  height: 3px;
  background-color: $white;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;

  &--with-shadow {
    border: 1px solid $black;
    box-shadow: 0 1px 0 $black;
  }
}

.small-sm {
  @include media-breakpoint-down(sm, $breakpoints) {
    font-size: 0.75rem !important;
  }
}

.text-transform-none {
    text-transform: none !important;
  }
}
