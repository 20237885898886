// @import "~@angular/cdk/text-field";
@use 'sass:map';
@import "@angular/cdk/text-field-prebuilt.css";

$host: '.auth-layout';

.auth-layout {
  $auth-content-padding: $spacer * 3;

  --offset: #{map.get($spacers, 3)};
  --offset-top: #{map.get($spacers, 4)};
  --offset-bottom: #{map.get($spacers, 4)};

  @include use-element-base-props;
  @include element-base($dark);

  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  min-height: 100vh;
  min-height: 100dvh;
  padding: calc(var(--offset-top) + var(--safe-area-inset-top)) calc(var(--offset) + var(--safe-area-inset-right)) calc(var(--offset-bottom) + var(--safe-area-inset-bottom)) calc(var(--offset) + var(--safe-area-inset-left));

  @include input-legend-bg($var-subface);

  @include light-theme-content {
    @include element-base(#F5F6F8);
  }

  @include media-breakpoint-up(md) {
    --offset: #{map.get($spacers, 4)};
    --offset-top: #{map.get($spacers, 4)};
    --offset-bottom: #{map.get($spacers, 4)};
  }

  .locale-switcher {
    display: flex;
  }

  &--full-screen {
    min-height: unset;

    @include element-base($var-subface);

    @include media-breakpoint-up(md) {
      padding: $auth-content-padding;
    }

    @include light-theme-content {
      @include element-base($var-subface);
    }
  }

  .modal-base & {
    background-color: transparent;
  }

  &__background {
    @include inset(0);

    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.ng-lazyloaded {
      transition-duration: 1s;
      opacity: 0.4;

      @include light-theme-content {
        opacity: 1;
      }
    }

    &--loaded {
      opacity: 0.4 !important;

      @include light-theme-content {
        opacity: 1 !important;
      }

      &.ng-lazyloaded {
        transition: none;
      }
    }
  }

  &__header {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;

    &--sub {
      @include media-breakpoint-down(md) {
        margin-top: map.get($spacers, 4);
      }
    }
  }

  &__title {
    margin-bottom: 0;
    text-align: center;
  }

  &__back {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
  }

  &__util {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &--full-screen &__locale-switcher {
    .locale-switcher__btn {
      @extend %btn-secondary-theme;
    }
  }

  &:not(&--full-screen) &__locale-switcher {
    .locale-switcher__btn {
      padding-right: map.get($spacers, 3);
      padding-left: map.get($spacers, 3);

      $btn-name: 'switcher';

      @include button-variant(
        var(--#{$btn-name}-button-background),
        var(--#{$btn-name}-button-border),
        var(--#{$btn-name}-button-color),
        var(--#{$btn-name}-button-hover-background),
        var(--#{$btn-name}-button-hover-border),
        var(--#{$btn-name}-button-hover-color),
        var(--#{$btn-name}-button-active-background),
        var(--#{$btn-name}-button-active-border),
        var(--#{$btn-name}-button-active-color),
        var(--#{$btn-name}-button-disabled-background),
        var(--#{$btn-name}-button-disabled-border),
        var(--#{$btn-name}-button-disabled-color),
      );
      @include --color-button($btn-name, $var-surface-5, ('color': $white, 'hover-background': $var-surface-10, 'active-background': $var-surface-15));

      @include light-theme-content {
        @include --color-button($btn-name, $white);
      }
    }
  }

  &__content {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: 536px;
    margin: auto;
    padding-top: map.get($spacers, 5);

    #{$host}--full-screen & {
      justify-content: flex-start;
      margin-top: 0;
      padding-top: 0;
    }

    .card {
      &, .preloader {
        border-radius: 2rem;
      }
    }

    @include media-breakpoint-up(md) {
      justify-content: center;

      .card-header,
      .card-body {
        padding-top: $auth-content-padding;
        padding-right: $auth-content-padding;
        padding-left: $auth-content-padding;
      }

      .card-body {
        padding-bottom: $auth-content-padding;
      }
    }
  }

  &__logo {
    position: relative;
    flex-grow: 0;
    margin: 0 map.get($spacers, 2);

    .logo {
      width: 137px;
      height: 28px;

      image-logo {
        width: auto;
        height: 100%;
      }
    }
  }

  &__desc {
    margin-top: map.get($spacers, 2);
    padding-top: map.get($spacers, 1);
    color: $var-surface-50;
    text-align: center;
  }

  &__provider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 0;

    @include light-theme-content {
      $active-color: rgba($var-primary-theme-rgb, 0.08);

      --#{$prefix}btn-hover-bg: #{$active-color};
      --#{$prefix}btn-active-bg: #{$active-color};
      --#{$prefix}btn-hover-border-color: #{$active-color};
      --#{$prefix}btn-active-border-color: #{$active-color};
    }
  }

  &__provider-img {
    display: flex;
    width: 24px;
    height: 24px;
  }

  &__providers-btn {
    $btn-border-color: $var-surface-10;
    $btn-border-color-hover: $var-surface-20;
    $btn-bg: #fff0;
    $btn-color: $var-surface;

    @include button-variant(
      $btn-bg,
      $btn-border-color,
      $btn-color,
      $btn-bg,
      $btn-border-color-hover,
      $btn-color,
      $btn-bg,
      $btn-border-color-hover,
      $btn-color
    );

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: map.get($spacers, 3);
    padding-left: map.get($spacers, 3);
    border-color: $var-surface-10;
  }

  &__providers-image {
    color: $var-grey;
    font-size: $font-size-sm;
    font-weight: $font-weight-semibold;

    &--count {
      background-color: $var-aside-bg;

      .modal-base & {
        background-color: $var-surface-accent;
      }
    }
  }

  &__footer {
    --padding: #{map.get($spacers, 5)} #{map.get($spacers, 7)} 0;

    position: relative;
    padding: var(--padding);
    color: $var-grey;
    font-size: $font-size-sm;
    text-align: center;

    #{$host}--full-screen & {
      --padding: #{map.get($spacers, 4)} 0 0;
    }
  }

  &__footer-content {
    width: 100%;
    max-width: 536px;
    margin: auto;
  }
}
