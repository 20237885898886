@use "sass:map";

.group-elements {
  display: flex;
  align-items: center;
  margin: 0 -#{map.get($spacers, 1)};

  &__item {
    margin: 0 map.get($spacers, 1);
  }

  &__divider {
    display: inline-block;
    align-self: stretch;
    width: $border-width;
    min-height: 100%;
    margin: 0 map.get($spacers, 3) - map.get($spacers, 1);
    background-color: $var-surface-10;
    border: none;
    opacity: 1;
  }
}
