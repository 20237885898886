@import 'bootstrap/scss/helpers/colored-links';

@each $color, $value in $themes {
  .link-#{$color} {
    @include colored-links(
      var(--#{$color}-link-color),
      var(--#{$color}-link-hover-color),
    );
  }
}

.link-grey {
  @include colored-links($grey);
}

.link-body {
  @include colored-links($body-color, var(--primary-theme-link-hover-color));
}
