@use "sass:map";
@import "index";

.card-token-buy {
  &__rate {
    min-width: 20px;
    min-height: 20px;
  }

  &__action-list {
    display: flex;
    align-items: center;
    margin: -#{map.get($spacers, 1)};
  }

  &__action-list--fill &__action-item {
    flex-grow: 1;
    padding: map.get($spacers, 2);
  }

  &__action-item {
    margin: map.get($spacers, 1);
    padding: map.get($spacers, 2) map.get($spacers, 3);
    font-weight: $font-weight-medium;
    background-color: $aside-bg;
    border-radius: $border-radius;
    transition: opacity $transition;

    &:disabled {
      opacity: .5;
    }

    &--selected {
      background-color: var(--primary-theme-button-opacity-background);
    }
  }
}
