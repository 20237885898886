@use "sass:math";

.modal-nft-list-investment {
  $offset: map-get($spacers, 2) + map-get($spacers, 1);

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: -#{math.div($offset, 2)};
  }

  &__col {
    flex: 0 0 auto;
    width: 100%;
    padding: math.div($offset, 2);

    @include media-breakpoint-up(sm) {
      width: math.div(100%, 2);
    }

    @include media-breakpoint-up(lg) {
      width: math.div(100%, 4);
    }

    @include media-breakpoint-up(xl) {
      width: math.div(100%, 5);
    }
  }

  &__card {
    display: block;
    padding: $offset;
    background: var(--aside-bg);
    border: $border-width solid $var-surface-10;
    border-radius: $border-radius;
  }

  &__card-image {
    width: 100%;
    padding-bottom: 100%;
    background: no-repeat center/cover var(--nft-image-url);
    border-radius: $border-radius-sm;
  }

  &__card-footer {
    margin-top: $offset;
    padding-top: map-get($spacers, 1);
    border-top: $border-width solid $var-surface-10;
  }
}
