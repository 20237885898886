@use "sass:math";

.card-nft-invites-banner {
  .card {
    overflow: hidden;
    border: none;
    background: linear-gradient(135deg, #7540EF 1.5%, #EB7DAF 72.78%, #F07D7D 91.78%);
  }

  .card-body {
    color: $white;
  }

  &__content {
    font-weight: $font-weight-medium;
    font-family: $font-family-headline;
    line-height: 1.2;
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: $lead-font-size;
    font-weight: $font-weight-bolder;
    text-transform: uppercase;
    border-radius: 50%;
    border: 1px dashed rgba($white, 0.2);

    &--fill {
      border: none;
      background: rgba($white, 0.2);
    }
  }

  &__copy {
    position: relative;

    &.copied {
      &:before {
        transform: translate(-50%, -20px);
        opacity: .6;
        transition: all .3s ease-out;
      }
    }

    &:before {
      content: attr(data-copied);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      opacity: 0;
      transition: none;
    }
  }

  &__btn {
    @include button-variant($white, $white);
  }

  &__total {
    $height: 70px;

    position: relative;
    overflow: hidden;
    height: $height;
    padding: map-get($spacers, 2) + map-get($spacers, 1);
    padding-right: math.div($height, 2);
    background: rgba($black, .3);
    border-radius: $border-radius;

    &::after {
      content: '';
      position: absolute;
      width: $height;
      top: 0;
      bottom: 0;
      right: rem(math.div(-$height, 2)) + $border-radius;
      background: rgba($black, .2) url('../../../assets/images/nft/total-income-btc.svg') no-repeat center;
      background-position-x: $border-radius;
      border-radius: 50%;
    }
  }

  &__decor-owner {
    height: 100px;

    &-image {
      position: absolute;
      left: -7px;
      top: 5px;
      mix-blend-mode: screen;
      max-width: 220px;

      @include ltr() {
        transform: rotate(10deg);
      }

      @include rtl() {
        transform: scaleX(-1) rotate(10deg);
      }
    }
  }

  &__decor-user {
    max-width: 176px;
    margin: 0 auto;
    margin-bottom: 30px;

    &-image {
      width: 100%;
      mix-blend-mode: screen;
    }
  }

  &__decor-miner {
    display: flex;
    justify-content: center;
    margin: -#{$card-spacer-y} -55px;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      margin: -#{$card-spacer-y-md} -55px;
      margin-bottom: 0;
    }

    &-image {
      width: 100%;
      max-width: 350px;
    }
  }

  &--gradient {
    .card {
      background: linear-gradient(135.39deg, #7540EF 4.81%, #A882FF 45.69%, #CDCDD2 93.01%);
    }
  }

  &--landscape {
    min-height: 200px;

    .card-body {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &--landscape &__decor-owner {
    height: 0;

    &-image {
      left: unset;
      top: auto;
      bottom: -80px;
      right: 55px;
      max-width: 300px;

      @include ltr() {
        transform: rotate(0deg);
      }

      @include rtl() {
        transform: scaleX(-1) rotate(0deg);
      }
    }
  }

  &--landscape &__decor-user {
    position: absolute;
    right: 130px;
    bottom: -50px;
    margin-bottom: 0;
    max-width: 200px;
  }

  &--landscape &__decor-miner {
    position: absolute;
    right: 65px;
    bottom: -65px;
    margin: 0;
  }
}
