@use "sass:map";

$tooltip-zindex: $zindex-modal - 1;
$main-zindex: $zindex-modal - 2;
$overlay-zindex: $zindex-modal - 3;

$intro-arrow-width: 30px;

.intro {
  position: absolute;
  z-index: $main-zindex;
  top: 0;
  transition: all .5s ease-in-out;
  will-change: width, height, transform;

  @include dir-ignore {
    left: 0;
  }

  &__arrow {
    height: 100%;
    max-height: 41px;
    width: 30px;
    animation: bounce .3s linear infinite alternate;
  }

  &__arrows-container {
    z-index: $tooltip-zindex;
    position: absolute;
    max-height: 74px;
    top: 0;
    @include dir-ignore {
      left: 0;
    }
    display: flex;
    justify-content: space-between;
    padding: map-get($spacers, 3) calc(calc($boost-button-width / 2) + $intro-boost-offset - calc($intro-arrow-width / 2));
    opacity: 0;
    animation: fadeIn .5s ease-in-out;
    animation-delay: .5s;
    animation-fill-mode: forwards;
  }

  &--clickable {
    pointer-events: all;
  }

  &--disable-scroll {
    overflow: hidden;
  }

  &__overlay {
    position: absolute;
    z-index: $overlay-zindex;

    @include inset(0);
  }

  &__target {
    width: 100%;
    height: 100%;
    transition: all .5s ease-in-out;
    box-shadow: 0 0 0 5000px rgba($black, .4);
  }

  &__clickable-element {
    position: relative;
    z-index: $tooltip-zindex;
  }

  &__tooltip {
    z-index: $tooltip-zindex;
    box-sizing: content-box;
    width: 295px;
    transition: none !important;
    font-family: inherit;
    font-size: $font-size-base;
    text-align: center;

    @include media-breakpoint-up(sm) {
      width: 310px;
    }

    &.show {
      pointer-events: auto !important;
    }

    .tooltip-arrow {
      z-index: 2;
    }

    .tooltip-inner {
      --#{$prefix}tooltip-padding-y: #{map.get($spacers, 3_5)};
      --#{$prefix}tooltip-padding-x: #{map.get($spacers, 3_5)};
      --#{$prefix}tooltip-border-radius: #{map.get($spacers, 4)};

      position: relative;
      box-sizing: border-box;
      margin: -1px; // Фикс когда каретка уходит за края

      .close {
        @extend %close;

        position: absolute;
        z-index: 3;
        top: map.get($spacers, 2);
        right: map.get($spacers, 2);
      }
    }

    &--game {
      width: 280px;

      @include media-breakpoint-up(sm) {
        width: 295px;
      }

      .tooltip-arrow {
        --#{$prefix}tooltip-bg: #{ $primary-game };
      }

      .tooltip-inner {
        $padding: #{ map.get($spacers, 2) + map.get($spacers, 1) };

        @include card-game-border;
        @include card-game($padding);
      }
    }
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}
