$host: '.running-line';

.running-line {
  --animation-duration: var(--duration, 10s);

   @keyframes run {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(calc(-100%));
    }
  }

  display: flex;
  visibility: hidden;
  position: relative;
  overflow: hidden;
  transition: opacity $transition, visibility $transition;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;

  &--init {
    visibility: visible;
    opacity: 1;
  }

  &__wrapper {
    display: flex;
    animation: run var(--animation-duration) linear infinite;
    white-space: nowrap;

    #{$host}--paused & {
      animation-play-state: paused;
    }
  }
}


.running-line-simple {
  @keyframes run-with-pause {
    0%, 15% {
      transform: translateX(0);
    }
    85%, 100% {
      transform: translateX(var(--running-line-move-distance));
    }
  }

  &--normal {
    animation: run-with-pause var(--running-line-duration) linear infinite;
  }

  &--alternate {
    animation: run-with-pause var(--running-line-duration) linear infinite alternate;
  }
}
