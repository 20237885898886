@use "sass:map";
@use "sass:math";

.post-snippet {
  display: flex;

  &, &:hover {
    color: $body-color;
  }

  &__image,
  &__skeleton-image {
    flex-shrink: 0;
    align-self: flex-start;
    width: 128px;
    height: 72px;
    border-radius: $border-radius;
    margin-right: map.get($spacers, 2) + math.div(map.get($spacers, 1), 2);
  }

  &__image {
    object-fit: cover;
  }
}
