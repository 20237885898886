
@use 'sass:map';

$dashboard-card-selector: '.ve-dashboard__card';

.ve-my-lock {
  .contract-provider {
    --contract-provider-logo-size: 48px;
    --contract-provider-border-radius: #{map.get($spacers, 3) + map.get($spacers, 1)};
    --contract-provider-padding: #{map.get($spacers, 3)};

    @include media-breakpoint-up(md) {
      --contract-provider-border-radius: #{map.get($spacers, 4)};
      --contract-provider-padding: #{map.get($spacers, 4)};
    }

    @include light-theme-content {
      --contract-provider-bg-hover-color: #{rgba($var-grey-rgb, .1)};
    }
  }

  &__card {
    --width: 584px;
    --padding: #{map.get($spacers, 4)};
    --radius: #{map.get($spacers, 4)};
    --border: 0;
    --bg-color: #{$card-bg};

    position: relative;
    width: 100%;
    max-width: var(--width);
    padding: var(--padding);
    border: var(--border);
    border-radius: var(--radius);
    background-color: var(--bg-color);

    .twa & {
      --border: 1px solid #{$var-surface-10};
    }

    @include media-breakpoint-up(md) {
      --padding: #{map.get($spacers, 5)};
      --radius: #{map.get($spacers, 5)};

      // --border: 1px solid #{$var-surface-10};
    }

    @include media-breakpoint-up(lg) {
      --padding: #{map.get($spacers, 7)};
    }

    &--sm, &--xs {
      --width: 100%;
      --padding: #{map.get($spacers, 3)};
      --radius: #{map.get($spacers, 3) * 1.25};
      --border: 1px solid #{$var-surface-10};

      @include media-breakpoint-up(md) {
        --padding: #{map.get($spacers, 4)};
        --radius: #{map.get($spacers, 4)};
      }
    }

    &--sm {
      --padding: #{map.get($spacers, 4)};
      --radius: #{map.get($spacers, 3) + map.get($spacers, 1)};
    }

    &--single {
      --width: 100%;
      --padding: #{map.get($spacers, 3) * 1.25} #{map.get($spacers, 3)};
      --radius: #{map.get($spacers, 3)};
    }

    &--gray {
      --bg-color: var(--body-bg);
      --border: 0;

      .twa & {
        --bg-color: var(--aside-bg);
      }
    }

    &--pending {
      min-height: 200px;
    }
  }

  &__label {
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    text-transform: none;
  }

  &__col {
    flex-basis: 100%;
    flex-grow: 1;

    &--main {
      @include media-breakpoint-up(sl) {
        flex-basis: calc(100% / 3);
      }
    }

    &--actions {
      @include media-breakpoint-up(sl) {
        flex-basis: calc(200% / 3);
      }
    }

    #{$dashboard-card-selector}-action {
      font-size: $font-size-sm;
      white-space: nowrap;

      svg {
        width: 7px;
        height: 14px;
      }
    }
  }

  &__value-icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: $white;

    &--orange {
      background-color: $orange;
    }

    &--green {
      background-color: $green;
    }
  }

  &__extra {
    margin-top: map.get($spacers, 1) * .5;
    color: $green;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    text-align: right;

    &::before {
      content: '+';
      display: inline;
    }
  }
}
