@use "sass:map";

@mixin form-control-game-status($color) {
  --game-control-border-color: #{$color};

  .input-group-info {
    color: $color;
  }
}

.form-control-game {
  --game-control-border-color: #{$var-surface};

  display: flex;
  flex-direction: column;

  .form-label {
    color: $var-surface;
    font-size: $font-size-lg;
    line-height: 1.25;
  }

  &:focus-within {
    --game-control-border-color: #{$primary-game};
  }

  &--invalid {
    @include form-control-game-status($danger-game)
  }

  &--disabled {
    .form-label, .input-group-info {
      opacity: $disabled-opacity;
    }
  }

  &--success {
    @include form-control-game-status($success-game)
  }

  .input-group-info {
    margin-top: map.get($spacers, 2);
    margin-bottom: 0;
    font-size: $font-size-sm;
    line-height: 1.5;
  }

  .prepend > .form-control {
    padding-left: 0.5rem;
  }

  .append, .prepend {
    border: none;

    .input-group-append, .input-group-prepend {
      border-top-width: 2px;
      border-bottom-width: 2px;
      border-color: var(--game-control-border-color);
      background: $var-surface-1;
    }

    .input-group-append {
      border-left-width: 2px;
      border-right-width: 2px;
    }

    .input-group-prepend {
      border-right-width: 2px;
      border-left-width: 2px;

      &:not(:empty) {
        padding-left: 0.75rem;
      }
    }

    & .input-group-append {
      border-left: none;
    }

    & .input-group-prepend {
      border-right: none;
    }
  }

  .form-control {
    transition: border-right-color $transition, border-top-color $transition, border-bottom-color $transition, border-left-color $transition;
    border-width: 2px;
    border-color: var(--game-control-border-color);
    background: $var-surface-1;


    &:focus ~ .input-group-append, &:focus ~ .input-group-prepend {
      border-color: var(--game-control-border-color);
    }
  }
}

.form-check-game {
  padding-left: calc($form-check-size + .375rem);
  border-radius: .325rem;
}

@include game-style {
  .form-check {
    @include form-check-colors($white, $white, $white, $white);
  }

  .ng-dropdown-panel {
    --#{$prefix}dropdown-bg: #{$primary-game};
    --#{$prefix}dropdown-item-padding-y: #{map-get($spacers, 2_5)};
    --#{$prefix}dropdown-item-padding-x: #{map-get($spacers, 2_5)};

    width: auto;

    .ng-dropdown-panel-items {
      max-height: none !important;
    }
  }

  .ng-select-dropdown-align-end {
    .ng-dropdown-panel {
      right: 0;
      left: unset;
    }
  }
}
