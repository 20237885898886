@import "../../vendors/bootstrap/pagination";

.pagination {
  margin-bottom: 0;
  align-items: center;

  .sr-only {
    display: none;
  }
}

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 36px;
  min-width: 36px;
  border-radius: $pagination-border-radius;

  &[aria-label="Next"],
  &[aria-label="Previous"] {
    padding-left: 0;
    padding-right: 0;
    color: $pagination-color;
  }
}

.page-item {
  transition: opacity $transition;

  //&.active {
  //  .page-link {
  //    cursor: default;
  //    color: $white
  //  }
  //}

  &:first-child,
  &:last-child {
    opacity: .5;
  }

  &.disabled .page-link {
    cursor: default;
    color: $pagination-disabled-color;
  }
}
