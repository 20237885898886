.modal-winner-clan {
  max-width: 615px !important;

  &__card {
    padding: 0;
    background-color: transparent;
  }

  &__preloader-clan-lines {
    position: relative;
    min-height: 83px; // TODO костыль
  }

  .modal-content {
    overflow: hidden;
    transform: translateZ(0);
  }

  .modal-body {
    padding-top: $modal-inner-padding-y + map-get($spacers, 2);
  }
}
