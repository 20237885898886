@use "sass:map";

$icon-size: 20px;
$icon-size-md: 28px;
$icon-offset: map.get($spacers, 1);
$icon-offset-md: map.get($spacers, 2);

.card-indicator {
  .card-body {
    color: $body-color;
  }

  &__icon {
    margin-right: $icon-offset;

    @include media-breakpoint-up(md) {
      margin-right: $icon-offset-md;
    }

    &, > * {
      display: inline-flex;
    }

    svg {
      width: $icon-size;
      height: $icon-size;

      @include media-breakpoint-up(md) {
        width: $icon-size-md;
        height: $icon-size-md;
      }
    }
  }

  &__icon-list {
    display: flex;
    align-items: center;
  }

  &__icon-list-item {
    & + & {
      position: relative;
      z-index: 1;
      margin-left: -#{map.get($spacers, 2)};

      @include media-breakpoint-up(sm) {
        margin-left: -#{map.get($spacers, 1)};
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: $card-bg;
        border-radius: 50%;

        @include inset(-2px);
      }
    }
  }

  &__title {
    margin-bottom: map.get($spacers, 2);
    color: $var-grey;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
  }

  balance-viewer {
    max-width: calc(100% - #{$icon-offset} - #{$icon-size});

    @include media-breakpoint-up(md) {
      max-width: calc(100% - #{$icon-offset-md} - #{$icon-size-md});
    }
  }

  .balance-viewer {
    &__total,
    &__currency {
      border-bottom: 0;
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      line-height: 1.4;

      @include media-breakpoint-up(md) {
        font-family: $font-family-headline;
        font-size: $font-size-sl;
        font-weight: $font-weight-medium;
      }
    }
  }
}
