%modal-calculation__formula {
  //padding-bottom: 2rem;
  font-size: .625rem;

  @include media-breakpoint-up(sm) {
    font-size: .875rem;
  }
}

%modal-calculation__formula-line {
  min-height: 3rem;
}

%modal-calculation__equal {
  color: $success;
  font-weight: 600;
  white-space: nowrap;
}

.modal-calculation {
  @include media-breakpoint-down(sm) {
    &--nft-root {
      z-index: $zindex-fixed - 1;
      inset: $page-top-height 0 0 0;
      height: auto;
      padding: 0;

      .app-tab-bar--active & {
        bottom: $tab-bar-height;
      }

      .modal-content {
        padding: var(--safe-area-inset-top) var(--safe-area-inset-right) var(--safe-area-inset-bottom) var(--safe-area-inset-left);

        .page-top--active & {
          padding-top: 0;
        }

        .app-tab-bar--active & {
          padding-bottom: 0;
        }
      }
    }

    &--nft {
      width: 100vw;
      min-width: 100vw;
      max-width: 100vw;
      height: 1px;
      min-height: 100%;
      margin: 0;

      .modal-content {
        min-height: 100%;
        border-radius: 0;
        background-clip: border-box;

        .modal-header,
        .close {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 680px;
  }

  &--auto-width {
    max-width: 100%;
  }

  .modal-body {
    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }
  }

  &__formula {
    @extend %modal-calculation__formula;
  }

  &__formula-line {
    @extend %modal-calculation__formula-line;
  }

  &__required-label[data-label],
  &__formula > span[data-label],
  &__formula-line > span[data-label] {
    display: inline-block;
    position: relative;
    padding-bottom: 2rem;

    &::after {
      content: attr(data-label);
      position: absolute;
      top: 1rem;
      left: 0;
      color: $var-surface-50;
      font-size: .625rem;
      font-weight: normal;

      @include media-breakpoint-up(sm) {
        top: 1.4rem;
      }
    }
  }

  &__equal {
    @extend %modal-calculation__equal;
  }

  &__block {
    font-size: .875rem;
  }

  &__label {
    min-width: 70px;
    color: $var-surface-50;
    font-size: 0.75rem;
    line-height: 1.3;
  }

  &__separator {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $var-surface-10;
  }

  &__value {
    font-size: 0.875rem;

    //color: $body-color;

    &--txid {
      max-width: 90%;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  &__comment {
    color: $var-surface-50;
    font-size: .625rem;
    font-weight: 400;
    line-height: 1rem;
  }

  &__real {
    font-size: .75rem;
  }

  &__mark {
    color: $danger;
    font-weight: 600;
    text-decoration: line-through;
  }

  &__legend {
    font-size: .75rem;
  }

  &__legend-label {
    color: $var-surface-50;
  }

  &__legend-code {
    width: 3.25rem;
    padding: .25rem .75rem;
    border-radius: .25rem;
    background-color: $var-surface-5;
    text-align: center;
  }

  &__tooltip {
    max-width: 280px;
    padding: .5rem .75rem;
  }

  &__nft-box {
    width: 100%;
  }

  &__nft-img {
    width: 6rem;
    height: 6rem;
    overflow: hidden;
    border-radius: 1rem;

    @include media-breakpoint-up(sm) {
      width: 8rem;
      height: 8rem;
    }
  }

  &__title {
    font-size: 0.875rem;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }

  &__discount {
    font-size: .625rem;
  }
}
