.leaderboard-card {
  $header-height: 84px;

  height: 100%;
  overflow: hidden;
  border-radius: 2rem;
  background-color: $card-bg;
  isolation: isolate; // Fix for safari border-radius

  .twa & {
    border: $card-border-width solid $card-border-color;
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include media-breakpoint-between(sm, lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__header {
    height: $header-height;
    padding: 0 $card-spacer-x;
    background-color: #0E0F1F;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    font-size: 0;

    @include media-breakpoint-up(md) {
      padding: 0 $card-spacer-x-md;
    }

    &--1 {
      $color: #d4b54c;

      background-image: url('../../../assets/images/leaderboard/cup-1.png'), radial-gradient(circle at 75% center, rgba($color, 0.5), rgba($color, 0));
    }

    &--2 {
      $color: #fff;

      background-image: url('../../../assets/images/leaderboard/cup-2.png'), radial-gradient(circle at 75% center, rgba($color, 0.5), rgba($color, 0));
    }

    &--3 {
      $color: #e0bf97;

      background-image: url('../../../assets/images/leaderboard/cup-3.png'), radial-gradient(circle at 75% center, rgba($color, 0.5), rgba($color, 0));
    }
  }

  &__body {
    position: relative;
    padding: 0 $card-spacer-x $card-spacer-y;

    @include media-breakpoint-up(md) {
      padding: 0 $card-spacer-x-md $card-spacer-y-md;
    }
  }

  &__position,
  &__position-sup {
    text-shadow: 0 0 12px;
  }

  &__position {
    font-size: 4rem;
    font-style: italic;
    font-weight: $font-weight-bolder;
    line-height: 1.2;
  }

  &__position-sup {
    margin-top: map-get($spacers, 2);
    margin-left: map-get($spacers, 1);
    font-size: 2rem;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 1.1;
  }

  &__avatar {
    $border-size: 6px;

    margin-top: -$header-height + 10px;
    margin-left: -$border-size;

    .avatar {
      box-sizing: content-box;
      width: 96px;
      height: 96px;
      overflow: hidden;
      border: $border-size solid $white;
      border-radius: 20px;
      background-color: $aside-bg;
      box-shadow: 0 0 8px 0 rgba(#979a9f, 0.25);
      color: $var-grey;
      font-size: $h1-font-size;
      font-weight: $font-weight-bolder;
      isolation: isolate;
    }

    @include media-breakpoint-up(md) {
      margin-top: -$header-height + 18px;
    }

    @include media-breakpoint-only(lg) {
      margin-top: -32px;

      .avatar {
        width: 64px;
        height: 64px;
        font-size: $h2-font-size;
      }
    }
  }

  &__badge {
    display: inline-flex;
    align-items: center;
    padding: map-get($spacers, 1) map-get($spacers, 2);
    border-radius: 10px;
    background-color: $aside-bg;
    color: $var-grey;
    font-size: $font-size-sm;
    font-weight: $font-weight-semibold;

    svg {
      width: auto;
      height: 16px;

      path {
        stroke-width: 2px;
      }
    }

    &--blue {
      background-color: rgba($blue, 0.2);
      color: $blue;

      @include light-theme-content {
        background-color: rgba($blue, 0.1);
      }
    }
  }
}
