@mixin alert-variant($background, $border, $color, $link-color: shade-color($color, 20%), $icon-color: null) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;

  .alert-link {
    color: $link-color;
  }

  .alert-icon {
    color: $icon-color;
  }
}
