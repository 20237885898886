@import "bootstrap/scss/helpers/text-truncation";

%text-truncate-2 {
 overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @supports (display: -webkit-inline-box) and (-webkit-box-orient: vertical) and (-webkit-line-clamp: 2) {
    display: -webkit-inline-box;
    vertical-align: text-bottom;
    text-overflow: initial;
    white-space: initial;
    -webkit-line-clamp: 2; /* Число отображаемых строк */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
  }
}

.text-truncate-2 {
  @extend %text-truncate-2;
}

.text-truncate-3 {
  @extend %text-truncate-2;

  @supports (display: -webkit-inline-box) and (-webkit-box-orient: vertical) and (-webkit-line-clamp: 2) {
    -webkit-line-clamp: 3; /* Число отображаемых строк */
  }
}
