@mixin game-style() {
  .miner-wars__container {
    @content;
  }
}

@include game-style {
  @extend %dark-theme;

  color: $body-color;

  --bs-link-color: $body-color;
  --bs-link-hover-color: $body-color;

  .miner-wars {
    flex: 1 0 0;
    min-height: 0;
    background-image: url('../../../../assets/images/game/background.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.miner-wars {
  .app-tab-bar--active & {
    margin-bottom: $tab-bar-height;
  }
}

.miner-wars-host {
  .twa & {
    position: fixed;

    @include inset(0);
  }
}

.desktop-game {
  width: 100%;

  @include media-breakpoint-up(md, $game-breakpoints) {
    width: 375px;
    min-height: 600px;
    max-height: 748px;
    margin: map-get($spacers, 4) 0;
    overflow: hidden;
    border-radius: $game-desktop-border-radius;
  }
}


.game-container {
  max-width: $game-container-max-width;
}
