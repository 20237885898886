.static-page {
  $padding-x: map-get($spacers, 3);
  $padding-y: map-get($spacers, 5);

  $padding-x-sm: $padding-y;
  $padding-y-sm: $padding-y;

  @extend %h-flex-full;

  align-items: center;
  padding-top: calc(#{$padding-y} + var(--safe-area-inset-top));
  padding-bottom: calc(#{$padding-y} + var(--safe-area-inset-bottom));
  padding-left: calc(#{$padding-x} + var(--safe-area-inset-left));
  padding-right: calc(#{$padding-x} + var(--safe-area-inset-right));
  background-color: $aside-bg;

  @include media-breakpoint-up(sm) {
    padding-top: calc(#{$padding-y-sm} + var(--safe-area-inset-top));
    padding-bottom: calc(#{$padding-y-sm} + var(--safe-area-inset-bottom));
    padding-left: calc(#{$padding-x-sm} + var(--safe-area-inset-left));
    padding-right: calc(#{$padding-x-sm} + var(--safe-area-inset-right));
  }

  &__card {
    width: 100%;
    max-width: 524px;
    margin-top: $padding-y;

    .card-body {
      color: $body-color;

      @include media-breakpoint-up(md) {
        padding: map-get($spacers, 3) * 2;
      }
    }

    &--lg {
      .card-body {
        @include media-breakpoint-up(md) {
          padding: map-get($spacers, 4) * 2;
        }
      }
    }
  }
}
