@use 'sass:map';

.miner-create-modal {
  @extend .nft-upgrade-modal;

  --#{$prefix}modal-header-padding: 1.25rem 0 0 !important;

  max-width: none;

  @include media-breakpoint-up(md) {
    max-width: 600px;

    --#{$prefix}modal-header-padding: 3rem 0 0 !important;
  }

  .carousel-scroll {
    --fade-color: var(--#{$prefix}modal-bg);
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    text-align: center;
  }

  &__traits-btn {
    --icon-color: var(--gray);

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: map.get($spacers, 3);
    padding: 0;
    font-weight: $font-weight-semibold;
    cursor: pointer;

    &:hover {
      --icon-color: currentcolor;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      padding: map.get($spacers, 3) 0;
    }
  }

  &__traits-btn-img {
    width: 40px;
    height: 40px;
    margin-right: map.get($spacers, 2) * 1.5;
  }

  &__arrow-icon {
    transition: color $transition;
    color: var(--icon-color);
  }

  &__container {
    margin: 0 calc(var(--#{$prefix}modal-padding) * -1);
  }

  &__content {
    @include media-breakpoint-down(md) {
      z-index: 1;
      flex-basis: 0;
      flex-grow: 1;
      min-height: 240px;
      margin: 0 calc(var(--#{$prefix}modal-padding) * -1);
      padding: 0 var(--#{$prefix}modal-padding);
      overflow: auto;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    padding: 0 var(--#{$prefix}modal-padding);
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__calculator-card {
    border: 1px solid $var-surface-10;
    border-radius: map.get($spacers, 3) + map.get($spacers, 1);
  }

  &__info-body {
    padding: map.get($spacers, 3);
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    background-color: $var-surface-accent;
  }

  &__info-header {
    border: $border-width solid $var-surface-accent;
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    background-color: var(--#{$prefix}modal-bg);

    & > div {
      margin-top: -1px;
    }
  }

  &__info-tab {
    width: 100%;
    padding: map.get($spacers, 2) map.get($spacers, 3);
    transition: background-color $transition, color $transition, font-weight $transition;
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    background-color: transparent;
    color: $var-grey;
    text-align: center;

    &--active {
      background-color: $var-surface-accent;
      color: $var-surface;
      font-weight: $font-weight-semibold;
    }
  }

  &__select-price.ng-select {
    font-weight: $font-weight-semibold;

    .ng-arrow-wrapper {
      background-color: $input-focus-border-color;
    }

    .ng-dropdown-panel {
      right: 0;
      width: auto;
    }
  }

  &__discount-tag {
    /* stylelint-disable-next-line selector-class-pattern */
    .nft-label--softred {
      @include light-theme-content(true) {
        margin-top: -#{map.get($spacers, 1)};
        margin-bottom: -#{map.get($spacers, 1)};
        padding: 0 #{map.get($spacers, 1) * 1.5};
        background-color: $red;
        color: $white;
        font-size: $font-size-sm;
        line-height: 1.25rem;
      }
    }
  }

  &__trait-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: map.get($spacers, 3) * 1.25;
    border-radius: $border-radius-lg;
    background-color: $var-surface-accent;
  }

  &__trait-image {
    width: 64px;
    height: 64px;
    object-fit: contain;
    margin-right: map.get($spacers, 2) * 1.5;
    border-radius: $border-radius-sm;

    &--fallback {
      background: $var-grey;
    }
  }

  &__trait-tag {
    display: inline-flex;
    margin-bottom: map.get($spacers, 1) * 1.5;
    text-transform: uppercase;
  }

  &__trait-name {
    margin-bottom: map.get($spacers, 1) * 0.5;
    font-weight: $font-weight-semibold;
  }
}
