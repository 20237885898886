.image-bscscan {
  --color-1: #fff;

  @include light-theme-content {
    --color-1: #12161C;
  }

  &__color-1 {
    fill: var(--color-1);
  }

  &__color-2 {
    fill: #F0B90B;
  }
}
