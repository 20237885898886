@use "sass:map";

.modal-notification {
  $padding: map.get($spacers, 2);
  $border-radius-default: 2rem;

  border: none;

  --#{$prefix}modal-border-radius: #{$border-radius-default} !important;

  .close {
    $offset: map.get($spacers, 3) + map.get($spacers, 1);

    top: $offset;
    right: $offset;
  }

  &__body {
    padding: $padding;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__image {
    width: 100%;
    height: auto;
    border-radius: calc($border-radius-default - $padding);

    @supports (border-radius: min(2rem)) {

    }
  }

  &__content {
    flex-grow: 1;
    align-self: center;
    padding: 1rem;

    @include media-breakpoint-up(sm) {
      padding: 1.5rem;
    }

    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
  }

  &__date {
    display: block;
    margin-bottom: map.get($spacers, 2_5);
    color: $grey;
    font-size: $small-font-size;
    font-weight: $font-weight-medium;
  }

  &__header {
    margin-bottom: map.get($spacers, 2_5);
    font-family: $font-family-headline;
    font-size: $lead-font-size;
    font-weight: $lead-font-weight;
  }
}
