@use 'sass:map';

.ve-onboarding-modal {
  @include media-breakpoint-up(md) {
    width: 600px;
    max-width: 600px;
  }

  &__badge {
    padding: .25rem .625rem;
    background-color: $var-surface-10;
    line-height: 1.5;
  }

  &__badge-faq {
    border-radius: 100px;
    background-color: $var-surface;
    color: $var-subface;

    &:hover, &:focus {
      color: $var-subface;
    }
  }

  &__icon-box {
    padding: map.get($spacers, 2);
    border-radius: $border-radius;
    background-color: $var-surface;
    color: $var-subface;
  }

  &__link-text {
    a &, button & {
      flex-grow: 1;
      line-height: 1.25rem;
      text-align: start;
      text-overflow: initial;
      word-break: break-word;
      white-space: initial;
    }
  }
}
