/* You can add global legacy-styles to this file, and also import other style files */
@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap';

// SCSS FILES
@import './meta';
@import "./scss/env-safe-area";
@import "./scss/themes";

// @import "./modules/miner-wars/game-variables";

// BASE FILES
@import "./base/fonts";
@import "./base/normalize";
@import "./base/responsive";
@import "./base/type";


// INCLUDES
@import "./includes/bootstrap";
@import "./includes/ng-lazyload-image";
@import "./includes/ng-select";
@import "./includes/intercom";
@import "./includes/intl-tel-input";
@import "./includes/ngx-slider";
@import "./includes/owl-carousel";
@import "./includes/walletconnect-modal";
@import "./includes/recaptcha";
@import "./includes/plyr";

// MODULES
@import "./modules/home";

// не используется
// @import "./modules/app-root";
@import "./modules/smart-banner";
@import "./modules/group-elements";

// используется в нескольких местах
@import "./modules/cards-container";
@import "./modules/running-line";

// @import "./modules/banner";
// @import "./modules/banner-brand";
// не используется
// @import "./modules/banner-secondary-market";
// не используется
// @import "./modules/banner-nft-purchase-token-bonus";
// не используется
// @import "./modules/banner-nft-trial-period";
@import "./modules/main";
@import "./modules/svg-effects";
@import "./modules/formula";
@import "./modules/logo";

// есть в разных компонентах
@import "./modules/auth-layout";
@import "./modules/static-page";
@import "./modules/code-divider";
@import "./modules/app-divider";

// не используется
// @import "./modules/card-staking-type";
@import "./modules/status-circle";
@import "./modules/list";
@import "./modules/content";
@import "./modules/skeleton";
@import "./modules/btn";
@import "./modules/sidebar";
@import "./modules/sidebar-redesign";
@import "./modules/navbar";
@import "./modules/post-snippet";
@import "./modules/post-list";
@import "./modules/page-bottom";
@import "./modules/smart-table";
@import "./modules/page-top";
@import "./modules/page-top-nav";
@import "./modules/profile";
@import "./modules/qr-display";
@import "./modules/empty-data";
@import "./modules/icon-box";
@import "./modules/icon-highlight";
@import "./modules/icon-gmt";
@import "./modules/icon-chat";
@import "./modules/income";
@import "./modules/preloader";
@import "./modules/preloader-globe";
@import "./modules/router-outlet-loader";
@import "./modules/premium-wrapper";
@import "./modules/file-uploader";
@import "./modules/form-check";
@import "./modules/form-switch";
@import "./modules/select-bg-base";
@import "./modules/select-wallets";
@import "./modules/select-nfts";
@import "./modules/select-currencies";
@import "./modules/tooltip-selection";
@import "./modules/server-error";
@import "./modules/wallet";
@import "./modules/locale-switcher";
@import "./modules/recaptcha";
@import "./modules/web-site-data";
@import "./modules/crypto-address-presenter";
@import "./modules/progress-bar";
@import "./modules/progress-circles";
@import "./modules/card-token-buy";

// используется в нескольких местах
@import "./modules/avatar";

// используется в нескольких местах
@import "./modules/avatar-editor";
@import "./modules/notification";
@import "./modules/notification-message";
@import "./modules/notification-version";
@import "./modules/input-phone";
@import "./modules/input-wallet";
@import "./modules/input-presenter";
@import "./modules/provider";
@import "./modules/contract-provider";
@import "./modules/walletconnect-modal";
@import "./modules/modal-share-generator";
@import "./modules/modal-video";
@import "./modules/modal-base";
@import "./modules/modal-presenter";
@import "./modules/modal-withdraw-order-history";
@import "./modules/modal-exchanges-list";
@import "./modules/modal-locale-switcher";
@import "./modules/modal-settings-account";
@import "./modules/modal-settings-two-fa";
@import "./modules/modal-with-circle";
@import "./modules/modal-notification";
@import "./modules/board-nft";
@import "./modules/leaderboard";
@import "./modules/leaderboard-card";
@import "./modules/leaderboard-periods-carousel";
@import "./modules/nft-sell";
@import "./modules/nft-miners";
@import "./modules/nft-pool";
@import "./modules/widget-maintenance";
@import "./modules/widget-card-wallet";

// используется в нескольких компоннетах
@import "./modules/card-indicator";
@import "./modules/card-nft-invites-banner";

// не используется
// @import "./modules/card-staking-calculator";
@import "./modules/slider-banners";
@import "./modules/top-banner";
@import "./modules/tooltip-time-locked";
@import "./modules/image-logo";
@import "./modules/image-coin-empty";
@import "./modules/image-advcash";
@import "./modules/image-bscscan";
@import "./modules/image-etherscan";
@import "./modules/image-discord";
@import "./modules/string-display";
@import "./modules/daterange-input";
@import "./modules/modal-calculation";
@import "./modules/farm";
@import "./modules/filter-block";
@import "./modules/underline-tabs";

// есть в разных компонентах
@import "./modules/aside-viewer";
@import "./modules/catalog-index";
@import "./modules/catalog-bag";
@import "./modules/rewards-settings";
@import "./modules/ve-rewards-settings";
@import "./modules/catalog-bag-indicator";
@import "./modules/catalog-item";
@import "./modules/catalog-item-card";
@import "./modules/nft-item-list";
@import "./modules/nft-income-history";
@import "./modules/nft-label";
@import "./modules/nft-upgrade-package";
@import "./modules/nft-request";
@import "./modules/nft-display";
@import "./modules/nft-roi-calculator";
@import "./modules/nft-avatar-clan";
@import "./modules/nft-payment-promocode-modal";
@import "./modules/notifications-list";
@import "./modules/clan-create-update-modal";
@import "./modules/rating-number";
@import "./modules/trial-achievements";
@import "./modules/resource-selector";
@import "./modules/staking";
@import "./modules/referral";
@import "./modules/referral-banner-generator";
@import "./modules/social-share";
@import "./modules/social-share-links";
@import "./modules/catalog-config";
@import "./modules/currency";
@import "./modules/circle-progress";
@import "./modules/country";
@import "./modules/get-started-modal";
@import "./modules/pay-buttons";
@import "./modules/select-countries";
@import "./modules/vgmt-widget";
@import "./modules/nft-upgrade-modal";
@import "./modules/expandable-text";
@import "./modules/infinite-scroll-container";
@import "./modules/modal-winner-clan";
@import "./modules/modal-contact";
@import "./modules/modal-auth-starter";
@import "./modules/modal-auth-finisher";
@import "./modules/modal-nft-marketplace-starter";
@import "./modules/modal-nft-marketplace-filters";
@import "./modules/modal-nft-list-investment";
@import "./modules/modal-nft-sales";
@import "./modules/modal-nft-purchase-token-bonus";
@import "./modules/modal-nft-maintenance-discount";
@import "./modules/modal-social-links";

// используется в нескольких компоннетах
@import "./modules/ve-token/ve-dashboard";

// используется в нескольких компоннетах
@import "./modules/ve-token/ve-my-lock";

// используется в нескольких компоннетах
@import "./modules/ve-token/ve-onboarding-modal";
@import "modules/ve-token/ve-token";
@import "./modules/brand-font";
@import "./modules/content-separator";
@import "./modules/nft-bag-promocode-modal";
@import "./modules/steps-bar-progress";
@import "./modules/ton-connect";

// используется в разных модалках
@import "./modules/gominers/modal-gominers";

// используется в разных модалках
@import "./modules/gominers/modal-contest";

// @import "./modules/gominers/modal-gominers-benefits";
// @import "./modules/gominers/modal-gominer-avatar-settings";
@import "./modules/miner-board-display";
@import "./modules/miner-create-modal";
@import "./modules/miner-create-video";

// используется в нескольких местах
@import "./modules/board-miner-create-modal";
@import "./modules/nft-mint";

// @import "./modules/consent-popup";
@import "./modules/modal-checkout-halving";

// используется в нескольких местах
@import "./modules/banner-builder";
@import "./modules/modal-thank-you";
@import "./modules/nft-reward-calculator";
@import "./modules/overlay-circle";

// используется в нескольких местах
@import "./modules/banner-promocode";
@import "./modules/modal-token-bonus-deposit";
@import "./modules/subscription-calculator";
@import "./modules/maintenance-discount";
@import "./modules/nfc-gift";
@import "./modules/promo-card";
@import "./modules/nft-page-stub";
@import "./modules/launchpad";
@import "./modules/launchpad-card";
@import "./modules/launchpad-countdown";
@import "./modules/launchpad-tag";
@import "./modules/nft-achievements";
@import "./modules/modal-nft-achievement";
@import "./modules/modal-ambassador";
@import "./modules/stepper";

// @import "./modules/bounty/bounty-tasks";
// @import "./modules/bounty/bounty-tasks-modal";
// @import "./modules/bounty/bounty-history";
// @import "./modules/bounty/bounty-leaderboard";

// Game
@import "./modules/miner-wars/miner-wars";
@import "./modules/miner-wars/type-game";
@import "./modules/miner-wars/colors-game";
@import "./modules/miner-wars/btn-game";
@import "./modules/miner-wars/form-control-game";
@import "./modules/miner-wars/card-game";
@import "./modules/miner-wars/modal-game";
@import "./modules/miner-wars/badge-game";
@import "./modules/miner-wars/onboarding";
@import "./modules/miner-wars/main";
@import "./modules/miner-wars/boost";
@import "./modules/miner-wars/button-tag";
@import "./modules/miner-wars/nft-display-game";
@import "./modules/miner-wars/app-nav-tabs-game";
@import "./modules/miner-wars/modal-select-country-game";
@import "./modules/miner-wars/modal-token-bundles";
@import "./modules/miner-wars/tooltip-game";
@import "./modules/miner-wars/ability-events";
@import "./modules/miner-wars/cycle-card";
@import "./modules/miner-wars/profile";
@import "./modules/miner-wars/pool";
@import "./modules/miner-wars/participant-info";
@import "./modules/miner-wars/info-card";
@import "./modules/miner-wars/buy-config-item";
@import "./modules/miner-wars/loading-screen";
@import "./modules/miner-wars/leaderboard";
@import "./modules/miner-wars/switch";
@import "./modules/miner-wars/alert";
@import "./modules/miner-wars/select-pool";
@import "./modules/miner-wars/game-pagination";
@import "./modules/miner-wars/chat";
@import "./modules/miner-wars/round-history";
@import "./modules/miner-wars/divider-game";
@import "./modules/miner-wars/clan-indicators";

// не используются 
// @import "./modules/browser-miner-wars";
@import "./modules/intro";
@import "./modules/modal-download-app";
@import "./modules/mobile-table";

// Mobile
// используется в нескольких компоннетах
@import "./modules/mobile/app-bottom-sheet";

// используется в нескольких компоннетах
@import "./modules/mobile/app-tab-bar";

// используется в нескольких компоннетах
@import "./modules/mobile/app-nav-tabs";

// используется в нескольких компоннетах
@import "./modules/mobile/app-option";

// не используется
// @import "./modules/mobile/app-profile";

// @import "./modules/mobile/app-bottom-sheet-auth";
// @import "./modules/mobile/app-bottom-sheet-consent";

// Static pages
@import "./modules/christmas";
@import "./modules/investment";

// Helpers
@import "./helpers/visually-hidden";
@import "./helpers/text-truncation";
@import "./helpers/colored-links";
@import "./helpers/gradients";


// Swap
@import "./modules/swap";
