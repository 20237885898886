@use "sass:map";
@use "sass:math";

.widget-card-wallet {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: map.get($spacers, 2) + map.get($spacers, 1);
  background-color: var(--card-wallet-bg);
  border-radius: $card-wallet-border-radius;
  border: $border-width solid $var-surface-5;

  &::before {
    content: '';
    position: absolute;
    pointer-events: none;
    background-repeat: no-repeat;
    background-size: contain;

    @include inset(0);

    @include dir-ignore {
      background-position: center right;
    }

    @include rtl(true) {
      transform: scaleX(-1);
    }
  }

  &--virtual {
    &::before {
      background-image: url('../../../assets/images/wallets/virtual-bg.svg');
    }
  }

  &__badge {
    $padding-y: math.div(map.get($spacers, 1), 2);
    $padding-x: math.div(map.get($spacers, 1), 2) + map.get($spacers, 1);

    padding: $padding-y $padding-x;
    color: $var-grey-contrast;
    font-weight: $font-weight-semibold;
    font-size: $font-size-xxs;
    background-color: $var-grey;
    border-radius: 10rem;
  }
}
