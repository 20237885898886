.miner-create-video {
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  &__video {
    @include inset(-70%);

    position: absolute;
    z-index: 1;
    width: 240%;
    height: 240%;
    transition: opacity $transition;
    opacity: 0;
    object-fit: contain;

    &--loaded {
      opacity: 1;
    }
  }

  &__fallback {
    @include inset(0);

    position: absolute;
    z-index: -1;
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
  }
}