%card-body-vendor {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: var(--#{$prefix}card-spacer-y) var(--#{$prefix}card-spacer-x);
  color: var(--#{$prefix}card-color);
}

.card {
    --preloader-overlay-bg: #{$card-bg};
  --#{$prefix}card-spacer-y: #{$card-spacer-y};
  --#{$prefix}card-spacer-x: #{$card-spacer-x};
  --#{$prefix}card-title-spacer-y: #{$card-title-spacer-y};
  --#{$prefix}card-border-width: #{$card-border-width};
  --#{$prefix}card-border-color: #{$card-border-color};
  --#{$prefix}card-border-radius: #{$card-border-radius};
  --#{$prefix}card-box-shadow: #{$card-box-shadow};
  --#{$prefix}card-inner-border-radius: #{$card-inner-border-radius};
  --#{$prefix}card-cap-padding-y: #{$card-cap-padding-y};
  --#{$prefix}card-cap-padding-x: #{$card-cap-padding-x};
  --#{$prefix}card-cap-bg: #{$card-cap-bg};
  --#{$prefix}card-cap-color: #{$card-cap-color};
  --#{$prefix}card-height: #{$card-height};
  --#{$prefix}card-color: #{$card-color};
  --#{$prefix}card-bg: #{$card-bg};
  --#{$prefix}card-img-overlay-padding: #{$card-img-overlay-padding};
  --#{$prefix}card-group-margin: #{$card-group-margin};

  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  height: var(--#{$prefix}card-height);
  word-wrap: break-word;
  background-color: var(--#{$prefix}card-bg);
  background-clip: border-box;
  border: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color);
  @include border-radius(var(--#{$prefix}card-border-radius));
  @include box-shadow(var(--#{$prefix}card-box-shadow));
}

.card-header {
  padding: var(--#{$prefix}card-cap-padding-y) var(--#{$prefix}card-cap-padding-x);
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: var(--#{$prefix}card-cap-color);
  background-color: var(--#{$prefix}card-cap-bg);
  border-bottom: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color);

  &:first-child {
    @include border-radius(var(--#{$prefix}card-inner-border-radius) var(--#{$prefix}card-inner-border-radius) 0 0);
  }
}

.card-body {
  @extend %card-body-vendor;
}

.card-footer {
  padding: var(--#{$prefix}card-cap-padding-y) var(--#{$prefix}card-cap-padding-x);
  color: var(--#{$prefix}card-cap-color);
  background-color: var(--#{$prefix}card-cap-bg);
  border-top: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color);

  &:last-child {
    @include border-radius(0 0 var(--#{$prefix}card-inner-border-radius) var(--#{$prefix}card-inner-border-radius));
  }
}

.card-title {
  margin-bottom: var(--#{$prefix}card-title-spacer-y);
}
