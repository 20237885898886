@use "sass:map";

.post-list {
  &__item {
    $padding-y: map.get($spacers, 2) + map.get($spacers, 1);

    display: block;
    padding: $padding-y 0;
    border-bottom: $border-width solid $border-color;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}
