@include dir-ignore() {
  @import "../../vendors/bootstrap/spinners";

  .spinner-border {
    @supports ((background: conic-gradient(from 0turn, #{rgba($white, 0)}, currentColor))) and
  ((mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)) or
  (-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0))) {
      &.spinner-border-gradient {
        position: relative;
        border-color: transparent;

        &::before,
        &::after {
          content: '';
          position: absolute;
          z-index: initial;
        }

        &::before {
          inset: -$spinner-border-width;
          border-radius: 50%;
          padding: $spinner-border-width;
          background: conic-gradient(from 0turn, #{rgba($white, 0)} 10%, currentColor);
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
        }

        &::after {
          top: -$spinner-border-width;
          left: 50%;
          z-index: -1;
          transform: translateX(-50%);
          width: $spinner-border-width;
          height: $spinner-border-width;
          border-radius: 50%;
          background-color: currentColor;
        }

        &.spinner-border-sm {
          &::before {
            inset: -$spinner-border-width-sm;
            padding: $spinner-border-width-sm;
          }
        }
      }
    }
  }
}
