@use "sass:math";

.cards-container {
  $grid-gutter-width: map-get($spacers, 1);

  .twa & {
    overflow: hidden;
    background-color: $aside-bg;
    border-radius: $card-border-radius;
  }

  .twa .main--inner & {
    margin-left: main-inner-offset('left', false, -1);
    margin-right: main-inner-offset('right', false, -1);

    @include media-breakpoint-up(md) {
      margin-left: main-inner-offset('left', true, -1);
      margin-right: main-inner-offset('right', true, -1);
    }
  }

  .twa &__row {
    margin-left: math.div(-$grid-gutter-width, 2);
    margin-right: math.div(-$grid-gutter-width, 2);

    &.row-grid {
      margin-top: math.div(-$grid-gutter-width, 2);
      margin-bottom: math.div(-$grid-gutter-width, 2);
    }
  }

  .twa &__col {
    padding-left: math.div($grid-gutter-width, 2);
    padding-right: math.div($grid-gutter-width, 2);

    &.col-grid {
      padding-top: math.div($grid-gutter-width, 2);
      padding-bottom: math.div($grid-gutter-width, 2);
    }
  }

  .twa &__card {
    border-width: 0;
  }
}
