$dark-50: rgba($dark, 0.5);
$light-50: rgba($light, 0.5);
$gift-box-bg-height: 252px;
$gift-box-bg-margin-bottom: 17.2px;

$bottom-sheet-height-config: (
  loading: 400px,
  errored: (
    newUsersOnly: 320px,
    cardWasUsed: 320px,
    cardExpired: 320px
  ),
  ready: (
    signUp: 420px,
    tokens: 440px,
    nft: 440px,
    tokensAndNft: 440px
  ),
  claimed: (
    tokens: 480px,
    nft: 480px,
    tokensAndNft: 500px
  )
);

@each $status, $height in $bottom-sheet-height-config {
  @if type-of($height) == map {
    @each $inner-status, $inner-height in $height {
      .bottom-sheet-nfc-gift--#{$status}-#{$inner-status} {
        height: $inner-height !important;
      }
    }
  } @else {
      .bottom-sheet-nfc-gift--#{$status} {
        height: $height !important;
      }
  }
}

.nfc-gift {
  &__loader {
    margin-top: 1.125rem;
  }

  &__title-margin {
    margin-top: calc(1rem + $gift-box-bg-margin-bottom);
  }

  &__box-bg:before {
    content: '';
    position: absolute;
    background: url('../../..//assets/images/nfc-gift/gift-box-bg.png');
    background-position: center;
    border-radius: $modal-content-border-radius;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    margin: 0.5rem;
    height: $gift-box-bg-height;
  }

  &__desc {
    color: $light-50;

    @include light-theme-content {
      color: $dark-50;
    }
  }
}
