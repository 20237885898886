@use 'sass:map';

@include game-style {
  .buy-config-item {
    $col-spacer: $spacer-75 * 0.5;

    display: block;
    overflow: hidden;
    cursor: pointer;

    &--disabled {
      position: relative;
      pointer-events: none;

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        background-color: rgba($secondary-game, 0.5);

        @include inset(0);
      }
    }

    &__row {
      display: flex;
      align-items: center;
      margin: 0 -#{$col-spacer};
    }

    &__col {
      padding: 0 $col-spacer;
    }

    &__desc {
      margin-top: map.get($spacers, 1) * 0.5;
      margin-bottom: map.get($spacers, 1);
      font-size: $font-size-xs;
      text-transform: uppercase;
    }

    &__fee {
      font-size: $font-size-xs;
      text-align: right;
      text-transform: uppercase;
    }
  }
}

