/* rtl:begin:ignore */
@import "../../vendors/bootstrap/tooltip";

/* rtl:end:ignore */

.tooltip {
  text-align: left;
}

.tooltip-arrow {
  text-align: left;
}

.tooltip-inner {
  box-shadow: $tooltip-box-shadow;
  text-align: inherit;
}

.tooltip-tiny-toggle {
  .tooltip-arrow {
    margin: 2px;
  }

  &.bs-tooltip {
    &-bottom-end,
    &-top-end {
      .tooltip-arrow {
        @include dir-ignore {
          margin-left: -2px;
        }
      }
    }
  }
  
}

.tooltip-xs {
  max-width: 200px;
}

.tooltip-sm {
  max-width: 300px;
}

.tooltip-md {
  max-width: 400px;
}
