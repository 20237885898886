.modal-nft-marketplace-starter {
  --#{$prefix}modal-width: 600px;

  .modal-content {
    margin-top: calc(32% - #{$modal-inner-padding-x * 2});
  }

  &__image {
    width: 100%;
    max-width: 420px;
    height: auto;
    margin-top: calc(-25% - #{$modal-inner-padding-y});
    margin-bottom: -5%;
    user-select: none;
  }
}
