@use 'sass:map';

.daterange-input {
  padding: map.get($spacers, 2) map.get($spacers, 2) + map.get($spacers, 1);
  border-radius: $border-radius;
  background-color: $var-surface-accent;

  &--inline {
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: transparent;
    color: $var-surface-50;
  }

  &--hidden {
    max-width: 0;
    max-height: 0;
    overflow: hidden;
  }

  .form-control,
  .input-group-text {
    color: inherit;
  }

  &:not(&--inline) {
    .form-control,
    .input-group-text,
    .input-group-prepend,
    .input-group-append {
      min-height: auto;
      padding-top: 0;
      padding-bottom: 0;
      border: none;
    }
  }
}
