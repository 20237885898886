@use "sass:math";

$nft-accent-color: $primary;
$nft-background-color: #e4e5e7;
$nft-border-color: $gray-200;
$nft-border-width: $border-width;
$nft-padding-y: map-get($spacers, 3);
$nft-padding-x: $nft-padding-y;
$nft-padding-y-sm: map-get($spacers, 4);
$nft-padding-x-sm: $nft-padding-y-sm;
$nft-padding-y-lg: map-get($spacers, 4) + map-get($spacers, 2);
$nft-padding-x-lg: $nft-padding-y-lg;
$nft-progress-bar-bg: #aa85ff;
$nft-progress-bar-fill-bg: $nft-accent-color;
$nft-progress-bar-border-radius: $border-radius;
$nft-backdrop-filter: blur(5px);
$nft-thumb-size: 64px;
$nft-thumb-size-sm: 80px;
$nft-thumb-next-scale: .2;
$nft-thumb-border-radius: $border-radius;
$nft-thumb-offset-next: rem($nft-thumb-size * math.div($nft-thumb-next-scale, 2));
$nft-thumb-offset-next-sm: rem($nft-thumb-size-sm * math.div($nft-thumb-next-scale, 2));
$nft-bg-color: $white;
$nft-border-size: .375rem;
$nft-border: $nft-border-size solid $nft-bg-color;
$nft-border-radius: 1.5rem;
$nft-border-radius-lg: 2rem;
$action-panel-height: rem(52px) + $nft-border-size * 2;

%board-nft-card--action-panel {
  padding-bottom: math.div($action-panel-height, 2);
  margin-bottom: math.div($action-panel-height, 2);
}

%board-nft-card__action-panel {
  $border-radius: 1.25rem;

  overflow: hidden;
  position: absolute;
  left: 50%;
  bottom: 0;
  max-width: 85%;
  height: $action-panel-height;
  transform: translate(-50%, 50%);
  min-width: 180px;
  background: $nft-bg-color;
  border: $nft-border;
  box-shadow: 0 0 map-get($spacers, 3) rgba($black, 0.08);
  border-radius: $nft-border-size + $nft-border-radius;
}

%board-nft-card__action {
  width: 100%;
  height: 100%;
  font-weight: $font-weight-semibold;
}
