@use 'sass:map';
@import 'index';
@import 'module-variables/app-onboarding-vars';
@import 'scss/themes';

$close-size: 24px;
$aside-viewer-padding: map-get($spacers, 4);
$aside-viewer-bg: $var-subface;

%sticky-top {
  position: sticky;
  top: var(--safe-area-inset-top);
}

%h-flex-full {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

%h-flex-scroll {
  @extend %h-flex-full;

  flex-basis: 0;
  overflow: auto;
}

%app-bottom-sheet-consent__title {
  margin-bottom: map.get($spacers, 1);
  font-family: $font-family-headline;
  font-size: 1.125rem;
  font-weight: $font-weight-medium;
}

%lead {
  @include font-size($lead-font-size);

  font-weight: $lead-font-weight;
}

%lead-app {
  @include font-size($font-size-lg);

  font-weight: $lead-font-weight;
}

%lead-caption {
  color: $var-grey;
  font-size: $font-size-lg;
  font-weight: $font-weight-medium;
}

%lead-caption-app {
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
}

%aside-viewer {
  position: fixed;
  z-index: $zindex-modal;

  @include inset(0);
  @include input-legend-bg($aside-viewer-bg);
}

%aside-viewer__overlay {
  position: absolute;
  z-index: 1;
  background-color: rgba($gray-800, .6);

  @include inset(0);
}

%aside-viewer__fixed {
  position: absolute;
  z-index: 2;
  max-width: 100%;
  margin: var(--safe-area-inset-top) var(--safe-area-inset-right) var(--safe-area-inset-bottom) var(--safe-area-inset-left);
  padding: $aside-viewer-padding;
  background-color: $aside-viewer-bg;

  @include inset(0);

  @include media-breakpoint-up(sm) {
    @include inset(map-get($spacers, 3));

    left: unset;
    width: $aside-viewer-width;
    border-radius: $border-radius-lg;
  }
}

%aside-viewer__box {
  position: relative;
  height: 100%;
}

%aside-viewer__close {
  @extend %close;

  position: absolute;
  top: 0;
  right: 0;
}

%aside-viewer__title {
  @extend %lead;

  padding-right: rem($close-size) + map.get($spacers, 1);
}

%close {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $close-size;
  height: $close-size;
  transition: color $transition;
  border-radius: 100%;
  background-color: $var-surface-accent;

  //padding: math.div(map.get($spacers, 1), 2);
  color: $var-grey;

  &:hover,
  &:focus {
    color: $body-color;
  }

  svg {
    width: 10px;
    height: 10px;
  }
}

%app-onboarding {
  position: fixed;
  z-index: $zindex-fixed + 10;
  overflow: hidden auto;
  background-color: rgba($black, .8);

  @extend %dark-theme;
  @include inset(0);

  @include media-breakpoint-up(sm) {
    padding: calc(#{$onboarding-padding} + var(--safe-area-inset-top)) calc(#{$onboarding-padding} + var(--safe-area-inset-right)) calc(#{$onboarding-padding} + var(--safe-area-inset-bottom)) calc(#{$onboarding-padding} + var(--safe-area-inset-left));
  }
}

%app-onboarding__content {
  position: relative;
  width: 100%;
  max-width: 576px;
  min-height: 450px;
  margin: auto;
  overflow: hidden;
  background-color: $dark;
  color: color-contrast($dark);
  text-align: center;

  owl-stage {
    display: block;
  }

  .owl-carousel,
  .owl-stage-outer,
  owl-stage,
  owl-stage > div,
  .owl-stage,
  .owl-item {
    height: 100% !important;
  }

  @include media-breakpoint-up(sm) {
    max-height: 750px;
    border-radius: $border-radius;
  }
}

%app-onboarding__dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

%app-onboarding__dot-item {
  display: block;
  width: 6px;
  height: 6px;
  transition: $transition;
  transition-property: background-color, width;
  border-radius: 10rem;
  background-color: $var-grey;
  font-size: 0;
}

%app-onboarding__dot-item-active {
  width: 17px;
  background-color: $white;
}
