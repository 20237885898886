.nft-avatar-clan {
  $gradients: (
    0: (1: #EA7D9C, 2: #FADF66),
    1: (1: #EA7D9C, 2: #FADF66),
    2: (1: #CE9FFC, 2: #7367F0),
    3: (1: #92FFC0, 2: #4B84DD),
    4: (1: #FA7F99, 2: #97A6FE),
    5: (1: #FBEAD3, 2: #F2BAA4),
    6: (1: #8FEBA2, 2: #78F3D3),
    7: (1: #2AFADF, 2: #4C83FF),
    8: (1: #F1CC79, 2: #F0AC87),
    9: (1: #FECAE8, 2: #FF9DA3),
    10: (1: #469BF8, 2: #B8FCE5),
    11: (1: #0BD2DF, 2: #AA47F4),
    12: (1: #FF597F, 2: #FFA593),
    13: (1: #ACC1EA, 2: #EDC5E9),
    14: (1: #DE94E4, 2: #E0657F),
    15: (1: #60ACF4, 2: #6BE9F7),
    16: (1: #D4F68E, 2: #B1E7A1),
    17: (1: #ACCAF8, 2: #C3E2F9),
    18: (1: #FFF9C6, 2: #D9B2FF),
    19: (1: #8AB1FF, 2: #6904E8),
    20: (1: #E2B0FF, 2: #9F44D3),
  );

  display: flex;
  align-items: center;
  position: relative;

  .avatar {
    color: $white;
  }

  @each $i, $map in $gradients {
    &--#{$i} {
      .avatar {
        background-image: linear-gradient(135deg, map-get($map, 1), map-get($map, 2));
      }
    }
  }
}
