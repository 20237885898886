.smart-table {
  .h-flex-full > &__h-flex-full {
    &,
    .smart-table,
    .smart-table__meta {
      @extend %h-flex-full;
    }
  }

  &__sticky-header {
    position: sticky;
    z-index: 2;
    top: $page-top-height;
    overflow: auto hidden;
    border-bottom: $table-border-width solid $table-border-color;
    background-color: inherit;
    -ms-overflow-style: none;  /* IE 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar { /* chrome based */
      display: none;
    }

    .twa & {
      position: relative;
      top: initial;
    }
  }

  &__hidden-header {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  &__meta {
    position: relative;
  }

  &__scroller {
    @extend %h-flex-full;

    flex-basis: 0;
    overflow: auto;
    transition: opacity .15s ease-in-out;

    &--loading {
      opacity: .5;
    }

    &--responsive {
      @include media-breakpoint-down(sm) {
        overflow: visible;
      }
    }
  }

  &__sort {
    font-weight: inherit;
  }

  &__icon-sort {
    color: $var-primary-theme;

    path {
      transition: opacity .15s ease-in-out;
    }
  }

  &__filter {
    min-width: 80px !important;
    max-width: 200px !important;

    &.ng-dropdown-panel {
      width: auto !important;
      z-index: 10000;
    }
  }
}

.smart-table-app-item {
  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__trigger {
    color: $grey;
  }
}

.input-group {
  &--filled {
    .smart-table {
      &__filter {
        border-right: 0;

        & ~ .input-group-append {
          .input-group-btn {
            &:last-child {
              padding-right: .5rem !important;
            }
          }
        }
      }
    }
  }
}
