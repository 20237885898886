@use 'sass:map';

.form-switch {
  $width: $form-check-switch-width;
  $height: $form-check-switch-height;
  $size-pin: $form-switch-size-pin;
  $size-pin-offset: $form-switch-size-pin-offset;
  $offset: rem($width) + map.get($spacers, 2) + map.get($spacers, 1);

  // max-width: 100%;
  display: inline-flex;
  position: relative;
  align-items: center;
  min-height: $height;

  // padding-left: $width;
  margin: 0 -#{map.get($spacers, 2)};
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;

  &--disabled {
    cursor: default;
  }

  &--readonly {
    pointer-events: none;
  }

  &__box,
  &__text {
    margin: 0 map.get($spacers, 2);
  }

  &__box {
    display: inline-flex;
    position: relative;
    align-self: flex-start;
    transition: all .3s ease;

    &::before {
      content: '';
      display: inline-block;
      width: $width;
      height: $height;
      transition: background-color .15s ease-in-out;
      border-radius: 10rem;
      background-color: $var-surface-10;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: $size-pin-offset;
      left: $size-pin-offset;
      width: $size-pin;
      height: $size-pin;
      transition: .15s ease-in-out;
      transition-property: background-color, transform;
      border-radius: 100%;
      background-color: $white;
      box-shadow: 0 3px 8px rgba($black, 0.15);
    }
  }

  &__input {
    position: absolute;
    appearance: none;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  &__input:checked + &__box {
    &::before {
      background-color: $primary;
    }

    &::after {
      transform: translate3d(#{$width - $size-pin - $size-pin-offset * 2}, 0, 0);
      background-color: $white;
    }
  }

  &__text {
    flex: 1 0 0;
    width: 100%;
    overflow: hidden;
    transition: opacity .15s ease-in-out;
    font-size: $font-size-sm;
    text-overflow: ellipsis;

    &:empty {
      display: none;
    }
  }

  &__input:disabled ~ &__box,
  &__input:disabled ~ &__text {
    opacity: .4;
    cursor: default;
  }
}

.form-switch-lock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $form-check-switch-width;
  min-width: $form-check-switch-width;
  height: $form-check-switch-height;
  border: $form-switch-size-pin-offset solid $var-surface-10;
  border-radius: $form-check-switch-height * 0.5;
  color: $var-grey;
}
