%l___list {
  margin: 0;
  padding: 0;
  line-height: 1.35;
  list-style: none;

  li + li {
    margin-top: map-get($spacers, 2);
  }

  li {
    position: relative;
  }
}


%ul___list {
  li {
    $size: 6px;

    padding-left: map-get($spacers, 3) + rem($size * 2);

    &::before {
      content: '';
      position: absolute;
      top: $size;
      left: $size;
      width: $size;
      height: $size;
      border-radius: 50%;
      background-color: $primary;
    }
  }
}

%ol___list-basic {
  counter-reset: list;

  li + li {
    margin-top: map-get($spacers, 2) + map-get($spacers, 1);
  }

  li {
    position: relative;
    padding-left: map-get($spacers, 1);

    &::before {
      content: counter(list) ".";
      position: absolute;
      top: 0;
      right: 100%;
      counter-increment: list;
    }
  }
}

%ol___list {
  li {
    $size: 32px;

    min-height: $size;
    padding-left: map-get($spacers, 3) +  rem($size);

    &::before {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: $size;
      height: $size;
      border-radius: 50%;
      background-color: $var-surface-5;
      color: $var-surface-50;
    }
  }
}

ul.list,
ol.list {
  @extend %l___list;
}

ul.list {
  @extend %ul___list;
}

ol.list-basic {
  @extend %ol___list-basic;
}

ol.list {
  @extend %ol___list-basic;
  @extend %ol___list;
}
