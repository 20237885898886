$qr-border-radius: map-get($spacers, 5);

.modal-download-app {
  .modal-content {
    border-radius: $qr-border-radius;
    .modal-body {
      padding-bottom: map-get($spacers, 7);
      padding-left: map-get($spacers, 7);
      padding-right: map-get($spacers, 7);
    }
  }


  &__item-container {
    & + & {
      margin-left: calc(map-get($spacers, 4) / 2) ;
    }
  }

  &__qr-canvas {
    width: 182px !important;
    height: 182px !important;
  }

  &__qr-container {
    border-radius: $qr-border-radius;
    padding: map-get($spacers, 3) * 2;

    @include light-theme-content {
      background-color: $gray-200;
    }
  }

  &__link-container {
    padding-top: $qr-border-radius + map-get($spacers, 2);
    padding-bottom: map-get($spacers, 2);
    margin-top: -$qr-border-radius;
    @include border-bottom-radius($qr-border-radius);

    @include light-theme-content {
      background-color: $black;
    }
  }

  &__link-btn {
    padding: calc(map-get($spacers, 4) / 4) calc(map-get($spacers, 4) / 2);
  }

}
