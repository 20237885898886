@use "sass:map";

// fonts
$font-family-base-game: 'Lilita One', $font-family-base;
$font-family-description-game: 'Poppins', $font-family-base;
$game-breakpoints: map-merge($grid-breakpoints, (
  xs: 0,
  sm: 375px,
  md: 415px
));
$game-desktop-border-radius: 1.5rem;
$game-desktop-breakpoint: 'sm';
$zindex-game-modal: 1025;
$zindex-game-tab-bar: $zindex-game-modal + 1;
$zindex-game-chat: $zindex-game-modal - 1;
$zindex-game-loading-screen: $zindex-game-modal + 2;
$zindex-game-onboarding: $zindex-game-modal + 3;
$zindex-game-reconnect: $zindex-game-modal + 4;  // should be the highest in game & the same as the intercom z-index


// colors
$primary-game: $purple;
$secondary-game: #46268F;
$danger-game: $red;
$success-game: $success;
$warning-game: $warning;
$grey-game: $grey;
$pink-game: #D94DF2;
$violet-game: $violet;
$blue-game: $blue;
$yellow-game: $yellow;

// spacers
$spacer-75: map.get($spacers, 2_5);
$disabled-opacity: .4;

// deprecated
$max-height-app: calc(100vh - var(--tab-bar-height) - var(--safe-area-inset-bottom) - var(--safe-area-inset-top));
$gradients-game: (
  'blue': linear-gradient(180deg, #2E95FE 0%, #2E5CFE 100%),
  'lightBlue': linear-gradient(0deg, #2E95FE 0%, #5BFFF4 100%),
  'green': linear-gradient(180deg, #1CCC8D 0%, #1CCCAC 100%),
  'purple': linear-gradient(180deg, #A240EF 0%, #7540EF 100%),
  'orange': linear-gradient(180deg, #FF2B5E 0%, #FF772B 100%),
  'yellow': linear-gradient(180deg, #FBA705 0%, #FB8A05 100%),
  'darkYellow': linear-gradient(180deg, #FBA705 0%, #FB2A05 100%)
);
$badge-light-color: $var-surface-10;
$badge-dark-color: rgba($dark, 0.5);
$volume-box-shadow: 0 1px 0 0 $black, 0 1px 0 0 rgba($black, 0.25);
$blur: 600px;
$border-radius-game: 16px;
$badge-game-border-radius: 2em;
$text-shadow-large: 0 5px 0 #000, -2px -2px 0 #000, 2px 2px 0 #000, -2px 2px 0 #000, 2px -2px 0 #000;
$text-shadow-base: 0 2px 0 $black, -0.5px -0.5px 0 $black, 0.5px 0.5px 0 $black, -0.5px 0.5px 0 #000, 0.5px -0.5px 0 $black;
$game-container-max-width: 536px;


$boost-button-width: 3.875em;
$intro-boost-offset: 4px;
