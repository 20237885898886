@use 'sass:map';

@mixin overlay-circle(
  $size: 28px,
  $offset: map.get($spacers, 2),
  $border-size: 2px,
  $border-color: var(--#{$prefix}modal-bg, var(--#{$prefix}card-bg, var(--element-bg-color, var(--body-bg)))),
  $direction: row
) {
  --overlay-circle-size: #{$size};
  --overlay-circle-offset: #{$offset};
  --overlay-circle-border-width: #{$border-size};
  --overlay-circle-border-color: #{$border-color};
  --overlay-circle-direction: #{$direction};
}

.overlay-circle {
  @include overlay-circle;

  display: flex;
  flex-direction: var(--overlay-circle-direction);
  margin: calc(var(--overlay-circle-border-width) * -1);
  padding-top: var(--overlay-circle-offset);
  padding-left: var(--overlay-circle-offset);

  &__item-host {
    position: relative;
    z-index: var(--overlay-circle-z-index);
    margin-top: calc(var(--overlay-circle-offset) * -1);
    margin-left: calc(var(--overlay-circle-offset) * -1);
    overflow: hidden;
    border-radius: 50%;
  }

  &__item {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: var(--overlay-circle-size);
    height: var(--overlay-circle-size);
    border: var(--overlay-circle-border-width) solid var(--overlay-circle-border-color);
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}