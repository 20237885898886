@use 'sass:map';
@import 'module-variables/banner-builder';
@import 'scss/mixins/banner-builder';

$banner-builder-image-wrapper: ".banner-builder__image-wrapper";

#{$banner-builder} {
  $font-size-xxl: 2rem;
  $banner-border-radius: var(--border-radius);

  --padding-start: #{map.get($spacers, 3)};
  --padding-end: 0;
  --border-radius: var(--banner-border-radius, 0);
  --padding-x: #{map.get($spacers, 3)};
  --padding-y: #{map.get($spacers, 3)};
  --bottom-gradient-height: 60%;
  --glow-size: 530px;
  --glow-width: var(--glow-size);
  --glow-left-offset: 100%;
  --glow-blur: 60px;
  --glow-opacity: 0.5;
  --text-width: auto;
  --text-shrink: 1;
  --image-wrapper-shrink: 0;
  --image-wrapper-width: 116px;
  --image-position: flex-end;
  --header-font-size: #{$font-size-lg};
  --header-line-height: 1.5rem;
  --header-offset-end: -#{map.get($spacers, 4)};
  --description-font-size: #{$font-size-sm};
  --description-line-height: 1.125rem;
  --description-offset: #{map.get($spacers, 1) * 0.5};

  &--light {
    @extend %light-theme;
  }

  &--dark {
    @extend %dark-theme;

    @include --color-button('primary-theme', $white);
  }

  &--safari {
    --glow-blur: 45px;
  }

  &--sm {
    --header-font-size: #{$font-size-sl};
    --header-line-height: 1.75rem;
    --description-font-size: #{$font-size-base};
    --description-line-height: 1.25rem;
    --image-wrapper-width: 156px;
  }

  &--md {
    --padding-start: #{map.get($spacers, 4)};
    --padding-y: #{map.get($spacers, 3)};
    --image-wrapper-width: 232px;
  }

  &--lg {
    --header-font-size: #{$font-size-xl};
    --header-line-height: 2rem;
    --description-font-size: #{$font-size-base};
    --description-offset: #{map.get($spacers, 1) * 1.5};
  }

  &--sl {
    --padding-start: #{map.get($spacers, 5)};
    --padding-y: #{map.get($spacers, 3)};
    --text-width: 600px;
    --text-shrink: 0;
    --header-font-size: #{$font-size-xxl};
    --header-line-height: 2.125rem;
    --description-font-size: #{$font-size-base};
    --description-offset: #{map.get($spacers, 2)};
    --image-wrapper-shrink: 1;
    --image-wrapper-width: 320px;
    --image-position: center;
    --glow-left-offset: calc(100px + 60%);
    --glow-blur: 90px;

    &#{$banner-builder}--safari {
      --glow-blur: 70px;
    }
  }

  &__container {
    position: relative;
    flex-grow: 1;
    height: var(--banner-height);
    overflow: hidden;
    border-radius: $banner-border-radius;
    background: var(--banner-color);
  }

  &__gradient {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--bottom-gradient-height);
    border-radius: 0 0 $banner-border-radius $banner-border-radius;
    background: var(--banner-bottom-gradient);
    pointer-events: none;
  }

  &__glow {
    position: absolute;
    z-index: 10;
    top: var(--banner-glow-top);
    width: var(--glow-width);
    height: var(--glow-size);
    border-radius: 50%;
    opacity: var(--glow-opacity);
    background: var(--banner-glow-color, transparent);
    filter: blur(var(--glow-blur));

    @include dir-ignore {
      transform: translate(-50%, -50%);
    }

    @include banner-builder-ltr {
      left: var(--glow-left-offset);
    }

    @include banner-builder-rtl {
      right: var(--glow-left-offset);
      transform: translate(50%, -50%);
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__start {
    display: flex;
    z-index: 200;
    flex: 1 var(--text-shrink) var(--text-width);
    flex-direction: column;
    justify-content: center;
    max-width: var(--text-width);
    padding: var(--padding-y) 0;

    @include banner-builder-ltr {
      padding-right: var(--padding-end);
      padding-left: var(--padding-start);
    }

    @include banner-builder-rtl {
      padding-right: var(--padding-start);
      padding-left: var(--padding-end);
    }
  }

  @include banner-builder-image-wrapper {
    display: flex;
    position: relative;
    z-index: 100;
    flex: 1 var(--image-wrapper-shrink) var(--image-wrapper-width);
    align-items: center;
    justify-content: var(--image-position);
    max-width: var(--image-wrapper-width);
    transform: translateZ(0); // hack for safari
  }

  &__end {
    display: flex;
    z-index: 200;
    flex-grow: 1 0 calc(200px + var(--padding-start));
    align-items: center;
    justify-content: end;
    min-width: calc(200px + var(--padding-start));
    padding: var(--padding-y) 0;

    @include banner-builder-ltr {
      padding-right: var(--padding-start);
    }

    @include banner-builder-rtl {
      padding-left: var(--padding-start);
    }
  }

  @include banner-builder-image {
    z-index: 300;
    min-width: var(--banner-image-width, 100%);
    height: var(--banner-image-height, var(--banner-height));
  }

  &__header {
    display: inline-block;
    margin-bottom: 0;
    font-family: $font-family-headline;
    font-size: var(--header-font-size);
    font-weight: $font-weight-medium;
    line-height: var(--header-line-height);

    &--xs {
      margin-bottom: map.get($spacers, 1);
      line-height: 1.1;
    }
  }

  &__description {
    margin-top: var(--description-offset);
    font-size: var(--description-font-size);
    line-height: var(--description-line-height);
  }
}
