$host: '.progressbar';

.progressbar {
  --progress-color: #{color-contrast($primary)};
  --progress-bar-height: 32px;
  --progress-bg-color: #{rgba($primary, .5)};

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: var(--progress-bar-height);
  overflow: hidden;
  border-radius: $border-radius;
  background-color: var(--progress-bg-color);
  font-weight: $font-weight-semibold;
  isolation: isolate;

  &__fill {
    --progress-bar-color: #{$primary};

    position: absolute;
    transform-origin: left center;
    transition: transform $transition;
    background-color: var(--progress-bar-color);
    inset: 0;

    &--rounded {
      border-radius: $border-radius;
    }

    &--orange {
      --progress-bar-color: #{$orange};
    }

    &--blue {
      --progress-bar-color: #{$ethereum-color};
    }

    &--green {
      --progress-bar-color: #{$green};
    }

    &--purple {
      --progress-bar-color: #{$purple};
    }
  }

  &__no-fill {
    --progress-no-fill-color: transparent;
    position: absolute;
    transform-origin: right center;
    transition: transform $transition;
    background-color: var(--progress-bar-color);
    inset: 0;

  }

  &__value {
    position: relative;
    color: var(--progress-color);
  }
}
