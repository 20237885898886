@use 'sass:map';

.steps-bar-progress {
  --steps-container-padding: #{0 map.get($spacers, 1) * 0.5};
  --step-margin: #{map.get($spacers, 1)};
  --step-size: #{map.get($spacers, 5)};
  --step-border-width: 0.125rem;
  --step-border-color: currentcolor;
  --step-default-dot-size: #{map.get($spacers, 2) + map.get($spacers, 1)};
  --step-default-dot-color: currentcolor;
  --step-text-offset: #{map.get($spacers, 1)};

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--steps-container-padding);
  padding-bottom: calc(var(--step-text-offset) + $font-size-sm * 1.5 - var(--step-margin));

  &__item {
    display: flex;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: var(--step-size);
    height: var(--step-size);
    margin: var(--step-margin);
    border: var(--step-border-width) solid var(--step-border-color);
    border-radius: 50%;
    color: $var-grey;

    &::after {
      content: var(--text, '');
      display: block;
      position: absolute;
      top: calc(100% + var(--step-text-offset) + var(--step-border-width));
      left: 50%;
      transform: translateX(-50%);
      color: currentcolor;
      font-size: $font-size-sm;
      font-weight: $font-weight-semibold;
      white-space: nowrap;
    }

    &--default {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: var(--step-default-dot-size);
        height: var(--step-default-dot-size);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: var(--step-default-dot-color);
      }
    }

    &--active {
      color: $var-primary-theme;
    }
  }

  &__line {
    flex-grow: 1;
    transition: color $transition;
    border-top: 2px dotted currentcolor;
    color: $var-grey;

    &--active {
      color: $var-primary-theme;
    }
  }
}
