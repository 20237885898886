@use "sass:map";

$avatar-border-color: #2B1B58;

.participant-info {
  $participant-info-card-border-radius: $border-radius-lg;
  $participant-info-card-border-width: $border-width;

  &.info-card {
    border-radius: $participant-info-card-border-radius;
    border-width: $participant-info-card-border-width;
  }

  &__avatar {
    --avatar-img-border-radius: .75rem !important;
    --avatar-placeholder-border-radius: .75rem !important;

    width: 44px;
    height: 44px;
  }

  &__clan-avatar {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    border: 2px solid $avatar-border-color;
    border-radius: 50%;
  }

  &__main {
    margin: 0 calc(map.get($spacers, 4) / 2);
  }

  &__rate {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__star {
    background-image: url("../../../../assets/images/miner-wars/star-rating.png");
  }


  &__boost, &__main-stat {
    margin-right: map.get($spacers, 4);
  }

  &--with-strip.info-card {
    position: relative;
    padding-top: map.get($spacers, 5) + $spacer-75;
  }

  &__header-strip {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: map.get($spacers, 5);
    background-color: $warning-game;

    @include border-top-radius($participant-info-card-border-radius - rem($participant-info-card-border-width));
  }

}

.participant-info-small {
  .participant-info__avatar {
    width: 36px;
    height: 36px;
  }

  .participant-info__boost, .participant-info__main-stat {
    margin-right: calc(map.get($spacers, 4) / 2);
  }
}
