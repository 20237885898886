%card-staking-type__btn-opacity {
  $color: $white;

  @include button-variant(
      rgba($color, .1),
      transparent, $color,
      rgba($color, .2),
      transparent,
      $color,
      rgba($color, .2),
      transparent,
      $color,
      rgba($color, .1),
      transparent,
      $color
  );
}

.modal-nft-marketplace-filters {
  .modal-body {
    @include media-breakpoint-down(sm) {
      padding: map-get($spacers, 3) + map-get($spacers, 1);
    }
  }

  &__card {
    $padding: map-get($spacers, 2) + map-get($spacers, 1);

    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 100%;
    padding: $padding;
    color: $white;
    background: linear-gradient(132.67deg, #7540EF 4.77%, #A882FF 128.54%);
    border-radius: $border-radius-lg;

    @include media-breakpoint-up(md) {
      flex-direction: column;
      align-items: stretch;
      padding: map-get($spacers, 3);
    }

    &--active &-image {
      @include media-breakpoint-up(md) {
        height: 70%;
      }
    }

    &--collection {
      background: $gray-800;
      border: $border-width solid rgba($white, .3);
    }

    &--collection &-decor {
      align-self: stretch;
      -webkit-filter: drop-shadow(0 0 15px $white);
      filter: drop-shadow(0 0 15px $white);

      @include media-breakpoint-up(md) {
        -webkit-filter: drop-shadow(0 0 40px $white);
        filter: drop-shadow(0 0 40px $white);
      }
    }

    &--collection &-image {
      position: absolute;
      top: -$padding;
      height: 100%;
      object-position: top;
      max-width: 50px;

      @include media-breakpoint-up(md) {
        top: -50px;
        height: 140%;
        max-width: 80px;
      }
    }
  }

  &__card-decor {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    min-height: 40px;
    margin-right: map-get($spacers, 3);

    @include media-breakpoint-up(md) {
      width: auto;
      height: 90px;
      margin-right: 0;
      margin-bottom: map-get($spacers, 4);
    }
  }

  &__card-image {
    object-fit: contain;
    width: 100%;
    height: 100%;
    mix-blend-mode: screen;
  }

  &__card-content {
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  &__card-title {
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    margin-bottom: map-get($spacers, 1);

    @include media-breakpoint-up(md) {
      font-size: $lead-font-size;
      margin-bottom: map-get($spacers, 2);
    }
  }

  &__card-text {
    color: rgba($white, .8);
    font-size: $small-font-size;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
      margin-bottom: map-get($spacers, 3);
    }
  }

  &__card-btn {
    @extend %card-staking-type__btn-opacity;
  }

  &__card-caret {
    color: rgba($white, .5);

    svg {
      width: 14px;
      height: 8px;
    }
  }
}
