.select-currencies {
  &.form-control {
    height: $input-height;
  }

  &.form-control-sm {
    height: $input-height-sm;
  }

  &.form-control-lg {
    height: $input-height-lg;
  }

  &.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    position: absolute;
    left: map-get($spacers, 3);
    width: auto;
    right: map-get($spacers, 3);
  }

  .ng-dropdown-panel {
    width: auto;
    min-width: 100%;
  }

  &--fix-width {
    .ng-select-container {
      width: 105px!important;
      min-height: 45px;
      transition: width .3s ease;
    }
  }

  &--width {
    .ng-select-container {
      width: 145px!important;
    }
  }

  &--with-search {
    .ng-dropdown-panel {
      width: 180px !important;
    }
  }

  .ng-dropdown-header {
    padding-right: 0;
    padding-left: 0;

    & + .ng-dropdown-panel-items .ng-option:first-child {
      @include border-top-radius(0);
    }
  }

  &__search-input-group {
    .form-control {
      font-size: $input-font-size-sm !important;
      height: $input-height-sm !important;
      min-height: $input-height-sm !important;
    }

    .form-control,
    .input-group-append,
    .input-group-prepend {
      border-bottom-width: $input-border-width !important;
    }
  }
}
