@use "sass:map";

.social-share {
  &__images {
    position: relative;
    margin-bottom: 1rem;
    user-select: none;

    @include owl-carousel-dots($dots-offset: - (map.get($spacers, 5)));

    --dot-color: #{rgba($var-grey, 0.4)};
    --dot-size: 6px;

    .carousel-inner {
      overflow: hidden;
      border-radius: .75rem;
    }

    .carousel-indicators {
      bottom: -2.1rem;

      [data-bs-target], li {
        width: .5rem;
        height: .5rem;
        border-width: .125rem;
        border-style: solid;
        border-radius: 100%;
        border-color: transparent;
        opacity: .2;
        background-color: $var-primary-theme;

        &.active {
          border-color: $var-primary-theme;
          opacity: 1;
        }
      }
    }
  }

  &__arrows {
    margin-top: 1rem;
  }

  &__comment {
    white-space: pre-wrap;
  }

  &__arrow-item {
    transition: $transition;
    opacity: .2;
    color: $var-primary-theme;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &__nft-frame {
    top: 0;
    left: 0;
  }

  &__nft-wrapper {
    position: absolute;
    top: 50%;
    width: 58%;

    @include dir-ignore {
      right: -15%;
    }
  }

  &__nft-image {
    width: 100%;
    padding-bottom: 100%;
    transform: translateY(-50%);
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  &__edit-btn {
    position: absolute;
    z-index: 1;
    top: .5rem;
    color: $white;
    cursor: pointer;

    @include dir-ignore {
      right: .5rem;
    }
  }

  &__nft-window {
    position: absolute;
    z-index: 2;
    top: 2rem;
    right: 2rem;
    width: 298px;
    height: 500px;
    max-height: 80vh;
    max-height: 80svh;
    overflow: hidden;
  }

  &__nft-list {
    position: relative;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    background: $body-bg;
  }

  &__window-close {
    position: absolute;
    top: map.get($spacers, 3) * 1.25;
    right: map.get($spacers, 4);
    color: $gray-400;
    cursor: pointer;
  }

  &__nft-item {
    display: block;
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      z-index: 0;

      @include inset(0);

      border-radius: .375rem;
    }

    &--selected {
      &::before {
        background: linear-gradient(135deg, #7540EF 1.5%, #EB7DAF 72.78%, #F07D7D 91.78%);
      }
    }
  }

  &__nft-item-image {
    position: relative;
    z-index: 1;
    padding: 0.125rem;
    border-radius: .375rem;
  }
}
