@use "sass:map";

$step-indicator-size: 1.5rem;
$step-indicator-offset: 0.125rem;
$step-indicator-size-inner: 0.625rem;
$step-line-width: 0.125rem;
$step-indicator-color: rgba($grey, .2);
$step-indicator-completed-color: $success;

.stepper {
  &__step {
    --step-color: #{$step-indicator-color};

    display: block;
    position: relative;
    padding-left: calc($step-indicator-size + map.get($spacers, 2));

    &:not(&:last-child) {
      padding-bottom: map.get($spacers, 2_5);

      &::before {
        content: '';
        position: absolute;
        top: $step-indicator-size;
        bottom: $step-indicator-offset * 2;
        left: calc($step-indicator-size / 2) - calc($step-line-width / 2);
        width: $step-line-width;
        background-color: var(--step-color);
      }
    }

    &-indicator {
      position: absolute;
      top: -$step-indicator-offset;
      left: 0;
      width: $step-indicator-size;
      height: $step-indicator-size;
      border: 2px solid var(--step-color);
      border-radius: 50%;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: $step-indicator-size-inner;
        height: $step-indicator-size-inner;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: var(--step-color);
      }
    }

    &--completed {
      --step-color: #{$step-indicator-completed-color};
    }
  }
}
