@use 'sass:map';

$bottom-sheet-padding: map.get($spacers, 3);
$bottom-sheet-transition-duration: 1s;
$bottom-sheet-transition-timing-function: cubic-bezier(.44, .63, 0, 1);

.app-bottom-sheet-container {
  position: fixed;
  z-index: $zindex-bottom-sheet;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;

  &--priority-overlay {
    z-index: $zindex-modal-priority-overlay;
  }
}

.app-bottom-sheet-overlay {
  position: absolute;
  transition: opacity $bottom-sheet-transition-duration $bottom-sheet-transition-timing-function;
  opacity: 0;
  background-color: rgba($black, .5);

  @include light-theme-content {
    background-color: rgba($black, .2);
  }

  .miner-wars-chat & {
    background-color: rgba($black, .9);
  }

  &.active {
    opacity: 1;
  }

  &.dragging {
    transition: none;
  }

  @include inset(0);
}

.app-bottom-sheet {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: translate3d(0, 100%, 0);
  transition: transform $bottom-sheet-transition-duration $bottom-sheet-transition-timing-function;
  box-shadow: 0 0 map.get($spacers, 2) rgba($black, 0.1);

  @include border-top-radius($border-radius);

  &.active {
    transform: translate3d(0, 0, 0);
  }

  &.dragging {
    transition: none;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 93vh;
    height: 93svh;
    max-height: 93vh;
    max-height: 93svh;
    padding-top: $bottom-sheet-padding;
    background-color: $var-subface;
  }

  &--sm &__wrapper {
    height: 65vh;
    height: 65svh;
  }

  &--auto &__wrapper {
    height: auto;
  }

  &__btn-close {
    @extend %close;
  }

  &__handle {
    $offset-top: map.get($spacers, 1);
    $padding-bottom: map.get($spacers, 2);
    $padding-top: map.get($spacers, 5);
    $padding-x: map.get($spacers, 6);

    position: absolute;
    top: $offset-top;
    left: 50%;
    margin-top: -#{$padding-top};
    padding: $padding-top $padding-x $padding-bottom;
    transform: translateX(-50%);
    touch-action: pan-y;

    &::before {
      content: '';
      display: block;
      width: 30px;
      height: 5px;
      border-radius: 10rem;
      background-color: $var-surface-10;
    }
  }

  &__header {
    display: flex;
    position: relative;
    z-index: 2;
    align-items: center;
    padding-right: calc(#{$bottom-sheet-padding} + var(--safe-area-inset-right));
    padding-left: calc(#{$bottom-sheet-padding} + var(--safe-area-inset-left));

    &-section {
      flex: 0 1 100%;
    }
  }

  &__content {
    padding-right: calc(#{$bottom-sheet-padding} + var(--safe-area-inset-right));
    padding-bottom: calc(#{$bottom-sheet-padding} + var(--safe-area-inset-bottom));
    padding-left: calc(#{$bottom-sheet-padding} + var(--safe-area-inset-left));
    overflow: hidden auto;
    touch-action: pan-y;
  }
}

//html {
//  &.app-bottom-sheet--active {
//    background-color: $black;
//
//    body {
//      transform: scale(.95);
//      transition: transform $bottom-sheet-transition-duration $bottom-sheet-transition-timing-function;
//    }
//  }
//}
