.modal-social-links {
  .modal-presenter__image {
    background-position: center;
    background-size: cover;

    @media (max-width: 400px) {
      background-size: 120%;
    }

    @include media-breakpoint-up(md) {
      min-height: 250px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 300px;
      max-width: 288px;
    }
  }
}
