@include game-style {
  .page-item {
    &:not(:first-child) .page-link {
      margin-left: calc(map-get($spacers, 4) / 4);
    }

    &:first-child,
    &:last-child {
      opacity: 1;
    }

    &.active {
      .page-link:not([aria-label="Next"]):not([aria-label="Previous"]):not([aria-disabled]) {
        background: $violet-game !important;
      }
    }

    .page-link {
      @extend %text-shadow;
      &:not([aria-label="Next"]):not([aria-label="Previous"]):not([aria-disabled]) {
        --#{$prefix}btn-border-radius: #{$border-radius};

        padding: 0;
        @extend %btn, %btn-game;
        background: $secondary-game;


        &:hover, &:focus, &:active, &:focus-visible {
          background: $violet-game !important;
        }
      }

      &[aria-label="Next"],
      &[aria-label="Previous"] {
        &:hover, &:focus, &:active, &:focus-visible {
          background-color: transparent;
        }
      }

      &[aria-disabled="true"] {
        opacity: $disabled-opacity;
      }
    }
  }
}
