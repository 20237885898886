.infinite-scroll-container {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;

  &--dir-top {
    flex-direction: column;
  }

  &--dir-bottom {
    flex-direction: column-reverse;
  }

  &__preloader {
    position: relative;
    min-height: 40px;
  }
}