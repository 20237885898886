@mixin game-breakpoint-up($name, $breakpoints: $grid-breakpoints, $desktop-breakpoint: $game-desktop-breakpoint) {
  $min: breakpoint-min($name, $breakpoints);
  $desktop-min: breakpoint-min($desktop-breakpoint, $game-breakpoints);

  .desktop-game & {
    @if ($min <= $desktop-min) {
      @media (min-width: $min) {
        @content;
      }
    } @else {

    }
  }

  .app-game & {
    @if $min {
      @media (min-width: $min) {
        @content;
      }
    } @else {
      @content;
    }
  }
}
