@use 'sass:map';

.preloader {
  display: flex;
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: opacity .15s ease;
  animation: show .15s ease;

  @include inset(0);

  // States
  &.show {
    opacity: 1;
  }

  &.fade {
    display: none;
    opacity: 0;
  }

  // Positions
  &--position-start {
    margin: auto auto auto 0;
  }

  &--position-center {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
  }

  &--position-end {
    margin: auto 0 auto auto;
  }

  &__info {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    width: 100%;
    margin-top: map-get($spacers, 3);
    text-align: center;
  }

  // Modification
  &--content {
    position: relative;
  }

  &--card {
    background-color: $card-bg;
  }

  &--overlap {
    position: fixed;
    z-index: 1061;
    background-color: var(#{map.get($element-vars, 'bgColor')}, var(--preloader-overlay-bg));
  }

  &--overlay {
    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      opacity: .8;
      background-color: var(#{map.get($element-vars, 'bgColor')}, var(--preloader-overlay-bg));

      @include inset(0);
    }
  }
}

@keyframes show {
  from { opacity: 0 }
  to { opacity: 1 }
}
