@use 'sass:map';
@import 'scss/placeholders';

%full-page-fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@mixin media-landscape($grid-breakpoint) {
  @media (orientation: landscape) and (max-height: map-get($grid-breakpoints, $grid-breakpoint)) {
    @content;
  }
}

@mixin media-portrait($grid-breakpoint) {
  @media (min-width: map-get($grid-breakpoints, $grid-breakpoint)) and (orientation: portrait) {
    @content;
  }
}

@mixin portrait-margin($minHeight, $marginTop, $grid-breakpoint) {
  @media (max-width: map-get($grid-breakpoints, $grid-breakpoint)) and (min-height: $minHeight) and (orientation: portrait) {
    margin-top: $marginTop;
  }
}

$skip-header-height: 2.5rem;

.miner-wars-onboarding {
  @extend %app-onboarding;
  position: absolute !important;
  z-index: $zindex-game-onboarding !important;
  overflow: hidden auto;
  $controls-padding-bottom: 7.125rem;
  padding:  0 !important;

  &__content {
    @extend %app-onboarding__content;
    max-width: 100% !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1040;

    &:not(&--1) {
      background-image: url('../../../../assets/images/miner-wars/mw-bg-2.png');

      @include game-breakpoint-up(sm) {
        background-image: url('../../../../assets/images/miner-wars/mw-bg-2-ipad.png');
      }
    }

    &--1 {
      background-image: url('../../../../assets/images/miner-wars/mw-bg-start.png');
    }

    @include game-breakpoint-up(sm) {
      max-height: 100% !important;
      border-radius: unset !important;
    }
  }

  &__header {
    z-index: 2;
    display: flex;
    padding-right: $main-offset;
    padding-top: $main-offset;
    position: absolute;
    top: var(--safe-area-inset-top);
    right: var(--safe-area-inset-right);
    height: $skip-header-height;

    @include game-breakpoint-up(sm) {
      top: 0;
      right: 0;
    }
  }

  &__carousel {
    height: 100%;
  }

  &__slide {
    padding-bottom: $controls-padding-bottom;

    &-inner {
      display: flex;
      justify-content: center;
    }

    &-img {
      max-width: 500px;

      @include media-landscape(sm) {
        max-width: 300px;
      }

      @include media-portrait(md) {
        margin-top: 4rem;
      }

      @include portrait-margin(1100px, 9rem, sl);
      @include portrait-margin(1200px, 13rem, sl);

      @include portrait-margin(720px, 3.5rem, sm);
      @include portrait-margin(770px, 6rem, sm);
      @include portrait-margin(1000px, 9.5rem, sm);
      @include portrait-margin(1120px, 12rem, sm);
    }

  }

  &__slide-content {
    padding-bottom: $controls-padding-bottom;
  }

  &__slide-content,
  &__content-controls {
    padding-right: calc(#{$main-offset} + var(--safe-area-inset-right));
    padding-left: calc(#{$main-offset} + var(--safe-area-inset-left));
    position: absolute;
    bottom: var(--safe-area-inset-bottom);
    left: 0;
    right: 0;

    @include game-breakpoint-up(sm) {
      padding-right: $main-offset;
      padding-left: $main-offset;
      bottom: 0;
    }
  }

  &__content-controls {
    z-index: 1;
    padding-bottom: $main-offset;
  }

  &__dots {
    @extend %app-onboarding__dots;
  }

  &__dot-item {
    @extend %app-onboarding__dot-item;
    background-color: $grey;
    opacity: .3;
    transition-property: background-color, width, opacity;

    & + & {
      margin-left: map.get($spacers, 1);
    }

    &.active {
      @extend %app-onboarding__dot-item-active;
      opacity: 1;
    }
  }

  &__alias-page {
    @extend %full-page-fixed;
    background-image: url('../../../../assets/images/game/background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1050;
  }
}
