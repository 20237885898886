@use "sass:map";
@use "sass:math";

@import 'module-variables/brand-slider-vars';

.maintenance-discount {
  padding: map.get($spacers, 3);
  background-color: $aside-bg;

  @include border-radius($border-radius-xl);

  &__slider {
    display: block;

    .brand-slider {
      --default-bar-color: #{ rgba($grey, .2) };

      .ngx-slider {
        .ngx-slider-pointer-min {
          border-color: transparent !important;
          background-color: transparent !important;
          box-shadow: none !important;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2px;
            height: $ngx-slider-bar-height;
            transform: translate(-50%, -50%);
            background-color: $danger;
          }
        }

        .ngx-slider-selection {
          @include border-start-radius(0);
        }
      }

      &__pointer-label::before {
        padding: map.get($spacers, 1);
        font-size: $extra-small-font-size;
        line-height: $line-height-base;
      }

      &__tick::after {
        color: $grey;
      }

      &__tick--bold {
        background-color: $grey;
      }

      &__footer {
        color: $grey;
      }

      &--read-only {
        .ngx-slider {
          .ngx-slider-pointer {
            display: none !important;
          }
        }
      }
    }
  }

  &__info {
    padding: map.get($spacers, 2_5) map.get($spacers, 3) + map.get($spacers, 1);
    border: $border-width solid $border-color;
    border-radius: $border-radius-lg;
  }

  &__square {
    flex-shrink: 0;
    width: 0.875rem;
    height: 0.875rem;
    margin-right: map.get($spacers, 1) * 1.5;
    border-radius: $border-radius-xs;
    background: var(--color);
  }

  &__progress-bar {
    display: block;

    .progressbar {
      height: 8px;
      background-color: rgba($grey, .2);

      &, &__fill {
        border-radius: 10rem;
      }

      &__fill {
        background: $blue;

        @include border-end-radius(0);
      }
    }
  }

  &__circle-progress {
    $check-size: 24px;
    $check-size-inner: 16px;
    $check-color: color-contrast($blue);
    $check-bg: $var-surface-10;
    $check-bg-active: $blue;
    $label-color: $grey;
    $label-color-active: $body-color;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: math.div(map.get($spacers, 1), 2);
    }

    &-label {
      transition: color $transition;
      color: $grey;
      font-size: 8px;
      font-weight: $font-weight-semibold;
    }

    &-item.active &-check {
      border-color: $check-bg-active;

      *, &::before {
        opacity: 1;
      }
    }

    &-item.active &-label {
      color: $label-color-active;
    }
  }
}
