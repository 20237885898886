.skeleton {
  font-size: 0 !important;
  line-height: 1;
  display: inline-block;
  overflow: hidden;
  position: relative;
  background-color: var(--skeleton-bg);
  border-radius: 8px;
  transition: width .3s ease, height .3s ease;

  &.animated {
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(90deg, rgba($white, 0), rgba($white, 0.3), rgba($white, 0));
      animation: skeletonProgress 1s ease-in-out infinite;
    }
  }
}

@keyframes skeletonProgress {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}
