@use "sass:map";

.locale-switcher {
  position: relative;

  &__btn {
    --offset: 6px;
    --#{$prefix}btn-bg: #{$body-bg};
    --#{$prefix}btn-color: #{$dark};

    padding: .375rem .5rem;
    border-radius: $border-radius;
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;

    &--grey {
      background-clip: padding-box;

      $bg-color: $var-surface-5;
      $bg-hover-color: $var-surface-8;
      $bg-active-color: $var-surface-10;
      $color: $var-grey;

      @include button-variant(
          $bg-color,
          $bg-color,
          $color,
          $bg-hover-color,
          $bg-hover-color,
          $color,
          $bg-active-color,
          $bg-active-color,
          $color,
          $bg-color,
          $bg-color,
          $color,
      );
    }

    .twa & {
      --#{$prefix}btn-bg: #{$aside-bg};

      padding: 0.625rem;
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
      line-height: 1;

      &:hover {
        color: $body-color;
      }
    }
  }

  &__menu {
    min-width: auto !important;
    max-height: 70vh;
    overflow: auto;

    &.dropdown-menu {
      margin-top: $dropdown-spacer;
    }

    .dropup &.dropdown-menu {
      top: 0 !important;
      margin-top: 0;
    }
  }
}
