.filter-block {
  $host: &;

  .card {
    border: none;
    border-radius: 0;
  }

  .card-header {
    padding: 0;
  }

  .card-body {
    padding: .75rem 0;
  }

  &__title {
    display: flex;
  }

  &__dropdown-toggle {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;

    #{$host} &--has-reset.caret-down::after {
      margin-left: 0;
    }
  }

  &__btn-reset {
    order: 1;
    margin-left: auto;
  }

  .nft-label {
    &:not(&--dark) {
      background: $aside-bg;
      color: $var-surface-50;

      /* stylelint-disable-next-line selector-class-pattern */
      &.nft-label--softred {
        background: $red;
        color: $white;
      }
    }
  }

  .caret-down {
    &::after {
      flex-shrink: 0;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: auto;
      transition: $transition;
      transition-property: transform, background-color;
      color: $var-grey;
      order: 2;
      mask-image: url('../../../app/resources/entities/icons/icon-caret-filter/icon-caret-filter.component.svg');
    }
  }
}
