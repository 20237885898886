.nav {
  display: flex;
  white-space: nowrap;
  overflow: auto;

  &, &__wrapper {
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar { /* chrome based */
    display: none;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    background-color: $aside-bg;
  }

  &__item {
    position: relative;
    padding: map-get($spacers, 2) map-get($spacers, 2) + map-get($spacers, 1);
    color: $var-surface-50;
    font-weight: $font-weight-medium;
    border-radius: $border-radius;
    transition: $transition;
    transition-property: opacity, color, background-color;

    &:not(.active):hover {
      color: $var-surface;
    }

    &.active {
      color: $var-primary-theme-contrast;
      background-color: $var-primary-theme;
    }

    &.disabled {
      opacity: .5;
    }
  }
}

