@use "sass:map";

$zindex-modal-header-circle: 10;

@include game-style {
  .app-tab-bar {
    z-index: $zindex-game-tab-bar;
  }

  .modal-game__container {
    position: absolute;
    bottom: $tab-bar-height;
    height: auto;
  }

  .modal {
    z-index: $zindex-game-modal;

    &.modal-priority-overlay {
      z-index: $zindex-modal-priority-overlay;
    }

    &.fade {
      transition: opacity 75ms ease-in-out;

      .modal-dialog {
        transform: scale(.8);
        transition: transform 75ms ease-in-out;
      }
    }

    &.show {
      .modal-dialog {
        transform: none;
      }
    }
  }

  .modal.modal-round-finished {
    &.fade {
      transition: opacity 300ms ease-in-out;
      will-change: opacity;

      .modal-dialog {
        transition: none;
        animation: hide-finished 300ms ease-in-out both;
      }
    }

    &.show {
      transition: opacity 800ms ease-in-out;

      .modal-dialog {
        animation: show-finished 300ms 800ms ease-in-out both;
      }
    }

    @keyframes hide-finished {
      from {
        transform: scale(1);
        opacity: 1;
      }

      to {
        transform: scale(.8);
        opacity: 0;
      }
    }

    @keyframes show-finished {
      from {
        transform: scale(.8);
        opacity: 0;
      }

      to {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

.modal-header-circle {
  position: absolute;
  z-index: $zindex-modal-header-circle;
  top: -64px;
  left: calc(50% - 64px);
  width: 128px;
  height: 128px;
  border: 8px solid $primary-game;
  border-radius: 50%;
  background-size: contain;
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 50%);
}

.modal-game.modal-dialog {
  @include game-breakpoint-up(sm) {
    --#{$prefix}modal-margin: #{$modal-dialog-margin};

    padding-right: var(--#{$prefix}modal-margin);
    padding-left: var(--#{$prefix}modal-margin);
  }
}


@include game-style {
  .modal-dialog .modal-game,
  .modal-game.modal-dialog, .intro {
    --#{$prefix}modal-header-padding: #{map.get($spacers, 4) map.get($spacers, 4) map.get($spacers, 2) map.get($spacers, 4)};
    --#{$prefix}modal-padding: #{map.get($spacers, 4)};
    --#{$prefix}modal-bg: #{$secondary-game};
    --#{$prefix}modal-border-width: 8px;
    --#{$prefix}modal-border-color: #{$primary-game};
    --#{$prefix}modal-border-radius: #{map.get($spacers, 5)};
    --#{$prefix}modal-title-line-height: 1.2;

    .modal-header {
      margin: 0;
      border: none;
      font-size: $font-size-sl;
    }

    .modal-body {
      text-align: center;
    }

    .modal-content {
      margin-top: map.get($spacers, 6);
      margin-bottom: map.get($spacers, 6);
    }


    &:not(.modal-game--header-border) {
      .modal-controller {
        @include card-game-border($border-radius: map.get($spacers, 4), $border-width: 2px);
      }

      .modal-body {
        padding-top: 0;
      }
    }

    &.modal-game--header-border {
      --#{$prefix}modal-bg: #{$primary-game};
      --#{$prefix}modal-header-padding: #{map.get($spacers, 2) map.get($spacers, 4) 0 map.get($spacers, 4)};

      background-clip: border-box;

      .modal-controller::before {
        content: none;
      }

      .modal-body {
        @include card-game-border($border-radius: map.get($spacers, 4), $border-width: 2px);
        @include border-top-radius(0);

        background-color: $secondary-game;
      }

      .modal-header {
        padding-top: 0;
        padding-bottom: map.get($spacers, 2);
        text-transform: uppercase;
      }

      .modal-content {
        background-clip: unset;
      }

      .modal-game-header {
        padding-block: 0.25rem;
      }
    }

    &.modal-game--header-image {
      .modal-header {
        --#{$prefix}modal-header-padding: #{84px map.get($spacers, 4) map.get($spacers, 2) map.get($spacers, 4)};

        padding-top: 0;
      }

      .modal-controller {
        padding-top: calc(var(--modal-avatar-size) / 2 + map.get($spacers, 3));
      }

      &.modal-game--no-top-padding {
        .modal-controller {
          padding-top: 0;
        }
      }

      .modal-content {
        margin-top: calc(var(--modal-avatar-size) / 2 - var(--#{$prefix}modal-border-width));
        font-family: $font-family-base-game;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url('../../../../assets/images/game/modal-game-reward-background.svg');
          background-repeat: no-repeat;
          background-position: top center;
        }
      }

    }

    .close, .close-game {
      @include border-gradient(linear-gradient(180deg, $white 0%, $black 100%), 2.67px, true);
      @include btn-game-active-style;

      position: absolute;
      z-index: 3;
      top: -4px;
      right: -4px;
      width: 32px;
      height: 32px;
      background-color: $danger-game;
      box-shadow: 0 0 0 2px #321B64;
      color: $var-surface;
      border-radius: 100%;

      &::before {
        mix-blend-mode: soft-light;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &.modal-game--header-image.modal-game--skip-rays {
      .modal-content {
        &::before {
          content: none;
        }
      }
    }

    &.modal-game--video {
      $modal-video-close-top: -35px;

      .modal-content {
        overflow: visible;
        border: none;
        border-radius: 0;
      }

      .modal-controller {
        &::before {
          background: unset;
        }
      }

      .modal-video__close {
        position: absolute;
        top: $modal-video-close-top;
        right: 0;
        color: $white;
        font-family: $font-family-base-game;
      }
    }
  }
}

.modal-game {
  &--avatar {
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      inset: 0;
      background: var(--modal-game-header-background);
      pointer-events: none;
    }

    &-img {
      position: relative;
      z-index: 3;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: var(--modal-game-header-background, inherit);
      object-fit: cover;
    }
  }
}

.modal-game-card {
  &__before {
    position: absolute;
    top: -35px;
    width: 100%;
    font-size: 1.25rem;
    text-align: center;
  }

  &__list {
    margin-top: 0.75rem;
  }
}

.modal-game-avatar-tag {
  position: absolute;
  transform: translate(-50%, -50%);
  top: calc(var(--modal-avatar-size) / 2);
  left: 50%;
  z-index: $zindex-modal-header-circle;
}

.max-game-zindex {
  z-index: $zindex-game-reconnect !important;
}

.modal-min-width {
   @include game-breakpoint-up(sm) {
     min-width: 400px;
   }
}
