@use "sass:map";
@use "sass:math";

.input-presenter {
  $padding-y: map.get($spacers, 2) + math.div(map.get($spacers, 1), 2);
  $padding-x: map.get($spacers, 2) + map.get($spacers, 1);

  color: $body-color;
  padding: $padding-y $padding-x;
  background-color: $var-aside-bg;
  border-radius: $border-radius;
}
