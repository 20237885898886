@import "theme-placeholders";

body {
  // Note: Custom variable values only support SassScript inside `#{}`.

  // Colors
  //
  // Generate palettes for full colors, grays, and theme colors.

  @each $color, $value in $colors {
    --#{$prefix}#{$color}: #{$value};
  }

  @each $color, $value in $grays {
    --#{$prefix}gray-#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --#{$prefix}#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors-rgb {
    --#{$prefix}#{$color}-rgb: #{$value};
  }

  --#{$prefix}white-rgb: #{to-rgb($white)};
  --#{$prefix}black-rgb: #{to-rgb($black)};
  --#{$prefix}body-color-rgb: #{to-rgb($surface-dark)};
  --#{$prefix}body-bg-rgb: #{to-rgb($body-bg-dark)};

  // Fonts

  // Note: Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --#{$prefix}font-sans-serif: #{inspect($font-family-sans-serif)};
  --#{$prefix}font-monospace: #{inspect($font-family-monospace)};
  --#{$prefix}gradient: #{$gradient};

  // Root and body
  // scss-docs-start root-body-variables
  @if $font-size-root != null {
    --#{$prefix}root-font-size: #{$font-size-root};
  }

  --#{$prefix}body-font-family: #{$font-family-base};

  @include rfs($font-size-base, --#{$prefix}body-font-size);

  --#{$prefix}body-font-weight: #{$font-weight-base};
  --#{$prefix}body-line-height: #{$line-height-base};
  --#{$prefix}body-color: #{$body-color};

  @if $body-text-align != null {
    --#{$prefix}body-text-align: #{$body-text-align};
  }

  --#{$prefix}body-bg: #{$body-bg};

  // scss-docs-end root-body-variables

  // scss-docs-start root-border-var
  --#{$prefix}border-width: #{$border-width};
  --#{$prefix}border-style: #{$border-style};
  --#{$prefix}border-color: #{$border-color};
  --#{$prefix}border-color-translucent: #{$border-color-translucent};
  --#{$prefix}border-radius: #{$border-radius};
  --#{$prefix}border-radius-sm: #{$border-radius-sm};
  --#{$prefix}border-radius-lg: #{$border-radius-lg};
  --#{$prefix}border-radius-xl: #{$border-radius-xl};
  --#{$prefix}border-radius-2xl: #{$border-radius-2xl};
  --#{$prefix}border-radius-pill: #{$border-radius-pill};

  // scss-docs-end root-border-var

  --#{$prefix}link-color: #{$link-color};
  --#{$prefix}link-hover-color: #{$link-hover-color};
  --#{$prefix}code-color: #{$code-color};
  --#{$prefix}highlight-bg: #{$mark-bg};
}

body {
  @include --color('grey', $grey, false, true);
  @include --color-button('primary-theme', $primary);
  @extend %dark-theme;

  --link-color: var(--primary-theme-link-color);
  --link-hover-color: var(--primary-theme-link-hover-color);
  --link-decoration: underline;
  --legend-bg-color: #{ $body-bg };
  --preloader-overlay-bg: #{ $body-bg };
  --page-top-bg: #{ $gray-800 };
  --page-top-border-color: #{ $var-surface-10 };
  --page-top-avatar-color: #{ $gray-400 };
  --page-top-avatar-bg-color: #{ $var-surface-5 };
  --page-top-base-height: 61px;
  --page-top-under-height: 42px;
  --page-top-height: 0px;
  --tab-bar-height: 0px;
  --sidebar-bg: #{ $gray-800 };
  --sidebar-item-bg-color-hover-opacity: .4;
  --drp-ranges-btn-color: #{ $var-surface };
  --drp-ranges-btn-color-hover: #{ $var-surface };
  --drp-ranges-btn-color-active: #{ $var-surface };
  --drp-ranges-btn-bg-color: transparent;
  --drp-ranges-btn-bg-color-hover: transparent;
  --drp-ranges-btn-bg-color-active: transparent;
  --drp-ranges-btn-border-color: #{ $var-surface-10 };
  --drp-ranges-btn-border-color-hover: #{ $var-surface-10 };
  --drp-ranges-btn-border-color-active: #{ $primary };
  --drp-calendar-input-bg-color: #{ $body-bg };
  --wallet-select-bg: #{$var-surface-10};
  --wallet-select-border-color: transparent;
  --wallet-select-color: #{$body-color};
  --card-wallet-bg: #{$gray-800};
  --card-wallet-bg-gradient: linear-gradient(116deg, #{rgba($white, 0.2)} -150%, #{rgba($black, 0)} 102%);
  --card-wallet-crypto-address-bg: #{rgba($white, .1)};
  --bs-body-font-family: #{$font-family-base};
  --bs-body-font-size: #{$font-size-base};
  --bs-body-font-weight: #{$font-weight-base};
  --bs-body-line-height: #{$line-height-base};
  --bs-body-color: #{$body-color};
  --bs-body-text-align: #{$body-text-align};
  --bs-body-bg: #{$body-bg};
  --skeleton-bg: #3e3e42;

  &.twa {
    @include --color('body-bg', $body-bg-dark-twa);
  }
}


html.light body {
  @extend %light-theme;

  --link-decoration: none;
  --page-top-bg: #{ $white };
  --page-top-avatar-color: #{ $primary };
  --page-top-avatar-bg-color: #{ rgba($primary, .05) };
  --sidebar-bg: #{ $white };
  --sidebar-item-bg-color-hover-opacity: .7;
  --wallet-select-bg: transparent;
  --wallet-select-border-color: #{ $primary };
  --wallet-select-color: #{$primary};
  --card-wallet-bg: #fff;
  --card-wallet-bg-gradient: linear-gradient(116deg, #{ rgba($white, 0.06) } -3%, rgb(95 95 95 / 9%) 54%);
  --card-wallet-crypto-address-bg: #{rgba($black, .05)};
  --skeleton-bg: #d6d6d6;

  &.twa {
    --card-wallet-bg: #{$gray-200};

    @include --color('body-bg', $body-bg-light-twa);
  }
}

html.home-theme:not(.light) body {
  --card-wallet-bg: #3A3B48;
}
