$highlighted: $primary;
@import "ngx-bootstrap/datepicker/bs-datepicker";

.bs-datepicker-container {
  padding: 0;
}

.bs-datepicker {
  border-radius: 1rem;
  overflow: hidden;
  background: $body-bg;
  border: none;
}

.bs-datepicker-body {
  border: none;
}

.bs-datepicker-body table td {
  color: $body-color;
}
