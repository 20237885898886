@use 'sass:map';

.crypto-address-presenter {
  display: flex;
  align-items: center;

  &--tag {
    padding: map.get($spacers, 1) map.get($spacers, 2);
    border-radius: .625rem;
    background: $var-surface-5;

    @include light-theme-content {
      background: $var-surface-3;
    }
  }

  &__btn-copy {
    display: inline-flex;

    .btn {
      font-size: inherit;
    }

    .icon-box {
      width: auto;
      height: auto;
    }

    svg {
      width: 1em;
      height: 1em;

      * {
        stroke-width: 2px;
      }
    }
  }
}
