.input-phone {
  &__disabled {
    &.form-control {
      @extend %form-control___disabled;

      pointer-events: none;
    }
  }

  &.form-control {
    padding: 0;

    &:focus-within {
      @extend %form-control___focus;

    }

    input[type=tel] {
      @extend %form-control___placeholder;

      height: 100%;
      width: 100%;
      padding-right: 0;
      padding-left: 0 !important;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      background: none;
      border: none;
    }

    .iti {
      display: flex;
      position: static;
      z-index: 5;
      height: 100%;
    }

    .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
      background: none;
    }

    .iti__flag-container {
      position: static;
      padding: 0;
    }

    .iti__selected-flag {
      width: auto !important;
      padding-left: 0;
      padding-right: 20px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        height: 23px;
        width: 1px;
        background-color: $input-border-color;
      }
    }

    .iti__flag {
      box-shadow: none;
    }

    .iti__arrow {
      // TODO ng-update
      //@extend .dropdown-toggle::after;

      border: none !important;
    }

    .iti__arrow,
    .selected-dial-code {
      margin-left: 10px !important;
    }

    .selected-dial-code + .iti__arrow {
      margin-left: 4px !important;
    }

    .country-dropdown {
      @extend %dropdown-menu;

      padding: 0;
      border: $dropdown-border-width solid $dropdown-border-color;

      &.show {
        width: auto;
        left: 0 !important;
        right: 0 !important;
      }

      .search-container input {
        @extend .form-control;

        padding-left: $dropdown-item-padding-x !important;
        padding-right: $dropdown-item-padding-x !important;
        border-color: $input-border-color;

        @include border-bottom-radius(0);
      }
    }

    .iti__country-list {
      box-shadow: none;
      background: none;
    }

    .iti__country {
      @extend %dropdown-item;

      font-size: $font-size-base;
    }

    .iti__country-name {
      @include text-truncate;
    }
  }
}
