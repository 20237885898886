@use 'sass:map';
@import "../../vendors/bootstrap/dropdown";

%dropdown-item-hover {
  .dropdown-icon {
    color: $dropdown-icon-hover-color;
  }
}

%dropdown-item-active {
  color: var(--#{$prefix}dropdown-link-active-color);
  font-weight: $font-weight-medium;
  text-decoration: none;

  @include gradient-bg(var(--#{$prefix}dropdown-link-active-bg));

  .dropdown-icon {
    color: $dropdown-icon-active-color;
  }
}

%dropdown-menu {
  @extend %dropdown-menu-vendor;

  overflow: hidden;
  box-shadow: $dropdown-box-shadow;
}

%dropdown-item-disabled {
  background-color: transparent;

  // Remove CSS gradients if they're enabled
  background-image: if($enable-gradients, none, null);
  color: var(--#{$prefix}dropdown-link-disabled-color);
  pointer-events: none;
}

%dropdown-item {
  @extend %dropdown-item-vendor;

  display: flex;
  align-items: center;
  transition: $dropdown-transition;
  border-radius: $dropdown-inner-border-radius;
}

.dropdown-menu {
  @extend %dropdown-menu;
}

.dropdown-item {
  @extend %dropdown-item;

  &:hover {
    @extend %dropdown-item-hover;
  }

  &.active,
  &:active {
    @extend %dropdown-item-active;

    // .dropdown-icon {
    //  color: $dropdown-icon-active-color;
    // }
  }
}

.dropdown-icon {
  margin-right: map.get($spacers, 2) * 1.5;
  transition: $dropdown-transition;
  color: $dropdown-icon-color;

  // .icon-box {
  //  width: 18px;
  //  height: 18px;
  
  //  & > * {
  //    display: flex;
  //    align-items: center;
  //    justify-content: center;
  //    width: inherit;
  //    height: inherit;
  //  }
  
  //  svg {
  //    max-width: 100%;
  //    max-height: 100%;
  //  }
  // }
}

.dropdown-divider {
  margin: $dropdown-divider-margin-y -#{$dropdown-padding-x};
}
