.nft-achievement {

  &__card {
    background-color: $dark;

   @include light-theme-content {
      background-color: $gray-200;
    }

    border-radius: 1rem;
  }

  &__tag {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 3px 6px;
    border-radius: 8px;
    background-color: $primary;
    color: $white;
    font-size: 0.625rem;
    line-height: 1.4;
    font-weight: 600;
  }

  &__img {
    max-width: 100%;
    height: auto;

    &--mix {
      mix-blend-mode: soft-light;
    }

    &--not-done {
      filter: grayscale(1);
      opacity: 0.4;
    }

    &--patrick {
      width: 180px !important;
      height: auto;
      margin: auto;
    }
  }

  &__title {
    &--not-done {
      opacity: 0.4;
    }
  }

  &__score {
    opacity: 0.4;
  }

  &__round-number {
    font-family: $font-family-headline;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    position: absolute;
    // TODO: нужно отдельное изображение для рамок
    bottom: 0;
    color: $white;
    font-size: 1.375rem;
    left: 0;
    right: 0;
    // TODO: нужно отдельное изображение для рамок
    width: 90px;
    margin-inline: auto;
  }

  &__await {
    padding: 10.5px;
    border-radius: 20px;

    background-color: $dark;

    @include light-theme-content {
      background-color: $gray-200;
    }
  }

  &__progress-bar {

    .progressbar {
      --progress-bg-color: #{rgba($grey, 0.2)};
      --progress-bar-height: 0.25rem;
    }
  }
}
