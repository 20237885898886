@use 'sass:map';

.nft-label {
  $label-themes: (
    'binance': $binance-color,
    'ton': $ton-color,
    'ethereum': $ethereum-color,
    'purple': $purple,
    'green': $green,
    'pink': #d773bb,
    'softred': #f07d7d,
    'red': #ff2b5e,
    'orange': $orange,
  );

  position: relative;
  padding: 3px 6px;
  overflow: hidden;

  // backdrop-filter: blur(8px);
  border-radius: $border-radius-sm;
  background: rgba($black, 0.4);
  color: $white;
  font-size: $extra-small-font-size;
  font-weight: $font-weight-semibold;
  isolation: isolate; // Fix for safari border-radius

  @each $color, $value in $label-themes {
    &--#{$color} {
      background-color: rgba($value, .3);
      color: $body-color;

      @include light-theme-content {
        background-color: rgba($value, .2);
      }

      &.nft-label--accent {
        background-color: rgba($value, .2);
        color: $value;

        @include light-theme-content {
          background-color: rgba($value, .1);
        }
      }
    }
  }

  &--solana {
    background: map.get($gradients, 'solana');
    color: $white;
  }

  &--trial {
    background-color: $violet;
    color: color-contrast($violet);
  }

  &--softred {
    color: $body-color;

    @include light-theme-content {
      color: map.get($label-themes, 'softred');
    }
  }

  &--primary {
    background-color: rgba($primary, .4);
    color: $white;

    @include light-theme-content {
      background-color: rgba($primary, .15);
      color: $primary;
    }
  }

  &--fillred {
    $color: map.get($label-themes, 'red');

    background-color: $color;
    color: color-contrast($color);
  }

  &--red {
    $color: map.get($label-themes, 'red');

    background-color: rgba($color, .1);
    color: $body-color;

    @include light-theme-content {
      background-color: rgba($color, .1);
      color: $color;
    }
  }

  &--gradient {
    background: map.get($gradients, 'purple');
    color: $white;
  }

  &--rainbow {
    background: map.get($gradients, 'rainbow');
    color: $white;
  }

  &--rainbow-2 {
    background: map.get($gradients, 'rainbow-2');
    color: $white;
  }

  &--dark {
    background-color: var(--surface);
    color: var(--surface-contrast);
  }

  &--gray {
    background-color: rgba(#fff, .05);
    color: $var-grey;

    @include light-theme-content {
      background-color: rgba(#0e0f1f, .05);
    }
  }

  &--white {
    background-color: $white;
    color: $dark;
  }

  &--primary-theme {
    background-color: $var-primary-theme;
    color: $var-primary-theme-contrast;
  }

  &--light {
    background-color: $var-surface-20;
    color: $body-color;

    @include light-theme-content {
      background-color: $var-surface-10;
    }
  }

  &--gold {
    background: linear-gradient(83.46deg, #CC9954 -1.81%, #EFD180 33.44%, #FFF7B4 74.3%, #CC9954 100%);
    color: $dark;
  }

  &--silver {
    background: linear-gradient(83.46deg, #949494 -1.81%, #C8C8C4 33.44%, #E3E3E3 74.3%, #949494 100%);
    color: $dark;
  }

  &--bronze {
    background: linear-gradient(83.46deg, #E49A70 -1.81%, #FFC9AA 33.44%, #FFE1C6 74.3%, #E49A70 100%);
    color: $dark;
  }

  &--blue {
    background-color: $blue;
    color: $white;
  }

  &__value {
    position: relative;
    line-height: 1.4;
  }

  &__image {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__icon-start {
    display: inline-flex;
    margin-right: map.get($spacers, 1) * 0.5;
  }
}

.catalog-item-card {
  &:hover {
    .nft-label {
      &::before {
        opacity: 1;
      }
    }
  }
}
