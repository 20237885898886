.btn {
  --offset: 10px;

  &__icon {
    height: 100%;
    display: inline-flex;
    align-items: center;

    .btn:not(.btn-inline) & {
      margin: -#{map-get($spacers, 1)} 0;
    }

    &--start {
      order: -1;

      & ~ .btn__text {
        margin-left: var(--offset, 10px);
      }
    }

    &--end {
      order: 1;

      & ~ .btn__text {
        margin-right: var(--offset, 10px);
      }
    }

    &--absolute-end {
      position: absolute;
      right: var(--bs-btn-padding-x);
    }
  }

  &__text {
    &--offset-sm {
      --offset: 5px;
    }
  }
}
