.christmas {
  max-width: 656px;
  margin: 0 auto;
  font-size: $font-size-lg;

  &__image {
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: $var-surface-5;
    border-radius: $border-radius-lg;
  }

  .content {
    h2 {
      margin-top: map-get($spacers, 6);
      margin-bottom: map-get($spacers, 2) + map-get($spacers, 1);
    }

    p {
      margin-bottom: map-get($spacers, 2) + map-get($spacers, 1);
    }
  }
}
