$boost-tag-height: 1.25em;
$boost-icon-zIndex: 1;


.boost {

  &:not(&--disabled) {
    @include btn-game-active-style;
  }

  text-shadow: $text-shadow-base;

  &--disabled {
    .boost__icon {
      filter: grayscale(100%);
    }

    .boost__tag-content {
      opacity: $disabled-opacity;
    }
  }

  &--disabled, &--locked {
    .boost__tag {
      background-color: $grey-game;
    }
  }

  &__button {
    position: relative;
    width: $boost-button-width;
    height: $boost-button-width;
    background-size: cover;
  }

  &__usage-count {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
    text-align: center;
  }

  &__free-usage-count {
    position: absolute;
    top: 5px;
    right: 0;
  }

  &__icon {
    z-index: $boost-icon-zIndex;
    width: 3.25em;
    height: 3.25em;
  }

  &__text {
    position: absolute;
    z-index: $boost-icon-zIndex + 1;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    font-size: em($font-size-sm);
  }

  &__discount {
    top: 12px;
    height: 13px !important;
    font-size: 0.5rem;
  }

  &__coming-soon {
    top: 1.25rem;
    height: unset !important;
  }

  &__loader {
    width: 2ch;
    height: 1em;
  }
}

