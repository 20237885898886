%dark-theme {
  @include --color('aside-bg', $aside-bg-dark);
  @include --color('aside-bg-200', $aside-bg-200-dark);
  @include --color('aside-bg-600', $aside-bg-600-dark);
  @include --color('body-bg', $body-bg-dark);
  @include --color('surface', $surface-dark, false, true);
  @include --color('surface-accent', $aside-bg-dark);
  @include --color('subface', $subface-dark);
  @include --color('subface-200', $subface-200-dark);
  @include --color('primary-theme', $white, false, true, true);
  @include --color-alert('primary-theme', $white, $body-bg-dark);
  @include --color-alert('danger-theme', $danger, $body-bg-dark);
  @include --color-button('secondary-theme', $var-surface-5, ('color': $white, 'hover-background': $var-surface-10, 'active-background': $var-surface-15));
  @include --color-button('contrast-theme', $white);
  @include --color-button-opacity('primary-theme', $var-surface-5, ('color': $white, 'background': $var-surface-5, 'hover-background': $var-surface-10, 'active-background': $var-surface-15));
  @include --color-pagination('primary-theme', $var-surface-10, ('color': $white, 'background': $var-surface-10, 'hover-background': $var-surface-5));

  --box-shadow: 0 0 4px 0 rgb(14 15 31 / 12%), 0 4px 24px 0 rgb(14 15 31 / 60%);
}

%light-theme {
  @include --color('aside-bg', $aside-bg-light);
  @include --color('aside-bg-200', $aside-bg-200-light);
  @include --color('aside-bg-600', $aside-bg-600-light);
  @include --color('body-bg', $body-bg-light);
  @include --color('surface', $surface-light, false, true);
  @include --color('surface-accent', $aside-bg-light);
  @include --color('subface', $subface-light);
  @include --color('subface-200', $subface-200-light);
  @include --color('primary-theme', $primary, false, true, true);
  @include --color-alert('primary-theme', $primary, $body-bg-light);
  @include --color-alert('danger-theme', $danger, $body-bg-light);
  @include --color-button('secondary-theme', $gray-200);
  @include --color-button('contrast-theme', $dark);
  @include --color-button-opacity('primary-theme', $primary);
  @include --color-pagination('primary-theme', $primary);


  --box-shadow: 0 0 4px 0 rgb(14 15 31 / 4%), 0 4px 24px 0 rgb(14 15 31 / 8%);
}
