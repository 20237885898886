@use "sass:map";

.app-nav-tabs-game {
  display: flex;
  padding: 0;
  background: transparent;
  border-radius: 0;

  &__item {
    @include border-top-radius(map.get($spacers, 4));
    @include border-bottom-radius(0);
    @extend %text-shadow;

    background-color: $secondary-game;
    flex: 1;
    padding: 10px 8px 6px 8px;

    &:not(:last-of-type) {
      margin-right: 4px;
    }

    &.active {
      background-color: $primary-game;
    }
  }
}
