@use "sass:math";

.nft-pool {
  &__avatar {
    .avatar {
      width: 80px;
      height: 80px;
      font-size: $h1-font-size;
      font-weight: $font-weight-bolder;
    }
  }

  &__badge {
    $padding-y: math.div(map-get($spacers, 1), 2);
    $padding-x: map-get($spacers, 1) + math.div(map-get($spacers, 1), 2);

    display: inline-block;
    padding: $padding-y $padding-x;
    color: $var-grey-contrast;
    font-size: $extra-small-font-size;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    background-color: $var-grey;
    border-radius: .375rem;

    &--danger {
      color: $danger;
      background: rgba($danger, 0.08);
    }

    &--gradient-1 {
      background: linear-gradient(135deg, #7540EF 1.5%, #EB7DAF 72.78%, #F07D7D 91.78%);
    }

    &--gradient-2 {
      background: linear-gradient(135.39deg, #7540EF 4.81%, #A882FF 93.01%);
    }
  }

  &__card {
    color: $body-color;

    &--gradient {
      color: $white;
      background: linear-gradient(135.39deg, #7540EF 4.81%, #A882FF 93.01%);
      background-clip: padding-box;

      .text-muted {
        color: rgba($white, .8) !important;
      }

      .rating-number__icon {
        color: $white;
      }
    }

    &--position-1:not(&--gradient) {
      .rating-number {
        @extend .svg-gradient-inst;
      }
    }

    &--position-2:not(&--gradient) {
      .rating-number {
        @extend .svg-gradient-purple;
      }
    }

    &--position-3:not(&--gradient) {
      .rating-number {
        @extend .svg-gradient-blue;
      }
    }

    .card-body {
      color: inherit;
    }
  }

  &__card-icon {
    margin-right: map-get($spacers, 2) + map-get($spacers, 1);

    svg {
      width: 34px;
      height: 34px;
    }

    &--with-bg {
      display: inline-block;
      padding: map-get($spacers, 2);
      color: $var-primary-theme;
      background-color: rgba($var-primary-theme-rgb, 0.08);
      border-radius: $border-radius;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__btn-rank {
    border-radius: $btn-border-radius;
  }

  &__count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
    background-color: $danger;
    padding: .25rem .25rem .25rem 0.2rem;
    height: 18px;
    min-width: 18px;
    line-height: 12px;
    text-align: center;
    color: $white;
    font-size: 10px;
  }
}
