@use 'sass:map';

$element-vars: (
  'bgColor': '--element-bg-color'
);

@mixin element-base($bg-color) {
  --element-bg-color: #{$bg-color};
}

@mixin use-element-base-props() {
  background-color: var(#{map.get($element-vars, 'bgColor')});
}