%icon-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $icon-box-size;
  height: $icon-box-size;
  line-height: 0;
}

.icon-box {
  @extend %icon-box;

  width: var(--icon-box-size, #{$icon-box-size});
  height: var(--icon-box-size, #{$icon-box-size});
}

.icon-sm {
  svg {
    width: 12px;
    height: 12px;
  }
}

.icon-box-sm {
  &, .icon-box {
    --icon-box-size: #{$icon-box-size-sm};
  }
}

.icon-box-xs {
  &, .icon-box {
    --icon-box-size: #{$icon-box-size-xs};
  }
}

.icon-box-xxs {
  &, .icon-box {
    --icon-box-size: #{$icon-box-size-xxs};
  }
}

.icon-box-xsl {
  &, .icon-box {
    --icon-box-size: #{$icon-box-size-xsl};
  }
}

.icon-box-xl {
  &, .icon-box {
    --icon-box-size: #{$icon-box-size-xl};
  }
}

.icon-box-xxl {
  &, .icon-box {
    --icon-box-size: #{$icon-box-size-xxl};
  }
}
