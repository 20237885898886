@use "sass:math";

.modal-presenter {
  $padding: map-get($spacers, 2);
  $border-radius-sl: 2rem;
  $border-radius-default: 1.5rem;

  $content-offset: $spacer * 1.25;
  $content-offset-md: map-get($spacers, 4);
  $content-offset-xl: $spacer * 3;

  border: none;

  --#{$prefix}modal-border-radius: #{$border-radius-default} !important;

  @include media-breakpoint-up(sl) {
    --#{$prefix}modal-border-radius: #{$border-radius-sl} !important;
  }

  .close {
    $offset: map-get($spacers, 3) + map-get($spacers, 1);

    top: $offset;
    right: $offset;
  }

  &__body {
    padding: $padding;
  }

  &__container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: stretch;
    }
  }

  &__content {
    flex-grow: 1;
    align-self: center;
    margin: $spacer * 1.25;

    @include media-breakpoint-up(md) {
      margin: map-get($spacers, 4);
    }

    @include media-breakpoint-up(xl) {
      margin: $spacer * 3;
    }
  }

  &__content-outside-x {
    margin: 0 -#{$content-offset + $padding};

    @include media-breakpoint-up(md) {
      margin: 0 -#{$content-offset-md + $padding};
    }

    @include media-breakpoint-up(xl) {
      margin: 0 -#{$content-offset-xl + $padding};
    }
  }

  &__content-inside-x {
    padding: 0 $content-offset + $padding;

    @include media-breakpoint-up(md) {
      padding: 0 $content-offset-md + $padding;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 $content-offset-xl + $padding;
    }
  }

  &__header {
    margin-bottom: map-get($spacers, 2);
    line-height: 1.75rem;

    text-align: center;
    font-family: $font-family-headline;
    font-size: 1.25rem;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(lg) {
      text-align: left;
      line-height: 2.75rem;
      font-size: 2.25rem;
    }
  }

  &__text {
    margin-bottom: $spacer * 1.25;
    line-height: $line-height-base * $font-size-lg;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  &__list {
    display: flex;
    list-style: none;
    flex-direction: column;
    padding: 0;

    &--buttons {
      margin-top: map-get($spacers, 4);

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        margin-top: map-get($spacers, 5);
      }
    }

    li {
      display: flex;
      align-items: center;

      &:last-child {
        margin-top: map-get($spacers, 2);
      }
    }
  }

  &__list-item {
    & + & {
      margin-top: map-get($spacers, 2);

      @include media-breakpoint-up(sm) {
        margin-top: 0;
        margin-left: map-get($spacers, 2);
      }
    }
  }

  &__preview {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: calc(var(--#{$prefix}modal-border-radius) - $padding);

    &--outside {
      margin-top: -#{$padding};
      margin-left: -#{$padding};
      margin-right: -#{$padding};

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__image {
    flex-shrink: 0;
    min-height: 232px;
    width: 100%;
    border-radius: calc(var(--#{$prefix}modal-border-radius) - $padding);
    background: no-repeat center/cover var(--image-url);
    background-position-y: 40%;

    &--centered {
      background-size: cover !important;
      background-position-y: center !important;
    }

    @include media-breakpoint-up(md) {
      min-height: 364px;
      background-position-y: 20%;
    }

    @include media-breakpoint-up(lg) {
      min-height: 304px;
      max-width: 255px;
      background-size: 337px;
    }

    @include media-breakpoint-up(sl) {
      border-radius: $border-radius-sl - $padding;
    }

    @include media-breakpoint-up(xl) {
      min-height: 304px;
      max-width: 320px;
      background-size: 420px;
    }
  }

  &__full-image {
    flex-shrink: 0;
    min-height: 248px;
    width: 100%;
    border-radius: calc(var(--#{$prefix}modal-border-radius) - $padding);
    background: no-repeat center/cover var(--image-url);
    //background-position-y: 40%;

    &--image-scale-xs {
      min-height: 200px;
    }

    @include media-breakpoint-up(md) {
      min-height: 300px;
      //background-position-y: 20%;
    }

    //@include media-breakpoint-up(lg) {
    //  min-height: 304px;
    //  //background-size: 337px;
    //}

    @include media-breakpoint-up(sl) {
      border-radius: $border-radius-sl - $padding;
    }

    //@include media-breakpoint-up(xl) {
    //  min-height: 304px;
    //  //background-size: 420px;
    //}
  }

  &__btn {
    @include button-size(14px, 1rem, $btn-font-size-xs, 1rem);
    flex-basis: 100%;
    flex-grow: 1;
    font-family: $font-family-base;
  }
}
