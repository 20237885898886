.farm {
  background-size: cover;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;

  &--lvl-1 {
    background-image: url('../../..../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-2 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-3 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-4 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-5 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-6 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-7 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-8 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-9 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-10 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-11 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-12 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-13 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }
  &--lvl-14 {
    background-image: url('../../../assets/images/nft/farms/farm.jpg')
  }

  &__header {
    font-weight: 900;
    font-size: 3rem;
    line-height: 3.5rem;
  }

  &__sub-header {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $var-surface-80;
  }

  &__btn {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #242327;
    box-shadow: -5px 5px 10px rgba(172, 172, 172, 0.2), 5px -5px 10px rgba(172, 172, 172, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(172, 172, 172, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(172, 172, 172, 0.5);
    border-radius: 16px;
    padding: 24px 32px;

    font-weight: 700;
    font-size: .875rem;
    line-height: 1.125rem;
    color: $white;
    text-transform: uppercase;
  }
}
