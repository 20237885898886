@use "sass:map";

.modal-gominers {
  $modal-inner-padding-xs: map.get($spacers, 4);

  --#{$prefix}modal-header-padding: 3rem 0 0 !important;

  @include media-breakpoint-down(xs) {
    --#{$prefix}modal-padding: #{$modal-inner-padding-xs} !important;
  }

  @include media-breakpoint-up(sl) {
    $modal-inner-padding: 3rem;

    --#{$prefix}modal-padding: #{$modal-inner-padding} !important;

    .modal-controller {
      &.modal-has-footer {
        & > .modal-body {
          padding-bottom: $modal-inner-padding-y;
        }

        & > .modal-footer {
          padding-top: $modal-inner-padding-y;
        }
      }
    }
  }
}
