.nft-item-list {
  padding: .5rem 0;
  cursor: pointer;

  &__name {

  }
  &__image-box {
    width: 3rem;
    height: 3rem;
    //border-radius: .5rem;
    overflow: hidden;
  }

  &__img {
    width: 100%;
  }
}
