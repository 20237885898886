.string-display {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $font-size-lg;
  padding: map-get($spacers, 2) map-get($spacers, 3);
  border-radius: $border-radius;
  background-color: $aside-bg;

  &--inline {
    font-size: inherit;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    border: none;
  }
}
