@use 'sass:map';

$table: '.smart-table';

.modal-contest {
  &:not(.modal-md, .modal-lg) {
    @include media-breakpoint-up(md) {
      --#{$prefix}modal-width: 700px !important;
    }

    @include media-breakpoint-up(lg) {
      --#{$prefix}modal-width: 900px !important;
    }
  }

  &__join-title {
    margin-bottom: map.get($spacers, 1);
    font-family: $font-family-headline;
    font-size: 1.125rem;
    font-weight: $font-weight-medium;
  }

  //&__table {
  //  #{$table} {
  //    background: none;
  //
  //    thead {
  //      background: var(--body-bg);
  //    }
  //  }
  //}

  &__discord-image {
    --discord-bg-color: #{$white};
    --discord-color: #{$primary};
  }
}
