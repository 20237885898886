.provider {
  &__item {
    border: 1px solid $gray-300;
    border-radius: 1rem;

    &:hover {
      border: 1px solid $body-color;
    }

    &--disabled {

      &:hover {
        border: 1px solid $gray-300;
      }
    }
  }
  &__title {

  }
  &__image {
    width: 36px;
    height: 40px;
  }
}
