@use 'sass:map';
@use 'sass:color';

.launchpad-card {
  transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
    transform-origin: center;
    box-shadow: $dropdown-box-shadow;
  }

  &__header {
    padding: map.get($spacers, 3_5);
    background-color: color.mix($white, $dark, 4%);
    color: $white;

    @include light-theme-content {
      background-color: $dark;
    }
  }

  &__image {
    flex-shrink: 0;
    width: var(--size);
    height: var(--size);
    object-fit: contain;
    object-position: center;

    &--logo {
      --size: 40px;

      @include media-breakpoint-up(sm) {
        --size: 72px;
      }
    }

    &--network {
      --size: 12px;
    }
  }
}