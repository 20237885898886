.image-etherscan {
  --color-1: #fff;
  --color-2: #bfceda;

  @include light-theme-content {
    --color-1: #21325B;
    --color-2: #979695;
  }

  &__color-1 {
    fill: var(--color-1);
  }

  &__color-2 {
    fill: var(--color-2);
  }
}
