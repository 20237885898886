@use "sass:math";

.page-top {
  display: flex;
  position: fixed;
  z-index: $zindex-fixed + 12;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
  height: calc(var(--page-top-base-height) + var(--safe-area-inset-top));
  padding-top: var(--safe-area-inset-top);
  padding-right: main-inner-offset('right');
  padding-left: main-inner-offset('left');
  border-bottom: $border-width solid $page-top-border-color;
  background-color: $page-top-bg;

  @include media-breakpoint-up(md) {
    padding-right: main-inner-offset('right', true);
    padding-left: main-inner-offset('left', true);
  }

  &:has(+ &--under) {
    border-bottom: 0;
  }

  &--under {
    z-index: $zindex-fixed + 10;
    top: calc(var(--page-top-base-height) + var(--safe-area-inset-top));
    align-items: stretch;
    height: var(--page-top-under-height);
    padding-top: 0;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__notification {
    position: absolute;
    right: 8px;
    bottom: 0;
    min-width: 12px;
    height: 12px;
    padding: .1rem .125rem .125rem 0.1rem;
    border-radius: 100%;
    background-color: $danger;
    color: $white;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
  }

  &__logo {
    display: flex;
    align-items: center;
    margin-right: map-get($spacers, 2);
    padding: map-get($spacers, 2) 0;

    @include media-breakpoint-up(sm) {
      margin-right: $main-offset;
    }

    @include media-breakpoint-up(md) {
      margin-right: $main-offset-md;
    }

    // @include media-breakpoint-up($sidebar-static-breakpoint) {
    //   width: $sidebar-width;
    //   height: 100%;
    //   padding-right: 0;
    //   padding-left: calc(#{$padding} + var(--safe-area-inset-left));
    //   margin-left: calc(-1 * (#{$padding} + var(--safe-area-inset-left)));
    //   background: $sidebar-bg;
    // }
  }

  &__content {
    display: flex;
    align-items: center;
    align-self: stretch;
  }

  &__actions {
    display: flex;
    position: relative;
    align-items: center;

    & > * {
      &:not(:last-child) {
        margin-right: map-get($spacers, 1) + math.div(map-get($spacers, 1), 2);
      }
    }
  }

  &__avatar {
    position: relative;
    overflow: hidden;
    isolation: isolate;
  }

  &__avatar-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include inset(0);
  }

  &__provider-btn {
    position: relative;
    transition: opacity $transition;

    &::before {
      content: '';
      position: absolute;
      transition: opacity $transition;
      opacity: 0;
      background-color: $page-top-bg;

      @include inset(0);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      transition: opacity $transition;
      border: 2px solid $page-top-bg;
      border-radius: 50%;
      opacity: 0;
      background-color: $success;
    }

    &:disabled {
      &::before  {
        opacity: .4;
      }
    }

    &--active {
      &::after {
        opacity: 1;
      }
    }
  }

  &__bag-indicator {
    .catalog-bag-indicator__counter {
      box-shadow: 0 0 0 2px $page-top-bg;
    }
  }
}

.page-top--active {
  --page-top-height: var(--page-top-base-height);

  &.page-top--active-under {
    --page-top-height: calc(var(--page-top-base-height) + var(--page-top-under-height));
  }
}

.main--inner:not(.miner-wars) {
  .page-top--active & {
    padding-top: $main-offset;

    @include media-breakpoint-up(md) {
      padding-top: $main-offset-md;
    }
  }
}
