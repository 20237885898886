@use "sass:map";

.nft-page-stub {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: map.get($spacers, 3);

  @include media-breakpoint-up(sl) {
    flex-direction: row;
    padding: map.get($spacers, 5);
  }

  &__info {
    max-width: 428px;
  }

  &__image {
    --nft-page-stub-image-size: 196px;

    width: var(--nft-page-stub-image-size);
    height: var(--nft-page-stub-image-size);
    object-fit: cover;

    @include media-breakpoint-up(sm) {
      --nft-page-stub-image-size: 262px;
    }

    @include media-breakpoint-up(md) {
      --nft-page-stub-image-size: 393px;
    }

    @include media-breakpoint-up(sl) {
      --nft-page-stub-image-size: 524px;

      margin-left: map.get($spacers, 5) * 4;
    }
  }

  &__value {
    font-size: $h4-font-size;
    line-height: 1.1;

    @include media-breakpoint-up(md) {
      font-size: $h2-font-size;
    }
  }

  &__title {
    font-size: $h3-font-size;

    @include media-breakpoint-up(md) {
      font-size: $h1-font-size;
    }
  }
}
