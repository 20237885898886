@import "../../vendors/bootstrap/list-group";

.list-group-flush {
  & > .list-group-item {
    padding-left: 0;
    padding-right: 0;
  }
}

.list-group-flush-with-bottom {
  & > .list-group-item:last-child {
    border-bottom-width: $border-width;
  }
}

.list-group-item {
  transition: opacity $transition;

  &.disabled,
  &:disabled {
    opacity: .6;
  }
}
