@use 'sass:map';

$padding-x: .125rem;
$padding-y: .5rem;
$margin-icon: .375rem;

.badge-game {
  display: flex;
  align-items: center;
  padding: $padding-x $padding-y;
  border-radius: $badge-game-border-radius;
  font-family: $font-family-base-game;
  text-wrap: nowrap;
  backdrop-filter: blur($blur);

  &--border-gradient {
    @include border-gradient(linear-gradient(180deg, $white 0%, $black 100%), map.get($spacers, 0_5), true);

    &::before {
      mix-blend-mode: soft-light;
    }
  }

  &--with-icon {
    padding: $padding-x $padding-y $padding-x $padding-x;
  }

  &--light {
    border: 1px solid $badge-light-color;
    background: $badge-light-color;
  }

  &--dark {
    border: 1px solid $badge-dark-color;
    background: $badge-dark-color;
  }

  &--btn {
    min-width: $input-height;
    min-height: $input-height;
    border-radius: em($btn-border-radius);
  }

  &--alert {
    padding: map.get($spacers, 2_5);
    border-radius: $alert-border-radius;
    font-size: $font-size-lg;
    line-height: 1.25;
    text-wrap: auto;
  }

  &--red {
    background: rgba($danger-game, 0.4);
  }

  &--danger {
    background: $danger-game;
  }

  &--grey {
    background: $var-surface-10;
    color: $grey-game;
  }

  &--secondary {
    background: $secondary-game;
    border: 4px solid $primary-game;
  }

  &--no-blur {
    backdrop-filter: none;
  }

  &.badge-game--em {
    padding: em($padding-x) em($padding-y);

    &.badge-game--with-icon {
      padding: em($padding-x) em($padding-y) em($padding-x) em($padding-x);
    }
  }
}



.badge-game-icon {
  display: inline-flex;
  margin-right: $margin-icon;

  &--end {
    margin-right: 0;
    margin-left: $margin-icon;
  }

  .badge-game--em & {
    margin-right: em($margin-icon);

    &--end {
      margin-left: em($margin-icon);
    }
  }
}
