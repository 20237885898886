.nft-display-game {

  &__icon-trial {
    &--expiring {
      --icon-resource-color: #{$danger-game};
    }
  }

  &__icon {
    &--disabled {
      --icon-resource-color: #{$grey-game};
    }
  }

  &__img {
    &--disabled {
      filter: grayscale(100%);
    }
  }
}
