@import "../../vendors/bootstrap/alert";

.alert {
  display: flex;
  align-items: center;
  margin-bottom: map-get($spacers, 4);
  border-color: $alert-border-color;
  background-color: $alert-bg;

  .twa & {
    margin-bottom: map-get($spacers, 3);
  }

  @include media-breakpoint-up(md) {
    padding: $alert-padding-y-md $alert-padding-x-md;
  }

  ul {
    margin-bottom: 0;
    padding-left: $font-size-base;
    color: $gray-200;
  }

  li {
    margin: map-get($spacers, 1) 0;
  }
}

@each $state, $value in $alert-themes {
  $alert-color: var(--#{$state}-alert-color);
  $alert-icon-color: var(--#{$state}-alert-icon-color);
  $alert-bg-color: var(--#{$state}-alert-background);
  $alert-border-color: transparent;

  .alert-#{$state} {
    @include alert-variant($alert-bg-color, $alert-border-color, $alert-color, null, $alert-icon-color);
  }
}

.alert-icon {
  &--start {
    margin-right: map-get($spacers, 3);
  }

  &--end {
    margin-left: map-get($spacers, 3);
  }

  svg {
    width: map-get($spacers, 4);
    height: map-get($spacers, 4);
  }
}

.alert-close {
  color: $var-grey;
  margin-left: map-get($spacers, 3);

  svg {
    width: map-get($spacers, 3);
    height: map-get($spacers, 3);
  }
}

.alert-input {
  background-color: rgba(var(--surface-rgb), 0.05)
}
