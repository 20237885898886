@use 'sass:map';

.ve-rewards-settings {
  @extend %aside-viewer;

  &__overlay {
    @extend %aside-viewer__overlay;
  }

  &__fixed {
    @extend %aside-viewer__fixed;
  }

  &__box {
    @extend %aside-viewer__box;
  }

  &__close {
    @extend %aside-viewer__close;
  }

  &__title {
    @extend %aside-viewer__title;
  }

  // &__content {
  //   @extend .aside-viewer__content;
  // }

  &__item {
    display: block;

    & + & {
      margin-top: map.get($spacers, 4);
    }
  }

  &__label {
    $padding-y: map.get($spacers, 3);
    $padding-x: map.get($spacers, 3);

    width: 100%;
    padding: $padding-y $padding-x;
    border-radius: $border-radius-lg;
    background-color: $var-surface-accent;

    &-main {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-content {
      display: flex;
      flex-direction: column;
      margin-right: auto;
    }

    &-title {
      font-weight: $font-weight-medium;

      // text-transform: uppercase;
    }

    &-description {
      margin-top: 2px;
      color: $var-grey;
      font-size: $small-font-size;
    }

    &-indicator {
      display: inline-flex;
      flex-shrink: 0;
      margin-left: map.get($spacers, 4);
    }
  }

  &__badge {
    display: inline-block;
    padding: map.get($spacers, 1) map.get($spacers, 2);
    border-radius: $border-radius-sm;
    background: rgba($ethereum-color, .2);
    color: var(--surface);
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;
    white-space: nowrap;
  }

  &__select-nfts-panel {
    z-index: $zindex-modal !important;
  }

  &__select-nfts {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__action-container {
    $side-padding: map.get($spacers, 5);

    @include border-bottom-radius($border-radius-lg);

    z-index: 1;
    margin: -$aside-viewer-padding;
    padding: map.get($spacers, 3) $side-padding $side-padding $side-padding;
    background-color: $aside-viewer-bg;
    box-shadow: 0 -4px 32px 0 #36353814, 0 0 4px 0 #26252705; // todo unify styles with marketplace filter
  }
}
