@use 'sass:map';
@use 'sass:math';
@import "../../vendors/bootstrap/modal";

.modal-redesign {
  .modal-header {
    border-bottom: 0;
  }

  .modal-title {
    font-family: $font-family-headline;
  }
}

.modal-page {
  transition: none !important;

  $block-margin: map.get($spacers, 2) + map.get($spacers, 1);
  $modal-inner-padding-xs-y: map.get($spacers, 4);
  $modal-inner-padding-xs: map.get($spacers, 3);

  @include media-breakpoint-down(xs) {
    --#{$prefix}modal-header-padding: 1.25rem 0 0 !important;
    --#{$prefix}modal-padding: #{$modal-inner-padding-xs} !important;

    .modal-controller {
      &.modal-has-footer,
      &.modal-has-footer-tmp, {
        & > .modal-body {
          padding-bottom: $modal-inner-padding-xs-y;
        }

        & > .modal-footer {
          padding-top: $modal-inner-padding-xs-y;
        }
      }
    }
  }

  .modal-dialog {
    transition: none !important;
  }

  .modal-header {
    font-size: $lead-font-size-sm;
    font-weight: $lead-font-weight;

    .modal-title {
      font-family: $font-family-base;
    }
  }

  .modal-controller .close {
    display: none !important;
  }

  .modal-btn-back {
    min-width: auto !important;
    min-height: auto !important;
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    color: $body-color !important;
  }
}

.modal {
  padding: var(--safe-area-inset-top) var(--safe-area-inset-right) var(--safe-area-inset-bottom) var(--safe-area-inset-left);
  background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);

  &#{&}--skip-bc {
    background-color: unset;
  }

  &-inner {
    //position: absolute;
    padding: 0;
  }
}

.modal-content {
  --modal-avatar-size: 128px;
  --modal-avatar-bg: var(--#{$prefix}modal-bg);
  --modal-avatar-border-width: var(--#{$prefix}modal-border-width);
  --modal-avatar-border-color: var(--#{$prefix}modal-border-color);

  .preloader {
    --preloader-overlay-bg: #{$modal-content-bg};
  }
}

.modal-dialog.modal-content {
  // scss-docs-start modal-css-vars
  --#{$prefix}modal-zindex: #{$zindex-modal};
  --#{$prefix}modal-width: #{$modal-md};
  --#{$prefix}modal-padding: #{$modal-inner-padding};
  --#{$prefix}modal-margin: #{$modal-dialog-margin};
  --#{$prefix}modal-color: #{$modal-content-color};
  --#{$prefix}modal-bg: #{$modal-content-bg};
  --#{$prefix}modal-border-color: #{$modal-content-border-color};
  --#{$prefix}modal-border-width: #{$modal-content-border-width};
  --#{$prefix}modal-border-radius: #{$modal-content-border-radius};
  --#{$prefix}modal-box-shadow: #{$modal-content-box-shadow-xs};
  --#{$prefix}modal-inner-border-radius: #{$modal-content-inner-border-radius};
  --#{$prefix}modal-header-padding-x: #{$modal-header-padding-x};
  --#{$prefix}modal-header-padding-y: #{$modal-header-padding-y};
  --#{$prefix}modal-header-padding: #{$modal-header-padding}; // Todo in v6: Split this padding into x and y
  --#{$prefix}modal-header-border-color: #{$modal-header-border-color};
  --#{$prefix}modal-header-border-width: #{$modal-header-border-width};
  --#{$prefix}modal-title-line-height: #{$modal-title-line-height};
  --#{$prefix}modal-footer-gap: #{$modal-footer-margin-between};
  --#{$prefix}modal-footer-bg: #{$modal-footer-bg};
  --#{$prefix}modal-footer-border-color: #{$modal-footer-border-color};
  --#{$prefix}modal-footer-border-width: #{$modal-footer-border-width};

  margin-right: auto;
  margin-left: auto;

  // not used ?
  // @each $breakpoint, $value in $grid-breakpoints {
  //   &.modal-#{$breakpoint} {
  //     @extend .modal-#{$breakpoint};
  //   }
  // }

  &.modal-dialog-centered {
    align-items: initial;
    min-height: auto;
  }
}

.modal-header {
  @extend %lead;

  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: 0 var(--#{$prefix}modal-padding);
  margin-bottom: 0;
  text-align: center;
}

.modal-desc {
  margin-top: map.get($spacers, 2) + map.get($spacers, 1);
  color: $text-muted;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
}

.modal-btn-back {
  @extend %btn-secondary-theme;

  color: $var-grey;
}

.modal-backdrop {
  visibility: hidden;
  z-index: 1045;
}

.modal-header-simple {
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 0;
  border-bottom: none;
  text-align: left;
}

.modal-footer {
  display: block;
  padding: var(--#{$prefix}modal-padding);
}

.modal-controller {
  height: 100%;

  @include input-legend-bg(var(--#{$prefix}modal-bg));

  &.modal-has-header > .modal-body {
    padding-top: $modal-header-padding-bottom;
  }

  &.modal-has-footer > .modal-body,
  &.modal-has-footer-tmp > .modal-body {
    margin-bottom: -$modal-inner-padding-y;
  }

  &:not(.modal-has-footer) .modal-footer {
    display: none;
  }

  .close {
    @extend %close;

    position: absolute;
    z-index: 3;
    top: map.get($spacers, 3) + map.get($spacers, 1);
    right: map.get($spacers, 3) + map.get($spacers, 1);
  }

  .smart-table {
    background: $modal-content-bg;

    &__sticky-header {
      top: 0 !important;
      margin-top: calc(-1 * var(--safe-area-inset-top));
      padding-top: var(--safe-area-inset-top);

      .twa & {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }

  .form-group-input {
    background-color: $var-surface-accent;
  }
}

.modal-bottom-shadow {
  --visibility: hidden;

  position: relative;

  &::before {
    content: '';
    display: block;
    visibility: var(--visibility);
    position: absolute;
    z-index: 1;
    right: calc(-1 * var(--#{$prefix}modal-padding));
    bottom: 100%;
    left: calc(-1 * var(--#{$prefix}modal-padding));
    height: 32px;
    background: linear-gradient(0deg, $var-surface-3, transparent);
    pointer-events: none;
  }

  @include media-breakpoint-down(md) {
    --visibility: visible;
  }

  &--md {
    @include media-breakpoint-up(md) {
      --visibility: visible;
    }
  }
}

.modal-attention {
  padding-left: map.get($spacers, 4);
  border-left: $border-width solid $primary;
  color: $text-muted;
}

.modal-html {
  p, ul, ol {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal-preloader {
  .preloader {
    border-radius: $modal-content-border-radius;
  }
}

.modal-outer {
  &-x {
    margin-right: -$modal-inner-padding-x;
    margin-left: -$modal-inner-padding-x;
  }

  &-b {
    margin-bottom: -$modal-inner-padding-y;
  }
}

.modal-divider {
  $offset: 20px;
  $size: 4px;

  position: relative;
  height: $offset * 2 + $size;
  margin: 0 calc(-1 * var(--#{$prefix}modal-padding));
  background-color: $var-surface-accent;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    height: $offset;
    background-color: var(--#{$prefix}modal-bg);
  }

  &::before {
    top: 0;
    border-radius: 0 0 $offset $offset;
  }

  &::after {
    bottom: 0;
    border-radius: $offset $offset 0 0;
  }
}

.modal-avatar {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 50%;
  width: var(--modal-avatar-size);
  height: var(--modal-avatar-size);
  overflow: hidden;
  transform: translate(-50%, -50%);
  border: var(--modal-avatar-border-width) solid var(--modal-avatar-border-color);
  border-radius: 50%;
  background-color: var(--modal-avatar-bg);
  background-size: cover;
  box-shadow: 0 0 24px 0 rgb($black, .5);

  &--loading {
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: skeletonProgress 1s ease-in-out infinite;
        background-image: linear-gradient(90deg, rgba($white, 0), rgba($white, 0.3), rgba($white, 0));
    }
  }
}

.modal.modal-priority-overlay {
  --#{$prefix}modal-zindex: #{$zindex-modal-priority-overlay};
}
