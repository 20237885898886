@use "sass:math";

.modal-video {
  .modal-content {
    overflow: hidden;
  }

  .close {
    $offset: map-get($spacers, 3);

    top: $offset;
    right: $offset;
  }
}
