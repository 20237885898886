@use "sass:map";

.resource-selector {
  &__carousel {
    display: flex;
    align-items: stretch;
    padding: map.get($spacers, 2) 0;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__carousel-arrow {
    @include media-breakpoint-down(md) {
      display: none;
    }

    @include media-breakpoint-up(md) {
      &--left {
        margin-right: map.get($spacers, 2);
      }

      &--right {
        margin-left: map.get($spacers, 2);
      }
    }
  }

  &__item {
    --border-color: transparent;
    --border-inner-width: 1px;
    --border-inner-color: #{$var-surface-5};

    $border-width: 1.5px;

    display: flex;
    position: relative;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    min-width: 110px;
    margin: 0 2px;
    padding: #{map.get($spacers, 2) * 1.5};
    transition: border-color $transition, box-shadow $transition, background-color $transition;
    border: $border-width solid var(--border-color);
    border-radius: map.get($spacers, 3) * 1.25;
    box-shadow: inset 0 0 0 var(--border-inner-width) var(--border-inner-color);
    cursor: pointer;

    @include media-breakpoint-up(md) {
      min-width: 130px;

      &:not(:last-child) {
        margin-right: map.get($spacers, 1);
      }
    }

    &--active {
      --border-color: #{$primary};
      --border-inner-color: #{$primary};
      --border-inner-width: #{$border-width};
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__addon-label {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(6px, -4px) rotate(15deg);
  }
}
