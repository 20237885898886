@include game-style {
  .alert {
    @include --color-alert('primary-theme', $white, $body-bg-dark, 10%);
    @include --color-alert('danger-theme', $danger, $body-bg-dark, 10%);

    padding: $spacer-75;
    border: 2px solid rgba($white, .05);
    font-family: $font-family-description-game;
    font-size: $font-size-sm;
  }
}