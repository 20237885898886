@use "sass:map";

.referral-banner-generator {
  $achievement-image-size: 190px;

  position: relative;
  padding-bottom: 52.5%;
  overflow: hidden;

  &--theme-dark {
    color: $white;
  }

  &--theme-light {
    color: $black;
  }

  &__container {
    position: absolute;
    right: 0;
    left: 0;
    width: 576px;
    height: 302.4px;
    transform-origin: top left;
  }

  &__logo {
    position: absolute;
    bottom: 20px;
    left: 20px;
    height: 24px;
  }

  &__image {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 56%;
    height: 107.5%;
    transform: translateY(-50%);

    @include border-start-radius(50%);
  }

  &__sub-image {
    position: absolute;
  }

  &__gradient {
    position: absolute;
    top: 50%;
    right: 0;
    width: 324px;
    height: 324px;
    transform: translate(50%, -50%) scale(1.5);
    background: radial-gradient(circle at 50% 50%, $primary, rgba($white, 0) 70%);
  }

  &__content {
    display: flex;
    position: absolute;
    top: 20px;
    left: 20px;
    flex-direction: column;
    max-width: 50%;
  }

  &__label {
    margin-bottom: map.get($spacers, 2);
    font-size: $small-font-size;
    font-weight: $font-weight-medium;
    line-height: 1;
    text-transform: uppercase;
  }

  &__text {
    font-family: $font-family-headline;
    font-size: $font-size-xl;
    font-weight: $font-weight-medium;
    line-height: 1;
    text-transform: uppercase;
  }

  &--clan &__text,
  &--achievement &__text {
    font-size: 38.4px;
  }

  &--clan &__image {
    min-width: 44%;
  }

  &--achievement &__image {
    top: auto;
    bottom: 20px;
    left: 50%;
    width: $achievement-image-size;
    height: $achievement-image-size;
    transform: none;

    @include border-radius(12px);
  }

  &--achievement &__sub-image {
    bottom: calc(20px + #{$achievement-image-size});
    left: calc(50% + #{$achievement-image-size});
    width: 180px;
    height: 180px;
    transform: translate(-50%, 50%);
  }

  &--achievement &__content {
    max-width: 45%;
  }

  &--rewards &__image,
  &--promocode &__image {
    inset: 0 0 0 auto;
    height: 100%;
    transform: none;
  }

  &--rewards &__text {
    font-size: 34px;
  }

  &__code {
    color: #D94DF2;
    text-transform: none;
  }
}
