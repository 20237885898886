.status-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 136px;
  border-radius: 50%;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &::before {
    $size: 76.4705882353%;

    width: $size;
    height: $size;
  }

  &::after {
    $size: 52.9411764706%;

    width: $size;
    height: $size;
  }

  &--success {
    $color: $success;

    color: color-contrast($success);
    background-color: rgba($color, .1);

    &::before {
      background-color: rgba($color, .15);
    }

    &::after {
      background-color: $color;
    }
  }
}
