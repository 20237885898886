@use 'sass:map';
@import 'module-variables/ve-dashboard-vars';

$host: '.ve-dashboard';

.ve-dashboard {
  &__card {
    padding: map.get($spacers, 4);
    border: 0 solid $var-surface-10;
    border-radius: 1.25 * $border-radius-lg;
    background: $card-bg;

    .twa & {
      border-width: 1px;
    }
  }

  &__card-action {
    display: inline-flex;
    align-items: center;
    font-weight: $font-weight-semibold;
  }

  &__label {
    display: flex;
    align-items: center;
    color: $var-grey;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    &--progress {
      font-size: $font-size-xs;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
    }

    &--sm {
      font-size: $font-size-sm;
      font-weight: $font-weight-normal;
      text-transform: none;
    }
  }

  &__label-icon {
    display: flex;
    margin-right: $semi-spacer;
  }

  &__end-icon {
    display: flex;
    margin-left: $semi-spacer;
  }

  &__key-value {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &--column {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__value-container {
    display: flex;
    align-items: center;

    #{$host}__key-value--column & {
      position: relative;
      width: 100%;
    }
  }

  &__value {
    display: inline-flex;
    align-items: center;
    transition: opacity $transition;
    opacity: 1;
    font-weight: $font-weight-semibold;

    &--loading {
      opacity: 0;
    }

    #{$host}__key-value--column & {
      width: 100%;

      &.flex-row-reverse {
        justify-content: flex-end;
      }
    }
  }

  &__progress-value {
    margin: 0;
    font-family: $font-family-headline;
    font-weight: $font-weight-medium;
    line-height: 2.75rem;

    &--epoch {
      font-size: 2.25rem;
    }

    &--cycle {
      font-size: 1.5rem;
    }
  }

  &__value-skeleton {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 100px;
    height: 100%;

    &--start {
      right: unset;
      left: 0;
    }

    &--center {
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }

    &--end#{&}--column {
      right: unset;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    &--circle {
      top: 50%;
      min-width: 100px;
      height: 2rem;
      transform: translate(-50%, -50%);
    }
  }

  &__details-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family-headline;
    font-size: $font-size-lg * 1.25;
    font-weight: $font-weight-medium;
  }

  &__table {
    display: block;
    margin-top: map.get($spacers, 3);

    .card {
      --#{$prefix}card-border-color: #{$var-surface-10};
      --#{$prefix}card-border-radius: #{1.25 * $border-radius-lg};
    }
  }

  &__details-card {
    display: block;
    margin-top: map.get($spacers, 2);

    .card {
      --#{$prefix}card-border-color: #{$var-surface-10};
      --#{$prefix}card-border-radius: #{1.25 * $border-radius-lg};
      --#{$prefix}card-bg: #{$var-surface-accent};
    }
  }

  &__progress-bar {
    .progressbar {
      --progress-bg-color: #{rgba($var-grey-rgb, 0.15)};
      --progress-bar-height: 0.5rem;

      &--orange {
        --progress-bar-color: #{$orange};
      }

      &--blue {
        --progress-bar-color: #{$ethereum-color};
      }

      &--green {
        --progress-bar-color: #{$green};
      }
    }
  }

  &__modal {
    @include media-breakpoint-up(md) {
      --#{$prefix}modal-width: 600px !important;
    }
  }

  &__modal-lg {
    @include media-breakpoint-up(md) {
      --#{$prefix}modal-width: 900px !important;
    }
  }

  &__modal-xl {
    @include media-breakpoint-up(md) {
      --#{$prefix}modal-width: 700px !important;
    }

    @include media-breakpoint-up(lg) {
      --#{$prefix}modal-width: 1000px !important;
    }
  }

  &__row {
    $offset: map.get($spacers, 2) + map.get($spacers, 1);

    --#{$prefix}gutter-x: #{$offset};
    --#{$prefix}gutter-y: #{$offset};
  }

  &__item {
    padding: map.get($spacers, 3) map.get($spacers, 4);

    overflow: hidden;
    background: $var-surface-5;
    border-radius: $border-radius-lg;


    @include media-breakpoint-up(xl) {
      padding: map.get($spacers, 3) map.get($spacers, 3);
    }

    &-icon {
      .icon-box {
        --icon-box-size: #{map.get($spacers, 5)};
      }
    }
  }

  &__divider {
    margin-top: $semi-spacer * 2;
    margin-bottom: $semi-spacer * 2;
  }

  &__feature-block {
    padding: map.get($spacers, 3) map.get($spacers, 4);
    border-radius: $border-radius-lg;
    background-color: $var-aside-bg;
  }

  &__feature-skeleton {
    min-height: map.get($spacers, 4);
  }
}
