@use 'sass:map';

.miner-board-display {
  margin: 0 main-inner-offset('right', false, -1) 0 main-inner-offset('left', false, -1);
  padding: 0 main-inner-offset('right') 0 main-inner-offset('left');
  overflow: hidden;

  @include media-breakpoint-up(md) {
    margin: 0 -#{main-inner-offset('right', true, -1)} 0 -#{main-inner-offset('left', true, -1)};
    padding: 0 main-inner-offset('right', true) 0 main-inner-offset('left', true);
  }

  &__container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__miner {
    position: relative;
    z-index: -1;
    justify-content: center;
    width: 100%;
    object-fit: contain;
    height: 204px;
    margin-bottom: map.get($spacers, 3);
  }

  &__content {
    width: 100%;
    max-width: 343px;
    text-align: center;
  }
}
