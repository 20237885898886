@use 'sass:map';

.liquidity {
  &__pool-info {
    .card {
      --#{$prefix}card-border-color: #{$var-surface-10};
      --#{$prefix}card-border-width: 1px;
      --#{$prefix}card-border-radius: #{1.25 * $border-radius-lg};

      border-width: var(--#{$prefix}card-border-width);
    }
  }

  &__cell-invert-btn {
    &.btn.btn-square {
      min-width: 21px;
      min-height: 21px;
    }
  }

  &__filter-tab {
    flex-basis: unset;
  }

  &__dot {
    display: inline-block;
    flex-shrink: 0;
    width: map.get($spacers, 2);
    height: map.get($spacers, 2);
    border-radius: 50%;
    background-color: currentcolor;
  }
}