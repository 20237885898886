.input-group {
  display: flex;
  position: relative;
  width: 100%;

  > .form-control:focus {
    //z-index: 3;
  }

  > * {
    &:not(.form-control, .input-group-append, .input-group-prepend) {
      display: flex;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
    }
  }

  &:not(.prepend) {
    .input-group-prepend {
      visibility: hidden;
    }
  }

  &:not(.append) {
    .input-group-append {
      visibility: hidden;
    }
  }

  &.prepend {
    .input-group-prepend {
      visibility: visible;
    }

    & > .form-control {
      padding-left: $input-padding-rounded-x;
      border-left: none;

      @include border-start-radius(0);
    }

    & > .form-control-sm {
      padding-left: $input-padding-rounded-x-sm;
    }

    & > .form-control-lg {
      padding-left: $input-padding-rounded-x-lg;
    }
  }

  &.append {
    .input-group-append {
      visibility: visible;
    }

    & > .form-control {
      padding-right: $input-padding-rounded-x;
      border-right: none;

      @include border-end-radius(0);
    }

    & > .form-control-sm {
      padding-right: $input-padding-rounded-x-sm;
    }

    & > .form-control-lg {
      padding-right: $input-padding-rounded-x-lg;
    }
  }
}

.input-group > .form-control.form-rounded {
  @extend %form-control___form-rounded;
}

.input-group-sm > .form-control {
  min-height: $input-height-sm;
  padding: $input-padding-y-sm $input-padding-x-sm;

  @include font-size($input-font-size-sm);
  @include border-radius($input-border-radius-sm);

  &.form-rounded {
    @extend %form-control-sm___form-rounded;
  }
}

.input-group-lg > .form-control {
  min-height: $input-height-lg;
  padding: $input-padding-y-lg $input-padding-x-lg;

  @include font-size($input-font-size-lg);
  @include border-radius($input-border-radius-lg);

  &.form-rounded {
    @extend %form-control-lg___form-rounded;

  }
}

.input-group-error,
.input-group-controls {
  display: flex;
}

.input-group-prepend {
  order: -1;
}

.input-group-prepend,
.input-group-append {
  display: flex;
  background-clip: border-box;
}

.form-control {
  background-clip: border-box;

  &.form-rounded {
    & ~ .input-group-prepend {
      @include border-start-radius($input-border-radius-rounded);

      border-top-width: $input-border-width;
      border-left-width: $input-border-width;

      &:not(:empty) {
        padding-left: $input-padding-rounded-x;
      }
    }

    & ~ .input-group-append {
      @include border-end-radius($input-border-radius-rounded);

      border-top-width: $input-border-width;
      border-right-width: $input-border-width;

      &:not(:empty) {
        padding-right: $input-padding-rounded-x;
      }
    }
  }

  & ~ .input-group-prepend,
  & ~ .input-group-append {
    transition: $input-transition;
    border-width: 0;
    border-bottom-width: $input-border-width;
    border-style: solid;
    border-color: $input-border-color;
    background-color: $input-bg;

    .icon-box {
      height: 100%;

      //border-bottom: $input-border-width solid $input-border-color;
      transition: $input-transition;
      color: $input-group-addon-color;
    }

    .input-group-error {
      .icon-box {
        color: $danger !important;
      }
    }

    .input-group-text {
      border: none;
    }

    .btn-link .icon-box {
      height: 100%;
    }

    .input-group-btn,
    .input-group-text {
      &:first-child {
        padding-left: 0 !important;
      }

      &:last-child {
        padding-right: 0 !important;
      }
    }
  }

  &:disabled,
  &[readonly] {
    & ~ .input-group-prepend,
    & ~ .input-group-append {
      //background-color: $input-disabled-bg;
      opacity: $input-disabled-opacity;
      pointer-events: none;
    }
  }

  &:focus {
    & ~ .input-group-prepend,
    & ~ .input-group-append {
      border-color: $input-focus-border-color;
      background-color: $input-focus-bg;

      .icon-box {
        color: $input-focus-border-color;

        //border-color: $input-focus-border-color;
      }

      .btn-link .icon-box {
        color: currentcolor;
      }
    }
  }

  &.is-invalid {
    & ~ .input-group-prepend,
    & ~ .input-group-append {
      .icon-box {
        color: $form-feedback-invalid-color;

        //border-color: $form-feedback-invalid-color;
      }
    }
  }

  //&:placeholder-shown {
  //  & ~ .input-group-prepend,
  //  & ~ .input-group-append {
  //    .icon-box {
  //      color: $var-grey;
  //      height: 100%;
  //      border-bottom: $input-border-width solid $input-border-color;
  //    }
  //  }
  //}
}

.input-group-sm > .form-rounded,
.form-control-sm.form-rounded {
  & ~ .input-group-prepend {
    @include border-start-radius($input-border-radius-rounded-sm);

    &:not(:empty) {
      padding-left: $input-padding-rounded-x-sm;
    }
  }

  & ~ .input-group-append {
    @include border-end-radius($input-border-radius-rounded-sm);

    &:not(:empty) {
      padding-right: $input-padding-rounded-x-sm;
    }
  }
}

.input-group-lg > .form-rounded,
.form-control-lg.form-rounded {
  & ~ .input-group-prepend {
    @include border-start-radius($input-border-radius-rounded-lg);

    &:not(:empty) {
      padding-left: $input-padding-rounded-x-lg;
    }
  }

  & ~ .input-group-append {
    @include border-end-radius($input-border-radius-rounded-lg);

    &:not(:empty) {
      padding-right: $input-padding-rounded-x-lg;
    }
  }
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: $input-group-addon-padding-y $input-group-addon-padding-x;
  border: $input-border-width solid $input-group-addon-border-color;
  background-color: $input-group-addon-bg;
  color: $input-group-addon-color;
  font-weight: $input-group-addon-font-weight;
  line-height: $input-line-height;
  text-align: center;
  white-space: nowrap;

  @include font-size($input-font-size); // Match inputs
  @include border-radius($input-border-radius);
}

.input-group-info {
  $line-height: 1.25rem;

  display: block;
  min-height: $line-height;
  margin-bottom: -$line-height;
  color: $body-color;
  font-size: $font-size-xs;
  line-height: $line-height;
  user-select: none;
}

.input-group-btn {
  height: 100%;
  padding: 0 .375rem !important;
  border: none !important;
  border-radius: 0 !important;
  color: $input-group-addon-color;

  &:hover, &:focus {
    box-shadow: none;
    color: $input-color;
  }

  &--clear {
    svg {
      width: 12.5px;
      height: 12.5px;
    }
  }

  &--password {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

