@use "sass:math";
@use "sass:map";

@include dir-ignore {
  @import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
  @import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
}

.owl-carousel {
  .owl-dot {
    margin: -#{map-get($spacers, 1)};
    padding: map-get($spacers, 1);

    span {
      margin: 0 #{math.div(map-get($spacers, 1),  2)} !important;
      width: 16px !important;
      height: 4px !important;
      border-radius: 10rem !important;
      background: rgba($white, 0.2) !important;
      transition: $transition !important;
      transition-property: width, background-color !important;
    }

    &.active {
      span {
        width: 32px !important;
        background-color: $white !important;
      }
    }
  }
}

@mixin owl-carousel-dots($dots-offset: map.get($spacers, 2)) {
  --dot-color: $var-surface-accent;
  --dot-color-active: #{ $var-primary-theme };
  --dot-size: 4px;
  --dot-size-active: 16px;
  --dots-offset: #{$dots-offset};

  .owl-dots {
    position: absolute;
    bottom: var(--dots-offset);
    left: 50%;
    margin-top: 0;
    transform: translateX(-50%);
    line-height: var(--dot-size) !important;
  }

  .owl-dot {
    margin: 0;
    padding: 0;

    span {
      width: var(--dot-size) !important;
      height: var(--dot-size) !important;
      background-color: var(--dot-color) !important;
    }

    &.active span {
      width: var(--dot-size-active) !important;
      background-color: var(--dot-color-active) !important;
    }
  }
}



