@use 'sass:map';

.launchpad-tag {
  $label-themes: (
    'binance': $binance-color,
    'ton': $ton-color,
    'ethereum': $ethereum-color,
    'purple': $purple,
    'green': $green,
    'pink': #d773bb,
    'softred': #f07d7d,
    'red': #ff2b5e,
    'orange': $orange,
  );

  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 map.get($spacers, 1_5);
  border-radius: $border-radius-sm;
  background-color: var(--launchpad-tag-color);
  color: $white;
  font-size: $font-size-xs;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
}
