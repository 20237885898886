@use 'sass:map';

.leaderboard-periods-carousel {
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  &__item-container {
    scroll-snap-align: center;
    padding: map.get($spacers, 0_5);
  }
}
