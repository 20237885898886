@use "sass:map";
@use "sass:math";

.modal-nft-maintenance-discount {
  &__progress {
    --pie-size: 8rem;
    --pie-bar-width: 1rem;
  }

  &__total {
    font-family: $font-family-headline;
    font-size: $h3-font-size;
    font-weight: $font-weight-medium;
    line-height: 1;
    margin-top: map.get($spacers, 2);
    margin-bottom: map.get($spacers, 1);
  }

  &__card {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: map-get($spacers, 3);
    border-radius: $border-radius-lg;
    background: $aside-bg;

    @include media-breakpoint-up(sm) {
      padding: map-get($spacers, 4);
    }

    hr {
      margin: map-get($spacers, 2) + map-get($spacers, 1) 0;
      border-color: $var-surface-5;
      border-width: $border-width;
      opacity: 1;

      @include media-breakpoint-up(sm) {
        margin: map-get($spacers, 3) 0;
      }
    }
  }

  &__label {
    $padding-y: map-get($spacers, 1) + math.div(map-get($spacers, 1), 2);
    $padding-x: map-get($spacers, 2) + math.div(map-get($spacers, 1), 2);

    display: inline-block;
    align-self: flex-start;
    flex-shrink: 0;
    padding: $padding-y $padding-x;
    color: $var-primary-theme;
    font-weight: $font-weight-semibold;
    font-size: $extra-small-font-size;
    background-color: rgba($var-primary-theme-rgb, .12);
    border-radius: 10rem;
    text-transform: uppercase;

    &--warning {
      color: $warning;
      background-color: rgba($warning, .12);
    }
  }
}
