@use "sass:math";
@use "sass:map";

// @import "@ng-select/ng-select/scss/mixins";
// @import "~@ng-select/ng-select/scss/ng-select.component";

.ng-select {
  $icon-size: 16px;
  $icon-width: 10px;
  $spinner-size: 10px;

  .ng-select-container {
    display: flex;
    align-items: center;
  }

  .ng-select-container,
  .ng-input input {
    cursor: pointer !important;
  }

  .ng-spinner-loader {
    align-self: center;
    width: $spinner-size !important;
    height: $spinner-size !important;
    margin-right: math.div($icon-size - $spinner-size, 2) - math.div($icon-size - $icon-width, 2) !important;
    margin-left: map.get($spacers, 1) + rem(math.div($icon-size - $spinner-size, 2)) !important;
    border-color: $var-surface-50 !important;
    border-left-color: $var-primary-theme !important;

    & + .ng-arrow-wrapper {
      display: none;
    }
  }

  &.ng-select-empty {
    pointer-events: none;

    .ng-arrow-wrapper {
      display: none;
    }
  }

  &.ng-select-searchable {
    .ng-select-container,
    .ng-input input {
      cursor: text !important;
    }
  }

  &.ng-select-disabled {
    .ng-select-container,
    .ng-input input {
      cursor: default !important;
    }
  }

  .ng-value:empty {
    display: none !important;
  }

  .ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }

  &.ng-select-opened {
    .ng-arrow-wrapper {
      transform: scaleY(-1);
    }

    &.form-control {
      .ng-arrow-wrapper {
        background-color: $input-focus-border-color;
      }
    }
  }

  .ng-arrow-wrapper {
    width: $icon-size;
    height: $icon-size;
    margin-right: -#{math.div($icon-size - $icon-width, 2)};
    margin-left: map.get($spacers, 1);
    transition: $transition;
    transition-property: opacity, transform, background-color;
    background-color: currentcolor;
    mask-repeat: no-repeat;
    mask-position: 50%;
    mask-image: url('../../../assets/images/icons/caret.svg');

    .ng-arrow {
      display: none;
    }
  }

  .ng-clear-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
    margin-left: map.get($spacers, 1);
  }

  .input-group-sm &.form-control {
    min-height: $input-height-lg;

    .ng-select-container {
      padding: $input-padding-y-sm $input-padding-x-sm;
    }

    &.form-rounded {
      .ng-select-container {
        padding-right: $input-padding-rounded-x-sm;
        padding-left: $input-padding-rounded-x-sm;
      }
    }
  }

  .input-group-lg &.form-control {
    min-height: $input-height-lg;

    .ng-select-container {
      padding: $input-padding-y-lg $input-padding-x-lg;
    }

    &.form-rounded {
      .ng-select-container {
        padding-right: $input-padding-rounded-x-lg;
        padding-left: $input-padding-rounded-x-lg;
      }
    }
  }

  &.form-control {
    padding: 0;

    .ng-select-container {
      display: flex;
      align-items: center;
      height: 100%;
      padding: $input-padding-y $input-padding-x;
    }

    &.form-rounded {
      padding: 0;

      .ng-select-container {
        padding-right: $input-padding-rounded-x;
        padding-left: $input-padding-rounded-x;
      }

      // TODO Сделать ng-input динамичным
      &.ng-select-single {
        .ng-select-container {
          .ng-value-container {
            .ng-input {
              padding-right: $input-padding-rounded-x;
              padding-left: $input-padding-rounded-x;
            }
          }
        }
      }
    }

    &.form-control-sm {
      .ng-select-container {
        padding: $input-padding-y-sm $input-padding-x-sm;
      }

      &.form-rounded {
        .ng-select-container {
          padding-right: $input-padding-rounded-x-sm;
          padding-left: $input-padding-rounded-x-sm;
        }

        // TODO Сделать ng-input динамичным
        &.ng-select-single {
          .ng-select-container {
            .ng-value-container {
              .ng-input {
                padding-right: $input-padding-rounded-x-sm;
                padding-left: $input-padding-rounded-x-sm;
              }
            }
          }
        }
      }
    }

    &.form-control-lg {
      .ng-select-container {
        padding: $input-padding-y-lg $input-padding-x-lg;
      }

      &.form-rounded {
        .ng-select-container {
          padding-right: $input-padding-rounded-x-lg;
          padding-left: $input-padding-rounded-x-lg;
        }

        // TODO Сделать ng-input динамичным
        &.ng-select-single {
          .ng-select-container {
            .ng-value-container {
              .ng-input {
                padding-right: $input-padding-rounded-x-lg;
                padding-left: $input-padding-rounded-x-lg;
              }
            }
          }
        }
      }
    }

    &.ng-select-single {
      .ng-select-container {
        .ng-value-container {
          .ng-input {
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    &.ng-select-focused {
      @extend %form-control___focus;
    }

    .ng-placeholder {
      @extend %form-control___placeholder;
    }

    .ng-input {
      @extend %form-control___focus;

      &,
      input {
        padding: 0;
      }

      input {
        color: inherit;
        caret-color: inherit;
      }
    }

    &.ng-select-disabled {
      @extend %form-control___disabled;

      //.ng-placeholder {
      //  @extend .form-control:disabled::placeholder;
      //}
    }

    .ng-arrow-wrapper {
      background-color: $var-grey;
    }

    .ng-clear-wrapper {
      color: $var-grey;
    }

    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          //flex-wrap: nowrap;
          align-items: center;
          margin: -#{math.div(map.get($spacers, 1), 2)} 0;
          overflow: auto;
        }
      }

      .ng-value {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: map-get($spacers, 1);
        margin-right: map.get($spacers, 2);
        padding: math.div(map.get($spacers, 1), 2) map.get($spacers, 2);
        border-radius: $border-radius;
        background: rgba($var-primary-theme-rgb, .1);
        color: $var-primary-theme;
      }

      .ng-value-icon {
        @extend %icon-box;

        transition: .3s ease-in-out;
        transition-property: transform, background-color;
        background-color: currentcolor;
        font-size: 0;
        mask-repeat: no-repeat;
        mask-position: 50% 55%;
        mask-size: 10px;
        mask-image: url('../../../assets/images/icons/times.svg');

        //margin-left: map.get($spacers, 2);
      }
    }
  }

  &.btn-link {
    display: inline-block;

    .ng-arrow-wrapper {
      margin-left: map_get($spacers, 2);
      background-color: currentcolor;
    }

    .ng-dropdown-panel {
      display: inline-block;
      right: 0;
      left: unset;
      width: auto;
    }

    &.dropdown-menu-end {
      .ng-dropdown-panel {
        right: unset;
        left: 0;
      }
    }
  }
}

.ng-dropdown-panel {
  @extend %dropdown-menu;

  display: block;
  min-width: auto;

  //top: auto;

  &.ng-dropdown-panel--in-modal {
    z-index: $zindex-fixed + 80;
  }

  &.form-control {
    height: auto;
    padding: 0;
    border: $dropdown-border-width solid $dropdown-border-color !important;
    border-radius: $dropdown-border-radius;
  }

  &.ng-select-top {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }

  &.ng-select-bottom {
    margin-top: $dropdown-spacer;
    margin-bottom: 0;
  }

  .ng-dropdown-header {
    @extend %dropdown-header;
  }

  .ng-option {
    @extend %dropdown-item;

    &:hover {
      @extend %dropdown-item__hover;
    }

    &.ng-option-selected {
      @extend %dropdown-item-active;
    }

    &.ng-option-disabled {
      @extend %dropdown-item-disabled;
    }

    .selected-icon {
      display: none;
    }

    &.ng-option-selected {
      .selected-icon {
        display: block;
      }
    }
  }
}
