@use "sass:math";
@use "sass:map";
@import 'index';
@import "scss/placeholders";

.catalog-index {
  $selector: &;
  $dropdown-menu-offset: map.get($spacers, 2);
  $border-radius-sl: 2rem;

  display: flex;

  &__header {
    scroll-snap-margin-top: calc($page-top-height + map-get($spacers, 3));
    scroll-margin-top: calc($page-top-height + map-get($spacers, 3));
  }

  &__tooltip-list {
    margin: 0;
    padding-left: map.get($spacers, 3);

    li::marker {
      color: #A0A0B5;
    }
  }

  &__presenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: map.get($spacers, 3);
    border: $border-width solid $var-surface-10;
    border-radius: $border-radius-lg;

    @include media-breakpoint-up(md) {
      padding-right: map.get($spacers, 4);
      padding-left: map.get($spacers, 4);
    }
  }

  &__circle-progress {
    display: flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;

    .circle-progress {
      --pie-bar-width: 0;

      position: absolute;
      width: 100%;
      height: 100%;

      @include inset(0);

      &__trail,
      &__path {
        r: calc(50% - 7px);
        stroke-width: 7px;
        stroke-linecap: round;
      }

      &__path {
        transform: rotate(-90deg);
        transform-origin: center;
        transition: stroke-dashoffset $transition;
      }

      &__trail {
        stroke: $var-surface-5;
      }
    }
  }

  &__tabs {
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;

    .main--inner & {
      margin-right: calc(-1 * (#{$main-offset} + var(--safe-area-inset-right)));
      margin-left: calc(-1 * (#{$main-offset} + var(--safe-area-inset-left)));

      @include media-breakpoint-up(md) {
        margin-right: calc(-1 * (#{$main-offset-md} + var(--safe-area-inset-right)));
        margin-left: calc(-1 * (#{$main-offset-md} + var(--safe-area-inset-left)));
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__tab {
    width: 100%;
    scroll-snap-align: center;
    padding: 0 $main-offset;

    @include media-breakpoint-up(md) {
      padding: 0 $main-offset-md;
    }

    &:not(:last-child) {
      margin-right: -#{rem($main-offset)};

      @include media-breakpoint-up(md) {
        margin-right: -#{rem($main-offset-md) * 2 - map.get($spacers, 3)};
      }
    }
  }

  &__tab-item {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    min-width: 260px;
    height: 100%;
    min-height: 120px;
    padding: map.get($spacers, 3) + map.get($spacers, 1);
    overflow: hidden;
    transition: $transition;
    transition-property: color, background-color;
    border-radius: 20px;
    background-color: #a3a3a5;
    color: $white;
    text-align: left;
    user-select: none;
    isolation: isolate; // Fix for safari border-radius

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: -1;

      @include inset(0);
    }

    &::before {
      transition: transform $transition;
      transition-duration: 300ms;
      border-radius: inherit;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include rtl(true) {
        transform: scaleX(-1);
      }
    }

    &::after {
      $offset-gradient: 40px;

      transition: background-color $transition;
      transition-duration: 300ms;
      background: rgba($black, .4)  linear-gradient(to top, rgba($black, 0.5) #{-$offset-gradient}, rgba($black, 0) calc(100% - #{$offset-gradient}));
    }

    &.coming-soon::before {
      filter: blur(8px);
    }

    &--vikings {
      &::before {
        background-image: url("../../../assets/images/nft/tabs/vikings-bg.jpg")
      }
    }

    &--emirates {
      &::before {
        background-image: url("../../../assets/images/nft/tabs/emirates-bg.jpg");
      }
    }

    &--khabib {
      &::before {
        background-image: url("../../../assets/images/nft/tabs/khabib-bg.jpg")
      }
    }

    &--secondary {
      &::before {
        background-image: url("../../../assets/images/nft/tabs/miners-cards.png");
        background-position: right center;
        background-size: auto 100%;
      }

      #{$selector}__tab-title {
        max-width: 50%;
      }
    }

    &:not(.selected):hover {
      $scale: 1.06;

      &::before {
        opacity: 1;

        @include ltr(true) {
          transform: scale($scale);
        }

        @include rtl(true) {
          transform: scale(-$scale, $scale);
        }
      }

      &::after {
        background-color: transparent;
      }
    }

    &.selected {
      &::before {
        opacity: 1;
      }

      &::after {
        background-color: transparent;
      }
    }
  }

  &__tab-meta {
    margin-bottom: map.get($spacers, 1);
    font-size: $extra-small-font-size;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
  }

  &__tab-title {
    max-width: 75%;
    font-family: $font-family-headline;
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
    line-height: 1.1;

    @include media-breakpoint-up(sm) {
      max-width: 60%;
      font-size: 1.125rem;
    }
  }

  &__btn-filter {
    &:disabled {
      border-color: transparent;
      opacity: 1;
      background-color: $aside-bg;
    }
  }

  &__block-filter {
    $padding-y: map.get($spacers, 3);

    position: sticky;
    z-index: $zindex-sticky;
    top: 0;
    margin-top: calc(-1 * (var(--safe-area-inset-top)));
    margin-right: calc(-1 * (#{$main-offset} + var(--safe-area-inset-right)));
    margin-left: calc(-1 * (#{$main-offset} + var(--safe-area-inset-left)));
    padding: calc(#{$padding-y} + var(--safe-area-inset-top)) calc(#{$main-offset} + var(--safe-area-inset-right)) $padding-y calc(#{$main-offset} + var(--safe-area-inset-left));
    background-color: $body-bg;

    @include media-breakpoint-up(md) {
      margin-right: calc(-1 * (#{$main-offset-md} + var(--safe-area-inset-right)));
      margin-left: calc(-1 * (#{$main-offset-md} + var(--safe-area-inset-left)));
      padding-right: calc(#{$main-offset-md} + var(--safe-area-inset-right));
      padding-left: calc(#{$main-offset-md} + var(--safe-area-inset-left));
    }

    .page-top--active & {
      top: $page-top-height;
      padding-top: $padding-y;
    }
  }

  &__block-filter-presenter {
    overflow: auto;

    .main--inner & {
      margin-right: calc(-1 * (#{$main-offset} + var(--safe-area-inset-right)));
      margin-left: calc(-1 * (#{$main-offset} + var(--safe-area-inset-left)));
      padding-right: calc(#{$main-offset} + var(--safe-area-inset-right));
      padding-left: calc(#{$main-offset} + var(--safe-area-inset-left));

      @include media-breakpoint-up(md) {
        margin-right: calc(-1 * (#{$main-offset-md} + var(--safe-area-inset-right)));
        margin-left: calc(-1 * (#{$main-offset-md} + var(--safe-area-inset-left)));
        padding-right: calc(#{$main-offset-md} + var(--safe-area-inset-right));
        padding-left: calc(#{$main-offset-md} + var(--safe-area-inset-left));
      }
    }
  }

  &__block-filter-item {
    $padding-y: map.get($spacers, 1) + math.div(map.get($spacers, 1), 2);
    $padding-x: map.get($spacers, 3) + map.get($spacers, 1);

    display: flex;
    align-items: center;
    padding: $padding-y $padding-x;
    border-radius: $border-radius;
    background-color: rgba($var-primary-theme-rgb, .08);
    color: $var-primary-theme;
    font-weight: $font-weight-medium;
    white-space: nowrap;

    & + & {
      margin-right: map.get($spacers, 2);
    }
  }

  &__dropdown-sort {
    max-width: 288px;

    @include media-breakpoint-up(sm) {
      max-width: none;
    }
  }

  &__dropdown-filter {
    width: 232px;
    padding: map.get($spacers, 4);

    &--medium {
      width: 270px;
    }

    &--large {
      width: 300px;
    }

    &--auto {
      width: auto;
    }

    .close {
      position: absolute;
      top: map.get($spacers, 2) + $dropdown-menu-offset;
      right: map.get($spacers, 2);
    }
  }

  &__actions {
    padding: map.get($spacers, 3) map.get($spacers, 5) map.get($spacers, 5);
  }

  &__filter {
    display: flex;
    position: fixed;
    z-index: $zindex-fixed + 20;
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    width: 100%;
    margin-left: 1.5rem;
    padding-top: var(--safe-area-inset-top);
    padding-right: var(--safe-area-inset-right);
    padding-bottom: var(--safe-area-inset-bottom);
    transform: translateX(120%);
    transition: transform .3s ease-in-out;
    border-left: $border-width solid $border-color;
    background-color: $var-subface;

    &--visible {
      transform: translateX(0);
    }

    @include media-breakpoint-up(md) {
      top: map.get($spacers, 4);
      right: map.get($spacers, 4);
      bottom: map.get($spacers, 4);
      width: auto;
      border-radius: $border-radius-sl;
    }

    &:not(&--fixed) {
      @include media-breakpoint-up(lg) {
        position: relative;
        z-index: 1;
        top: auto;
        padding: 0;
        transform: translateX(0) !important;
      }
    }
  }

  &__filter-backdrop {
    position: fixed;
    z-index: $zindex-fixed + 19;
    transition: opacity .3s ease-in-out;
    opacity: 0;
    background-color: $dark;
    pointer-events: none;

    @include inset(0);

    &--visible {
      opacity: .6;
      pointer-events: auto;
    }
  }

  &__filter-box {
    width: auto;
    padding: 0 map.get($spacers, 4) map.get($spacers, 4);
    overflow: hidden auto;

    @include media-breakpoint-up(md) {
      width: 368px;
      padding: 0 map.get($spacers, 5) map.get($spacers, 5);
    }
  }

  &__filter-footer {
    padding: map.get($spacers, 4) map.get($spacers, 3);
    border-top: $border-width solid $border-color;
  }

  &__button-reset {
    background-color: $var-surface-accent;
  }

  &__filter-header {
    padding: map.get($spacers, 4) map.get($spacers, 4) 0;

    @include media-breakpoint-up(md) {
      padding: map.get($spacers, 5) map.get($spacers, 5) 0;
    }
  }

  &__filter-sticky {
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      position: sticky;
      top: $page-top-height;

      // overflow: visible;
    }
  }

  // not used
  // &__help {
  //   @extend .badge;
  //   @include badge-variant($var-surface-10, var(--primary-theme-link-hover-color));

  //   display: inline-flex;
  //   align-items: center;
  //   color: var(--primary-theme-link-color);
  //   font-weight: $font-weight-medium;
  // }

  &__cards-row {
    $grid-breakpoints: (
      xxl: 1700px,
    );

    @include media-breakpoint-up(xxl, $grid-breakpoints) {
      & > * {
        flex: 0 0 auto;
        width: 20%;
      }
    }
  }

  // TODO Check actual
  &__card-presenter {
    @include --color('surface', $surface-dark, false, true);
    @include --color('primary-theme', $white, false, true, true);
    @include --color-button('primary-theme', $white);

    border: none !important;
    background: linear-gradient(88.75deg, #C97EF8 0%, #7540EF 100%);

    .card-body {
      color: $var-surface;
    }
  }

  &__card-presenter-image-wrapper {
    position: relative;
    flex-grow: 1;
    height: 190px;

    @include media-breakpoint-up(sm) {
      height: auto;
    }
  }

  &__card-presenter-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__paginator {
    margin-bottom: map.get($spacers, 3) * 5;

    @include media-breakpoint-up(md) {
      margin-bottom: map.get($spacers, 4) * 3;
    }
  }
}
