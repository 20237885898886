@use "sass:map";

.miner-wars-select-pool {
  &__badge-new-clan {
    @include border-bottom-radius(map.get($spacers, 3));

    background-color: $primary-game;
  }

  &__card-new-clan {
    @include border-bottom-radius(0 !important);
  }

  &__image-loader {
    position: relative;
    height: 48px;
    width: 48px;
    background-color: $var-surface-10;
    border: 2px solid $var-surface;
    border-radius: 50%;
  }
}
