@import "../scss/variables-null";
@import "bootstrap/scss/reboot";


html, body, app-root {
  min-height: 100vh;
  min-height: 100dvh;
}

body {
  position: relative;
  width: 100%;
  background-color: $body-bg;
}

app-root {
  display: flex;
  flex-direction: column;
}

main {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 0;
  margin-left: 0;
}

*:focus {
  outline: none !important;
}

svg {
  display: inline-flex;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: inherit;

  &:hover, &:focus {
    outline: none;
  }
}

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}

b, strong {
  font-weight: $font-weight-bold;
}

label {
  margin-bottom: 0;
}


[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  @include rtl(true) {
    direction: rtl;
  }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  transition: background-color 5000s ease-in-out 0s, $input-transition;
  background: linear-gradient(top,  rgb(255 255 255 / 0%) 0%,rgb(0 174 255 / 4%) 50%,rgb(255 255 255 / 0%) 51%,rgb(0 174 255 / 3%) 100%);
  box-shadow: 0 0 0 1000px transparent inset;
  caret-color: $input-color;
  -webkit-text-fill-color: $input-color;
}

@keyframes autofill {
  to {
    background: transparent;
    color: inherit;
  }
}

input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal {
  display: none; width : 0; height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

button, a {
  &:focus {
    box-shadow: none !important;
  }
}

a {
  transition: $link-transition;

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}

.no-transition {
  *:not(:-webkit-autofill) {
    transition: none !important;
  }
}

.text-dotted {
  border-bottom: 1px dotted;
}

a, img {
  -webkit-touch-callout: none;
}
