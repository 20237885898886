@use 'sass:map';

.content-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  color: $var-grey;
  font-size: $font-size-xs;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;

  &__label {
    display: inline-block;
    position: relative;
    padding: 0 map.get($spacers, 2);

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 99999px;
      height: $border-width;
      transform: translateY(-50%);
      background-color: $var-surface-10;
    }

    &::before {
      right: 100%;
    }

    &::after {
      left: 100%;
    }
  }
}
