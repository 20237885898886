@use "sass:map";

.banner-promocode {
  &__day-counter {
    // margin-left: map.get($spacers, 2) + map.get($spacers, 1);
    background-color: $violet;
    line-height: 1;

    .icon-box {
      width: auto;
      height: auto;
    }
  }

  &__code {
    color: #D94DF2;
  }

  &__skeleton-desc {
    width: 150px;
    height: 1.25rem;
  }

  &__skeleton-header {
    width: 100px;
    height: 1.5rem;
  }
}
