@use 'sass:map';

@property --angle {
  syntax: '<number>';
  initial-value: 0;
  inherits: false;
}

@keyframes animate-bar {
  from {--angle: 0}
}

@keyframes animate-label {
  to {opacity: 1}
}

@mixin loaded-animation($name, $delay: 0.5s, $duration: 1s) {
  animation: $name $duration $delay ease both;
}

.circle-progress {
  @mixin center-circle($size) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $size;
    height: $size;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  --pie-size: 10.75rem;
  --pie-bar-width: 1.5rem;
  --pie-bar-color: #{$var-surface-accent};
  --pie-bg-color: #{$var-subface};
  --progress-angle-inner: 0deg;
  --progress-angle-outer: 0deg;
  --progress-bg-inner: #{$green};
  --progress-bg-outer: #{$green};
  --label-bg: var(--progress-bg-outer);

  $size: calc(var(--pie-size) + 2 * var(--pie-bar-width));

  position: relative;
  width: $size;
  height: $size;
  border: var(--pie-bar-width) solid var(--pie-bar-color);
  border-radius: 50%;

  &::before {
    @include center-circle(#{var(--pie-size)});

    content: '';
    z-index: 4;
    background-color: var(--pie-bg-color);
    box-shadow:
      0 4px 32px 0 #36353814,
      0 0 4px 0 #26252705;
    pointer-events: none;
  }

  &--green {
    --progress-bg-inner: #{$green};
    --progress-bg-outer: #{$green};
  }

  &--red {
    --progress-bg-inner: #{$red};
    --progress-bg-outer: #{$red};
  }

  &--blue {
    --progress-bg-inner: #{$blue};
    --progress-bg-outer: #{$blue};
  }

  &__bar {
    --size: #{$size};
    --color: var(--progress-bg-outer);
    --angle: var(--progress-angle-outer);

    @include center-circle(var(--size));

    z-index: 1;

    $color-stop: calc(var(--angle) * 1deg);

    background: conic-gradient(var(--color) $color-stop, var(--pie-bar-color) $color-stop 360deg);

    @include rtl {
      $color-stop: calc(360deg - (var(--angle) * 1deg));

      background: conic-gradient(var(--pie-bar-color) $color-stop, var(--color) $color-stop 360deg);
    }

    &--outer {
      --size: #{$size};
      --color: var(--progress-bg-outer);
      --angle: var(--progress-angle-outer);
    }

    &--inner {
      --size: calc(var(--pie-size) + var(--pie-bar-width));
      --color: var(--progress-bg-inner);
      --angle: var(--progress-angle-inner);
    }

    &.animate {
      @include loaded-animation(animate-bar);
    }
  }

  &__content {
    @include center-circle(var(--pie-size));

    display: flex;
    z-index: 5;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  &__ruler {
    @include center-circle($size);

    z-index: 3;
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__center {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
  }

  &__label {
    display: flex;
    position: absolute;
    top: calc(0px - (#{$size}) / 2);
    align-items: center;
    justify-content: center;
    width: 0;
    height: var(--pie-bar-width);
    transform: rotate(calc(var(--label-angle) * 1deg));
    transform-origin: 0 calc((#{$size}) / 2);
    opacity: 0;

    &.animate {
      @include loaded-animation(animate-label, 1.5s, 0.3s);
    }

    span {
      display: inline-block;
      padding: 0 map.get($spacers, 1);
      background-color: var(--pie-bar-colo);
      color: $var-grey;
      font-size: 0.5rem;
      line-height: 1;
      white-space: nowrap;
    }

    &--filled {
      span {
        background-color: var(--label-bg);
        color: $white;
      }
    }

    &--reversed {
      span {
        transform: rotate(180deg);
      }
    }
  }
}
