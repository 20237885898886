@use "sass:math";

.staking {
  &__card-details {
    display: block;
    max-width: 525px;
    margin: 0 auto;

    .card-body {
      color: $body-color;
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 53px;
    border-radius: $border-radius;

    &--success {
      color: $success;
      background-color: rgba($success, .16);
    }

    &--danger {
      color: $danger;
      background-color: rgba($danger, .16);
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    transition: opacity $transition;

    &--disabled {
      pointer-events: none;
      opacity: .4;
    }
  }

  &__amount-input-group {
    @include media-breakpoint-up(sm) {
      max-width: 130px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 160px;
    }

    .form-control,
    .input-group-append,
    .input-group-prepend {
      border: none;
      background-color: $aside-bg;
    }

    .form-control {
      padding-left: map-get($spacers, 1) !important;
      padding-right: map-get($spacers, 1) !important;
    }

    .input-group-append {
      align-items: center;
      padding-right: 2px !important;
    }
  }
}
