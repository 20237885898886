.select-wallets {
  &--readonly-item {
    &.ng-select {
      &.ng-select-single {
        .ng-select-container {
          &.ng-has-value {
            pointer-events: none;

            .ng-arrow-wrapper {
              display: none;
            }

            .ng-clear-wrapper {
              pointer-events: all;
            }
          }
        }
      }
    }
  }

  &--with-input {
    &.ng-select {
      .ng-arrow-wrapper {
        display: none;
      }
    }
  }

  &--visually-hidden {
    .ng-select-container {
      @include visually-hidden();
    }
  }
}