.tooltip-selection {
  display: inline-block;
  margin: -#{map-get($spacers, 2)};
  padding: map-get($spacers, 2);

  &__child {
    display: inherit;
  }

  &__tooltip,
  .tooltip {
    pointer-events: all !important;
  }
}
