@import 'module-variables/banner-builder';

@mixin banner-builder-ltr() {
  #{$banner-builder}--ltr & {
    @include dir-ignore {
      @content;
    }
  }
}

@mixin banner-builder-rtl() {
  #{$banner-builder}--rtl & {
    @include dir-ignore {
      @content;
    }
  }
}

@mixin banner-builder($self: false) {
  @if $self {
    &#{$banner-builder} {
      @content;
    }
  } @else {
    #{$banner-builder} {
      @content;
    }
  }

}

@mixin banner-builder-image-wrapper($full: false) {
  @if $full {
    #{$banner-builder}__image-wrapper {
      @content;
    }
  } @else {
    &__image-wrapper {
      @content;
    }
  }

}

@mixin banner-builder-image {
  &__image {
    @content;
  }
}
