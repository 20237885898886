@use "sass:math";
@use "sass:map";
@import "../scss/mixins";

$sidebar-redesign-host: '.sidebar-redesign';

.sidebar-redesign {
  --sidebar-max-width: 100%;

  $padding-top: map.get($spacers, 2);
  $padding-bottom: map.get($spacers, 3);
  $padding-x: map.get($spacers, 3);
  $padding-item-y: math.div(map.get($spacers, 1), 2);
  $padding-item-x: map.get($spacers, 2) * 1.5;
  $padding-link-y: map.get($spacers, 2);
  $padding-link-x: map.get($spacers, 2) + map.get($spacers, 1);

  display: flex;
  position: fixed;
  z-index: $zindex-fixed + 11;
  top: $page-top-height;
  right: 0;
  bottom: 0;
  flex-direction: column;
  width: 100%;
  padding-left: var(--safe-area-inset-left);
  padding-right: var(--safe-area-inset-right);
  max-width: calc(var(--sidebar-max-width) + var(--safe-area-inset-right) + var(--safe-area-inset-left));
  transform: translate3d(100%, 0, 0);
  transition: $sidebar-transition;
  background-color: $sidebar-bg;

  @media (width >= 400px) {
    --sidebar-max-width: 288px;

    padding-left: 0;
    max-width: calc(var(--sidebar-max-width) + var(--safe-area-inset-right));
  }

  &#{$sidebar-redesign-host}--active {
    transform: translate3d(0, 0, 0);
  }

  &__backdrop {
    position: fixed;
    z-index: $zindex-fixed + 10;
    top: $page-top-height;
    transition: opacity .3s ease-in-out;
    opacity: .6;
    background-color: $dark;

    @include inset(0);
  }

  &__nav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: $padding-top $padding-x map.get($spacers, 1);
    overflow: auto;
    touch-action: pan-y;

    @media (width >= 400px) {
      padding-left: $padding-x;
    }
  }

  &__list {
    margin: 0 0 #{map.get($spacers, 2) * 1.5};
    padding: map.get($spacers, 1);
    border-radius: $dropdown-border-radius;
    background-color: $var-aside-bg-600;
    list-style: none;
  }

  &__container {
    margin: map.get($spacers, 2) 0;
    margin-left: map.get($spacers, 2) * 1.5;
    color: $var-grey;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    &--active {
      color: $var-surface;
    }
  }

  &__item {
    display: block;
    padding: 0 $padding-item-x;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: map.get($spacers, 2) 0;
    color: $var-surface;
    white-space: nowrap;

    --sidebar-icon-color: #{$sidebar-item-icon-color};
    --sidebar-arrow-color: #{$sidebar-item-icon-color};

    &--active {
      --sidebar-icon-color: #{$var-surface};
    }

    &:hover {
      --sidebar-arrow-color: #{$var-surface};

      color: $var-surface;
    }

    &.disabled {
      opacity: .5;
    }

    #{$sidebar-redesign-host}__item:not(:last-child) & {
      padding-bottom: calc(#{map.get($spacers, 2)} - 1px);
      border-bottom: 1px solid $var-surface-5;
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $sidebar-item-icon-size;
    height: $sidebar-item-icon-size;
    margin-right: $sidebar-item-icon-offset;
    transition: inherit;
    color: var(--sidebar-icon-color);
    text-align: center;
  }

  &__arrow {
    transition: color $transition;
    color: var(--sidebar-arrow-color);
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 18px;
    height: 18px;
    padding: .25rem .25rem .25rem 0.2rem;
    border-radius: 10rem;
    background-color: $danger;
    color: $white;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }

  &__footer {
    padding: #{map.get($spacers, 2) * 1.5} $padding-x $padding-bottom;

    @media (min-width: 400px) {
      padding-left: $padding-x;
    }
  }

  &__divider {
    margin: 0 $padding-x;
  }

  &__widget {
    &.btn {
      &,
      &.locale-switcher__btn,
      &.theme-switcher__btn {
        --offset: #{map.get($spacers,  2)};

        @extend %btn-secondary-theme;
        @extend %btn;

        width: 100%;
        height: 40px;
        padding: 0 map.get($spacers, 2);
        background-clip: padding-box;
      }
    }
  }
}

body#{$sidebar-redesign-host}--active {
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }
}
