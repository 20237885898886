@use 'sass:map';

.expandable-text {
  white-space: initial;

  &:not(&--expanded) {
    @supports (display: -webkit-inline-box) and (-webkit-box-orient: vertical) and (-webkit-line-clamp: 2) {
      -webkit-line-clamp: var(--line-clamp);
    }
  }

  img {
    display: none;
  }

  .safari & {
    p, ul, ol, li {
      display: inline;

      &::after {
        content: " \A";
        white-space: pre;
      }
    }

    .btn {
      display: inline;
      text-align: left;
      padding: 0 !important;
      background: transparent !important;
      border: none !important;

      @each $color, $value in $theme-colors {
        &.btn-#{$color} {
          @include colored-links(
            var(--#{$color}-link-color),
            var(--#{$color}-link-hover-color),
          );
        }
      }

      @each $color, $value in $themes {
        &.btn-#{$color} {
          @include colored-links(
            var(--#{$color}-link-color),
            var(--#{$color}-link-hover-color),
          );
        }
      }
    }
  }
}
