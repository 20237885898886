@use "sass:math";

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.row-grid {
  // margin-top: calc(-0.5 * var(--#{$prefix}gutter-y, #{$grid-gutter-width}));
  // margin-bottom:calc(-0.5 * var(--#{$prefix}gutter-y, #{$grid-gutter-width}));
  margin-top: math.div(-$grid-gutter-width, 2);
  margin-bottom: math.div(-$grid-gutter-width, 2);
}

.col-grid {
  padding-top: math.div($grid-gutter-width, 2);
  padding-bottom: math.div($grid-gutter-width, 2);
}

.container,
.container-main {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.container {
  max-width: $container-max-width;
}

.container-main {
  max-width: $container-main-max-width;
}
