@use "sass:map";

@mixin --color($color-name, $value, $hsl: false, $contrast: false, $link: false) {
  --#{$color-name}: #{ $value };
  --#{$color-name}-rgb: #{ red($value), green($value), blue($value) };

  @if $hsl == true {
    --#{$color-name}-h: #{ hue($value) };
    --#{$color-name}-s: #{ saturation($value) };
    --#{$color-name}-l: #{ lightness($value) };
    --#{$color-name}-hsl: var(--#{$color-name}-h), var(--#{$color-name}-s), var(--#{$color-name}-l);
  }

  @if $contrast == true {
    --#{$color-name}-contrast: #{ color-contrast($value) };
  }

  @if $link {
    --#{$color-name}-link-color: #{ $value };
    --#{$color-name}-link-hover-color: #{ shift-color($value, $link-shade-percentage) };
  }
}

@mixin --color-button(
  $color-name,
  $value,
  $options: (
    'color': null,
    'hover-background': null,
    'active-background': null,
  )
) {
  $option-color: map.get($options, 'color');
  $option-hover-background: map.get($options, 'hover-background');
  $option-active-background: map.get($options, 'active-background');

  $background: $value;
  $color: if($option-color, $option-color, color-contrast($value));
  $hover-background: if($option-hover-background, $option-hover-background, if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)));
  $hover-color: if($option-color, $option-color, color-contrast($hover-background));
  $active-background: if($option-active-background, $option-active-background, if($color == $color-contrast-light, shade-color($background,$btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)));
  $active-color: if($option-color, $option-color, color-contrast($active-background));
  $disabled-background: $background;
  $disabled-color: $color;

  --#{$color-name}-button-background: #{ $background };
  --#{$color-name}-button-border: #{ $background };
  --#{$color-name}-button-color: #{ $color };
  --#{$color-name}-button-hover-background: #{ $hover-background };
  --#{$color-name}-button-hover-border: #{ $hover-background };
  --#{$color-name}-button-hover-color: #{ $hover-color };
  --#{$color-name}-button-active-background: #{ $active-background };
  --#{$color-name}-button-active-border: #{ $active-background };
  --#{$color-name}-button-active-color: #{ $active-color };
  --#{$color-name}-button-disabled-background: #{ $disabled-background};
  --#{$color-name}-button-disabled-border: #{ $disabled-background };
  --#{$color-name}-button-disabled-color: #{ $disabled-color };
}

@mixin --color-button-opacity(
  $color-name,
  $value,
  $options: (
    'color': null,
    'background': null,
    'hover-background': null,
    'active-background': null,
  )
) {
  $option-color: map.get($options, 'color');
  $option-background: map.get($options, 'background');
  $option-hover-background: map.get($options, 'hover-background');
  $option-active-background: map.get($options, 'active-background');

  $color: if($option-color, $option-color, $value);
  $background: if($option-background, $option-background, rgba($value, .08));
  $hover-background: if($option-hover-background, $option-hover-background, rgba($value, .16));
  $active-background: if($option-active-background, $option-active-background, rgba($value, .2));

  --#{$color-name}-button-opacity-color: #{ $color };
  --#{$color-name}-button-opacity-background: #{ $background };
  --#{$color-name}-button-opacity-hover-background: #{ $hover-background };
  --#{$color-name}-button-opacity-active-background: #{ $active-background };
}

@mixin --color-pagination(
  $color-name,
  $value,
  $options: (
    'color': null,
    'background': null,
    'hover-background': null,
  )
) {
  $option-color: map.get($options, 'color');
  $option-background: map.get($options, 'background');
  $option-hover-background: map.get($options, 'hover-background');

  $color: if($option-color, $option-color, $value);
  $background: if($option-background, $option-background, rgba($value, .08));
  $hover-background: if($option-hover-background, $option-hover-background, rgba($value, .04));

  --#{$color-name}-pagination-color: #{ $color };
  --#{$color-name}-pagination-background: #{ $background };
  --#{$color-name}-pagination-hover-background: #{ $hover-background };
}

@mixin --color-alert($color-name, $value, $body-bg, $delta: 8%) {
  $color: color-contrast(mix($value, $body-bg, $delta));
  $background: rgba($value, $delta);

  --#{$color-name}-alert-color: #{ $color };
  --#{$color-name}-alert-icon-color: #{ $value };
  --#{$color-name}-alert-background: #{ $background };
}


@mixin light-theme-content($both: false) {
  @if $both == true {
    [dir] &, [dir].light & {
      @content;
    }
  } @else {
    [dir].light &, .light & {
      @content;
    }
  }
}
