$cycle-days: 0, 1, 2, 3, 4, 5, 6, 7;

.miner-wars-leaderboard {
  &__cycle {
    font-size: 1.125rem;
  }

  &__nav-btn {
    min-width: 1.5rem;
    min-height: 1.5rem;

    &:disabled {
      opacity: $disabled-opacity;
    }
  }

  &__reward-fund {
    position: relative;
    border-radius: 2rem;
    padding: 0.325rem 1rem 0.325rem 0.325rem;
    overflow: hidden;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1rem);
    text-shadow: $text-shadow-base;
    font-size: $font-size-sm;

    &::after {
      content: "";
      position: absolute;
      @include inset(0);
    }

    &--btc {
      background-color: rgba(#F7931A, 0.2);
      &::after {
        background-color: #F7931A;
      }
    }

    &--gomining {
      background-color: rgba($primary-game, 0.2);
      &::after {
        background-color: $primary-game;
      }
    }

    @each $day in $cycle-days {
      &--day-#{$day} {
        &::after {
          transform: translateX(calc(100% / 7 * #{(7 - $day)} * -1));
          transition: transform .7s ease-in-out;
        }
      }
    }
  }

  &__reward-fund-img {
    width: 40px;
    height: 40px;
    margin-right: calc(map-get($spacers, 4) / 2);
  }
}
