$info-card-border-radius: 1.5rem;

.info-card {
  border-radius: $info-card-border-radius;
  border: 1px solid $badge-dark-color;
  background-color: $badge-dark-color;
  padding: calc(map-get($spacers, 4) / 2);

  &--has-addon {
    @include border-bottom-radius(0);
  }

  &__divider {
    margin: calc(map-get($spacers, 4) / 2) 0;
    height: 1px;
    background: $badge-light-color;
    box-shadow: 0px -1px 4px 0px rgba($black, 0.25), 0px 1px 0px 0px rgba($black, 0.25);

    &--invisible {
      background: transparent;
      box-shadow: none;
      border-top: none;
      margin-bottom: 0;
    }
  }

  &__addon {
    @include border-bottom-radius($info-card-border-radius);
    border-left: 1px solid $badge-dark-color;
    border-right: 1px solid $badge-dark-color;
    border-bottom: 1px solid $badge-dark-color;
  }
}
