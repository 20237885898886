.miner-wars-pool {
  &__avatar {
    width: 80px;
    height: 80px;

    &_review {
      background-color: $primary-game;
    }

    &--modal {
      width: 112px;
      height: 112px;
      border-radius: 50%;
    }
  }

  &__avatar-wrapper {
    background-color: $secondary-game;
    top: -150px;
  }

  &__alias-input {
    margin-top: 3rem;
  }

  &__name {
    margin-top: 4rem;
  }

  &__user-icon {
    margin-right: 0.375rem;
  }

  &__kick-title {
    margin-top: 4.625rem;
  }

  &__divider {
    margin-block: 1.25rem !important;
  }

  &__avatar-background {
    background: rgba($surface-light, 0.4);
  }

  &__ability {
    margin-left: 0.75rem;
  }

  &__list-item {
    margin-top: 0.5rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
