@use 'sass:map';

$host: '.launchpad-countdown';

.launchpad-countdown {
  display: flex;
  flex-direction: column;

  &--end {
    align-items: flex-end;
  }

  &__status {
    color: $white;
    font-size: $font-size-xxs;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
  }

  &__atom {
    $size: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    padding: 0 map.get($spacers, 1);
    border-radius: $border-radius;
    background-color: rgba($white, .1);
    color: $white;
    font-weight: $font-weight-semibold;
    text-align: center;
  }

  &__label {
    margin-top: -#{map.get($spacers, 0_5)};
    font-size: $font-size-xxs;
    text-transform: uppercase;
  }
}