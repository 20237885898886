@use "sass:math";

@import "index";
@import "module-variables/board-nft-vars";

$nft-game-chat-notification-offset: 5px;
$nft-game-chat-notification-size: 6px;

%notification {
  display: block;
  position: absolute;
  right: $nft-game-chat-notification-offset;
  top: $nft-game-chat-notification-offset;
  width: $nft-game-chat-notification-size;
  height: $nft-game-chat-notification-size;
  border-radius: 50%;
  background-color: $danger;
}

.board-nft-game-chat {
  $input-btn-size: 2rem;
  $input-btn-font-size: $small-font-size;
  $input-btn-border-radius: $border-radius-sm;

  $padding-x: map-get($spacers, 2) + map-get($spacers, 1);

  $chat-bg-color: $white;
  $chat-tabs-border-radius: $border-radius;
  $chat-mobile-breakpoint: lg;

  position: relative;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down($chat-mobile-breakpoint) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: auto;
    margin-left: -$nft-padding-x-sm;
    margin-right: -$nft-padding-x-sm;
    margin-bottom: -$nft-padding-y-sm;
    border-bottom: $nft-border-width solid $nft-border-color;
  }

  @include media-breakpoint-down(sm) {
    margin-left: -$nft-padding-x;
    margin-right: -$nft-padding-x;
    margin-bottom: -$nft-padding-y;
  }

  &__window {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 195px;
    background-color: $chat-bg-color;
    border-radius: $border-radius;

    @include media-breakpoint-down($chat-mobile-breakpoint) {
      @include border-bottom-radius(0);
    }
  }

  &__preloader-line {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__messages {
    position: relative;
    flex-grow: 1;
    padding: map-get($spacers, 1) $padding-x;
    padding-top: 0;
    margin-top: map-get($spacers, 1);
  }

  &__message {
    padding: map-get($spacers, 1) 0;
    word-wrap: break-word;
    user-select: text;
    transition: opacity $transition;

    &.disabled {
      opacity: .5;
    }
  }

  &__panel {
    display: flex;
    align-items: flex-end;
    padding: math.div(map-get($spacers, 4), 2) $padding-x;
  }

  &__input {
    $padding-x: map-get($spacers, 3);
    $padding-y: map-get($spacers, 1);

    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    //max-height: $input-font-size-sm * $input-line-height * 2 + $padding-y;
    height: auto;
    overflow: auto;
    padding-top: $padding-y;
    padding-bottom: $padding-y;
    padding-left: $padding-x;
    padding-right: $padding-x + $input-btn-size;
    min-height: $input-btn-size;
    max-height: $input-btn-size;
    font-size: $input-btn-font-size;
    background-color: $aside-bg;
    border-radius: $input-btn-border-radius;
    border: none;
    cursor: text;
  }

  &__native-input {
    width: 100%;
    color: inherit;
    caret-color: $input-caret-color;
    word-break: break-all;
    border: none;
    background-color: transparent;

    &::placeholder {
      color: $input-placeholder-color;
    }

    &:empty::before {
      content: attr(data-placeholder);
      color: $input-placeholder-color;
    }
  }

  &__btn-send {
    margin-left: -$input-btn-size;
    border-radius: $input-btn-border-radius;

    &.btn-square {
      min-width: $input-btn-size;
      min-height: $input-btn-size;
    }

    svg {
      width: 16px;
      height: 12px;
    }
  }

  &__rooms {
    max-width: 100%;
    position: relative;
    display: inline-flex;
    align-self: flex-start;
    align-items: center;
    background-color: mix($chat-bg-color, $nft-background-color, 40%);

    @include border-top-radius($chat-tabs-border-radius);

    @include media-breakpoint-down($chat-mobile-breakpoint) {
      width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: map-get($spacers, 3);
      background-color: $chat-bg-color;
    }
  }

  &__rooms-tab {
    $padding-y: map-get($spacers, 1);
    $padding-x: map-get($spacers, 3);

    position: relative;
    flex-grow: 1;
    font-size: $small-font-size;
    font-weight: $font-weight-bold;
    color: $var-surface-50;
    padding: $padding-y $padding-x;
    transition: $transition;
    transition-property: color, background-color;

    @include text-truncate;
    @include border-top-radius($chat-tabs-border-radius);

    &.active {
      color: $var-surface;
      background-color: $chat-bg-color;
    }

    &--notification {
      &::after {
        content: '';

        @extend %notification;
      }
    }
  }

  &__app-nav-tabs-item {
    position: relative;

    &--notification {
      &::after {
        content: '';

        @extend %notification;
      }

      &:last-child::after {
        right: rem($nft-game-chat-notification-offset) + $app-nav-tabs-item-offset;
      }
    }
  }

  &__btn,
  &__btn-desktop {
    position: relative;
    display: inline-flex;
    align-items: center;
    min-height: $input-btn-size;
    font-size: $small-font-size;
    font-weight: $font-weight-bold;
  }

  &__btn {
    justify-content: center;
    padding: map-get($spacers, 1) map-get($spacers, 2);
    color: $var-primary-theme;
    background-color: $white;
    border-radius: $border-radius-sm;
  }

  &__icon {
    position: relative;

    &--notification {
      &::after {
        $border-size: 2px;

        content: '';

        @extend %notification;

        top: -$border-size;
        right: -$border-size;
        border: $border-size solid $white;
        box-sizing: content-box;
      }
    }
  }

  &__btn-desktop {
    position: absolute;
    min-width: $input-btn-size;
    justify-content: center;
    right: -$nft-padding-x-sm;
    bottom: 0;
    padding: map-get($spacers, 3) map-get($spacers, 1);
    color: $gray-400;
    background-color: rgba($white, .4);
    transform: scale(-1);
    transition: background-color $transition;

    @include border-end-radius($border-radius);

    @include media-breakpoint-up(lg) {
      right: -$nft-padding-x-lg;
    }

    span {
      overflow: visible;
      writing-mode: vertical-lr;
    }

    &--notification {
      &::after {
        content: '';

        @extend %notification;

        top: auto;
        bottom: $nft-game-chat-notification-offset;
      }
    }
  }

  &__btn-desktop-icon {
    transition: transform $transition;
  }

  &__swiper {
    position: absolute;
    left: 0;
    right: 0;
    top: -40px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      display: block;
      cursor: pointer;
      width: 44px;
      height: 6px;
      background-color: rgba($black, .4);
      border-radius: 10px;
    }
  }
}
