.image-advcash {
  --color-1: #fff;

  @include light-theme-content {
    --color-1: #00364D;
  }

  &__color-1 {
    fill: var(--color-1);
  }
}
