@use "sass:map";

/* stylelint-disable */
@import "maps";
@import "scss/placeholders";

$utilities: map-merge(
    $utilities,
    (
      "gradient": null,
      "gap": null,
      "float": null,
      "border": null,
      //"border-top": null,
      "border-end": null,
      "border-start": null,
      "border-bottom": map-merge(
          map-get($utilities, "border-bottom"),
          (
            values: (
              null: $border-width solid,
              0: 0,
            )
          ),
      ),
      "border-color": null,
      "border-width": null,
      "width": map.deep-merge(
        map.get($utilities, 'width'),
        (values: (0: 0))
      ),
      "min-width": (
        property: 'min-width',
        class: 'min-w',
        values: (0: 0)
      ),
      //"max-width": null,
      "viewport-width": null,
      "min-viewport-width": null,
      "max-height": null,
      "min-height": (
        property: 'min-height',
        class: 'min-h',
        values: (0: 0)
      ),
      "viewport-height": null,
      "min-viewport-height": null,
      //"align-self": null,
      "font-family": null,
      "font-style": null,
      //"line-height": null,
      "user-select": null,
      //"rounded": null,
      "rounded-top": null,
      "rounded-bottom": null,
      "rounded-start": null,
      "rounded-end": null,
      "border-bottom-style": (
        property: border-bottom-style,
        class: border,
        values: (
          "bottom-solid": solid,
          "bottom-dashed": dashed,
          "bottom-dotted": dotted,
        )
      ),
      "color-surface": (
        property: color,
        class: text-surface,
        values: (
          null: $var-surface,
          10: $var-surface-10,
          20: $var-surface-20,
          50: $var-surface-50,
          80: $var-surface-80
        )
      ),
      "color": map-merge(
          map-get($utilities, "color"),
          (
            local-vars: null,
            values: map-merge-multiple(
                map-get(map-get($utilities, "color"), "values"),
                $themes, ("body": $body-color, "white": $white, "grey": $var-grey)
            ),
          ),
      ),
      "font-size": map-merge(
          map-get($utilities, "font-size"),
          (
            values: (
              sl: $font-size-sl,
              lg: $font-size-lg,
              sm: $font-size-sm,
              xs: $font-size-xs,
              base: $font-size-base,
              inherit: inherit
            ),
          ),
      ),
      "font-weight": map-merge(
          map-get($utilities, "font-weight"),
          (
            values: map-merge(
                map-get(map-get($utilities, "font-weight"), "values"),
                (
                  medium: $font-weight-medium,
                  semibold: $font-weight-semibold,
                  inherit: inherit
                ),
            ),
          ),
      ),
      "white-space": map-merge(
          map-get($utilities, "white-space"),
          (
            values: map-merge(
                map-get(map-get($utilities, "white-space"), "values"),
                (
                  pre-line: pre-line,
                ),
            ),
          ),
      ),
      "cursor": (
        property: cursor,
        class: cursor,
        values: pointer default
      ),
    )
);

.pointer-none {
  pointer-events: none !important;
}

.pointer-auto {
  pointer-events: auto !important;
}

.invisible-visually {
  opacity: 0;
  pointer-events: none;
}

.invisible-placeholder {
  &::placeholder {
    visibility: hidden;
  }
}

@include media-breakpoint-up(sm) {
  .visible-sm-placeholder {
    &::placeholder {
      visibility: visible;
    }
  }
}

.h-flex-full {
  @extend %h-flex-full;
}

.h-flex-height-full {
  @extend %h-flex-full;
  flex-basis: auto;
  height: 100%;
}

.h-flex-scroll {
  @extend %h-flex-scroll;
}


.hidden-empty {
  &:empty {
    display: none !important;
  }
}

.sticky-top {
  @extend %sticky-top;

  .page-top--active & {
    top: $page-top-height;
  }
}

.zindex-sticky-1 {
  z-index: $zindex-sticky + 1;
  transform: translate3d(0, 0, 0); // Safari hack
}

.zindex-1 {
  z-index: 1;
}

.zindex-2 {
  z-index: 2;
}

.text-fs-capitalize {
  &:first-letter {
    text-transform: uppercase;
  }
}

.text-gradient {
  @include text-gradient(linear-gradient(135deg, #7540EF 1.5%, #EB7DAF 72.78%, #F07D7D 91.78%));
}

.divider {
  border-bottom: 1px solid rgba(var(--surface-rgb), 0.1);
}

.of-cover {
  object-fit: cover;
}

.of-contain {
  object-fit: contain;
}

$svg-gradients: ('inst': #inst-gradient, 'purple': #purple-gradient, 'blue': #blue-gradient);

@each $color, $value in $svg-gradients {
  .svg-gradient-#{$color} {
    color: $white;

    svg {
      *[stroke=currentColor] {
        stroke: url($value);
      }

      *[fill=currentColor] {
        fill: url($value);
      }
    }
  }
}

@import "bootstrap/scss/utilities/api";
