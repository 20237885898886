.modal-auth-finisher {
  &__progress {
    height: 4px;
    background-color: $var-surface-8;
    border-radius: $border-radius;
  }

  &__progress-bar {
    display: block;
    height: 100%;
    background-color: $var-primary-theme;
    border-radius: inherit;
    transition: width;
  }

  &__action-field {
    display: block;

    .action-field {
      border-color: $var-surface-10;

      &--completed &__label,
      &--completed &__info {
        opacity: .5;
      }

      &__icon {
        width: 40px;
        height: 40px;
      }
    }
  }
}
