.miner-wars-chat {
  --chat-user-avatar-size: 38px;
  --chat-clan-avatar-size: 16px;
  --chat-clan-border-color: #{$secondary-game};
  --chat-clan-border-width: 2px;

  $chat-error-tooltip-max-width: 200px;

  .app-bottom-sheet-container {
    position: absolute;
    z-index: $zindex-game-chat;
  }

  .app-bottom-sheet {
    .desktop-game & {
      height: 65%;

      @include media-breakpoint-up($game-desktop-breakpoint, $game-breakpoints) {
        height: 530px;
      }
    }
  }

  app-bottom-sheet .app-bottom-sheet__wrapper {
    padding-top: 0;
    background-color: transparent;

    .app-game & {
      height: 75vh;

      @media (orientation: landscape) {
        height: calc(90vh - 50px);
      }
    }

    .desktop-game & {
      height: 100%;
      max-height: unset;
    }
  }

  .app-bottom-sheet__handle::before {
    background-color: unset;
  }

  .app-bottom-sheet__content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  &__tab {
    font-family: $font-family-base-game;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &__card {
    padding-top: 0.625rem !important;
  }

  &__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $primary-game;
  }

  &__message-header {
    font-family: $font-family-base-game;
    line-height: 1.125rem;
    color: $danger-game;

    &--pool {
      color: $success-game;
    }
  }

  &__system-header {
    color: $var-surface;
  }

  &__message-wrapper {
    margin-bottom: 0.125rem;

    &--first {
      margin-bottom: 1.75rem;
    }

    &--indent {
      margin-top: 0.375rem;
    }
  }

  &__message {
    padding: 0.75rem;
    padding-right: 17px;
    background-color: rgba($dark, 0.5);
    border-radius: 1rem;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 1rem;
    text-shadow: none !important;

    &--group {
      border-top-left-radius: 1rem;
    }

    &--self {
      padding-right: 0.75rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 0.375rem;
      background-color: $success-game;
    }

    &--self-group {
      border-top-right-radius: 1rem;
    }

    &--no-avatar {
      margin-left: calc(var(--chat-user-avatar-size) + 0.5rem);
    }


  }

  &__avatar {
    position: relative;
    height: var(--chat-user-avatar-size);
    width: var(--chat-user-avatar-size);
  }

  &__clan-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    border: var(--chat-clan-border-width) solid var(--chat-clan-border-color);
    border-radius: 50%;
  }

  &__clan-avatar {
    width: var(--chat-clan-avatar-size);
    height: var(--chat-clan-avatar-size);
  }

  &__date-group {
    margin: 0.375rem 0;
    padding: 0.25rem 0.5rem;
    background-color: rgba($dark, 0.5);
    border-radius: 100px;
  }

  &__time {
    font-size: 0.5rem;
    line-height: 0.875rem;
    opacity: 0.7;
  }

  &__input {
    background: $var-surface-10;
    border: 1px solid #fff;
    border-radius: 1rem;
  }

  &__tooltip {
    max-width: #{$chat-error-tooltip-max-width};
  }
}
