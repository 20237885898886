.code-divider {
  display: flex;
  justify-content: space-between;
  margin: 0 -#{map_get($spacers, 1)};

  &__box {
    margin: 0 map_get($spacers, 1);
    max-width: 58px;
    max-height: 58px;
  }

  &__item,
  &__control {
    border-radius: $border-radius-lg;
  }

  &__item {
    position: relative;
    background-color: transparent;
    width: 100%;
    padding-bottom: 100%;
    border: $border-width solid $input-border-color;
  }

  &__control {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0;
    height: 100%;
    min-height: auto;
    text-align: center;
    appearance: none;
    border: none;
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
