.content {
  h2 {
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
    line-height: 1.5;
    margin-top: map-get($spacers, 4) + map-get($spacers, 2);
  }

  b, strong {
    font-weight: $font-weight-semibold;
  }

  a {
    @extend %text-link;

    font-weight: $font-weight-semibold;

    &.btn {
      text-decoration: none !important;
    }
  }

  p {
    margin-bottom: map-get($spacers, 2);

    &:last-child {
      margin-bottom: 0;
    }
  }

  hr {
    margin: map-get($spacers, 3) 0;
    border-color: $var-surface-10;
    opacity: 1;
  }

  .formula {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    background-color: $var-surface-accent;
    border-radius: $border-radius;
    margin: 0 0 map-get($spacers, 3);
    padding: map-get($spacers, 2) + map-get($spacers, 1);

    &__line {
      min-height: auto;

      & > span[data-label] {
        margin-bottom: -#{map-get($spacers, 3)};

        &::after {
          top: 1.5rem;
        }
      }
    }
  }

  p + .formula {
    margin-top: -#{map-get($spacers, 2)};
  }

  .card-body {
    display: block;
    padding: map-get($spacers, 2) + map-get($spacers, 1);
  }

  ul {
    @extend %l___list;
    @extend %ul___list;
  }

  ol {
    @extend %l___list;
    @extend %ol___list;
  }

  ul, ol {
    &:not(:last-child) {
      margin-bottom: map-get($spacers, 3);
    }

    li::before {
      color: color-contrast(#A0A0B5);
      background-color: #A0A0B5;
    }
  }
}
