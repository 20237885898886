@use "sass:map";

.referral {
  $user-id-input-max-width: 134px;

  &__icon {
    &_warning {
      width: 20px;
      height: 20px;
      background-color: $orange;
      color: $white;
    }
  }

  &__card-header {
    margin: 0 !important;
    padding: map-get($spacers, 2) map-get($spacers, 2) map-get($spacers, 1);

    &-sm {
      margin: 0 !important;
      padding: map-get($spacers, 2) map-get($spacers, 2) map-get($spacers, 1);
    }
  }

  @include media-breakpoint-up(md) {
    &__card-header {
      padding: map-get($spacers, 4) map-get($spacers, 4) map-get($spacers, 1);

      &-sm {
        padding: map-get($spacers, 3) map-get($spacers, 3) map-get($spacers, 1);
      }
    }
  }

  &__card-body {
    margin: 0 !important;
    padding: map-get($spacers, 0) map-get($spacers, 3) map-get($spacers, 3) !important;

    &-sm {
      margin: 0 !important;
      padding: map-get($spacers, 0) map-get($spacers, 3) map-get($spacers, 3) !important;
    }
  }

  @include media-breakpoint-up(md) {
    &__card-body {
      padding: map-get($spacers, 0) map-get($spacers, 5) map-get($spacers, 5) !important;

      &-sm {
        padding: map-get($spacers, 0) map-get($spacers, 4) map-get($spacers, 4) !important;
      }
    }

  }

  &__stat-icon .icon-box,
  &__step .icon-box {
    color: $var-primary-theme;
    font-family: $font-family-headline;
  }

  &__stat-icon {
    .icon-box {
      width: 44px;
      height: 44px;
    }
  }

  &__user-id {
    max-width: $user-id-input-max-width;
  }

  &__close-filters {
    position: absolute;
    top: map.get($spacers, 2);
    right: map.get($spacers, 2);
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  &__terms-container {
    padding: map-get($spacers, 3);
    border: 1px solid $var-surface-10;
    border-radius: map-get($spacers, 3);
  }

  &__terms-list {
    padding-top: map-get($spacers, 3);
    padding-left: map-get($spacers, 4);

    li {
      margin-bottom: map-get($spacers, 1);

      & > * {
        vertical-align: text-top;
      }

      &::marker {
        color: $var-primary-theme;
      }
    }
  }
}
