@use "sass:map";
@use "sass:math";

$info-button-size: 1.5em;
$score-font-size-base: 3em;
$score-font-size-xs: 2.375em;
$score-font-size-sm: 2.5em;
$score-font-size-md: 2.75em;

$multiplier-bgs: (
  2: $primary-game,
  4: map-get($gradients-game, 'purple'),
  8: map-get($gradients-game, 'blue'),
  16: map-get($gradients-game, 'lightBlue'),
  32: map-get($gradients-game, 'green'),
  64: map-get($gradients-game, 'yellow'),
  128: map-get($gradients-game, 'darkYellow'),
  256: map-get($gradients-game, 'orange'),
);

$multipliers: 1, map-keys($multiplier-bgs);


.miner-wars-main {
  // scaling down ems for smaller viewports
  font-size: 14px;
  @include game-breakpoint-up(sm, $game-breakpoints) {
    font-size: 16px;
  }
  @include game-breakpoint-up(lg, $game-breakpoints) {
    font-size: 24px;
  }

  text-shadow: $text-shadow-base;

  overflow: hidden;
  background-image: linear-gradient(to bottom, rgba(14, 14, 31, 0.8) 30%, rgba(14, 14, 31, 0) 100%), url('../../../../assets/images/miner-wars/main-bgs/main-bg.webp');
  background-size: 100% 35%, cover;
  background-repeat: no-repeat, no-repeat;
  background-position: top, center 40%;
  background-color: $dark;
  position: relative;
  padding: $main-offset;

  @each $multiplier, $bg in $multiplier-bgs {
    &.multiplier--#{$multiplier} {
      background-image: linear-gradient(to bottom, rgba(14, 14, 31, 0.9) 30%, rgba(14, 14, 31, 0) 100%), url('../../../../assets/images/miner-wars/main-bgs/main-bg-#{$multiplier}.webp')
    }

    &.st-patricks--#{$multiplier} {
      background-image: linear-gradient(to bottom, rgba(14, 14, 31, 0.9) 30%, rgba(14, 14, 31, 0) 100%), url('../../../../assets/images/miner-wars/st-patricks-bg/bg-#{$multiplier}.webp');
    }
  }

  &.st-patricks--1 {
    background-image: linear-gradient(to bottom, rgba(14, 14, 31, 0.9) 30%, rgba(14, 14, 31, 0) 100%), url('../../../../assets/images/miner-wars/st-patricks-bg/bg-1.webp');
    background-position: top, center 70%;
  }

  &.st-patricks--128 {
    background-position: top, center 100%;
  }

  @include game-breakpoint-up(sm) {
    background-size: 100% 30%, cover;
    background-position: top, center 60%;
  }

  .main--inner & {
    margin: main-inner-offset('top', false, -1) main-inner-offset('right', false, -0.75) main-inner-offset('bottom', false, -1) main-inner-offset('left', false, -0.75);
    padding: main-inner-offset('top', false, 1) main-inner-offset('right', false, 0.75) main-inner-offset('bottom', false, 1) main-inner-offset('left', false, 0.75);

    @include game-breakpoint-up(md, $game-breakpoints) {
      margin: main-inner-offset('top', false, -1) main-inner-offset('right', false, -1) main-inner-offset('bottom', false, -1) main-inner-offset('left', false, -1);
      padding: main-inner-offset('top', false, 1) main-inner-offset('right', false, 1) main-inner-offset('bottom', false, 1) main-inner-offset('left', false, 1);
    }

    @include game-breakpoint-up(md) {
      margin: main-inner-offset('top', true, -1) main-inner-offset('right', true, -1) main-inner-offset('bottom', true, -1) main-inner-offset('left', true, -1);
      padding: main-inner-offset('top', true) main-inner-offset('right', true) main-inner-offset('bottom', true) main-inner-offset('left', true);
    }

    .app-tab-bar--active & {
      margin-bottom: -#{$main-offset};
      padding-bottom: $main-offset;

      @include game-breakpoint-up(md) {
        margin-bottom: -#{$main-offset-md};
        padding-bottom: $main-offset-md;
      }
    }
  }

  &__reconnect {
    z-index: $zindex-game-reconnect;
    position: absolute;
    padding: map.get($spacers, 3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba($dark, 0.9);

    top: 0;
    right: 0;
    left: 0;
    bottom: calc($tab-bar-height * -1);
  }

  &__reconnect-btn {
    animation: fadeInReconnectBtn .3s 5s ease-in-out both;

    @keyframes fadeInReconnectBtn {
      from {
        opacity: 0;
        pointer-events: none;
      }
      to {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__balance {
    transition: color 75ms ease-in-out;

    &.animated-number--active {
      &.animated-number--up {
        color: $success-game;
      }

      &.animated-number--down {
        color: $danger-game;
      }
    }
  }

  &__avatar {
    width: 3.375em;
    height: 3.375em;
    border-radius: 50%;
  }

  &__avatar-wrapper {
    margin: 0 .75em;
    position: relative;
    display: inline-block;
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      @include inset(0);
      z-index: 1;
      border-radius: 50%;
      border: 1px solid $white;
      opacity: .1;
    }

    &--placeholder {
      width: 3.375em;
      height: 3.375em;
    }
  }


  &__info-container {
    margin: em(map-get($spacers, 3)) main-inner-offset('right', false, -0.75) em(map-get($spacers, 3)) main-inner-offset('left', false, -0.75);

    @include game-breakpoint-up(md, $game-breakpoints) {
      margin: em(map-get($spacers, 3)) main-inner-offset('right', false, -1) em(map-get($spacers, 3)) main-inner-offset('left', false, -1);
    }

    @include game-breakpoint-up(md) {
      margin: em(map-get($spacers, 3)) main-inner-offset('right', true, -1) em(map-get($spacers, 3)) main-inner-offset('left', true, -1);
    }
  }

  &__info {
    position: relative;
    display: block;
    text-transform: uppercase;
    border-radius: $border-radius-game;
    border: 1px solid $badge-light-color;
    background: $badge-light-color;
    width: 7.625em;

    &--left {
      @include border-start-radius(0);
      margin-right: calc($info-button-size / 2 * -1);
      padding: .5em 1.25em .5em 1em;
      border-left: 0;
    }

    &--right {
      @include border-end-radius(0);
      text-align: end;
      margin-left: calc($info-button-size / 2 * -1);
      padding: .5em 1em .5em 1.25em;
      border-right: 0;
    }
  }

  &__info-title {
    font-size: em($font-size-xs);
  }

  &__info-indicator {
    display: inline-flex;
    margin-right: map.get($spacers, 1);
    color: $danger-game;
  }

  &__info-caret {
    display: inline-flex;
    margin-left: math.div(map.get($spacers, 1), 2);
  }

  &__info-divider {
    margin: em(map-get($spacers, 2)) 0;
    height: 1px;
    background: $badge-light-color;
    box-shadow: 0px -1px 4px 0px rgba($black, 0.25), 0px 1px 0px 0px rgba($black, 0.25);
  }

  &__info-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $info-button-size;
    height: $info-button-size;
    border-radius: 50%;
    border: 1px solid $badge-light-color;
    background: $badge-light-color;
    backdrop-filter: blur($blur);
  }

  &__info-button-icon {
    transition: transform $transition;
  }


  &__multiplier {
    transition: background-color $transition;

    @each $multiplier, $bg in $multiplier-bgs {
      &--#{$multiplier} {
        background: $bg;
      }
    }
  }

  &__score {
    font-size: $score-font-size-base;
    line-height: 91.667%;
    text-shadow: $text-shadow-large;

    &--7-symbols-sm {
      font-size: $score-font-size-md;
      @include game-breakpoint-up('sm', $game-breakpoints) {
        font-size: $score-font-size-base;
      }
    }

    &--8-symbols-sm {
      font-size: $score-font-size-xs;
      @include game-breakpoint-up('sm', $game-breakpoints) {
        font-size: $score-font-size-sm;
      }
      @include game-breakpoint-up('md', $game-breakpoints) {
        font-size: $score-font-size-base;
      }
    }
  }

  &__score-boost {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 8px);
    display: block;
    transform: translateX(-50%);
  }

  &__chance {
    font-size: em($font-size-lg);

  }

  &__miner-img {
    display: block;
    max-height: 400px;
    width: auto;
    height: auto;
    position: absolute;

    @include game-breakpoint-up(md) {
      max-height: unset;
    }
  }

  &__miner-video {
    position: relative;
    max-width: 700px;
    transform: scale(1.4);

    @include game-breakpoint-up(sm) {
      position: absolute;
      bottom: 0;
    }

    video {
      display: block;
      object-fit: contain;
      width: 100%;
      height: 100%;
      visibility: hidden;
      transition: $transition;

      & + video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &.loaded {
        visibility: visible;
      }
    }
  }

  &__events-container {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__boosts-container {
    margin-top: 1.75em;
  }

  &__boost-price {
    margin-right: 0.125em;
  }

  .balance-viewer {
    --font-size: var(--balance-viewer-font-size, #{em($font-size-base)});
  }

  &__menu {
    position: absolute !important;
    top: 0;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__chat-btn {
    border-radius: 50%;
    min-width: 2.75em !important;
    min-height: 2.75em !important;
    background: map-get($gradients-game, 'green') !important;

    &--unread {
      &::after {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $danger-game;
        content: '';
        position: absolute;
        top: 0;
        right: 3px;
        border: 1px solid $black;
      }
    }
  }

  &__chat-btn-icon {
    height: 2.125em;
    width: 2.125em;
  }

  &__timer {
    min-width: 7ch;
  }
}
