@use "sass:math";

.progress-circles {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -6px;

  &__item {
    width: 10px;
    height: 10px;
    margin: 0 6px;
    border-radius: 50%;
    background-color: $var-primary-theme;
    animation: flash 1.8s infinite linear;
    animation-fill-mode: backwards;

    $count: 9;

    @for $i from 1 through $count {
      $duration: 1.8s;
      $step: math.div($duration, $count);

      &:nth-child(#{$i}) {
        animation-delay: $step * $i - $step;
      }
    }

    @keyframes flash {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
