@use "sass:map";

.modal-settings-two-fa {
  &__code-divider {
    .code-divider {
      &__box {
        max-width: none;
        max-height: none;
        margin: 0 map.get($spacers, 0_5);
      }

      &__control {
        .twa & {
          font-family: $font-family-base;
        }

        font-size: $font-size-sl;
        font-family: $font-family-headline;
        font-weight: $font-weight-medium;

        @include media-breakpoint-up(sm) {
          font-size: $h2-font-size;
        }
      }
    }
  }
}
