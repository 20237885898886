@use "sass:math";
@use "sass:map";

%catalog-sticky-header {
  $padding-y: map.get($spacers, 2);
  $padding-t: map.get($spacers, 1);

  position: sticky;
  z-index: $zindex-sticky;
  top: -#{rem($main-offset) - $padding-y};
  margin-top: calc(-1 * (#{$main-offset} + var(--safe-area-inset-top)));
  margin-right: calc(-1 * (#{$main-offset} + var(--safe-area-inset-right)));
  margin-left: calc(-1 * (#{$main-offset} + var(--safe-area-inset-left)));
  padding: calc(#{$main-offset} + var(--safe-area-inset-top) - $padding-t) calc(#{$main-offset} + var(--safe-area-inset-right)) $padding-y calc(#{$main-offset} + var(--safe-area-inset-left));
  background-color: $body-bg;

  @include media-breakpoint-up(md) {
    top: -#{rem($main-offset-md) - $padding-y};
    margin-top: calc(-1 * (#{$main-offset-md} + var(--safe-area-inset-top)));
    margin-right: calc(-1 * (#{$main-offset-md} + var(--safe-area-inset-right)));
    margin-left: calc(-1 * (#{$main-offset-md} + var(--safe-area-inset-left)));
    padding-top: calc(#{$main-offset-md} + var(--safe-area-inset-top));
    padding-right: calc(#{$main-offset-md} + var(--safe-area-inset-right));
    padding-left: calc(#{$main-offset-md} + var(--safe-area-inset-left));
  }
}

@mixin catalog-item-card-base($padding: map.get($spacers, 3), $padding-sm: map.get($spacers, 4)) {
  @include border-radius(map.get($spacers, 3));

  padding: $padding;

  @include media-breakpoint-up(sm) {
    @include border-radius(map.get($spacers, 4));

    padding: $padding-sm;
  }
}

.catalog-item {
  h1 {
    font-family: $font-family-headline;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    .twa & {
      @extend %catalog-sticky-header;
    }
  }

  &__banner-maintenance {
    @include catalog-item-card-base(0, 0)
  }

  &__gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 96px;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  }

  &__card {
    @include catalog-item-card-base($padding: 0, $padding-sm: 0);

    overflow: hidden;
    border: 0 solid $border-color;
    background-color: $var-subface;

    &-mark-wrapper {
      display: flex;
      position: absolute;
      z-index: 1;
      top: 0;
      padding: 1.5rem;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      border: $border-width solid transparent;
      pointer-events: none;

      &--info {
        left: 0;
        pointer-events: auto;
      }

      &--days {
        right: 0;
      }
    }

    &-mark {
      display: block;
      padding: map.get($spacers, 1);
      transform: translate(30px, -30px) rotate(45deg);
      background-color: $orange;
      color: $dark;
      font-weight: $font-weight-semibold;
      text-align: center;
      text-transform: uppercase;
    }

    .twa &-nft  {
     @include border-radius(0);
      border-bottom-left-radius: map.get($spacers, 3);
      border-bottom-right-radius: map.get($spacers, 3);
    }

    .twa &--trial  {
     @include border-radius(0);
    }

    &--trial-expired {
      filter: grayscale(1);
    }
  }

  &__slider {
    @extend %investment-card__slider; // TODO Сделать базовые стили для слайдера
  }

  &__picture-wrapper {
    position: relative;
  }

  &__picture {
    position: relative;
    overflow: hidden;
  }

  &__image-wrapper {
    position: relative;
    padding-bottom: 100%;
  }

  &__image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
  }

  &__item-info {
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;
    padding: map.get($spacers, 4);
    color: $surface-dark;
  }

  &__item-name {
    font-size: $font-size-sl;
    font-weight: $font-weight-medium;
  }

  &__image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;

    @include inset(0);
  }

  &__actions-menu {
    $offset: map.get($spacers, 3);

    position: absolute;
    top: $offset;
    right: $offset;

    .action-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      transition: opacity $transition;
      border-radius: 50%;
      background-color: $white;
      color: $dark;

      &:disabled {
        opacity: 0;
      }
    }
  }

  &__pre-sale {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    text-transform: uppercase;

    @include inset(0);
  }

  &__marquee {
    display: flex;
    position: relative;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    white-space: nowrap;

    &--primary-theme {
      background-color: $var-primary-theme;
      color: $var-primary-theme-contrast;
    }

    &--red {
      background-color: $red;
      color: $white;
    }
  }

  &__marquee-item {
    display: inline-block;
    flex-shrink: 0;
    margin: 0;
    animation: animMarquee 15s linear infinite;

    @keyframes animMarquee {
      from {
        transform: translate3d(0, 0, 0);
      }

      to {
        transform: translate3d(-100%, 0, 0);
      }
    }
  }

  &__select-currencies {
    .ng-select.select-currencies.form-control {
      border-radius: 10rem;
      border-color: $border-color;
      background-color: $body-bg;

      .ng-select-container {
        $padding-x: map.get($spacers, 2);

        padding-right: $padding-x;
        padding-left: $padding-x;
      }

      .ng-arrow-wrapper {
        background-color: currentcolor;
      }

      .ng-dropdown-panel {
        width: auto;

        .ng-option {
          $padding: map.get($spacers, 2);

          padding: $padding;
        }
      }
    }
  }

  &__feat {
    @include catalog-item-card-base($padding-sm: map.get($spacers, 3) map.get($spacers, 4));

    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    background: $var-subface;
    text-align: left;

    .twa & {
      background: $var-surface-accent;
    }

    &--promo {
      @include media-breakpoint-up(sm) {
        padding: map.get($spacers, 4);
      }
    }

    &--nft-desc {
      @include border-radius($border-radius-lg);

      background: $var-aside-bg;
    }
  }

  &__feat-icon {
    --size: 40px;

    margin-right: map.get($spacers, 3);
    color: $var-primary-theme;

    @include media-breakpoint-up(sm) {
      --size: 52px;

      &--sm {
        --size: 40px;
      }
    }

    svg {
      width: var(--size);
      height: var(--size);
    }
  }

  &__feat-name {
    margin-bottom: map.get($spacers, 1);
    color: $text-muted;
    font-size: $extra-small-font-size;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
  }

  &__feat-value {
    @extend %lead-headline;

    font-weight: $font-weight-semibold;
  }

  &__specification,
  &__metadata,
  &__calculator,
  &__description,
  &__trial-tasks,
  &__benefits,
  &__trial-achievements {
    @include catalog-item-card-base;

    margin-top: map.get($spacers, 3);
    background: $var-subface;
    border: 0 solid $border-color;
  }

   &__description {
      @include media-breakpoint-down(lg) {
        margin-top: map.get($spacers, 2);

        .twa & {
          margin-top: map.get($spacers, 1);
        }
      }
   }


  &__trial-tasks-empty-icon {
    width: 56px;
    height: 56px;
  }

  &__info {
    @include catalog-item-card-base;
  }

  &__metadata {
    &-card {
      display: flex;
      flex-direction: column;
      padding: map.get($spacers, 3);
      border-radius: $border-radius-lg;
      background: $var-aside-bg;

      &--rainbow {
        @include border-gradient(map.get($gradients, 'rainbow'));

        background: $var-aside-bg;
      }
    }

  }

  &__time {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(7),
    &:nth-child(8) {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 46px;
      margin: 0 1px;
      border-radius: .375rem;
      background-color: rgba($white, .05);
    }

    &:nth-child(3),
    &:nth-child(6) {
      margin: 0 map.get($spacers, 1);
    }
  }


  &__google-pay, &__apple-pay {
    --#{$prefix}btn-bg: #{$surface-dark};
    --#{$prefix}btn-color: #{$surface-light};
  }

  &__apple-pay {
    --#{$prefix}btn-border-color: #{$black};
    --#{$prefix}btn-hover-border-color: #{$black};

    border: 0.5px solid $black;
  }

  &__google-pay {
    --#{$prefix}btn-border-color: #{$var-surface-10};
    --#{$prefix}btn-hover-border-color: #{$var-surface-10};

    border-color: $var-surface-10;
  }

  &__pay-transparent {
    border: 1px solid $var-surface-10 !important;
    background: transparent !important;
    color: $var-link-decoration !important;

    @include light-theme-content {
      color: $surface-light !important;
    }
  }

  &__benefit {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: map.get($spacers, 3) 0;
    border-bottom: 1px solid rgba($white, .05);

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    &-action {
      width: 100%;
      margin-top: map.get($spacers, 3);

      @include media-breakpoint-up(sm) {
        width: auto;
        margin-top: 0;
        margin-left: map.get($spacers, 3);
      }
    }
  }

  &__button-key-about {
    --#{$prefix}btn-border-color: #0E0F1F1A;
    --#{$prefix}btn-border-radius: 100px;
    --#{$prefix}btn-active-border-color: #0E0F1F1A;

    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  &__benefit-icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: map.get($spacers, 3);
    border-radius: $border-radius;
    background-color: $var-aside-bg;
    color: $var-grey;

    /* stylelint-disable-next-line no-descending-specificity */
    svg {
      width: 24px;
      height: 24px;
    }

    .catalog-item__benefit--highlight & {
      background: linear-gradient(135deg, #6928FF 14.5%, #1E9BFA 97.3%);
      color: $white;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__price-skeleton {
    position: absolute;
    top: 50%;
    left: 0;
    width: 150px;
    height: 1rem;
    transform: translate(0, -50%);
  }

  .twa &__content {
    @include media-breakpoint-down(md) {
      @include catalog-item-card-base(0, 0);
    }
  }

  .twa &__power {
    width: 100%;
    margin-inline: auto;
    padding: map.get($spacers, 3);
    background: $var-subface;
    border: 0 solid $border-color;
    @include border-radius(map.get($spacers, 3));
  }

  .twa & .cards-container  {
    border-radius: 0;
    @include media-breakpoint-up(lg) {
      background-color: unset;
    }
  }
}
