@use 'sass:map';

.contract-provider {
  @extend %btn;
  @extend %btn-secondary-theme;

  display: block;
  position: relative;
  width: 100%;
  padding: var(--contract-provider-padding, #{map.get($spacers, 4)});
  transition: .3s ease-in-out;
  transition-property: background-color, opacity;
  border-radius: var(--contract-provider-border-radius, #{$border-radius-lg});

  &--disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none;
  }

  &:not(&--connected, &--open),
  &--selectable:not(&--selected) {
    cursor: pointer;
  }

  &__mark {
    $size: 48px;

    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    overflow: hidden;
    border-top-right-radius: $border-radius-lg;
    color: $white;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: $size;
      height: $size;
      transform: rotate(45deg) scaleX(1.5) translateY(-50%);
      background-color: $primary;
      box-shadow: 0 12px 8px -9px rgba($black, .4);
    }
  }

  &__mark-icon {
    position: relative;
    transform: translate(35%, -35%);
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: flex;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: var(--contract-provider-logo-size, 40px);
    height: var(--contract-provider-logo-size, 40px);
    margin-right: map.get($spacers, 4);

    // @include media-breakpoint-up(sm) {
    //   width: var(--contract-provider-logo-size, 40px);
    //   height: var(--contract-provider-logo-size, 40px);
    //   margin-right: map.get($spacers, 4);
    // }
  }

  &__logo-system {
    &,
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__network-image {
    display: flex;
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 28px;
    height: 28px;
    border: 2px solid $var-body-bg;
    border-radius: 50%;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__name {
    color: $body-color;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(sm) {
      font-weight: $font-weight-semibold;
    }
  }

  &__beta {
    @extend %extra-small;

    color: $var-surface-50;
  }

  &__doc {
    @extend %small;

    color: $body-color;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      color: $var-primary-theme;
    }
  }

  &__info,
  &__meta {
    display: flex;
    flex-direction: column;
  }

  &__meta {
    align-items: flex-end;
    margin-left: auto;
    color: $var-surface-50;
    line-height: 1.4;

    & + & {
      margin-left: map.get($spacers, 3);
    }
  }
}
