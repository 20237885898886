.catalog-bag-indicator {
  position: relative;

  &--shake {
    animation: shakeBag .4s ease-in-out;
  }

  @keyframes shakeBag {
    25% {
      transform: translate3d(6px, 0, 0)
    }
    50% {
      transform: translate3d(-4px, 0, 0);
    }
    75% {
      transform: translate3d(2px, 0, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  &__counter {
    position: absolute;
    font-weight: 400;
    font-size: .625rem;
    line-height: 1rem;
    top: -.3rem;
    right: -.3rem;
    padding: 0 .25rem;
    color: $white;
    background: $danger;
    border-radius: 10rem;

    min-width: 1rem;
    text-align: center;
  }
}
