@use "sass:math";
@import "index";
@import "module-variables/board-nft-vars";

.board-nft-modal {
  $image-circle-size: 64px;
  $image-circle-size-xl: 128px;

  $modal-header-padding: $modal-inner-padding-y 0 0;

  --#{$prefix}modal-header-padding: #{$modal-header-padding} !important;
  --#{$prefix}modal-padding: #{$modal-inner-padding-y} !important;

  &, .modal-dialog {
    max-width: 600px;
  }

  &--large {
    &, .modal-dialog {
      max-width: 750px;
    }
  }

  &--small {
    &, .modal-dialog {
      max-width: 435px;
    }
  }

  @include media-breakpoint-up(sl) {
    $modal-inner-padding: 3rem;
    $modal-header-padding: $modal-inner-padding 0 0;

    --#{$prefix}modal-padding: #{$modal-inner-padding} !important;
    --#{$prefix}modal-header-padding: #{$modal-header-padding} !important;
    --#{$prefix}modal-border-radius: 2rem !important;

    .modal-controller {
      &.modal-has-footer > .modal-body {
        margin-bottom: -4rem;
      }
    }
  }

  &__image {
    overflow: hidden;
    position: relative;
    height: 30vh;
    min-height: 100px;
    max-height: 300px;
    margin-bottom: calc(-1 * var(--#{$prefix}modal-padding));

    @include border-top-radius(var(--#{$prefix}modal-border-radius));

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 32%;
      background: linear-gradient(to bottom, rgba($white, 0) 0%, var(--#{$prefix}modal-bg) 100%);
    }
  }

  &--image-circle {
    margin-top: math.div($image-circle-size, 2) !important;

    @include media-breakpoint-up(xl) {
      margin-top: math.div($image-circle-size-xl, 2) !important;
    }
  }

  &__image-circle {
    overflow: hidden;
    width: $image-circle-size;
    height: $image-circle-size;
    margin: -#{math.div($image-circle-size, 2)} auto calc(-1 * var(--#{$prefix}modal-padding));
    border-radius: 50%;
    border: 6px solid $white;

    @include media-breakpoint-up(xl) {
      width: $image-circle-size-xl;
      height: $image-circle-size-xl;
      margin-top: -#{math.div($image-circle-size-xl, 2)};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--action-panel {
    @extend %board-nft-card--action-panel;
    padding-bottom: 0;
  }

  &--action-panel .modal-controller {
    padding-bottom: map-get($spacers, 3);

    @include media-breakpoint-up(sl) {
      padding-bottom: 0;
    }
  }

  &__action-panel {
    @extend %board-nft-card__action-panel;
  }

  // TODO Выделить в отдельный UI
  &__action {
    @extend %board-nft-card__action;
  }

  &__btn-back {
    $bg-color: $gray-200;
    $bg-hover-color: darken($bg-color, 5%);
    $color: $primary;

    @include button-variant($bg-color, $bg-color, $color, $bg-hover-color, $bg-hover-color, $color);
  }

  .modal-header {
    font-family: $font-family-headline;
    border-bottom: none;
  }
}
