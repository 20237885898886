@use "sass:map";

.miner-wars-loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: $zindex-game-loading-screen;
  background-image:  url('../../../../assets/images/miner-wars/mw-bg-start.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &__text {
    padding-inline: 1rem;
    margin-bottom: 4.5rem;
  }

  &__logo {
    width: 100%;
    max-width: 1000px;
    padding: 1rem;
    margin-top: 50%;

    @include game-breakpoint-up(sm) {
      margin-block: auto;
    }

    @media (orientation: landscape) {
      margin-top: 10%;
      max-width: 500px;
    }
  }
}

.progress-bar-game {
  @extend %text-shadow;

  margin-top: auto;
  display: flex;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: map.get($spacers, 5);
  border-radius: map.get($spacers, 3);
  border: 2px solid black;
  background-color: $var-surface-10;

   @include game-breakpoint-up(sm) {
      margin-top: 0;
  }

  .progress-bar {
    @include border-gradient(linear-gradient(180deg, #33F2C7 0%, #00B661 100%), map.get($spacers, 1), true);

    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: width 0.5s ease-in-out;
    border-radius: map.get($spacers, 3);
    background-color: $success-game;
  }
}

@include game-style() {
  .skeleton {
    --skeleton-bg: rgba(255, 255, 255, .15);
  }
}
