.rating-number {
  $selector: &;

  position: relative;
  display: inline-block;
  color: $var-grey;
  transition: color $transition;

  &--active {
    color: $white;

    #{$selector}__icon {
      color: $primary;
    }
  }

  &__icon {
    color: $var-surface-10;
    transition: color $transition;
  }

  &__value {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    text-align: center;
    font-size: $extra-small-font-size;
    font-weight: $font-weight-bold;
    transform: translateY(-50%);

    &--1,
    &--2 {
      font-size: $font-size-lg;
    }

    &--3 {
      font-size: $font-size-sm;
    }
  }
}
