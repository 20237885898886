@function get-translate3d($dir, $value) {
  @if $dir == 'left' {
    @return translate3d(#{$value}, 0, 0);
  }

  @if $dir == 'right' {
    @return translate3d(#{$value}, 0, 0);
  }

  @if $dir == 'top' {
    @return translate3d(0, #{$value}, 0);
  }

  @if $dir == 'bottom' {
    @return translate3d(0, #{$value}, 0);
  }
}

@mixin build-animation($dir, $value: 20%) {
  @keyframes #{'glow-move-' + $dir} {
    0% {
      transform: get-translate3d($dir, $value);
      opacity: 0;
    }

    50% {
      transform: get-translate3d($dir, 0%);
      opacity: 1;
    }

    100% {
      transform: get-translate3d($dir, -$value);
      opacity: 0;
    }
  }
}

@mixin apply-animation($dir) {
  animation: 3s linear 0s infinite alternate #{'glow-move-' + $dir};
}

.premium-wrapper {
  $border-width: 0.25rem;
  $offset: $border-width * 0.5;
  $border-image-source: linear-gradient(334.07deg, #FBD405 0%, rgba(251, 167, 5, 0%) 34.88%),
    linear-gradient(126.11deg, #FBD405 0%, rgba(251, 167, 5, 0%) 70.53%),
    linear-gradient(0deg, #3F301A, #3F301A);

  @include border-gradient($border-image-source, $border-width, $border-width);

  position: relative;
  padding: $border-width;

  &__glow {
    position: absolute;
    width: 40%;
    height: auto;

    svg {
      @include build-animation('left');
      @include build-animation('top');
      @include build-animation('right', 15%);
    }

    &--top {
      &.animate svg {
        @include apply-animation('left');
      }

      top: $offset;
      left: 30%;
      transform: translate(-50%, -50%);

      @include rtl {
        right: 30%;
        transform: translate(50%, -50%);
      }
    }

    &--left {
      &.animate svg {
        @include apply-animation('top');
      }

      top: 30%;
      left: $offset;
      transform: translate(-50%, -50%) rotate(90deg);

      @include rtl {
        right: $offset;
        transform: translate(50%, -50%) rotate(90deg);
      }
    }

    &--bottom {
      &.animate svg {
        @include apply-animation('right');
      }

      right: 25%;
      bottom: $offset;
      width: 44.2%;
      transform: translate(50%, 50%);

      @include rtl {
        left: 25%;
        transform: translate(-50%, 50%);
      }
    }
  }
}
