@use 'sass:map';

.vgmt-widget {
  &__balance {
    padding: map.get($spacers, 3);
    border-radius: $dropdown-inner-border-radius;
    background-color: $var-surface-accent;
  }

  &__dropdown {
    width: 312px;
    margin-top: map.get($spacers, 1) * 0.5;
  }

  &__amount {
    position: relative;
    margin-top: map.get($spacers, 1);
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;

    &--converted {
      margin-top: map.get($spacers, 1) * 0.5;
      color: $var-grey;
      font-size: $font-size-sm;
      font-weight: $font-weight-normal;
    }
  }

  &__amount-skeleton {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    max-width: 200px;
    height: 24px;
    transform: translateY(-50%);

    &--converted {
      max-width: 150px;
      height: 18px;
    }
  }

  &__buttons {
    $padding-y: map.get($spacers, 2) * 1.5;
    $padding-x: map.get($spacers, 3);

    padding: $padding-y $padding-x;
  }

  &__btn {
    $padding-x: map.get($spacers, 2) + map.get($spacers, 1);

    padding-right: $padding-x;
    padding-left: $padding-x;
  }

  &__arrow-btn {
    color: $var-grey;

    &:hover {
      color: $var-surface;
    }
  }
}
