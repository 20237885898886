$button-tag-height: 1.25em;

.button-tag {
  padding-left: 0.375em;
  padding-right: 0.375em;
  z-index: 1;
  height: $button-tag-height;
  margin-top: calc($button-tag-height / 2 * -1);
  border-radius: $border-radius-game;
  border: 0.125em solid $black;
  box-shadow: $volume-box-shadow;
  display: flex;
  align-items: center;
  justify-content: center;

  &--primary {
    background-color: $primary-game;
  }

  &--danger {
    background-color: $danger-game;
  }

  &__content {
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    font-size: em($font-size-sm);
  }
}
