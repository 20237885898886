// Colors map (dark - light)
// ASIDE_BG: #181928 - #f7f8fa
// ASIDE_BG-200: #fff/5% - #f7f8fa
// ASIDE_BG-600: #242533 - #f7f8fa
// BODY_BG: #0e0f1f - #f1f1f9
// SURFACE: #fff - #0e0f1f
// SUBFACE: #242533 - #fff
// SUBFACE-200: #fff/10% - #fff

/* stylelint-disable */
@use "sass:math";
@use "sass:map";

// ----- BOOTSTRAP
$enable-validation-icons: false;
$enable-negative-margins: true;
$enable-smooth-scroll:    false;
$enable-caret:            false;
$enable-shadows:          false;

$transition: .15s ease-in-out;

// Z-INDEX
$zindex-modal:        1055;
$zindex-bottom-sheet: $zindex-modal;
$zindex-modal-priority-overlay: 2001; // more than auth-preloader

// COLORS
$white:             #fff;
$black:             #000;
$blue:              #2e95fe;
$purple:            #7540ef;
$violet:            #ab5bfa;
$red:               #ff2b5e;
$yellow:            #fbd405;
$orange:            #fba705;
$green:             #1ccc8d;
$grey:              #a0a0b5;

$dark:              #0e0f1f;
$light:             #f1f1f9;

$gray-800:          #181928;
$gray-600:          #242533;
$gray-400:          #9f9f9f;
$gray-200:          #f7f8fa;

$surface-dark:      $white;
$surface-light:     $dark;

$var-surface:       var(--surface);
$var-surface-1:     rgba(var(--surface-rgb), .01);
$var-surface-2:     rgba(var(--surface-rgb), .02);
$var-surface-3:     rgba(var(--surface-rgb), .03);
$var-surface-5:     rgba(var(--surface-rgb), .05);
$var-surface-8:     rgba(var(--surface-rgb), .08);
$var-surface-10:    rgba(var(--surface-rgb), .1);
$var-surface-15:    rgba(var(--surface-rgb), .15);
$var-surface-20:    rgba(var(--surface-rgb), .2);
$var-surface-50:    rgba(var(--surface-rgb), .5);
$var-surface-80:    rgba(var(--surface-rgb), .8);

$var-surface-accent: var(--surface-accent);

$subface-dark:      $gray-600;
$subface-light:     $white;
$var-subface:       var(--subface);

$subface-200-dark:  rgba($white, .1);;
$subface-200-light: $white;
$var-subface-200:       var(--subface-200);

$var-grey:          var(--grey);
$var-grey-rgb:      var(--grey-rgb);
$var-grey-contrast: var(--grey-contrast);

$primary:           $purple;
$success:           $green;
$warning:           $orange;
$danger:            $red;

$var-primary-theme:          var(--primary-theme);
$var-primary-theme-rgb:      var(--primary-theme-rgb);
$var-primary-theme-contrast: var(--primary-theme-contrast);

$binance-color:     #e8ba41;
$ton-color:         #0098EA;
$ethereum-color:    #1085ee;
$telegram-color:    #54a9eb;
$twitter-color:     #1d9bf0;
$discord-color:     #5865F1;

$grays: (
  "200": $gray-200,
  "400": $gray-400,
  "600": $gray-600,
  "800": $gray-800,
);

$colors: (
  "blue":       $blue,
  "red":        $red,
  "yellow":     $yellow,
  "green":      $green,
  "white":      $white,
  "gray":       $gray-400,
);

$theme-colors: (
  "primary":    $primary,
  "success":    $success,
  "warning":    $warning,
  "danger":     $danger,
);

$themes: (
  "primary-theme": $var-primary-theme,
);

$button-themes: (
  "primary-theme",
  "secondary-theme",
  "contrast-theme"
);

$button-themes-opacity: (
  "primary-theme",
);

$alert-themes: (
  "primary-theme",
  "danger-theme"
);

$gradients: (
  "blue": linear-gradient(135.39deg, #7540EF 4.81%, #A882FF 93.01%),
  "purple": linear-gradient(135deg, #7540EF 1.5%, #EB7DAF 72.78%, #F07D7D 91.78%),
  "solana": linear-gradient(
    220.27deg,
    #9945FF 8%,
    #8752F3 30%,
    #5497D5 50%,
    #43B4CA 60%,
    #28E0B9 72%,
    #19FB9B 97%
  ),
  "rainbow": linear-gradient(90deg, #1CCC8D 0%, #2E95FE 32%, #AB5BFA 68%, #D94DF2 100%),
  "rainbow-2": linear-gradient(90deg, #FFAA00 0%, #FD4DC6 31%, #995CFC 67%, #4670F8 100%),
);

$table-variants: ();

$aside-bg-dark:    $gray-800;
$aside-bg-light:   $gray-200;
$var-aside-bg:     var(--aside-bg);
$var-aside-bg-rgb: var(--aside-bg-rgb);
$var-aside-bg-hsl: var(--aside-bg-hsl);
$aside-bg:         $var-aside-bg;

$aside-bg-200-dark:  rgba($white, .05);
$aside-bg-200-light: $gray-200;
$var-aside-bg-200:   var(--aside-bg-200);

$aside-bg-600-dark:  $gray-600;
$aside-bg-600-light: $gray-200;
$var-aside-bg-600:   var(--aside-bg-600);

$body-bg-dark:      $dark;
$body-bg-light:     $light;
$body-bg-dark-twa:  $gray-600;
$body-bg-light-twa: $white;
$var-body-bg:       var(--body-bg);
$body-bg:           $var-body-bg;

$body-color:       $var-surface;

$text-muted:       $var-surface-50;

$link-color:            var(--link-color);
$link-decoration:       none;
$link-hover-color:      var(--link-hover-color);
$link-hover-decoration: null;
$link-transition:       color $transition;
$var-link-decoration:   var(--link-decoration);

$min-contrast-ratio:   2;

// RESPONSIVE
$container-max-width: 1920px;
$container-main-max-width: 1968px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  sl: 1200px,
  xl: 1440px,
);

$grid-gutter-width: 1rem;

// SPACERS
$spacer: 1rem;
$spacers: (
  0: 0,
  0_5: math.div($spacer, 8),
  1: math.div($spacer, 4),
  1_5: math.div($spacer, 4) * 1.5,
  2: math.div($spacer, 2),
  2_5: math.div($spacer, 2) * 1.5,
  3: $spacer,
  3_5: $spacer * 1.25,
  4: $spacer * 1.5,
  5: ($spacer * 2),
  6: ($spacer * 2.5),
  7: $spacer * 3
);

// TYPE
$hr-margin-y:                 1.5rem;
$hr-border-color:             $var-surface-10;
$font-family-sans-serif:      Inter, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-base:            $font-family-sans-serif;
$font-family-headline:        AtypDisplay, $font-family-base;

$font-size-base:              .875rem;
$font-size-xl:                1.5rem;
$font-size-sl:                1.25rem;
$font-size-lg:                1rem;
$font-size-sm:                .75rem;
$font-size-xs:                .625rem;
$font-size-xxs:               .5rem;

$line-height-base:            1.5;

$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-bolder:          900;

$headings-font-weight:        $font-weight-medium;
$headings-line-height:        1.2;
$headings-margin-bottom:      1rem;

$h1-font-size:                2.25rem;
$h2-font-size:                2rem;
$h3-font-size:                1.5rem;
$h4-font-size:                1.25rem;
$h5-font-size:                1rem;
$h6-font-size:                1rem;

$lead-font-size:              1.25rem;
$lead-font-size-sm:           1.125rem;
$lead-font-size-xs:           1rem;
$lead-font-weight:            $font-weight-medium;

$small-font-size:             $font-size-sm;
$extra-small-font-size:       $font-size-xs;

//$emphasized-link-hover-darken-percentage: 35%;

// BORDER
$border-width:                1px;
$border-color:                $var-surface-10;

$border-radius:               .75rem;
$border-radius-xl:            1.25rem;
$border-radius-lg:            1rem;
$border-radius-sm:            .5rem;
$border-radius-xs:            .25rem;

// SHADOWS
$box-shadow: var(--box-shadow);

// DROPDOWN
$dropdown-padding-y:               .25rem;
$dropdown-padding-x:               .25rem;
$dropdown-spacer:                  .25rem;
$dropdown-color:                   $body-color;
$dropdown-bg:                      $var-subface;
$dropdown-box-shadow:              $box-shadow;
$dropdown-item-padding-y:          .75rem;
$dropdown-item-padding-x:          1rem;
$dropdown-link-color:              $dropdown-color;
$dropdown-link-hover-color:        $dropdown-link-color;
$dropdown-link-hover-bg:           $var-aside-bg-200;
$dropdown-link-active-color:       $var-primary-theme;
$dropdown-link-active-bg:          transparent;
// $dropdown-link-active-bg:          rgba(var(--primary-theme-rgb), .04);
$dropdown-icon-color:              $var-grey;
$dropdown-icon-hover-color:        $dropdown-color;
$dropdown-icon-active-color:       $dropdown-link-active-color;
$dropdown-divider-margin-y:        $dropdown-padding-y;
$dropdown-border-width:            0;
$dropdown-border-color:            $var-surface-5;
$dropdown-transition:              background-color $transition, color $transition;
$dropdown-border-radius:           1rem;
$dropdown-inner-border-radius:     $dropdown-border-radius - $dropdown-padding-x;


// INPUT & BUTTON
$input-btn-border-width:     $border-width;
$input-btn-font-size:        $font-size-base;
$input-btn-font-size-lg:     $input-btn-font-size;
$input-btn-font-size-sm:     $input-btn-font-size;
$input-btn-font-size-xs:     $input-btn-font-size;
$input-btn-line-height:      $line-height-base;
$input-btn-padding-y:        .53125rem; // 8.5px
$input-btn-padding-y-lg:     .78125rem; // 12.5px
$input-btn-padding-y-sm:     .40625rem; // 6.5px
$input-btn-padding-y-xs:     .28125rem; // 6.5px

$input-btn-padding-x:        1rem;
$input-btn-padding-x-lg:     $input-btn-padding-x;
$input-btn-padding-x-sm:     $input-btn-padding-x;
$input-btn-padding-x-xs:     .5rem;

$input-btn-border-radius:    $border-radius;
$input-btn-border-radius-xs: $border-radius-sm;
$input-btn-border-radius-sm: $input-btn-border-radius;
$input-btn-border-radius-lg: $border-radius-lg;

$input-btn-disabled-opacity: .4;

// INPUT
$form-group-margin-bottom: 1.5rem;
$form-group-input-color:   $var-surface-50;
$form-group-input-bg:      $var-surface-accent;
$legend-margin-bottom:     .5rem;

$input-padding-x:                0;
$input-padding-x-sm:             $input-padding-x;
$input-padding-x-lg:             $input-padding-x;
$input-padding-rounded-x:        $input-btn-padding-x;
$input-padding-rounded-x-sm:     $input-btn-padding-x-sm;
$input-padding-rounded-x-lg:     $input-btn-padding-x-lg;

$input-padding-y-xs:             $input-btn-padding-y-xs;

$input-border-width:             $input-btn-border-width;
$input-border-radius:            0;
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;
$input-border-radius-rounded:    $input-btn-border-radius;
$input-border-radius-rounded-lg: $input-btn-border-radius-lg;
$input-border-radius-rounded-sm: $input-btn-border-radius-sm;

$input-line-height:        $input-btn-line-height;
$input-bg:                 transparent;
$input-color:              $var-surface;
$input-placeholder-color:  $var-surface-50;
$input-border-color:       $var-surface-10;
$input-hover-border-color: $input-border-color;
$input-focus-border-color: $var-primary-theme;
$input-caret-color:        $var-primary-theme;
$input-disabled-bg:        $input-bg;
$input-disabled-opacity:   $input-btn-disabled-opacity;
$input-transition:         color $transition, border-color $transition, opacity $transition;

$input-height-border:      $input-border-width * 2;
$input-height-xs:          add($input-line-height * 1em, add($input-padding-y-xs * 2, $input-height-border, false));

$input-check-color:               $var-primary-theme-contrast;
$input-check-bg-color:            transparent;
$input-check-bg-active-color:     $var-primary-theme;
$input-check-border-color:        $var-surface-10;
$input-check-border-hover-color:  $var-primary-theme;
$input-check-border-active-color: $var-primary-theme;

$input-label-color:               $var-surface-50;

$input-legend-color:              $body-color;
$input-legend-font-size:          $font-size-base;
$input-legend-font-size-lg:       $input-legend-font-size;
$input-legend-font-size-sm:       $small-font-size;
$input-legend-padding-x:          map.get($spacers, 1);
$input-legend-bg:                 var(--legend-bg-color);

// INPUT GROUP
$input-group-addon-color:        $var-grey;
$input-group-addon-bg:           $input-bg;
$input-group-addon-border-color: transparent;

// FORM CHECK
$form-check-size: 20px;

// FORM SWITCH
$form-check-switch-width: 50px;
$form-check-switch-height: 30px;
$form-switch-size-pin: 26px;
$form-switch-size-pin-offset: 2px;

// BUTTONS
$btn-border-radius:       $input-btn-border-radius;
$btn-border-radius-lg:    $input-btn-border-radius-lg;
$btn-border-radius-sm:    $input-btn-border-radius-sm;
$btn-border-radius-xs:    $input-btn-border-radius-xs;
$btn-font-weight:         $font-weight-medium;
$btn-font-size-xs:        $input-btn-font-size-xs;
$btn-transition:          opacity $transition, color $transition, background-color $transition, border-color $transition;
$btn-padding-x:           2rem;
$btn-padding-x-lg:        $btn-padding-x;
$btn-padding-x-sm:        $btn-padding-x;
$btn-padding-x-xs:        $input-btn-padding-x-xs;
$btn-padding-y-xs:        $input-btn-padding-y-xs;
$btn-disabled-opacity:    $input-btn-disabled-opacity;
$btn-link-disabled-color: $gray-400;

//$btn-active-box-shadow: none;
//$btn-focus-box-shadow:  none;
//$btn-focus-width:       0;

$btn-hover-bg-shade-amount:       20% !default;
$btn-hover-bg-mix-amount:         4% !default;
$btn-hover-bg-mix-amount-dark:    10% !default;
$btn-hover-bg-tint-amount:        15% !default;
$btn-hover-border-shade-amount:   20% !default;
$btn-hover-border-tint-amount:    10% !default;
$btn-active-bg-shade-amount:      20% !default;
$btn-active-bg-tint-amount:       20% !default;
$btn-active-border-shade-amount:  25% !default;
$btn-active-border-tint-amount:   10% !default;


//CARD
$card-bg:                               $var-subface;
$card-border-color:                     $border-color;
$card-border-radius:                    $border-radius-xl;
$card-border-width:                     1px;
$card-inner-border-radius:              $card-border-radius;
$card-spacer-x:                         map.get($spacers, 3);
$card-spacer-y:                         $card-spacer-x;
$card-spacer-y-md:                      map.get($spacers, 4);
$card-spacer-x-md:                      $card-spacer-y-md;
$card-spacer-inner-y:                   map.get($spacers, 4);
$card-box-shadow:                       none;
$card-cap-bg:                           transparent;
$card-cap-padding-y:                    $card-spacer-y;
$card-cap-padding-x:                    $card-spacer-x;
$card-cap-padding-y-md:                 $card-spacer-y-md;
$card-cap-padding-x-md:                 $card-spacer-x-md;
$card-title-color:                      $body-color;
$card-title-font-size:                  $font-size-lg;
$card-title-font-weight:                $font-weight-medium;
$card-title-spacer-y:                   0;
$card-body-color:                       $var-surface-80;
$card-main-width:                       585px;

// BADGE
$badge-padding-y:     .375rem;
$badge-padding-x:     .75rem;
$badge-font-size:     inherit;
$badge-font-weight:   inherit;
$badge-color:         $body-color;
$badge-border-radius: $border-radius;
$badge-transition:    border-color $transition, color $transition;

$badge-padding-y-sm:     .3125rem;
$badge-padding-x-sm:     .375rem;

// SPINNER
$spinner-border-width:    .25rem;
$spinner-border-width-sm: .2rem;

// PROGRESS
$progress-bg: $var-surface-10;
$progress-bar-bg: $var-primary-theme;
$progress-height: 100%;

// ALERT

// TABLES
$table-cell-padding-x:       map.get($spacers, 3);
$table-cell-padding-y:       map.get($spacers, 3);
$table-cell-padding-y-sm:    map.get($spacers, 2);
$table-cell-padding-x-sm:    map.get($spacers, 2);
$table-color:                $body-color;
$table-bg:                   transparent;
$table-hover-bg:             $var-surface-1;
$table-border-color:         $var-surface-5;
$table-border-width:         $border-width;
$table-head-bg:              transparent;
$table-head-color:           $var-grey;
$table-head-font-size:       $small-font-size;
$table-head-font-weight:     $font-weight-normal;
$table-head-padding-y:       map.get($spacers, 2);

// PAGINATION
$pagination-padding-y:                  map.get($spacers, 1);
$pagination-padding-x:                  map.get($spacers, 1);
$pagination-border-width:               0;
$pagination-color:                      $table-color;
$pagination-bg:                         transparent;
$pagination-active-color:               var(--primary-theme-pagination-color);
$pagination-active-bg:                  var(--primary-theme-pagination-background);
$pagination-hover-color:                $pagination-active-color;
$pagination-hover-bg:                   var(--primary-theme-pagination-hover-background);;
$pagination-focus-color:                $pagination-hover-color;
$pagination-focus-bg:                   $pagination-hover-bg;
$pagination-focus-box-shadow:           none;
$pagination-focus-box-shadow:           none;
$pagination-disabled-color:             $var-grey;
$pagination-disabled-bg:                $pagination-bg;
$pagination-border-radius:              $border-radius;
$pagination-border-radius-lg:           $pagination-border-radius;
$pagination-border-radius-sm:           $pagination-border-radius;
$pagination-transition:                 color $transition, background-color $transition;


// LIST GROUP

 // TOOLTIP
$tooltip-max-width:          100%;
$tooltip-arrow-width:        1.25rem;
$tooltip-arrow-height:       .5rem;
$tooltip-bg:                 $var-subface;
$tooltip-color:              $body-color;
$tooltip-border-radius:      $border-radius;
$tooltip-font-size:          $small-font-size;
$tooltip-padding-y:          .5rem;
$tooltip-padding-x:          .75rem;
$tooltip-opacity:            1;
$tooltip-box-shadow:         $box-shadow;

//MODAL
$modal-content-bg:                  $var-subface;
$modal-content-border-color:        transparent;
$modal-content-border-radius:       $border-radius-lg;
$modal-inner-padding-y:             2rem;
$modal-inner-padding-x:             2rem; // Есть зависимости в TS, помечены, как TS:$modal-inner-padding-x
$modal-header-padding-top:          3rem;
$modal-header-padding-bottom:       1rem;
$modal-header-padding:              $modal-header-padding-top 0 $modal-header-padding-bottom;
$modal-inner-padding:               $modal-inner-padding-y $modal-inner-padding-x;
$modal-footer-border-width:         0;
$modal-footer-margin-between:       0;

$modal-md:                          600px;

// CAROUSEL
$carousel-indicator-hit-area-height:      7px;

// LIST-GROUP
$list-group-color:        inherit;
$list-group-bg:           transparent;
$list-group-border-color: $var-surface-5;

// ALERT
$alert-bg:             $body-bg;
$alert-border-color:   transparent;
$alert-border-width:   0;
$alert-border-radius:  $card-border-radius;
$alert-padding-y:      map.get($spacers, 3);
$alert-padding-x:      $card-spacer-y;
$alert-padding-y-md:   $alert-padding-y;
$alert-padding-x-md:   $card-spacer-x-md;
$alert-bg-scale:       0%;
$alert-border-scale:   0%;
$alert-color-scale:    0%;

$alert-card-padding-y: $alert-padding-y;
$alert-card-padding-x: $alert-padding-y;

// CARET
$caret-width: .6rem;

// ----- MODULES

// MAIN
$main-offset:    16px;
$main-offset-md: 24px;

// PAGE-TOP
$page-top-height:          calc(var(--page-top-height) + var(--safe-area-inset-top));
$page-top-bg:              var(--page-top-bg);
$page-top-border-color:    var(--page-top-border-color);
$page-top-avatar-color:    var(--page-top-avatar-color);
$page-top-avatar-bg-color: var(--page-top-avatar-bg-color);

// TAB BAR
$tab-bar-height:           calc(var(--tab-bar-height) + var(--safe-area-inset-bottom));
$tab-bar-bg:               $aside-bg;

// NAV TABS
$app-nav-tabs-item-offset:            map.get($spacers, 1) * 0.5;
$app-nav-tabs-item-padding-y:         map.get($spacers, 1);
$app-nav-tabs-item-padding-x:         map.get($spacers, 2) + map.get($spacers, 1);
$app-nav-tabs-item-padding-x-extreme: $app-nav-tabs-item-offset + $app-nav-tabs-item-padding-x;

// SIDEBAR
$sidebar-width:                  calc(208px + var(--safe-area-inset-left));
$sidebar-bg:                     var(--sidebar-bg);
$sidebar-transition:             transform $transition;
$sidebar-item-border-radius:     $border-radius;
$sidebar-item-bg-color:          transparent;
$sidebar-item-bg-color-hover:    rgba(var(--body-bg-rgb), var(--sidebar-item-bg-color-hover-opacity));
$sidebar-item-bg-color-active:   $body-bg;
$sidebar-item-color:             $var-surface-80;
$sidebar-item-color-hover:       $var-surface;
$sidebar-item-color-active:      $sidebar-item-color-hover;
$sidebar-item-icon-color:        $var-grey;
$sidebar-item-icon-color-hover:  $sidebar-item-icon-color;
$sidebar-item-icon-color-active: $var-primary-theme;
$sidebar-item-icon-size:         24px;
$sidebar-item-icon-offset:       map.get($spacers, 2) + map.get($spacers, 1);
$sidebar-item-transition:        background-color $transition, color $transition, opacity $transition;
$sidebar-item-bottom-color:      $var-surface-50;

$sidebar-static-breakpoint:      md;

// ICON-BOX
$icon-box-size: 24px;
$icon-box-size-sm: 20px;
$icon-box-size-xs: 16px;
$icon-box-size-xxs: 12px;
$icon-box-size-xsl: 18px;
$icon-box-size-xl: 40px;
$icon-box-size-xxl: 48px;

// TOASTS
$toast-padding-y:               map.get($spacers, 2) + map.get($spacers, 1);
$toast-padding-x:               map.get($spacers, 4);
$toast-header-background-color: transparent;
$toast-header-color:            inherit;
$toast-color:                   $var-surface;
$toast-background-color:        $var-subface;
$toast-border-color:            transparent;
$toast-border-width:            0;
$toast-box-shadow:              $box-shadow;


// CARD-WALLET
$card-wallet-min-height:    194px;
// deprecated
$card-wallet-min-height-md: 220px;
$card-wallet-border-radius: $card-border-radius;

// INPUT-WALLET
$input-wallet-bg:            $aside-bg;
$input-wallet-border-radius: $border-radius-lg;
$input-wallet-padding-y:     map.get($spacers, 4);
$input-wallet-padding-x:     map.get($spacers, 4);
$input-wallet-icon-size-max:     40px;
$input-wallet-icon-size-min:     24px;

// CARD-BRIDGE
$card-bridge-padding:     $spacer;
$card-bridge-padding-lg:  map-get($spacers, 7);

// ASIDE VIEWER
$aside-viewer-width:      352px;
