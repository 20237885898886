.nft-mint {
  &__wallet-wrapper {
    position: relative;

    &--disabled {
      pointer-events: none;

      &::after {
        @include inset(0);

        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background-color: $var-body-bg;
      }
    }
  }
}