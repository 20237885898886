@mixin border-gradient($gradient, $border-width: $border-width, $inside: false) {
  position: relative;
  z-index: 1;
  background: transparent;
  background-clip: padding-box;

  @if $inside == false {
    border: $border-width solid transparent;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    padding: $border-width;
    border-radius: inherit;
    background: $gradient;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    @include inset(if($inside, 0, -$border-width));
  }
}
