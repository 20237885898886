.modal-token-bonus-deposit {
  .close {
    z-index: 5;
  }

  &__content {
    @include media-breakpoint-up(xl) {
      margin: 4rem 4rem 4rem 3.25rem;
    }
  }

  &__image {
    min-height: max(252px, 50vw);
    background-position: center;
    background-position-y: 40%;

    @include media-breakpoint-up(md) {
      min-height: 348px;
      background-position-y: center;
    }

    @include media-breakpoint-up(lg) {
      background-size: cover;
    }

    @include media-breakpoint-up(xl) {
      background-size: cover;
    }
  }
}