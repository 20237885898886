@use 'sass:map';

$active-tab-bar: '.app-tab-bar--active';

.notification-version {
  $offset: map.get($spacers, 3);
  $offset-left: calc(#{$offset} + var(--safe-area-inset-left));
  $offset-right: calc(#{$offset} + var(--safe-area-inset-right));
  $offset-bottom: calc(#{$offset} + var(--safe-area-inset-bottom));

  &-host {
    display: flex;
    position: fixed;
    z-index: $zindex-fixed + 1;
    bottom: $offset-bottom;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 #{$offset-right} 0 #{$offset-left};
    pointer-events: none;

    #{$active-tab-bar} & {
      bottom: $tab-bar-height;
      margin-bottom: $offset;
    }
  }

  display: flex;
  align-items: center;
  padding: map.get($spacers, 3) map.get($spacers, 4);
  border-radius: 200px;
  background-color: $var-subface;
  box-shadow: $box-shadow;
  pointer-events: all;
}
