@use "sass:map";
@use "sass:math";

.miner-wars-cycle-card {
  $cycle-card-border-radius: $border-radius-lg;
  $cycle-card-border-width: 2px;
  $cycle-card-border-color: rgba($dark, .5);
  $cycle-card-bg: rgba($dark, .5);

  background-color: $cycle-card-bg;
  border-radius: $cycle-card-border-radius;

  &__header {
    --progress-bar-width: 100%;

    @extend %text-shadow;

    overflow: hidden;
    position: relative;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    padding: map.get($spacers, 1) + math.div(map.get($spacers, 1), 2);
    background-color: rgba($success-game, .3);

    @include border-top-radius(inherit);

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: -1;

      @include inset(0);
    }

    &::before {
      width: var(--progress-bar-width, 100%);
      background-color: $success-game;
    }

    &::after {
      border: $cycle-card-border-width solid $cycle-card-border-color;
      border-bottom: none;
      border-radius: inherit;
    }
  }

  &__body {
    padding: map.get($spacers, 2) + map.get($spacers, 1);
    border: $cycle-card-border-width solid $cycle-card-border-color;
    border-top: none;

    @include border-bottom-radius(inherit);
  }

  .info-card {
    padding: map.get($spacers, 2) + map.get($spacers, 1);
    border-radius: map.get($spacers, 2) + map.get($spacers, 1);
  }
}

