.image-coin-empty {
  --color-1: #414142;
  --color-2: #4F4F4F;
  --color-3: #333333;

  @include light-theme-content {
    --color-1: #E5E5E5;
    --color-2: #F0F0F0;
    --color-3: #BCBCBC;
  }

  &__color-1 {
    fill: var(--color-1);
  }

  &__color-2 {
    fill: var(--color-2);
  }

  &__color-3 {
    fill: var(--color-3);
  }
}
