@use 'sass:map';

.wallet {
  --balance-viewer-font-size: #{$font-size-sl};
  --balance-viewer-line-height: 1.75rem;

  @include media-breakpoint-up(md) {
    padding-bottom: map.get($spacers, 3);
  }

  &__select {
    min-width: 100px;
    transition: all .15s ease-in-out;

    .twa & {
      border-color: $aside-bg;
      background-color: $aside-bg;
    }

    &.ng-select-disabled {
      opacity: .5 !important;
      pointer-events: none;
    }

    .ng-arrow-wrapper {
      background-color: currentcolor !important;
    }

    @include media-breakpoint-up(sm) {
      min-width: 160px;
    }
  }

  &__board {
    position: relative;
    flex-grow: 1;
  }

  &__board-wallets {
    display: block;
    transition: opacity .3s ease-in-out;

    &--loading {
      opacity: .4;
    }
  }

  &__option {
    --size: 40px;
    --icon-size: 24px;

    @include media-breakpoint-up(md) {
      --size: 48px;
      --icon-size: 28px;
    }

    .catalog-config__logo {
      width: var(--size);
      height: var(--size);
    }
  }

  &__option-icon-wrapper {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    color: $white;

    &--blue {
      border-radius: 50%;
      background-color: $blue;
    }

    &--green {
      border-radius: 50%;
      background-color: $green;
    }

    svg {
      width: var(--icon-size);
      height: var(--icon-size);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
