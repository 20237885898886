/**
 * This file is supposed to be imported in component styles
*/

@import './scss/functions';
@import './scss/variables';
@import './scss/mixins';
@import './scss/maps';
@import "bootstrap/scss/variables";
@import './scss/variables-null';
@import './modules/miner-wars/game-variables';
