@use "sass:math";

%investment-card__slider {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-lg;
  overflow: hidden;
  isolation: isolate;

  owl-stage {
    display: block;
  }

  .owl-carousel,
  .owl-stage-outer,
  owl-stage,
  owl-stage > div,
  .owl-stage,
  .owl-item {
    height: 100% !important;
  }

  .owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: map-get($spacers, 2) + map-get($spacers, 1);
  }

  .owl-dot {
    span {
      box-shadow: 0 0 3px 0 rgba($black, .5);
    }
  }

  .owl-nav {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: map-get($spacers, 2) + map-get($spacers, 1);
    transform: translateY(-50%);
    pointer-events: none;
  }

  .owl-prev,
  .owl-next {
    position: relative !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 40px !important;
    height: 40px !important;
    margin: 0 !important;
    color: $white !important;
    font-size: 0 !important;
    background: rgba($black, 0.5) !important;
    border-radius: $border-radius !important;
    backdrop-filter: blur(2px) !important;
    transition: opacity $transition;
    pointer-events: all;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      mask-repeat: no-repeat;
      mask-position: 50%;
      mask-image: url('../../../assets/images/icons/icon-arrow-short.svg');
      background-color: $white;
    }
  }

  .owl-prev {
    transform: scaleX(-1);
  }
}

.investment {
  overflow: hidden;
  position: relative;
  padding: 16px;
  background: $aside-bg;
  border-radius: $border-radius-lg;
  isolation: isolate;

  @include media-breakpoint-up(sm) {
    padding: 40px;
  }

  @include media-breakpoint-up(lg) {
    padding: 48px;
  }

  @include media-breakpoint-up(sl) {
    padding: 64px;
  }

  @include media-breakpoint-up(xl) {
    padding: 80px;
  }

  &__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 600px;
    pointer-events: none;

    // TODO: place rtl image
    @include rtl() {
      transform: scaleX(-1);
    }
  }

  &__container {
    width: 100%;
    max-width: map-get($container-max-widths, xl);
    margin: 0  auto;
  }

  &__title {
    @extend .h2;

    font-weight: $font-weight-bold;
    margin-bottom: map-get($spacers, 4);

    @include media-breakpoint-up(sm) {
      margin-bottom: map-get($spacers, 5);
    }

    @include media-breakpoint-up(md) {
      margin-bottom: map-get($spacers, 4) * 2;
    }
  }

  &__amount-group {
    font-size: 1.75rem;
    font-weight: $font-weight-semibold;
  }

  &__amount-input {
    font-size: inherit;
    font-weight: inherit;
  }

  &__btn-option {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
    background-clip: padding-box;

    @include button-variant(
      $var-surface-5,
      $var-surface-5,
      $body-color,
      $var-surface-10,
      $var-surface-10,
      $body-color,
      $var-surface-8,
      $var-surface-8,
      $body-color,
      $var-surface-5,
      $var-surface-5,
      $body-color,
    );

    &.selected {
      @extend %btn-primary-theme;

      pointer-events: none;
    }
  }

  &__preloader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    @include inset(0);
  }

  &__selected-amount {
    display: flex;
    align-items: center;
    padding: map-get($spacers, 2);
    border-radius: $border-radius-lg;
    background-color: $body-bg;
  }

  &__card {
    display: block;

    & + & {
      margin-top: map-get($spacers, 4);
    }
  }
}

.investment-steps {
  &__item {
    display: flex;
    align-items: flex-start;

    & + & {
      margin-top: map-get($spacers, 4);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    background-color: $body-bg;
    margin-right: map-get($spacers, 3);
    border-radius: $border-radius-lg;

    @include media-breakpoint-up(sm) {
      width: 56px;
      height: 56px;
      margin-right: map-get($spacers, 3) + map-get($spacers, 1);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
    margin-bottom: map-get($spacers, 1);
  }

  &__desc {
    color: $var-surface-80;
  }
}

.investment-card {
  display: flex;
  padding: map-get($spacers, 2);
  background-color: $body-bg;
  border-radius: $border-radius;

  @include media-breakpoint-up(md) {
    border-radius: 1.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: map-get($spacers, 2);

    @include media-breakpoint-up(sm) {
      padding: map-get($spacers, 3);
    }

    @include media-breakpoint-up(lg) {
      padding: map-get($spacers, 4);
    }

    @include media-breakpoint-up(sl) {
      padding: map-get($spacers, 4) + map-get($spacers, 3);
    }
  }

  &__main {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &__meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-shrink: 0;

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      justify-content: space-between;
      max-width: 220px;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -#{math.div(map-get($spacers, 1), 2)};
  }

  &__tag {
    margin: math.div(map-get($spacers, 1), 2);
    color: $var-grey;
    text-transform: uppercase;
    font-size: $extra-small-font-size;
    font-weight: $font-weight-semibold;
    padding: 6px 12px;
    background-color: $aside-bg;
    border-radius: $border-radius-sm;
  }

  &__price {
    color: $var-surface;
    font-size: 20px;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 48px;
      margin: auto;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    margin: map-get($spacers, 3) 0;
    background-color: $border-color;

    @include media-breakpoint-up(md) {
      margin: map-get($spacers, 4) 0;
    }

    @include media-breakpoint-up(lg) {
      width: 1px;
      height: auto;
      margin: 0 map-get($spacers, 5);
    }
  }

  &__info {
    flex-grow: 1;
    color: $var-surface-80;
  }

  &__title {
    @extend .h3;

    margin-top: map-get($spacers, 3);
    margin-bottom: map-get($spacers, 2);
    color: $var-surface;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  &__desc {
    margin-bottom: map-get($spacers, 4);
  }

  &__stat {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & + & {
      margin-top: map-get($spacers, 1);
    }
  }

  &__stat-name {
    display: flex;
    align-items: center;
    margin-right: map-get($spacers, 2);
    font-size: $small-font-size;
  }

  &__stat-value {
    text-align: right;
    color: $var-surface;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(md) {
      font-size: $font-size-lg;
    }
  }

  &__price-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $var-surface-80;
  }

  &__picture {
    position: relative;
    display: none;
    flex-shrink: 0;
    width: 335px;
    min-height: 326px;
    border-radius: $border-radius-lg;
    overflow: hidden;

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__slider {
    @extend %investment-card__slider;
  }
}
