// scss-docs-start caret-mixins
@mixin caret-down {
  &::after {
    transform: scale(1);
  }
}

@mixin caret-up {
  &::after {
    transform: scaleY(-1);
  }
}

@mixin caret-end {
  &::after {
    transform: rotate(-45deg);
  }
}

@mixin caret-start {
  &::after {
    transform: rotate(45deg);
  }
}

@mixin caret-base {
  flex-shrink: 0;
  width: $caret-width;
  height: $caret-width;
  background-color: currentColor;
  mask-repeat: no-repeat;
  mask-position: 50% 55%;
  mask-image: url('../../../../assets/images/icons/caret.svg');
  transition: transform $transition;
}

@mixin caret($direction: down, $force: false) {
  @if $enable-caret or $force {
    &::after {
      display: inline-block;
      margin-left: $caret-spacing;
      vertical-align: $caret-vertical-align;
      content: "";

      @include caret-base();

      @if $direction == down {
        @include caret-down();
      } @else if $direction == up {
        @include caret-up();
      } @else if $direction == end {
        @include caret-end();
      }
    }

    @if $direction == start {
      &::after {
        display: none;
      }

      &::before {
        display: inline-block;
        margin-right: $caret-spacing;
        vertical-align: $caret-vertical-align;
        content: "";

        @include caret-base();
        @include caret-start();
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}
