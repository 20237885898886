@use "sass:map";
@use "sass:math";

@mixin btn-game-active-style() {
  &:active {
    transform: scale(0.9);
  }
}

%btn-game {
  @include border-gradient(linear-gradient(180deg, $white 0%, $black 100%), map.get($spacers, 1), true);
  @include btn-game-active-style;
  @extend %text-shadow;

  $var-btn-bg: var(--#{$prefix}btn-bg);

  --#{$prefix}btn-border-width: 0;
  --#{$prefix}btn-padding-x: #{map.get($spacers, 5)};
  --#{$prefix}btn-padding-y: #{$spacer-75};
  --#{$prefix}btn-border-radius: #{$border-radius-lg};

  box-shadow: 0 0 0 2px rgb(0 0 0 / 50%);
  background-color: $var-btn-bg;
  text-transform: uppercase;

  &::before {
    mix-blend-mode: soft-light;
  }

  &:focus, &:disabled, &:active {
    background-color: $var-btn-bg;
  }
}

$game-button-colors: (
  'yellow': $warning-game,
  'green': $success-game,
  'purple': $primary-game,
  'red': $danger-game,
  'grey': $grey-game,
  'violet': $violet-game,
  'blue': $blue-game,
  'pink': $pink-game
);

.btn.btn-game {
  @extend %btn-game;

  @each $key, $value in $game-button-colors {
    &--#{$key} {
      --#{$prefix}btn-bg: #{$value}
    }
  }

  &--flat {
    border: 0;

    &::before {
      content: unset;
    }
  }

  .btn__text {
    padding-right: 2px;
    padding-left: 2px;
  }

  &--small, &.btn-square {
    --#{$prefix}btn-border-radius: #{$border-radius};
  }

  &--small {
    padding: map.get($spacers, 2) map.get($spacers, 3);
  }

  &--pillow {
    padding: map.get($spacers, 1);
    border-radius: calc(#{map.get($spacers, 4)} - 2px);
  }

  &--icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 50%;
  }

  &--image {
    --width: 16px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--width);
    height: var(--width);
    padding: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 calc(var(--width) / 16) rgb(0 0 0);

    &::before {
      z-index: 1;
      padding: calc(100% / 12);
    }
  }
}
