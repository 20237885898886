.input-wallet {
  border-radius: $input-wallet-border-radius;

  &--existing {
    background: $input-wallet-bg;
    padding: $input-wallet-padding-y $input-wallet-padding-x;
  }

  &--empty {
    border: 1px solid $var-surface-10;
    padding: calc($input-wallet-padding-y * 2) $input-wallet-padding-x;
  }

  &__balance {
    color: $var-primary-theme;
    text-decoration: underline;
    @include light-theme-content() {
      text-decoration: none;
      font-weight: $font-weight-semibold;
    }
  }


  &__icon {
    svg {
      width: 10vw;
      height: 10vw;
      min-height: $input-wallet-icon-size-min;
      min-width: $input-wallet-icon-size-min;
      max-width: $input-wallet-icon-size-max;
      max-height: $input-wallet-icon-size-max;
    }
  }

  &__info {
    flex-grow: 1;
    width: 120px;
  }

  .ng-arrow-wrapper {
    width: 24px;
    height: 24px;
    margin-right: 0;
    mask-image: url('../img/caret-large-down.svg');
  }

  &__dropdown {
    .ng-dropdown-panel {
      margin-top: $input-wallet-padding-y + map-get($spacers, 1) !important;

      @include media-breakpoint-up(sm) {
        left: -$input-wallet-padding-x !important;
        width: calc($card-main-width - $card-bridge-padding-lg * 2);
      }
    }
  }

  &__control {
    flex-grow: 100;
    width: 150px;
  }

  &__input-group {
    height: 40px;

    .form-control,
    .input-group-append,
    .input-group-prepend {
      background-color: $body-bg;
    }
  }

  &__divider {
    width: 3px;
    height: 3px;
    background-color: $grey;
    border-radius: 50%;
  }
}
