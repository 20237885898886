.nft-sell {
  &__icon {
    display: inline-block;
    padding: map-get($spacers, 2);
    border-radius: $border-radius-lg;
    background-color: rgba($var-primary-theme-rgb, .08);
    color: $var-primary-theme;
  }

  &__socials {
    display: inline-flex;
    align-items: center;
    margin: 0 -#{map-get($spacers, 2)};
  }

  &__socials-item {
    margin: 0 map-get($spacers, 2);

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &__board-overlay {
    position: absolute;
    z-index: 1;
    top: 12px;
    left: calc(50% - 5px);
    width: 278px;
    height: auto;
    transform: translateX(-50%);
  }
}
