.miner-wars-ability-events {
  display: flex;
  flex-direction: column;

  &__item {
    font-size: em($font-size-xs);
    margin-bottom: .375em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
