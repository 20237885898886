.checkout-halving {
  $padding: map-get($spacers, 2); // From .modal-presenter

  &__image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    overflow: hidden;
    border-radius: calc(var(--#{$prefix}modal-border-radius) - $padding);
    isolation: isolate;
  }

  &__image {
    height: 100%;
    object-fit: cover;
    transform: translateX(-7%);
  }
}
