.select-countries {
  &.form-control {
    height: $input-height;
  }

  &.form-control-sm {
    height: $input-height-sm;
  }

  &.form-control-lg {
    height: $input-height-lg;
  }

  &--empty {
    pointer-events: none;

    .ng-arrow-wrapper {
      opacity: 0;
    }
  }

  &--no-arrow {
    .ng-arrow-wrapper {
      display: none;
    }
  }

  .ng-dropdown-panel {
    right: 0;
  }

  &:not(&--full) {
    &.form-rounded {
      border-radius: 100px !important;
    }

    .ng-dropdown-panel {
      width: 240px;
    }

    .ng-select-container {
      padding: .375rem !important;
    }
  }

  .ng-dropdown-header {
    padding-right: 0;
    padding-left: 0;

    & + .ng-dropdown-panel-items .ng-option:first-child {
      @include border-top-radius(0);
    }
  }
}
