@import "index";
@import "module-variables/board-nft-vars";

.board-nft-rank {
  &__btn {
    height: 40px;
    background-color: $white;

    &, &:hover {
      color: $dark;
    }

    @include media-breakpoint-up(sm) {
      height: 44px;
    }
  }

  &__avatar {
    .avatar {
      width: 40px;
      height: 40px;

      @include media-breakpoint-up(sm) {
        width: 44px;
        height: 44px;
      }
    }
  }

  &__top-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background: linear-gradient(135.39deg, #{$nft-accent-color} 4.81%, #A882FF 45.69%, #CDCDD2 93.01%);
    border-radius: $border-radius-lg;

    @include media-breakpoint-up(sm) {
      width: 44px;
      height: 44px;
    }
  }

  &__top-btn-icon {
    color: $white;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
