@use 'sass:map';

.catalog-bag {
  @extend %aside-viewer;

  &__overlay {
    @extend %aside-viewer__overlay;
  }

  &__fixed {
    @extend %aside-viewer__fixed;
  }

  &__box {
    @extend %aside-viewer__box;
  }

  &__close {
    @extend %aside-viewer__close;
  }

  &__title {
    @extend %aside-viewer__title;
  }

  &__icon-completed {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: $border-radius;
    background-color: rgba($success, .16);
    color: $success;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
  }

  &__list {
    flex-grow: 0;
    min-height: 100px;
    overflow: auto;
  }

  &__content,
  &__list {
    margin-left: -$aside-viewer-padding;
    padding-left: $aside-viewer-padding;
  }

  &__item {
    display: flex;
    position: relative;
    align-items: center;
    padding-top: map.get($spacers, 3);
    padding-bottom: map.get($spacers, 3);
    border-bottom: $border-width solid $var-surface-10;

    &:first-child {
      border-top: $border-width solid $var-surface-10;
    }

    &--bag {
      border: none !important;
    }
  }

  &__item-btn-remove {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: $aside-viewer-bg;
    font-size: 0;
  }

  &__item-btn-remove-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    transition: color $transition, background-color $transition;
    border-radius: 50%;
    background-color: $var-aside-bg;
    color: $var-grey;

    .catalog-bag__item-btn-remove:hover & {
      background-color: rgba($red, .3);
      color: $red;

      @include light-theme-content {
        background-color: rgba($red, .16);
      }
    }
  }

  &__item-image {
    display: block;
    width: 4rem;
    height: 4rem;
    border-radius: $border-radius-sm;
    font-size: 0;
    object-fit: cover;
  }

  &__empty-title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
  }

  &__empty-desc {
    color: $var-surface-80;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  &__wallet-text,
  &__not-auth-text {
    color: $var-surface-80;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  &__discount {
    background: linear-gradient(135deg,#7540EF 1.5%,#eb7daf 72.78%,#f07d7d 91.78%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    /* stylelint-disable-next-line property-no-unknown */
    text-fill-color: transparent;
  }

  &__tab-item {
    position: relative;
    padding-bottom: .375rem;
    transition: $transition;
    transition-property: color, opacity;
    color: $gray-400;
    font-weight: $font-weight-medium;

    // font-size: .875rem;
    text-align: left;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      right: calc(var(--bs-gutter-x) * 0.5);
      left: 0;
      width: calc(100% - #{map.get($spacers, 1)});
      height: .25rem;
      transition: opacity $transition;
      border-radius: 1px;
      opacity: .08;
      background-color: $var-primary-theme;
    }

    &:disabled {
      opacity: .6;
      cursor: not-allowed;
    }

    &--completed {
      color: $var-primary-theme;

      &::after {
        opacity: 1;
      }
    }
  }

  &__tab-title {
    font-size: 1.25rem;
    font-weight: $font-weight-medium;
  }

  &__tip {
    padding: .5rem .75rem;
    border-radius: .75rem;
    background-color: rgba($var-primary-theme-rgb, .08);
    color: $var-primary-theme;
  }

  &__discount-tag {
    /* stylelint-disable-next-line selector-class-pattern */
    .nft-label--softred {
      @include light-theme-content(true) {
        padding-top: map.get($spacers, 1);
        padding-bottom: map.get($spacers, 1);
        background-color: $red;
      }
    }
  }

  &__btn-promocode {
    display: flex;
    align-items: center;
    width: 100%;
    transition: opacity $transition;

    &:disabled {
      opacity: .6;
    }
  }

  &__key {
    display: inline-flex;
    color: $orange;
  }

  &__label {
    display: inline-flex;
    align-items: center;
    padding: map.get($spacers, 1) map.get($spacers, 2);
    border-radius: $border-radius-sm;
    background-color: $var-surface-accent;
    font-size: $extra-small-font-size;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
  }
}

