.modal-contact {
  &__card {
    display: flex;
    align-items: center;
    padding: map-get($spacers, 4);
    color: $body-color;
    background: $var-surface-5;
    border-radius: $border-radius;
    transition: background $transition;

    @at-root a#{&} {
      &:hover {
        background: $var-surface-8;
      }

      &:hover,
      &:focus,
      &:active {
        color: $body-color
      }
    }
  }

  &__card-icon {
    display: inline-flex;
    margin-right: map-get($spacers, 2) + map-get($spacers, 1);

    svg {
      width: 40px;
      height: 40px;
    }
  }

  &__card-title {
    font-weight: $font-weight-medium;
  }
}
