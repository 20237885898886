.icon-chat {
  &__outer {
    fill: $white;

    @include light-theme-content {
      fill: $gray-800;
    }
  }

  &__inner {
    fill: $gray-800;

    @include light-theme-content {
      fill: $white;
    }
  }
}
