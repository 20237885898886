@use "sass:math";

.modal-locale-switcher {
  $grid-offset-y: math.div(map-get($spacers, 1), 2);

  &__row {
    margin-top: -$grid-offset-y;
    margin-bottom: -$grid-offset-y;
  }

  &__col {
    padding-top: $grid-offset-y;
    padding-bottom: $grid-offset-y;

    &--is-page {
      padding: 0;

      & + & {
        border-top: $border-width solid $var-surface-5;
      }
    }
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: map-get($spacers, 2) + map-get($spacers, 1) map-get($spacers, 3);
    border-radius: $border-radius-lg;
    font-weight: $font-weight-normal;
    transition: background-color $transition;

    &:hover,
    &:focus,
    &.active {
      background-color: $var-surface-accent;
    }

    &.active {
      font-weight: $font-weight-semibold;
    }

    &--is-page {
      padding-left: 0;
      padding-right: 0;

      &:hover,
      &:focus,
      &.active {
        background-color: transparent;
      }

      &.active {
        font-weight: $font-weight-normal;
      }
    }
  }

  &__country-icon {
    margin-right: map-get($spacers, 2) + map-get($spacers, 1);
  }
}
