.page-top-nav {
  overflow: auto;
  display: flex;
  font-weight: $font-weight-medium;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    color: $var-grey;
    transition: color $transition;

    & + & {
      margin-left: map-get($spacers, 3);

      @include media-breakpoint-up(lg) {
        margin-left: map-get($spacers, 4);
      }

      @include media-breakpoint-up(sl) {
        margin-left: map-get($spacers, 5);
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      width: 100%;
      height: 2px;
      background: $var-primary-theme;
      opacity: 0;
      transition: opacity $transition;
    }

    &:hover,
    &:focus,
    &.active{
      color: $body-color;
    }

    &.active {
      pointer-events: none;

      &::before {
        opacity: 1;
      }
    }
  }
}
