@use "sass:math";

.notification-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 16px rgba($gray-300, 0.15);

  &__panel {
    display: flex;
    align-items: flex-end;
    padding: math.div(map-get($spacers, 4), 2) map-get($spacers, 4);
    //box-shadow: 0px -4px 16px rgba($black, .04);;
  }

  &__input {
    position: relative;
    flex-grow: 1;
    max-height: 100px;
    height: auto;
    overflow: auto;
    border-color: $var-surface-10;
    padding: 0.375rem 1rem;
    font-size: .75rem;
    color: $gray-300;

    &:focus,
    &:hover {
      background-color: transparent !important;
      border-color: $primary !important;
    }

    &:focus-within {
      @extend %form-control___focus;


      background-color: transparent !important;
      border-color: $primary !important;
    }
  }

  &__input-controls {
    position: absolute;
  }

  &__native-input {
    //caret-color: $input-caret-color;
    word-break: break-all;
    white-space: pre-wrap;
  }
  &__btn-send {
    margin-left: map-get($spacers, 2);
  }
}
