@use "sass:math";

[dir] {
  $enableNgDeep: true !default;

  $handleColor: $surface-dark;

  $handleActiveColor: $handleColor; // use
  $handleHoverColor: $handleColor; // use
  $labelTextColor: $var-grey; // use
  $handleBgColor: $primary; // use
  $handleBorderColor: $handleColor; // use
  $handleInnerColor: $handleColor; // use
  $handleDisabledColor: $handleColor; // use
  $limitLabelTextColor: $labelTextColor; // use
  $barFillColor: $handleBgColor; // use
  $barDisabledFillColor: #8b91a2 !default;
  $barNormalColor: $var-surface-10; // use
  $barLeftOutSelectionColor: $danger; // use
  $barRightOutSelectionColor: $success; // use

  $ticksColor: $barNormalColor !default;
  $selectedTicksColor: $barFillColor !default;
  $ticksWidth: 10px !default;
  $ticksHeight: 10px !default;
  $ticksValuePosition: -34px !default;
  $ticksLegendPosition: 24px !default;
  $ticksValuePositionOnVertical: 24px !default;
  $tickLegendMaxWidth: 50px !default;

  $handleSize: 12px; // use
  $handlePointerSize: 4px;
  $bubblePaddingVertical: 0;
  $bubblePaddingHorizontal: 0;
  $labelFontSize: $extra-small-font-size;
  $barDimension: 8px; // use

  $withLegendMargin: 40px !default;

  $sliderMargin: 15px !default;
  $sliderMarginWithLabel: 30px;
  $sliderVerticalMargin: 20px !default;

  $animationDuration: 0.3s !default;

  .ngx-slider {
    display: inline-block;
    position: relative;
    height: $barDimension;
    width: 100%;
    margin: $sliderMarginWithLabel 0 $sliderMargin 0;
    vertical-align: middle;
    user-select: none;
    touch-action: pan-y;

    &.with-legend {
      margin-bottom: $withLegendMargin;
    }

    &[disabled] {
      cursor: not-allowed;

      .ngx-slider-pointer {
        cursor: not-allowed;
        background-color: $handleDisabledColor;
      }

      .ngx-slider-draggable {
        cursor: not-allowed;
      }

      .ngx-slider-selection {
        background: $barDisabledFillColor;
      }

      .ngx-slider-tick {
        cursor: not-allowed;

        &.ngx-slider-selected {
          background: $barDisabledFillColor;
        }
      }
    }

    .ngx-slider-span {
      white-space: nowrap;
      position: absolute;
      display: inline-block;
    }

    .ngx-slider-base {
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .ngx-slider-bar-wrapper {
      left: 0;
      box-sizing: border-box;
      margin-top: math.div(-$handleSize, 2);
      padding-top: math.div($handleSize, 2);
      width: 100%;
      height: $handleSize;
      z-index: 1;
    }

    .ngx-slider-draggable {
      cursor: move;
    }

    .ngx-slider-bar {
      left: 0;
      width: 100%;
      height: $barDimension;
      z-index: 1;
      background: $barNormalColor;
      border-radius: math.div($barDimension, 2);
    }

    .ngx-slider-bar-wrapper.ngx-slider-transparent .ngx-slider-bar {
      background: transparent;
    }

    .ngx-slider-bar-wrapper.ngx-slider-left-out-selection .ngx-slider-bar {
      background: $barLeftOutSelectionColor;
    }

    .ngx-slider-bar-wrapper.ngx-slider-right-out-selection .ngx-slider-bar {
      background: $barRightOutSelectionColor;
    }

    .ngx-slider-selection {
      z-index: 2;
      background: $barFillColor;
      border-radius: math.div($barDimension, 2);
    }

    .ngx-slider-pointer {
      cursor: pointer;
      width: $handleSize;
      height: $handleSize;
      top: math.div(-$handleSize, 2) + math.div($barDimension, 2);
      background-color: $handleBgColor;
      z-index: 3;
      box-shadow: 0 0 0 4px $handleBorderColor, 0 0 8px 4px rgba(#979a9f, 0.25);
      border-radius: math.div($handleSize, 2);

      &:after {
        display: none;
      }

      &.ngx-slider-active {
        z-index: 4;
      }
    }

    .ngx-slider-bubble {
      cursor: default;
      bottom: math.div($handleSize, 2);
      padding: $bubblePaddingVertical $bubblePaddingHorizontal;
      color: $labelTextColor;
      font-size: $labelFontSize;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;

      &.ngx-slider-limit {
        color: $limitLabelTextColor;
      }
    }

    .ngx-slider-ticks {
      box-sizing: border-box;
      width: 100%;
      height: 0;
      position: absolute;
      left: 0;
      top: math.div(-($ticksHeight - $barDimension), 2);
      margin: 0;
      z-index: 1;
      list-style: none;
    }

    .ngx-slider-ticks-values-under {
      .ngx-slider-tick-value {
        top: auto;
        bottom: $ticksValuePosition - 2;
      }
    }

    .ngx-slider-tick {
      text-align: center;
      cursor: pointer;
      width: $ticksWidth;
      height: $ticksHeight;
      background: $ticksColor;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: math.div($handleSize, 2) - math.div($ticksWidth, 2); // for centering

      &.ngx-slider-selected {
        background: $selectedTicksColor;
      }
    }

    .ngx-slider-tick-value {
      position: absolute;
      top: $ticksValuePosition;
      transform: translate(-50%, 0);
    }

    .ngx-slider-tick-legend {
      position: absolute;
      top: $ticksLegendPosition;
      transform: translate(-50%, 0);
      max-width: $tickLegendMaxWidth;
      white-space: normal;
    }

    &.vertical {
      position: relative;
      width: $barDimension;
      height: 100%;
      margin: 0 $sliderVerticalMargin;
      padding: 0;
      vertical-align: baseline;
      touch-action: pan-x;

      .ngx-slider-base {
        width: 100%;
        height: 100%;
        padding: 0;
      }

      .ngx-slider-bar-wrapper {
        top: auto;
        left: 0;
        margin: 0 0 0 (math.div(-$handleSize, 2));
        padding: 0 0 0 math.div($handleSize, 2);
        height: 100%;
        width: $handleSize;
      }

      .ngx-slider-bar {
        bottom: 0;
        left: unset;
        width: $barDimension;
        height: 100%;
      }

      .ngx-slider-pointer {
        left: math.div(-$handleSize, 2) + math.div($barDimension, 2) !important;
        top: auto;
        bottom: 0;
      }

      .ngx-slider-bubble {
        left: math.div($handleSize, 2) !important;
        bottom: 0;
      }

      .ngx-slider-ticks {
        height: 100%;
        width: 0;
        left: math.div(-($ticksHeight - $barDimension), 2);
        top: 0;
        z-index: 1;
      }

      .ngx-slider-tick {
        vertical-align: middle;
        margin-left: auto;
        margin-top: math.div($handleSize, 2) - math.div($ticksWidth, 2); // for centering
      }

      .ngx-slider-tick-value {
        left: $ticksValuePositionOnVertical;
        top: auto;
        transform: translate(0, -28%);
      }

      .ngx-slider-tick-legend {
        top: auto;
        right: $ticksLegendPosition;
        transform: translate(0, -28%);
        max-width: none;
        white-space: nowrap;
      }

      .ngx-slider-ticks-values-under {
        .ngx-slider-tick-value {
          bottom: auto;
          left: unset;
          right: $ticksValuePositionOnVertical;
        }
      }
    }

    &.labels-below {
      .ngx-slider-limit {
        top: $barDimension + 10px;
      }
    }

    &.compact-bubble-label {
      .ngx-slider-model-value {
        line-height: 1.1;
      }
    }

    * {
      transition: none;
    }

    &.animate {
      .ngx-slider-bar-wrapper {
        transition: all linear $animationDuration;
      }

      .ngx-slider-selection {
        transition: background-color linear $animationDuration;
      }

      .ngx-slider-pointer {
        transition: all linear $animationDuration;

        &:after {
          transition: all linear $animationDuration;
        }
      }

      .ngx-slider-bubble {
        transition: all linear $animationDuration;

        &.ngx-slider-limit {
          transition: opacity linear $animationDuration;
        }

        &.ngx-slider-combined {
          transition: opacity linear $animationDuration;
        }
      }

      .ngx-slider-tick {
        transition: background-color linear $animationDuration;
      }
    }
  }
}

.ngx-slider-inner-tooltip {
  height: 100%;
}
