.avatar {
  --avatar-img-border-radius: #{$border-radius-xs};
  --avatar-placeholder-border-radius: #{$border-radius-lg};

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  text-transform: uppercase;
  color: $gray-500;
  font-size: $lead-font-size;
  font-weight: $font-weight-bolder;
  //background-color: $gray-300;
  transition: opacity .15s ease-in-out;
  border-radius: var(--avatar-placeholder-border-radius);

  &--relative {
    width: 100%;
    height: 100%;
  }

  &--small {
    width: 24px;
    height: 24px;
  }

  &--large {
    width: 64px;
    height: 64px;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: var(--avatar-img-border-radius);
    image-rendering: initial;
    object-fit: cover;
  }

  &--online {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
      transform: translate3d(50%, 50%, 0);
      border-radius: 50%;
      border: 2px solid $white;
      background-color: $success;
    }
  }

  &--circle {
    border-radius: 50%;

    &::after {
      right: 5px;
      bottom: 5px;
    }
  }

  &--circle &__image {
    border-radius: 50%;
  }
}
