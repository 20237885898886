.main {
  // внимательно, сюда попадут значения safe-area из переменных $page-top-height и $tab-bar-height
  min-height: calc(100vh - #{$page-top-height} - #{$tab-bar-height});
  min-height: calc(100dvh - #{$page-top-height} - #{$tab-bar-height});
  min-height: min(calc(100vh - #{$page-top-height} - #{$tab-bar-height}), 1000px);
  min-height: min(calc(100dvh - #{$page-top-height} - #{$tab-bar-height}), 1000px);

  .page-top--active & {
    margin-top: $page-top-height;
  }

  &--inner {
    padding: main-inner-offset('top') main-inner-offset('right') main-inner-offset('bottom') main-inner-offset('left');

    &:not(.miner-wars) {
      @include media-breakpoint-up(md) {
        padding: main-inner-offset('top', true) main-inner-offset('right', true) main-inner-offset('bottom', true) main-inner-offset('left', true);
      }
    }

    &.miner-wars {
      padding: main-inner-offset('top', false, 1) main-inner-offset('right', false, 0.75) main-inner-offset('bottom', false, 1) main-inner-offset('left', false, 0.75);

      @include game-breakpoint-up(md, $game-breakpoints) {
        padding: main-inner-offset('top') main-inner-offset('right') main-inner-offset('bottom') main-inner-offset('left');
      }

      @include game-breakpoint-up(md) {
        padding: main-inner-offset('top', true) main-inner-offset('right', true) main-inner-offset('bottom', true) main-inner-offset('left', true);
      }
    }
  }

  &--miner-wars {
    overflow-y: auto;
    background: linear-gradient(0deg, rgb(0 0 0 / 70%) 0%, rgb(0 0 0 / 70%) 100%), radial-gradient(50% 50% at 50% 50%, $purple 0%, #432589 100%);
  }
}
