@use 'sass:map';

.mobile-table {
  --toggle-offset: var(--mobile-table-toggle-offset, #{map.get($spacers, 2)});
  --toggle-padding: var(--mobile-table-toggle-padding, #{map.get($spacers, 2)});
  --offset: var(--mobile-table-offset, #{map.get($spacers, 3)});
  --cell-offset: var(--mobile-table-cell-offset, #{map.get($spacers, 2)});
  --bottom-offset: var(--mobile-table-bottom-offset, #{map.get($spacers, 3)});

  &__item {
    display: block;
    color: $var-surface;
    
    &:hover {
      background-color: $var-surface-1;
      color: $var-surface;
    }

    &:not(:first-child) {
      margin-top: var(--offset);
    }

    &:not(:last-child) {
      padding-bottom: var(--offset);
      border-bottom: $border-width solid $border-color;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__cell {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: var(--cell-offset);
  }

  &__label {
    color: $var-grey;
    font-size: $font-size-sm;
  }

  &__toggle-btn {
    $btn-color: $grey;
    $btn-active-color: $var-surface;
    $btn-bg: transparent;
    $btn-border: transparent;

    @include button-variant(
      $btn-bg,
      $btn-border,
      $btn-color,
      $btn-bg,
      $btn-border,
      $btn-active-color,
      $btn-bg,
      $btn-border,
      $btn-active-color,
      $btn-bg,
      $btn-border,
      $btn-color,
    );

    margin-left: var(--toggle-offset);
    padding: var(--toggle-padding);
  }

  &__bottom {
    margin-top: var(--bottom-offset);
  }
}