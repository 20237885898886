.select-nfts {
  &--alone {
    .ng-arrow-wrapper {
      display: none;
    }
  }

  .ng-value-container {
    flex-wrap: wrap !important;
    margin: -#{map-get($spacers, 1)};

    .ng-value,
    .ng-placeholder,
    .ng-input {
      margin: map-get($spacers, 1);
    }
  }

  &.ng-select.ng-select-multiple.form-control {
    .ng-arrow-wrapper {
      $size: 20px;

      align-self: flex-start;
      width: $size;
      height: $size;
      margin-right: 0;
      background-color: $var-primary-theme;
      mask-size: $size;
      mask-image: url('../../../app/resources/entities/icons/icon-plus-circle/icon-plus-circle.component.svg');
    }

    &.ng-select-opened {
      .ng-arrow-wrapper {
        transform: rotate(45deg);
      }
    }
  }

  &.ng-dropdown-panel {
    .ng-option {
      &.ng-option-selected {
        background-color: $dropdown-bg;
        color: $dropdown-link-color;

        &:hover {
          background-color: $dropdown-link-hover-bg;
          color: $dropdown-link-hover-color;
        }
      }

      &:active {
        background-color: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;
      }

      &.ng-option-disabled {
        opacity: 0.4;

        &:not(:first-child) {
          border-top: 1px solid $border-color;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $border-color;
        }

        & + .ng-option-disabled {
          border-top: none;
        }
      }
    }
  }
}
