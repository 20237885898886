.avatar-editor {
  display: flex;
  align-items: center;
  width: 100%;
  color: $var-grey;
  transition: opacity .15s ease-in-out;

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }

  &__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    background-color: $var-surface-5;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
    width: 80px;
    height: 80px;

    .avatar {
      position: absolute;
      z-index: 1;
      cursor: pointer;
      width: 100%;
      height: 100%;

      @include inset(0);
    }
  }

  &__progress {
    position: absolute;
    left: 10%;
    right: 10%;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);

    & + avatar .avatar {
      opacity: .5;
    }
  }

  &__message {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    padding: map_get($spacers, 2);
    margin-left: map_get($spacers, 2);
  }

  &__btn-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    color: $white;
    margin: 0;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity .15s ease-in-out;

    @include inset(0);

    svg {
      width: 40px;
      height: 40px;
    }
  }

  &__image:hover &__btn-delete {
    opacity: 1;
    pointer-events: auto;
  }

  &__overlay {
    background-color: $var-surface-10;
  }
}
