@use "sass:math";

.app-tab-bar {
  user-select: none;
  display: flex;
  position: fixed;
  z-index: $zindex-fixed + 10;
  right: 0;
  bottom: 0;
  left: 0;
  height: $tab-bar-height;
  padding-right: var(--safe-area-inset-right);
  padding-bottom: var(--safe-area-inset-bottom);
  padding-left: var(--safe-area-inset-left);
  border-top: $border-width solid $var-surface-10;
  background-color: $tab-bar-bg;

  &__item {
    $padding-y: map-get($spacers, 1) + math.div(map-get($spacers, 1), 2);
    $padding-x: map-get($spacers, 1);
    $color: $var-grey;
    $color-active: $var-primary-theme;

    display: flex;
    flex: 0 1 100%;
    flex-direction: column;
    align-items: center;
    padding: $padding-y $padding-x;
    transition: color $transition;
    color: $color;
    font-size: $extra-small-font-size;
    font-weight: $font-weight-medium;
    white-space: nowrap;

    &-icon {
      display: flex;
      margin-bottom: map-get($spacers, 1);

      .icon-box {
        position: relative;
      }
    }

    &:hover,
    &:focus {
      color: $color;
    }

    &.active {
      color: $color-active;
    }
  }
}

.app-tab-bar--active {
  --tab-bar-height: 56px;
}

main {
  .app-tab-bar--active & {
    margin-bottom: $tab-bar-height;
  }
}

.main--inner {
  .app-tab-bar--active & {
    padding-bottom: $main-offset;

    &:not(.miner-wars) {
      @include media-breakpoint-up(md) {
        padding-bottom: $main-offset-md;
      }
    }
  }
}
