.tooltip-game.tooltip {
  --bs-tooltip-arrow-height: 0.375rem;
  --bs-tooltip-arrow-width: 0.875rem;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-font-size: #{$font-size-sm};
  --bs-tooltip-border-radius: .5rem;
  --bs-tooltip-bg: #{$primary-game};

  .tooltip-inner {
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.50);
    font-family: $font-family-base-game;
    font-weight: 400;
  }
}

