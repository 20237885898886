.app-option {
  $padding-x: map-get($spacers, 3);
  $padding-y: map-get($spacers, 2) + map-get($spacers, 1);

  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $padding-y $padding-x;
  color: $body-color;
  background-color: $aside-bg;
  border-radius: $border-radius-lg;
  transition: opacity $transition;

  &:disabled,
  &.disabled {
    opacity: .6;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    border-radius: inherit;
    background-color: rgba(var(--surface-rgb), .03);
    opacity: 0;
    transition: opacity $transition;

    @include inset(0);
  }

  &:active {
    &::before {
      opacity: 1;
    }
  }
}
