@use 'sass:map';

@include game-style {
  .modal-token-bundles {
    &__option {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: $spacer-75 * 0.5;

      &:active {
        .btn {
          transform: scale(0.9);
        }
      }
    }

    &__option-name {
      margin-top: -#{map.get($spacers, 1) * 2.5};
    }

    &__option-btn {
      width: 100%;
      margin-top: 2px;
    }
  }
}
