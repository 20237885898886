.qr-display {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__block {
    display: inline-flex;
    padding: map-get($spacers, 4);
    border: $border-width solid $var-surface-10;
    border-radius: $border-radius-xl;
  }

  &__canvas {
    width: 128px !important;
    height: 128px !important;
    margin: 0 auto;
    cursor: none;
  }

  &__link {
    display: inline-block;
    margin-top: map-get($spacers, 2);
  }
}
