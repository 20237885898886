@use "sass:map";
@use "sass:math";

.nft-miners {
  &__block-filter {
    @extend .catalog-index__block-filter;

    &-item {
      @extend .catalog-index__block-filter-item;
    }
  }

  &__block-filter-presenter {
    @extend .catalog-index__block-filter-presenter;
  }

  &__filter {
    @extend .catalog-index__filter;

    &--fixed {
      @extend .catalog-index__filter--fixed;
    }

    &--visible {
      @extend .catalog-index__filter--visible;
    }
  }

  &__btn-filter {
    &:disabled {
      @extend .catalog-index__btn-filter, :disabled;
    }
  }

  &__filter-backdrop {
    @extend .catalog-index__filter-backdrop;

    &--visible {
      @extend .catalog-index__filter-backdrop--visible;
    }
  }

  &__filter-header {
    @extend .catalog-index__filter-header;
  }

  &__filter-box {
    @extend .catalog-index__filter-box;
  }

  &__filter-actions {
    @extend .catalog-index__actions;
  }

  &__dropdown-filter {
    @extend .catalog-index__dropdown-filter;

    &--large {
      @extend .catalog-index__dropdown-filter--large;
    }
  }

  &__label {
    margin: math.div(map.get($spacers, 1), 2);
  }
}

.nft-miners-filters {

  &__title {
    font-weight: $font-weight-medium;

    @include media-breakpoint-down(md) {
      text-transform: uppercase;
      color: $gray-400;
    }
  }
}
