.modal-settings-account {
  &__avatar {
    position: relative;
    width: 100%;
    height: 100%;
    color: rgba($grey, .4);
    background-color: $aside-bg;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 60%;
      transform: translate(-50%, -50%);
    }
  }

  &__avatar-image {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__avatar-control {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    color: $white;
    background-color: rgba($black, .4);
    transition: opacity $transition;

    @include inset(0);

    svg {
      height: 30%;
    }
  }

  &__avatar:hover &__avatar-control {
    opacity: 1;
  }
}
