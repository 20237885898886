/* stylelint-disable no-descending-specificity */
/* stylelint-disable scss/percent-placeholder-pattern */
@use "sass:map";
@use "sass:math";
@import "../../vendors/bootstrap/form-control";


%form-control___disabled {
  border-color: $input-disabled-border-color;
  opacity: $input-disabled-opacity;
  background-color: $input-disabled-bg;
  color: $input-disabled-color;
}

%form-control___focus {
  border-color: $input-focus-border-color;
  outline: 0;
  background-color: $input-focus-bg;
  color: $input-focus-color;
  caret-color: $input-caret-color;

  @if $enable-shadows {
    @include box-shadow($input-box-shadow, $input-focus-box-shadow);
  } @else {
    box-shadow: $input-focus-box-shadow;
  }
}

%form-control___placeholder {
  opacity: 1;
  color: $input-placeholder-color;
}

%form-control___form-rounded {
  padding-right: $input-padding-rounded-x;
  padding-left: $input-padding-rounded-x;
  border-radius: $input-border-radius-rounded;
}

%form-control-sm___form-rounded {
  padding-right: $input-padding-rounded-x-sm;
  padding-left: $input-padding-rounded-x-sm;
  border-radius: $input-border-radius-rounded-sm;
}

%form-control-lg___form-rounded {
  padding-right: $input-padding-rounded-x-lg;
  padding-left: $input-padding-rounded-x-lg;
  border-radius: $input-border-radius-rounded-lg;
}

.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.form-height {
  min-height: $input-height;
}

.form-height-sm {
  min-height: $input-height-sm;
}

.form-height-lg {
  min-height: $input-height-lg;
}

.form-label {
  margin-bottom: $form-label-margin-bottom;
  color: $input-label-color;
  font-size: $font-size-base;
}

.form-legend {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  margin: 0 $input-padding-rounded-x - $input-legend-padding-x;
  margin-bottom: -$input-legend-font-size * math.div($line-height-base, 2);
  color: $input-legend-color;
  font-size: $input-legend-font-size;
}

.form-legend-text,
.form-legend-plain {
  padding: 0 $input-legend-padding-x;
  background-color: $input-legend-bg;

  & + & {
    padding-left: 0;
  }
}

.form-legend-text {
  @include text-truncate;

  display: block;
}

.form-legend-sm {
  margin: 0 $input-padding-rounded-x-sm - $input-legend-padding-x;
  margin-bottom: -$input-legend-font-size-sm * math.div($line-height-base, 2);
  font-size: $input-legend-font-size-sm;
}

.form-legend-lg {
  margin: 0 $input-padding-rounded-x-lg - $input-legend-padding-x;
  margin-bottom: -$input-legend-font-size-lg * math.div($line-height-base, 2);
  font-size: $input-legend-font-size-lg;
}

.form-required {
  &::after {
    content: '*';
    color: $danger;
  }
}

hr.form-divider {
  border-width: $border-width;
  border-color: $var-surface;
  opacity: .1;

  &--light {
    opacity: .05;
  }
}

.form-control {
  min-height: $input-height;

  &:not(.form-rounded) {
    border-top: none;
    border-right: none;
    border-left: none;
  }

  &:focus {
    @extend %form-control___focus;
  }

  // &::selection {
  //   background-color: rgba($input-caret-color, .2);
  // }

  &:disabled,
  &[readonly] {
    @extend %form-control___disabled;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active  {
    transition: background-color 5000s ease-in-out 0s, $input-transition;
  }

  &.is-invalid {
    caret-color: $form-feedback-invalid-color;
  }

  &.form-rounded {
    @extend %form-control___form-rounded;

  }
}

.form-control-sm {
  min-height: $input-height-sm;

  &.form-rounded {
    @extend %form-control-sm___form-rounded;
  }
}

.form-control-lg {
  min-height: $input-height-lg;

  &.form-rounded {
    @extend %form-control-lg___form-rounded;
  }
}

.form-group-input {
  margin-bottom: map.get($spacers, 3);
  padding: map.get($spacers, 3) map.get($spacers, 4);
  border-radius: $border-radius-lg;
  background: $form-group-input-bg;
  color: $form-group-input-color;

  .currency,
  .ng-select.form-control.select-currencies .ng-select-container {
    padding: (map.get($spacers, 1) + math.div(map.get($spacers, 1), 2)) map.get($spacers, 2);
    border-radius: 10rem;
    background-color: $var-subface;
    color: $body-color;
    font-size: $font-size-base;
  }

  .ng-select.form-control.select-currencies {
    .ng-select-container,
    .ng-dropdown-panel {
      // TODO Костыль для релиза
      .currency {
        padding: initial;
        border-radius: initial;
        background-color: initial;
        color: inherit;
        font-size: inherit;
      }
    }
  }

  .ng-select.form-control.select-nfts {
    height: auto;
  }

  .form-control,
  .input-group-append,
  .input-group-prepend {
    border-width: 0 !important;
  }

  .input-group-text {
    font-size: inherit;
  }

  .form-control {
    height: 40px;
    min-height: 40px !important;
    font-size: $lead-font-size !important;

    &::placeholder {
      opacity: .2;
      color: $body-color;
    }

    &-sm {
      font-size: $font-size-base !important;
    }

    &-lg {
      font-size: $font-size-lg !important;
    }
  }

  .input-group-info {
    $line-height: 1rem;

    min-height: auto;
    margin-bottom: -$line-height;
    line-height: $line-height;
  }

  .ng-select.form-control {
    z-index: auto;
  }

  .ng-select.select-currencies {
    height: auto;
    min-height: auto;

    .ng-dropdown-panel {
      right: 0;
      width: auto;
      font-size: $font-size-base;
    }
  }

  .ng-select.select-wallets {
    font-size: $font-size-lg !important;
  }

  &--adaptive {
    @include media-breakpoint-down(md) {
      padding: map.get($spacers, 3) map.get($spacers, 3);
    }

    .form-control {
      @include media-breakpoint-down(md) {
        height: 36px;
        min-height: 36px !important;
        font-size: $font-size-lg !important;
      }
    }
  }

  // temporary
  &--twa {
    .twa & {
      @include media-breakpoint-down(md) {
        background: $var-aside-bg;
      }
    }
  }
}

.form-control-grow-wrap {
  display: grid;

  .form-control-grow-wrap-target,
  textarea {
    grid-area: 1 / 1 / 2 / 2;
    height: auto !important;
    resize: none;
  }

  .form-control-grow-wrap-target {
    visibility: hidden;
    overflow: hidden;
    white-space: pre-wrap;
  }
}
