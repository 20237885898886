@use "sass:map";
@import 'index';

$underline-tabs-gap: map.get($spacers, 3);
$underline-tabs-border-width: 2px;
$underline-tabs-border-color: rgba($grey, .2);
$underline-tabs-border-color-active: $primary;
$host: '.underline-tabs';

.underline-tabs {
  --tabs-gap: var(--underline-tabs-gap, #{$underline-tabs-gap});
  --tabs-border-width: var(--underline-tabs-border-width, #{$underline-tabs-border-width});
  --tabs-border-color: var(--underline-tabs-border-color, #{$underline-tabs-border-color});
  --tabs-border-color-active: var(--underline-tabs-border-color-active, #{$underline-tabs-border-color-active});

  position: relative;
  margin: 0 calc(var(--tabs-gap) * -1);
  padding: 0 var(--tabs-gap);
  overflow: auto;

  &__nav {
    display: flex;
    position: relative;
    align-items: center;
    min-width: 100%;
    border-bottom: var(--tabs-border-width) solid var(--tabs-border-color);

    &--loading {
      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: skeleton-progress 1s ease-in-out infinite;
        background-image: linear-gradient(90deg, rgba($dark, 0), rgba($dark, 0.3), rgba($dark, 0));
      }

      @keyframes skeleton-progress {
        from {
          transform: translate3d(-100%, 0, 0);
        }

        to {
          transform: translate3d(100%, 0, 0);
        }
      }
    }
  }

  &__item {
    position: relative;
    padding-bottom: calc(#{map.get($spacers, 3)} - var(--tabs-border-width));
    transition: color $transition;
    color: $grey;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    white-space: nowrap;
    scroll-margin: 0 var(--tabs-gap);

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: calc(var(--tabs-border-width) * -1);
      left: 0;
      height: var(--tabs-border-width);
      transition: opacity $transition;
      opacity: 0;
      background-color: var(--tabs-border-color-active);
    }

    & + & {
      margin-left: map.get($spacers, 3);
    }

    &:hover,
    &:focus {
      color: $body-color;
    }

    &.active {
      color: $body-color;

      &::after {
        opacity: 1;
      }
    }

    #{$host}__nav--loading & {
      opacity: 0;
      pointer-events: none;
    }
  }
}