@use "sass:math";

// TODO Рефакторинг по переменным
.nft-roi-calculator {
  &__row {
    &:not(:last-of-type) {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: math.div($grid-gutter-width, 2);
        right: math.div($grid-gutter-width, 2);
        height: $border-width;
        background-color: $border-color;
      }
    }
  }

  &__form {
    line-height: 1.2;
  }

  &__period {
    margin-bottom: -$small-font-size * $line-height-base;
    white-space: nowrap;
    text-align: right;
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;
    line-height: $line-height-base;
  }

  &__blocks {
    padding: map-get($spacers, 2) + map-get($spacers, 1) map-get($spacers, 3);
    background-color: $aside-bg;
    border-radius: $border-radius;

    @include media-breakpoint-up(sm) {
      padding: map-get($spacers, 3) map-get($spacers, 4);
      border-radius: $border-radius-lg;
    }

    &-separator {
      height: 1px;
      background-color: $var-surface-10;
    }
  }

  &__block {
    border-radius: $border-radius-lg;

    &--first {
    }

    &--second {
    }

    &--bold {
      .nft-roi-calculator__value {
        font-weight: $font-weight-semibold;
        font-size: $font-size-lg;

        @include media-breakpoint-up(sm) {
          font-weight: $font-weight-bold;
          font-size: $lead-font-size;
        }
      }
    }
  }

  &__label {
    max-width: 100%;
    min-height: 24px;
    color: $gray-400;
    font-size: $extra-small-font-size;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    overflow: visible;
    //@include text-truncate;

    @include media-breakpoint-up(xl) {
      margin-bottom: .5rem;
    }
  }

  &__item {
    @include media-breakpoint-up(xl) {
      //min-height: 4.5rem;
    }
  }

  &__value {
    font-weight: $font-weight-medium;
    font-size: $font-size-base;
    text-align: right;

    @include media-breakpoint-up(sm) {
      font-weight: $font-weight-semibold;
      font-size: $font-size-lg;
      text-align: left;
    }

    &--small {
      @include media-breakpoint-up(sm) {
        font-size: $font-size-base;
      }
    }
  }

  &__open-history-modal {
    color: $var-primary-theme;
    font-weight: $font-weight-medium;
  }
}

// TODO Вынести в UI
.maintenance-label {
  cursor: pointer;
  font-weight: $font-weight-medium;
  border: $border-width solid $var-surface-10;
  border-radius: $border-radius;
  text-transform: uppercase;
  user-select: none;

  &--active {
    border-color: $primary;
    background-color: mix($primary, $white, 9%);
    box-shadow: inset 0 0 0 $border-width $primary;
    //background-color: rgba($primary, .1);
  }

  &--active &__title {
    color: $surface-light;
  }

  &--active &__description {
    color: $primary;
  }

  &__title {
    font-weight: $font-weight-semibold;
  }

  &__description {
    color: $var-grey;
    font-size: $extra-small-font-size;
  }
}
