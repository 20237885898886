$modal-border-radius: 14px;

.modal-nft-achievement {
  position: relative;

  &--done {
    .modal-controller {
      // найти переменную
      border-radius: $modal-border-radius;
    }

    .modal-controller, .app-bottom-sheet__wrapper {
      background-color: $dark;
      overflow: hidden;
    }

    .close {
      background-color: rgba($white, 0.1);
    }
  }

  &__generator {
    position: relative;
    overflow: hidden;
    background-color: $dark;
  }

  &__title {
    font-family: $font-family-headline;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: $white;

    &--in-progress {
      color: $white;

      @include light-theme-content {
        color: $dark;
      }
    }
  }

  &__desc {
    color: rgba($white, .5);

    &--in-progress {
      color: rgba($white, .5);

      @include light-theme-content {
        color: rgba($dark, .5);
      }
    }
  }

  &__time {
      color: $grey;
  }

  &__round-number {
    font-family: $font-family-headline;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    position: absolute;
    // TODO: нужно отдельное изображение для рамок
    bottom: 0;
    color: $white;
    left: 0;
    right: 0;
    // TODO: нужно отдельное изображение для рамок
    width: 160px;
    margin-inline: auto;
    font-size: 2.5rem;
  }

  &__progress-bar {
    .progressbar {
      --progress-bg-color: #{rgba($grey, 0.2)};
      --progress-bar-height: 0.5rem;
    }

    &-subtitle {
      text-align: start;
      color: $grey;
      text-transform: uppercase;
    }
  }

  &__btn-share {
    @include button-variant(mix($white, $dark, 5%));
  }
}
