@import "bootstrap/scss/carousel";

.carousel {
  display: flex !important;
  flex-direction: column-reverse;
}

.carousel-indicators {
  //position: static;
  //margin-top: map-get($spacers, 1);
  margin-bottom: 0;
}
