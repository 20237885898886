.slider-banners {
  position: relative;

  &__btn-action {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &__carousel {
    .carousel-indicators {
      button[data-bs-target] {
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));
      }
    }
  }
}
