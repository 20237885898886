@import "../../vendors/bootstrap/badge";

.badge {
  transition: $badge-transition;
  border-radius: 10px;
  font-weight: $font-weight-medium;
}

.badge-sm {
  padding: $badge-padding-y-sm $badge-padding-x-sm;
  border-radius: 8px;
  font-size: $extra-small-font-size;
  font-weight: $font-weight-semibold;
}

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    border: none;
    background: rgba($value, .1);
    color: $value;
  }
}

@each $color, $value in $theme-colors {
  .badge-#{$color}-fill {
    border: none;
    background: $value;
    color: color-contrast($value);
  }
}

@each $color, $value in $themes {
  .badge-#{$color} {
    border: none;
    background: rgba(var(--#{$color}-rgb), .1);
    color: $value;
  }
}

.badge-grey {
  border: none;
  background-color: $var-surface-5;
  color: $grey;
}

.badge-action {
  padding: .375rem .5rem;
  background-color: rgba($grey, .1);
  color: $grey;
  font-weight: $font-weight-semibold;
  line-height: 1.5;

  &:hover, &.active {
    background-color: rgba($var-primary-theme-rgb, .1);
    color: $var-primary-theme;
  }

  &:disabled {
    color: rgba($grey, 0.5);
    cursor: not-allowed;

    &:hover {
      background-color: rgba($grey, .1);
      color: rgba($grey, 0.5);
    }
  }
}

.badge-blue {
  border: none;
  background-color: $blue;
  color: color-contrast($blue);
}

// TODO Создать все badge с режимом opacity
.badge-blue-opacity {
  border: none;
  background-color: rgba($blue, .1);
  color: $blue;
}

.badge-danger-fill {
  border: none;
  background-color: $danger;
  color: color-contrast($danger);
}

.badge-warning-fill {
  border: none;
  background-color: $warning;
  color: color-contrast($warning);
}

.badge-gradient {
  $gradient: linear-gradient(135deg, #7540EF 1.5%, #EB7DAF 72.78%, #F07D7D 91.78%);

  @include border-gradient($gradient);
  @include text-gradient($gradient);
}
