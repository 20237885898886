@use 'sass:map';
@use 'sass:math';

$locale-switcher-btn-host: '.locale-switcher__btn';
$theme-switcher-btn-host: '.theme-switcher';
$select-countries-btn-host: '.select-countries';

.page-bottom {
  --footer-padding: #{map.get($spacers, 7)} #{main-inner-offset('right')} #{calc(#{map.get($spacers, 5)} + var(--safe-area-inset-bottom))} #{main-inner-offset('left')};
  --footer-content-direction: column;
  --footer-nav-grow: 1;
  --footer-nav-group-basis: 50%;
  --footer-nav-group-grow: 0;
  --footer-nav-group-min-width: unset;
  --footer-nav-group-max-width: unset;
  --footer-gap-x: #{map.get($spacers, 3)};
  --footer-gap-y: #{map.get($spacers, 5)};
  --footer-nav-gap-y: #{map.get($spacers, 5)};
  --footer-nav-gap-x: #{map.get($spacers, 3)};
  --footer-legal-gap: #{map.get($spacers, 4)};
  --footer-brand-direction: column;
  --footer-bottom-flex-direction: column-reverse;
  --footer-widgets-width: 100%;
  --footer-widgets-flex-direction: row-reverse;
  --footer-legal-direction: column;
  --footer-legal-text-align: center;
  --footer-legal-links-direction: column;
  --footer-legal-links-gap-y: #{map.get($spacers, 2)};
  --footer-legal-links-gap-x: #{map.get($spacers, 4)};
  --#{$prefix}body-color: #{$white};

  @include element-base($gray-800);

  @include media-breakpoint-up(sm) {
    --footer-nav-group-basis: #{math.div(100%, 3)};
  }

  @include media-breakpoint-up(md) {
    --footer-nav-group-basis: 20%;
    --footer-padding: #{map.get($spacers, 7)} #{main-inner-offset('right', true)} #{calc(#{map.get($spacers, 5)} + var(--safe-area-inset-bottom))} #{main-inner-offset('left', true)};
    --footer-brand-direction: row;
    --footer-bottom-flex-direction: row;
    --footer-widgets-width: unset;
    --footer-widgets-flex-direction: row;
  }

  @include media-breakpoint-up(lg) {
    --footer-legal-links-direction: row;
    --footer-legal-text-align: start;
    --footer-legal-gap: #{map.get($spacers, 3)};
  }

  @include media-breakpoint-up(sl) {
    --footer-nav-group-basis: auto;
    --footer-nav-group-grow: 1;
  }

  @include media-breakpoint-up(xl) {
    --footer-content-direction: row;
    --footer-brand-direction: column;
    --footer-nav-group-min-width: 90px;
    --footer-nav-group-max-width: 110px;
    --footer-nav-grow: 0;
    --footer-gap-x: #{map.get($spacers, 5)};
    --footer-legal-direction: row;
  }

  @include light-theme-content {
    @include element-base($var-body-bg);
    @extend %dark-theme;
  }

  @include use-element-base-props;

  padding: var(--footer-padding);

  &__content {
    display: flex;
    flex-flow: var(--footer-content-direction) wrap;
    align-items: stretch;
    justify-content: space-between;
    margin: calc(var(--footer-gap-y) / -2) calc(var(--footer-gap-x) / -2);
  }

  &__content-block {
    padding: calc(var(--footer-gap-y) / 2) calc(var(--footer-gap-x) / 2);
  }

  &__nav {
    display: flex;
    flex-flow: row wrap;
    flex-grow: var(--footer-nav-grow);
    margin: calc(var(--footer-nav-gap-y) / -2) calc(var(--footer-nav-gap-x) / -2);
  }

  &__nav-group {
    flex: var(--footer-nav-group-grow) 0 var(--footer-nav-group-basis);
    padding: calc(var(--footer-nav-gap-y) / 2) calc(var(--footer-nav-gap-x) / 2);
  }

  &__nav-title {
    min-width: var(--footer-nav-group-min-width);
    max-width: var(--footer-nav-group-max-width);
    padding-bottom: map.get($spacers, 1) * 0.5;
    color: $var-grey;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__nav-item {
    margin-top: map.get($spacers, 1) * 1.5;
  }

  &__link {
    display: inline-block;
    max-width: var(--footer-nav-group-max-width);
    transition: opacity $transition;
    color: $var-surface;
    font-weight: $font-weight-medium;
    line-height: 1.25rem;

    &:hover, &:active {
      opacity: 0.8;
      color: $var-surface;
    }
  }

  &__brand {
    display: flex;
    flex-direction: var(--footer-brand-direction);
    justify-content: space-between;
    margin: calc(var(--footer-gap-y) / -2) 0;
  }

  &__brand-block {
    padding: calc(var(--footer-gap-y) / 2) 0;
  }

  &__stores {
    max-width: 288px;
  }

  &__store-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 #{map.get($spacers, 2) * 1.5};
    transition: border-color $transition;
    border: 1px solid $var-surface-10;
    border-radius: $border-radius;

    &:hover {
      border-color: $var-surface;
    }

    &--apple {
      img {
        height: 26px;
      }
    }

    &--google {
      img {
        height: 28px;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-flow: var(--footer-bottom-flex-direction) wrap-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__widgets {
    display: flex;
    flex-direction: var(--footer-widgets-flex-direction);
    align-items: center;
    width: var(--footer-widgets-width);
  }

  &__theme-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  &__legal {
    display: flex;
    flex-flow: var(--footer-legal-direction) wrap;
    align-items: center;
    justify-content: space-between;
    margin: calc(var(--footer-legal-gap) / -2);
    color: $var-grey;
    font-size: $font-size-sm;
  }

  &__legal-block {
    flex-grow: 0;
    padding: calc(var(--footer-legal-gap) / 2);
    text-align: var(--footer-legal-text-align);
  }

  &__legal-container {
    display: flex;
    flex-direction: var(--footer-legal-links-direction);
    align-items: center;
    margin: calc(var(--footer-legal-links-gap-y) / -2) calc(var(--footer-legal-links-gap-x) / -2);
  }

  &__legal-item {
    display: inline-block;
    padding: calc(var(--footer-legal-links-gap-y) / 2) calc(var(--footer-legal-links-gap-x) / 2);
    transition: color $transition;
    color: $var-grey;
    white-space: nowrap;

    &--link {
      &:hover, &:active {
        color: $var-surface;
      }

      @include media-breakpoint-up(lg) {
        border-left: 1px solid $var-surface-10;
      }
    }
  }

  &__divider {
    border-color: $white;
  }

  #{$locale-switcher-btn-host},
  #{$theme-switcher-btn-host} {
    @extend %btn-secondary-theme;

    color: var(--#{$prefix}btn-color);
  }


  #{$select-countries-btn-host} {
    .ng-dropdown-panel {
      right: unset;
      left: 0;
      min-width: 200px;

      @include media-breakpoint-up(md) {
        right: 0;
        left: unset;
      }
    }
  }
}
