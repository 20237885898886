@use 'sass:map';

.modal-nft-sales {

  &__row {
    $offset: map.get($spacers, 2) + map.get($spacers, 1);

    --#{$prefix}gutter-x: #{$offset};
    --#{$prefix}gutter-y: #{$offset};
  }

  &__item {
    padding: map.get($spacers, 3) map.get($spacers, 4);
    background: $var-surface-5;
    border-radius: $border-radius-lg;
  }
}
