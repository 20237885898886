.subscription-calculator {
  &__value-skeleton {
    top: 50%;
    right: 0;
    width: 100px;
    height: 1.25rem;
    transform: translate(0, -50%);
  }

  &__dollar-sign {
    width: 20px;
    height: 20px;
  }
}
