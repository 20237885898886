@each $color, $value in $gradients-game {
  :root {
    --game-gradient-#{$color}: #{$value};
  }
  .bg-game-#{$color}-gradient {
    background-image: var(--game-gradient-#{$color});
  }
}

.bg-game-blue {
  background: $blue-game;
}
