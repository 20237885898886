@use "sass:math";

@mixin form-check-colors(
  $border-color: $input-check-border-color,
  $border-color-active: $input-check-border-active-color,
  $border-color-hover: $input-check-border-hover-color,
  $bg-color-active: $input-check-bg-active-color,
) {
  --form-check-border-color: #{$border-color};
  --form-check-border-color-active: #{$border-color-active};
  --form-check-border-color-hover: #{$border-color-hover};
  --form-check-bg-color-active: #{$bg-color-active};
}

.form-check {
  $width: $form-check-size;
  $height: $form-check-size;
  $offset: rem($width) + map_get($spacers, 2) + map-get($spacers, 1);
  $line-height: 18px;
  $outerX: 2px;
  $outerY: $outerX;

  @include form-check-colors;

  display: inline-flex;
  position: relative;
  align-items: center;
  min-height: $height;
  padding-left: $offset;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;

  @include rtl {
    padding-left: 0;
  }

  &--disabled {
    cursor: default;
  }

  &--readonly {
    pointer-events: none;
  }

  &.empty {
    padding-left: $width;
  }

  &:hover &__input:not(:disabled) + &__box::before,
  &:focus &__input:not(:disabled) + &__box::before,
  &__input:not(:disabled):focus + &__box::before {
    border-color: var(--form-check-border-color-hover);
  }

  &__box {
    position: absolute;
    top: math.div($line-height - $height, 2);
    left: 0;
    width: $width;
    height: $height;
    transition: opacity .15s ease-in-out;
    color: var(--form-check-bg-color-active);

    &::before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      width: $width;
      height: $height;
      transition: .15s ease-in-out;
      transition-property: background-color, border-color;
      border: 1.5px solid var(--form-check-border-color);
      border-radius: 6px;
    }

    &--center {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__icon {
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: $transition;
    transition-property: opacity, color;
    opacity: 0;
    line-height: 0;
  }

  &__input {
    position: absolute;
    appearance: none;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  &__input[type="radio"] + &__box {
    &::before,
    &::after {
      border-radius: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      width: $width - 6px;
      height: $height - 6px;
      transition: opacity $transition;
      opacity: 0;
      background-color: var(--form-check-bg-color-active);

      @include dir-ignore {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__input:checked + &__box {
    &::before {
      border-color: var(--form-check-border-color-active);
    }
  }

  &__input[type="radio"]:checked + &__box {
    &::after {
      opacity: 1;
    }
  }

  &__input:checked + &__box &__icon {
    opacity: 1;
  }

  &__input:disabled ~ &__box,
  &__input:disabled ~ &__text {
    opacity: $input-disabled-opacity;
  }

  &__text {
    transition: opacity $transition;
    font-size: $font-size-sm;
    line-height: $line-height;

    &:empty {
      display: none;
    }
  }
}
