@use 'sass:map';
@use 'sass:math';

.notifications-list {
  display: flex;
  flex-direction: column;

  &:not(&--inModal) {
    padding: map.get($spacers, 4);

    @include media-breakpoint-up(md) {
      width: 420px;
    }
  }

  &__drop {
    margin-top: map.get($spacers, 1) * 0.5;
  }

  &__btn-close {
    @extend %close;
  }

  &__counter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 1.25rem;
    min-height: 1.25rem;
    margin-left: map.get($spacers, 1) * 1.5;
    padding: 0 map.get($spacers, 1);
    border-radius: 10rem;
    background-color: $red;
    color: $white;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
  }

  &__container {
    position: relative;
    max-height: min(calc(85vh - 5rem), 400px);
    margin: 0 #{- map.get($spacers, 3)};
    padding: 0 map.get($spacers, 3);
  }

  &__item {
    display: flex;
    align-items: flex-start;
    padding: map.get($spacers, 1) + map.get($spacers, 2) 0;
    border-bottom: 1px solid $var-surface-10;
    cursor: pointer;

    & + & {
      border-top: $border-width solid $border-color;
    }
  }

  &__item:hover &__icon {
    color: $body-color;
  }

  &__item-title {
    font-weight: $font-weight-semibold;

    &--new {
      position: relative;
      padding-left: map.get($spacers, 1) + map.get($spacers, 2);

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: map.get($spacers, 1) * 1.5;
        height: map.get($spacers, 1) * 1.5;
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: $red;
      }
    }
  }

  &__icon {
    padding: map.get($spacers, 1) * 1.5;
    transition: color $transition;
    border-radius: $btn-border-radius;
    background-color: $var-surface-5;
    color: $var-grey;
  }
}
