.notification {
  &__item {
    margin-bottom: .5rem;
    border-bottom: 1px solid $var-surface-5;
  }

  &__icon {
    width: 2.5rem;
  }

  &__heading,
  &__next {
    color: $body-color;
  }

  &__circle {
    width: 8px;
    height: 8px;
    left: 8px;
    top: 8px;

    border-radius: 100%;

    background: currentColor;
  }

  &__wrapper {
    max-width: 594px;
    margin-left: auto;
    margin-right: auto;
  }

  &__header {
    border-bottom: $border-width solid $border-color;
  }

  &__back-arrow {
    //transform: rotate(180deg);
  }

  &__icon-trash {
    color: $gray-300;

    &:hover {
      color: $danger;
    }
  }

  &__message {
    img {
      max-width: 100%;
    }
  }

  &__user-message {
    white-space: pre-wrap;
    word-break: break-all;
    background-color: $var-surface-10;
    border-radius: 1rem;
    padding: .5rem 1.5rem;
  }

  &__user-message-edit {

  }

}
