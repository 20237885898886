@use 'sass:map';

.catalog-config {
  display: block;
  position: relative;
  width: 100%;
  padding: #{map.get($spacers, 3) + map.get($spacers, 1)} map.get($spacers, 3);
  transition: .3s ease-in-out;
  border-radius: $border-radius-lg;
  text-align: left;
  cursor: pointer;

  &--addable {
    padding: 2rem map.get($spacers, 3);
  }

  &__mark {
    $size: 48px;

    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    overflow: hidden;
    border-top-right-radius: $border-radius-lg;
    color: $white;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: $size;
      height: $size;
      transform: rotate(45deg) scaleX(1.5) translateY(-50%);
      background-color: $primary;
      box-shadow: 0 12px 8px -9px rgba($black, .4);
    }
  }

  &__mark-icon {
    position: relative;
    transform: translate(35%, -35%);
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  &__logo {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: .75rem;

    @include media-breakpoint-up(sm) {
      width: 40px;
      height: 40px;
      margin-right: .75rem;
    }
  }

  &__logo-system {
    &,
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    color: $primary;
    object-fit: contain;

    &, svg {
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    color: $body-color;
    font-weight: $font-weight-medium;
  }

  &__desc {
    color: $gray-400;
    font-size: $font-size-sm;
  }

  &__tip {
    color: $var-surface-80;
  }

  &__fee {
    &--gradient {
      @include text-gradient(linear-gradient(197.56deg, #EA7D9C 1.04%, #FADF66 77.01%));

      @include light-theme-content {
        @include text-gradient(linear-gradient(135deg, #7540EF 1.5%, #EB7DAF 72.78%, #F07D7D 91.78%));
      }
    }
  }

  &__badge {
    display: inline-block;
    padding: map.get($spacers, 1) map.get($spacers, 2);
    border-radius: $border-radius-sm;
    font-size: $extra-small-font-size;

    &--success {
      background-color: rgba($success, .2);
    }
  }

  &__locked {
    display: none;
  }

  &__icon {
    width: 16px;

    //height: 16px;
  }
}
