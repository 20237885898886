.modal-nft-purchase-token-bonus {
  .modal-presenter__image {
    background-image: var(--image-url), radial-gradient(141.42% 141.42% at 100% 100%, #B698FF 7.29%, #552EC7 44.27%, #251955 73.44%, #0E0F1F 91.15%);
    background-size: cover;

    @include media-breakpoint-up(lg) {
      min-height: 350px;
      max-width: 290px;
    }
  }
}
