@import "../../vendors/bootstrap/tables";

$separator-height: 25px;

.table {
  vertical-align: middle;

  & > thead {
    vertical-align: inherit;

    th, td {
      font-size: $table-head-font-size;
      padding-top: $table-head-padding-y;
      padding-bottom: $table-head-padding-y;
    }
  }

  th {
    color: $table-head-color;
    font-weight: $table-head-font-weight;
    white-space: pre-wrap;
  }

  td {
    box-sizing: content-box;
    border-top: $table-border-width solid $table-border-color;
  }

  th, td {
    border-bottom: none;

    &:first-child {
      padding-left: 0 !important;
    }

    &:last-child {
      padding-right: 0 !important;
    }
  }

  &-responsive {
    @include media-breakpoint-down(sm) {
      overflow-x: hidden;

      &,
      tbody,
      tr {
        display: block;
      }

      thead {
        display: none !important;
      }

      td {
        display: flex;
        justify-content: space-between;
        width: 100% !important;
        max-width: none !important;
        min-width: auto !important;
      }

      td, th {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      tr {
        border-bottom: #{$separator-height * 2} solid transparent;
        position: relative;
        //&:after {
        //  content: '';
        //  position: absolute;
        //  height: $separator-height;
        //  //margin-left: - $card-spacer-x;
        //  //margin-right: - $card-spacer-x;
        //  left: - $card-spacer-x;
        //  right: - $card-spacer-x;
        //  bottom: -#{$separator-height * 2};
        //  background-color: var($body-background);
        //  width: calc( 100% + #{$card-spacer-x * 2});
        //}
      }
    }
  }
}

.table-hover > tbody > tr:hover {
  @include media-breakpoint-down(sm) {
    --bs-table-accent-bg: transparent;
  }
}
