@use 'sass:map';

.smart-banner {
  position: fixed;
  z-index: $zindex-fixed - 1;
  right: $main-offset;
  bottom: calc(#{map.get($spacers, 4)} + var(--safe-area-inset-bottom));
  left: $main-offset;
  pointer-events: none;
}

.smart-banner-installer {
  max-width: 360px;
  margin: 0 auto;
  padding: map.get($spacers, 2) + map.get($spacers, 1);
  border-radius: $border-radius-lg;
  background: $var-subface;
  box-shadow: 0 map.get($spacers, 1) map.get($spacers, 5) 0 rgba($black, 0.08),
    0 0 map.get($spacers, 1) 0 rgba($black, 0.02);
  pointer-events: auto;

  &__icon-box {
    .icon-box {
      width: 20px;
      height: 20px;
    }
  }

  &__image {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin: 0 map.get($spacers, 2) + map.get($spacers, 1);
    overflow: hidden;
    border-radius: $border-radius;
  }

  &__meta {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
  }
}
