.clan-create-update-modal {
  &__preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: $modal-content-bg;

    @include inset(0);
  }
}
