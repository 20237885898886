.nft-income-history {
  padding: 1rem;
  border-radius: 1rem;
  background: linear-gradient(135deg, #7540EF 1.5%, #EB7DAF 72.78%, #F07D7D 91.78%);
  color: $white;
  font-weight: 900;

  &__roi {
    font-size: .875rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.2rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 2rem;
    }
  }

  &__formula {
    font-size: .75rem;

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__formula-top {
    border-bottom: 2px white solid;

    @include media-breakpoint-up(md) {
      border-bottom: 3px white solid;
    }

    @include media-breakpoint-up(xl) {
      border-bottom: 4px white solid;
    }
  }

  &__board-overlay {
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 50%;
    width: 415px;
    height: auto;
    transform: translateX(-50%);
  }
}
