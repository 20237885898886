@use 'sass:map';

.ve-token {
  $padding: map.get($spacers, 3);
  $ve-gray: #A0A0B5;

  .drp {
    z-index: $zindex-fixed + 20;
    color: $body-color;
  }

  &__gray {
    color: $ve-gray;
  }

  &__btn-overview-text {
    margin-left: .375rem !important;
  }

  &__card-icon {
    height: 28px;
  }

  &__badge-skeleton {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100px;
  }

  &__vote-badge {
    position: relative;

    &-before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($var-primary-theme-rgb, .1);
    }
  }

  &__btn-action-field {
    @include media-breakpoint-up(sm) {
      min-width: 120px;
    }
  }

  &__btn-filter {
    border: 1.5px solid $var-surface-10;

    &:hover, &:focus {
      border-color: $var-primary-theme;

    }
  }

  &__filter-item {
    &:hover, &:focus, &.active {
      color: $var-primary-theme;
    }
  }

  &__card {
    padding: map.get($spacers, 4);

    @include media-breakpoint-down(lg) {
      padding: map.get($spacers, 2);
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      border: none;

      .card-body, .card-header {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  &__empty-min-width {
    min-width: 2rem;
  }

  &__bottom-info {
    min-height: 1.5rem;
  }

  &__card-value {
    letter-spacing: -0.025rem;
  }

  &__badge-period {
    padding: .375rem .5rem;
    background-color: rgba($ve-gray, .1);
    color: $ve-gray;
    line-height: 1.5;

    &:hover, &--selected {
      background-color: rgba($var-primary-theme-rgb, .1);
      color: $var-primary-theme;
    }

    &--disabled {
      color: rgba($ve-gray, 0.5);
      cursor: not-allowed;

      &:hover {
        background-color: rgba($ve-gray, .1);
        color: rgba($ve-gray, 0.5);
      }
    }

    &--demo {
      padding: map.get($spacers, 2) #{map.get($spacers, 2) * 1.25};
      font-size: $font-size-base;
    }
  }

  &__works-only-in-browser {
    overflow: hidden;
    border-radius: 1.25rem;
    background: linear-gradient(180deg, $var-surface-10, $var-surface-5);
  }

  &__works-only-in-browser-image {
    position: absolute;
    top: 16px;
    right: 0;
    left: 0;
    min-height: 148px;
    background-image: url('../../../../assets/images/ve-token/works-in-browser.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  &__works-only-in-browser-content {
    position: relative;
    z-index: 1;
    padding-top: 155px;
  }

  &__works-only-in-browser-btn {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
