@import "plyr/src/sass/plyr";

//.plyr {
//  --plyr-color-main: #{$primary};
//}

.plyr__volume {
  input[type='range'] {
    max-width: 50px;
    min-width: 30px;
  }
}
