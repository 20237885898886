.file-uploader {
  display: flex;
  align-items: center;
  line-height: 1.3;

  &--disabled {

  }

  &--empty {
    cursor: pointer;
  }

  &--error &__info {
    color: $danger;
    background-color: rgba($danger, .08);
  }

  &--error &__name {
    color: $danger;
  }

  &__info {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
    width: 40px;
    height: 40px;
    line-height: 1;
    color: $var-primary-theme;
    background-color: rgba($var-primary-theme-rgb, .08);
    border-radius: $border-radius;
    margin-right: map-get($spacers, 3);
    transition: $transition;
    transition-property: background-color, color;
  }

  &__name {
    color: $body-color;
    transition: color .15s ease-in-out;

    &--empty {
      color: $var-primary-theme;
      font-weight: $font-weight-medium;
    }

    @supports (display: -webkit-inline-box) and (-webkit-box-orient: vertical) and (-webkit-line-clamp: 2) {
      display: -webkit-inline-box;
      vertical-align: text-bottom;
      text-overflow: initial;
      white-space: initial;
      -webkit-line-clamp: 2; /* Число отображаемых строк */
      -webkit-box-orient: vertical; /* Вертикальная ориентация */
      overflow: hidden;
    }
  }

  &__progress {
    color: $info;
  }
}
