.country {
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  //width: 24px;
  //height: 24px;
  border-radius: 50%;
  //border: $border-width solid $border-color;
  isolation: isolate; // Fix for safari border-radius

  &__image {
    //width: 100%;
    //height: 100%;
    object-fit: cover;
  }
}
