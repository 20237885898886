@use "sass:map";

$inner-border-width: 2px;
$outer-border-width: 8px;
$full-border-width: $inner-border-width + $outer-border-width;

@mixin card-game-border($border-radius: map.get($spacers, 5), $border-width: $full-border-width) {
  @include border-gradient(linear-gradient(360deg, #AA86FF 0%, rgba(0, 0, 0, 0.3) 100%), $border-width, true);

  border-radius: $border-radius;

  &::before {
    z-index: 1;
    pointer-events: none;
  }
}

@mixin card-game($padding: map.get($spacers, 3)) {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $game-container-max-width;
  min-height: 0;
  padding: calc($full-border-width + $padding);
  background-color: $secondary-game;

    &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: $outer-border-width solid $primary-game;
    border-radius: inherit;
    pointer-events: none;
  }
}

.card-game {
  @include card-game-border;
  @include card-game;

  &__header {
    font-size: $font-size-sl;
    text-transform: uppercase;
  }


  &__title {
    margin-bottom: map.get($spacers, 2) + map.get($spacers, 1);
    font-size: $font-size-lg;
    text-transform: uppercase;
  }

  &__max-width-none {
    max-width: none;
  }

  &__tab-body {
    max-width: none;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__body {
    width: 100%;
    padding: calc($full-border-width + map.get($spacers, 3));
  }

  &--with-body {
    padding: 0;
  }

  &--scrollable {
    min-width: 0;
    padding-bottom: $full-border-width;
  }
}


.card-game-inner {
  $dark-50: rgba($dark, 0.5);

  display: flex;
  padding: $spacer-75;
  border: 2px solid $dark-50;
  border-radius: map.get($spacers, 3);
  background-color: $dark-50;

  &--rounded {
    border-radius: map.get($spacers, 4);
  }

  &--option {
    padding: 0;
    background-image: url('../../../../assets/images/game/game-reward-background.svg');
    background-repeat: no-repeat;
    background-position: bottom -100% center;
    background-size: 105% 109%;
  }
}

.card-game-container {
  @extend %h-flex-full;

  align-self: stretch;
  margin-right: calc(var(--padding) * -1);
  margin-left: calc(var(--padding) * -1);
  padding: 0 var(--padding);

  .card-game--scrollable & {
    @extend %h-flex-scroll;
  }

  &--full {
    margin-top: calc(var(--padding) * -1);
    margin-bottom: calc(var(--padding) * -1);
    padding-top: var(--padding);
    padding-bottom: var(--padding);

    .card-game--scrollable & {
      margin-bottom: 0;
    }
  }
}

.card-game-image {
  &--rays {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../../assets/images/miner-wars/rays.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: -30px;
    background-size: contain;
  }
}
