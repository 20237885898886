.intercom-lightweight-app {
  z-index: $zindex-fixed - 1 !important;
  transition: opacity $transition;

  .intercom-hidden & {
    opacity: 0;
    pointer-events: none;
  }

  .twa &,
  .intercom-hidden-force & {
    opacity: 0;
    pointer-events: none;
  }

  .app-tab-bar--active & {
    z-index: $zindex-fixed + 12 !important;

    .intercom-lightweight-app-launcher {
      bottom: calc(70px + var(--safe-area-inset-bottom)) !important;
    }
  }
}

.twa {
  .intercom-with-namespace-12xsums,
  .intercom-with-namespace-1aoo0ck {
    & + .intercom-with-namespace-52k34s {
      opacity: 0 !important;
      pointer-events: none !important;
    }
  }
}

.intercom-dfosxs,
.intercom-with-namespace-52k34s {
  .app-tab-bar--active & {
    bottom: calc(70px + var(--safe-area-inset-bottom)) !important;
  }
}

.intercom-176q0e3,
.intercom-lightweight-app-messenger {
  .app-tab-bar--active & {
    bottom: calc(130px + var(--safe-area-inset-bottom)) !important;
    height: min(658px, 100% - (104px + $tab-bar-height)) !important;
  }
}

.intercom-messenger-frame {
  padding: var(--safe-area-inset-top) var(--safe-area-inset-right) var(--safe-area-inset-bottom) var(--safe-area-inset-left);
  background-color: $gray-800;

  iframe {
    width: calc(100% - (var(--safe-area-inset-left) + var(--safe-area-inset-right)) * 2) !important;
    height: calc(100% - (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)) * 2) !important;
  }
}

