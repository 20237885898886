$ltr: 'ltr' !default;
$rtl: 'rtl' !default;

@mixin dir-ignore() {
  /* rtl:begin:ignore */
  @content;

  /* rtl:end:ignore */
}

@mixin dir($value: $ltr, $only-parent: false) {
  @if  $only-parent == true {
    [dir="#{$value}"] & {
      @include dir-ignore {
        @content;
      }
    }
  } @else {
    &[dir="#{$value}"],
    [dir="#{$value}"] &,
    [dir="#{$value}"] {
      @include dir-ignore {
        @content;
      }
    }

    :host-context([dir="#{$value}"]) & {
      @include dir-ignore {
        @content;
      }
    }
  }
}

@mixin ltr($only-parent: false) {
  @include dir($ltr, $only-parent) {
    @content;
  }
}

@mixin rtl($only-parent: false) {
  @include dir($rtl, $only-parent) {
    @content;
  }
}

@mixin dir-mark($root-selector: null, $only-parent: false) {
  @if  $root-selector {
    #{$root-selector}[dir] & {
      @include dir-ignore {
        @content;
      }
    }
  } @else {
    @if  $only-parent == true {
      [dir] & {
        @include dir-ignore {
          @content;
        }
      }
    } @else {
      &[dir],
      [dir] & {
        @include dir-ignore {
          @content;
        }
      }
    }
  }
}

@mixin dir-mark-pseudo($root-selector: null) {
  @include dir-mark($root-selector, true) {
    @content;
  }
}

@function wrap-dir($selector, $dir: null) {
  @if  $dir {
    @return #{'[dir=' + $dir + '] ' + $selector};
  } @else {
    @return #{'[dir] ' + $selector};
  }
}
