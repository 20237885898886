@use 'sass:map';

.modal-exchanges-list {
  &__card {
    @extend %btn;
    @extend %btn-secondary-theme;

    display: block;
    padding: map.get($spacers, 2) + map.get($spacers, 1);
    border-radius: $border-radius-lg;

    @at-root a#{&} {
      color: $body-color;
    }
  }

  &__card-icon {
    flex-shrink: 0;
    margin-right: map.get($spacers, 2) + map.get($spacers, 1);
  }

  &__card-body {
    margin-bottom: .125rem;
    text-align: start;
  }

  &__card-footer {
    font-size: .5rem;
    font-weight: $font-weight-semibold;
  }
}
