@include media-breakpoint-down(md) {
  .g-recaptcha-bubble-arrow {
    display: none;

    & + * {
      overflow: auto;
      position: fixed !important;
      display: flex;
      padding: 10px;
      width: 100% !important;
      height: 100% !important;
      pointer-events: none;
      @include inset(0);

      iframe {
        pointer-events: auto;
        margin: auto;
        border: 1px solid rgb(204, 204, 204);
      }
    }
  }
}
