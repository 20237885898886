@use "sass:map";

.trial-achievements {
  $host: &;
  $accent-color: $violet;
  $thumb-size: 24px;
  $max-items: 10;

  &__timeline-wrapper {
    $host-timeline-wrapper: &;

    position: relative;
    display: flex;
    flex-direction: column;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: -10px;
      width: $thumb-size;
      background: linear-gradient(to bottom, #{rgba($accent-color, .4)}, #{rgba($accent-color, 0)});
      border-radius: 10rem;
    }
  }

  &--mix &__timeline-wrapper {
    flex-direction: column-reverse;
  }

  &__timeline-wrapper &__item {
    padding-bottom: map.get($spacers, 3);
  }

  &--mix &__timeline-wrapper &__item {
    @for $i from 1 through $max-items {
      &:nth-child(#{$i}) {
        z-index: $max-items - $i;
      }
    }
  }

  &--mix &__timeline-wrapper.last-completed &__item.completed:first-child {
    &::before {
      height: calc(100% + #{$thumb-size});
    }
  }

  &__item {
    position: relative;
    z-index: $max-items;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: rem($thumb-size) + map.get($spacers, 3);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: $thumb-size;
      height: $thumb-size;
      background-color: $accent-color;
      border-radius: 10rem;
      opacity: 0.5;
      transition: opacity, height $transition;
    }

    &.completed::before {
      opacity: 1;

      @at-root #{$host} > #{&} {
        height: $thumb-size !important;
      }
    }

    &--starter {
      &::before {
        opacity: 1;
        height: rem($thumb-size) + map.get($spacers, 3);
      }
    }
  }

  &--sequence &__item {
    &.completed::before {
      height: calc(100% + #{$thumb-size});
    }
  }

  &--mix &__item {
    &.completed::before {
      height: rem($thumb-size) + map.get($spacers, 3);
    }

    &.completed + &.completed {
      &::before {
        height: calc(100% + #{$thumb-size});
      }
    }
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: $thumb-size;
    height: $thumb-size;
    color: color-contrast($accent-color);
  }

  &__icon-lock,
  &__icon-star {
    display: inline-flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity $transition;
  }

  &__icon-star {
    opacity: 0;
  }

  &__item--starter &__icon-lock,
  &__item.completed &__icon-lock,
  &--sequence &__item.completed + &__item &__icon-lock,
  &--sequence &__timeline-wrapper.last-completed + &__item &__icon-lock {
    opacity: 0;
  }

  &__item--starter &__icon-star,
  &--mix &__item.completed &__icon-star,
  &--sequence &__item.completed + &__item &__icon-star,
  &--sequence &__timeline-wrapper.last-completed + &__item &__icon-star {
    opacity: 1;
  }

  &--sequence &__item.completed &__icon-star {
    opacity: .4 !important;
  }

  &__text {
    display: flex;
    flex-direction: column;
    transition: opacity $transition;
  }

  &__item.completed &__text {
    opacity: .4;
  }

  &__badge {
    padding: 3px 6px;
    color: $var-grey;
    font-weight: $font-weight-semibold;
    font-size: $extra-small-font-size;
    text-transform: uppercase;
    white-space: nowrap;
    background-color: $var-surface-5;
    border-radius: 10rem;
    transition: background-color $transition;
  }

  &__item--starter &__badge,
  &__item.completed &__badge,
  &--sequence &__item.completed + &__item &__badge {
    color: color-contrast($accent-color);
    background-color: $accent-color;
  }
}
