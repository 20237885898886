@use 'sass:map';

$host: '.board-miner-create-modal';

.board-miner-create-modal {
  .close {
    z-index: 5;
  }

  &__content {
    @include media-breakpoint-up(xl) {
      margin: 4rem 4rem 4rem 3.5rem;
    }
  }

  &__image-wrapper {
    position: relative;
    overflow: hidden;
  }

  &__image {
    position: relative;
    z-index: 4;
    background-size: contain;

    &:not(&--onboarding) {
      @include media-breakpoint-up(md) {
        background-size: 60%;
      }

      @include media-breakpoint-up(lg) {
        max-width: 288px;
        min-height: 348px;
        background-position: center;
        background-size: 80%;
      }
    }

    &--bg {
      position: relative;
      overflow: hidden;
      background-color: $dark;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        padding-bottom: 90%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        opacity: 0.8;
        background: map.get($gradients, 'purple');
        filter: blur(80px);

        @include media-breakpoint-up(lg) {
          filter: blur(60px);
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 28%;
        transform: translateY(40%);
        background: linear-gradient(0deg, $dark 0%, rgba($dark, 0) 100%);
      }
    }

    &--onboarding {
      z-index: 1;
      height: 100%;
      min-height: 400px;

      &::before {
        top: 60%;
        width: 70%;
        padding-bottom: 70%;
        opacity: 0.7;
      }

      &::after {
        z-index: 3;
        transform: none;
      }
    }
  }

  &__onboarding-video {
    position: absolute;
    z-index: 3;
    top: 60%;
    left: 50%;
    width: 40%;
    transform: translate(-50%, -50%);

    @include media-breakpoint-up(md) {
      top: 50%;
      width: 60%;
    }

    #{$host}__image--onboarding & {
      width: 60%;
      max-width: 280px;
    }
  }
}