@use "sass:math";
@use "sass:map";
@import "../scss/mixins";

$navbar-host: '.navbar';
$navbar-highlight-start-color: #C97EF8;
$navbar-highlight-end-color: $primary;

.navbar-host {
  display: block;
  flex-grow: 1;
}

.navbar {
  width: 0;
  height: 100%;
  user-select: none;

  &--selectable {
    user-select: initial;
  }

  &__menu {
    display: flex;
    align-items: stretch;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    #{$navbar-host}--under & {
      align-items: flex-start;
    }

    &--no-scrollbar {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__li-wrapper {
    position: absolute;
    padding: 0 #{map.get($spacers, 1) * 0.75};
    opacity: 0;
    pointer-events: none;

    &--visible {
      position: static;
      opacity: 1;

      body:not(.pointer-none) & {
        pointer-events: all;
      }
      
    }

    &:first-child {
      padding-left: 0;
    }
  }

  &__li {
    display: flex;
    padding: map.get($spacers, 1) 0;

    &--hidden {
      padding-right: map.get($spacers, 4);
      padding-left: map.get($spacers, 1) * 0.75;
    }

    #{$navbar-host}--under & {
      height: 100%;
      padding: 0 #{map.get($spacers, 2) * 1.5};

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: map.get($spacers, 2);
    transition: background-color $transition, background-image $transition;
    border-radius: $border-radius;
    font-weight: $font-weight-medium;
    line-height: 1.25rem;
    white-space: nowrap;
    cursor: pointer;

    &--highlight {
      --navbar-icon-color: #{$navbar-highlight-start-color};

      &:not(#{$navbar-host}__item--active):not(#{$navbar-host}__item:active) {
        span {
          @include text-gradient(linear-gradient(135deg, $navbar-highlight-start-color 0%, $navbar-highlight-end-color 100%));
        }
      }

      &#{$navbar-host}__item--active,
      &#{$navbar-host}__item:active, {
        &, &:hover {
          --navbar-icon-color: #{$white};

          background: linear-gradient(135deg, $navbar-highlight-start-color 0%, $navbar-highlight-end-color 100%);

          span {
            color: $white;
          }
        }
      }
    }

    &, &:hover {
      color: $var-grey;
    }

    &--active,
    &:hover {
      background: $var-aside-bg-200;
    }

    &--active,
    &:active {
      &, &:hover {
        color: $var-surface;
      }
    }

    #{$navbar-host}--under & {
      align-items: flex-start;
      margin-bottom: -1px;
      padding: map.get($spacers, 2) 0 0;
      transition: border-color $transition, color $transition;
      border-bottom: 2px solid transparent;
      border-radius: 0;
      background-color: transparent;

      &--active {
        border-color: $primary;
      }

      &:hover {
        color: $var-surface;
      }
    }
  }

  &__highlight-icon {
    color: var(--navbar-icon-color);
  }

  &__nft-single {
    $nft-single-pb: 0.813rem;

    position: relative;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: rgb(160 160 181 / 20%);
    }

    & .navbar__item {
      margin-bottom: 0;
      padding-bottom: $nft-single-pb;
    }
  }
}
