tc-root {
  &> div {
    z-index: $zindex-modal + 1 !important;
  }

  // костыль, для цвета QR кода
  rect + path {
    fill: currentColor !important;
  }
}
