@use 'sass:map';
@use 'sass:math';

$host: '.nft-upgrade-modal';

.nft-upgrade-modal {
  $block-margin: map.get($spacers, 2) + map.get($spacers, 1);
  $modal-inner-padding-xs-y: map.get($spacers, 4);
  $modal-inner-padding-xs: map.get($spacers, 3);

  --#{$prefix}modal-header-padding: 1.25rem 0 0 !important;
  --#{$prefix}modal-padding: 2rem !important;


  @include media-breakpoint-down(md) {
    --#{$prefix}modal-padding: #{$modal-inner-padding-xs} !important;

    .modal-controller {
      &.modal-has-footer,
      &.modal-has-footer-tmp, {
        & > .modal-body {
          padding-bottom: $modal-inner-padding-xs-y;
        }

        & > .modal-footer {
          padding-top: $modal-inner-padding-xs-y;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 600px;

    --#{$prefix}modal-header-padding: 3rem 0 0 !important;
  }

  @include media-breakpoint-up(sl) {
    $modal-inner-padding: 3rem;

    --#{$prefix}modal-padding: #{$modal-inner-padding} !important;

    .modal-controller {
      &.modal-has-footer,
      &.modal-has-footer-tmp {
        & > .modal-body {
          padding-bottom: $modal-inner-padding-y;
        }

        & > .modal-footer {
          padding-top: $modal-inner-padding-y;
        }
      }
    }
  }

  &--scrollable {
    @include media-breakpoint-up(md) {
      .modal-controller {
        max-height: 90vh;
        max-height: 90svh;
        overflow: hidden;
      }
    }
  }

  .carousel-scroll {
    --fade-color: var(--#{$prefix}modal-bg);
  }

  .modal-title {
    font-family: $font-family-headline;
  }

  .close {
    $offset: map.get($spacers, 3) + map.get($spacers, 1);

    top: $offset;
    right: $offset;
  }

  &__content {
    @include media-breakpoint-down(md) {
      z-index: 1;
      flex-basis: 0;
      flex-grow: 1;
      min-height: 240px;
      margin: 0 calc(var(--#{$prefix}modal-padding) * -1);
      padding: 0 var(--#{$prefix}modal-padding);
      overflow: auto;
    }
  }

  &__nft-item {
    $padding-y: map.get($spacers, 2) + map.get($spacers, 1);

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: $padding-y 0;
    border-bottom: $border-width solid $border-color;
  }

  &__limiter {
    display: inline-flex;
    color: $gray-400;
  }

  &__nft {
    display: block;
    padding: map.get($spacers, 3);
    background-color: $var-aside-bg;

    @include border-radius($border-radius-lg);
  }

  &__select-nfts {
    border: none;
    background-color: $var-aside-bg;

    @include border-radius($border-radius-lg);

    .ng-select-container {
      padding: map.get($spacers, 3) !important;
    }

    .ng-arrow-wrapper {
      width: 24px;
      height: 24px;
      margin-right: 0;
      mask-image: url('../img/caret-large-down.svg');
    }
  }

  &__select-nfts-panel {
    z-index: $zindex-modal !important;
  }

  &__nav-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    border-radius: 50%;
    background-color: $green;
    color: $white;
  }

  &__input-power {
    width: 70%;
    max-width: 150px;
  }

  &__label {
    display: inline-flex;
    align-items: center;
    color: $var-grey;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
  }

  &__table-link {
    margin: $block-margin 0;
  }

  &__content {
    @mixin scrollable-content($withFlex: false) {
      margin: 0 calc(var(--#{$prefix}modal-padding) * -1);
      padding: 0 var(--#{$prefix}modal-padding);
      overflow: hidden auto;

      @if $withFlex {
        flex-basis: 0;
        flex-grow: 1;
      }
    }

    @include media-breakpoint-down(md) {
      @include scrollable-content(true);
    }

    #{$host}--scrollable & {
      @include scrollable-content;
    }
  }

  &__info {
    flex-basis: auto;
    flex-grow: 1;
    padding: map.get($spacers, 4);
    border-radius: map.get($spacers, 4);
    background-color: $var-surface-accent;

    &--active {
      @include border-gradient(map.get($gradients, 'purple'), 3px, true);
    }

  }

  &__info-title {
    margin-bottom: $block-margin;
  }

  &__info-divider {
    margin: $block-margin 0;

    @include media-breakpoint-up(sm) {
      margin: map.get($spacers, 3) 0;
    }
  }

  &__arrow {
    display: inline-flex;
    margin: map.get($spacers, 2);

    svg {
      $size: map.get($spacers, 3) + map.get($spacers, 1);

      width: $size;
      height: $size;
    }
  }

  &__text-counter {
    width: 2ch;
    text-align: center;
  }

  &__btn-counter {
    $size: 28px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    transition: background-color, opacity $transition;
    border-radius: 50%;
    background-color: $var-surface-5;
    color: $var-grey;

    &:not(:disabled):hover {
      background-color: $var-surface-8;
    }

    &:disabled {
      opacity: .6;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__list-item {
    display: flex;
    align-items: center;
    font-size: $small-font-size;

    & + & {
      margin-top: map.get($spacers, 2) + map.get($spacers, 1);
    }
  }

  &__list-item-icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-right: map.get($spacers, 2) + map.get($spacers, 1);
    border-radius: 50%;
    background-color: $aside-bg;
    color: $var-grey;
  }

  &__icon-dollar-circle {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      border-radius: 50%;
      background-color: $white;

      @include inset(10%);
    }

    svg {
      position: relative;
    }
  }
}
