$icon-highlight-size: 24px;
$host: '.icon-highlight';

.icon-highlight {
  --icon-highlight-size: 24;
  --icon-highlight-background-size: calc(var(--icon-highlight-size) / 1.2);

  $size: calc(var(--icon-highlight-size) * 1px);
  $bg-size: calc(var(--icon-highlight-background-size) * 1px);

  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;

  &__background,
  &__icon svg {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__background {
    svg {
      width: $bg-size;
      height: $bg-size;
    }
  }

  &__background-path {
    opacity: 0.4;

    @include light-theme-content {
      opacity: 0.2;
    }

    #{$host}--light & {
      @include light-theme-content(true) {
        opacity: 0.16;
      }
    }
  }

  &__icon {
    color: $white;

    #{$host}--preserve & {
      color: currentcolor;
    }

    @include light-theme-content {
      color: currentcolor;
    }
  }
}
