@use 'sass:map';

.nft-bag-promocode-modal {
  max-width: 90vw;
  margin-top: map.get($spacers, 5);
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: center;
    max-width: calc($aside-viewer-width - map.get($spacers, 5));
    height: 100%;
    margin-top: 0;
    margin-right: map.get($spacers, 5);
    margin-bottom: 0;
  }

  .form-control:disabled ~ .input-group-append {
    pointer-events: all;

    .input-group-btn {
      color: $var-surface-80;
    }
  }
}