.miner-wars-profile {
  // scaling down ems for smaller viewports
  font-size: 14px;
  @include game-breakpoint-up(sm, $game-breakpoints) {
    font-size: 16px;
  }
  @include game-breakpoint-up(lg, $game-breakpoints) {
    font-size: 24px;
  }

  &__alias {
    font-size: $font-size-sl;
  }

  &__badge {
    padding: .25rem .5rem;
    border-width: 1px;
    border-style: solid;
    border-radius: 100px;

    &--pro {
      background-color: $success;
      border-color: rgba($dark, 0.5);
    }

    &--starter {
      background-color: rgba($dark, 0.5);
      border-color: $dark;
    }
  }

  &__btc-balance {
    margin-top: 0.75rem;
  }

  &__balance-icon {
    margin-right: 0.625rem;
  }

  &__inviters-badge {
    margin-left: 0.313rem;
  }

  &__separator {
    margin-top: 0.75rem;
    margin-bottom: calc(0.75rem - 2px);
    height: 1px;
    background-color: $var-surface-10;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.25);
  }

  &__avatar {
    font-size: unset;
    width: 3.375em;
    height: 3.375em;
  }
}
