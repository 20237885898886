@use 'sass:map';

.launchpad {
  &__progress-bar {
    .progressbar {
      --progress-bg-color: #{rgba($var-grey-rgb, 0.15)};
      --progress-bar-height: 0.5rem;
    }

    &--cap {
      .progressbar {
        --progress-bar-height: 1.25rem;

        border-radius: map.get($spacers, 1_5);
      }
    }
  }

  &__accordion-btn {
    --launchpad-accordion-color: #{$var-grey};

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: #{map.get($spacers, 0_5)} 0;
    text-align: start;

    &:hover {
      --launchpad-accordion-color: #{$var-surface};
    }
  }

  &__accordion-icon {
    transition: color $transition, transform $transition;
    color: var(--launchpad-accordion-color);
  }
}