@use 'sass:map';

$host: '.catalog-item-card';
$running-line-height: 1.25rem;

.catalog-item-card {
  $card: #{$host}__action-card;
  $card-border-radius: 20px;
  $default-padding: map.get($spacers, 2) + map.get($spacers, 1);
  $action-button-height: $default-padding + map.get($spacers, 6);
  $min-footer-content-height: 68px;

  --action-button-height: #{$action-button-height};

  display: flex;

  //overflow: hidden; // causes a lot of bugs with rendering, like antialiasing or fake hover after click
  position: relative;
  flex-direction: column;
  border: 0 solid $border-color;
  border-radius: $card-border-radius;
  background-color: $var-subface;
  cursor: pointer;
  mask-image: radial-gradient(white, black); // fixes issue with incorrect antialiasing of border-radius

  .twa &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1001;
    top: 0;
    width: 100%;
    height: 100%;
    border: $border-width solid $border-color;
    border-radius: $card-border-radius;
    pointer-events: none;

    @include dir-ignore {
      left: 0;
    }
  }

  &--white {
    background-color: $white;
  }


  &__mark-wrapper {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    flex-direction: column;
    justify-content: center;
    width: 100px;
    height: 100px;
    overflow: hidden;
    pointer-events: none;
  }

  &__mark {
    display: block;
    padding: map.get($spacers, 1);
    transform: translate(25px, -25px) rotate(45deg);
    background-color: $orange;
    color: $dark;
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;
    text-align: center;
    text-transform: uppercase;
  }

  &__body-wrapper {
    &.premium-wrapper {
      @include border-radius($border-radius + 0.25rem);
    }
  }

  &__body {
    @include border-top-radius($border-radius);

    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 90%;
    overflow: hidden;
    background-color: $var-surface-20;

    &--square {
      padding-bottom: 100%;
    }

    &--generation {
      background-color: $body-bg;
    }
  }

  &__image--expired {
    filter: grayscale(1);
  }

  &__miner-create-video,
  &__image {
    @include inset(0);

    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--undefined {
      background: $var-aside-bg;
    }

    &-overlay {
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
    }
  }

  &__miner-create-video {
    .miner-create-video {
      z-index: 2;
      width: 100%;
      height: 100%;
      padding-bottom: 0;

      &__video {
        width: 90%;
        height: 90%;
        transform: scale(2);

        @include inset(5%);
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $dark;

      @include inset(0);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 130%;
      padding-bottom: 90%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: map.get($gradients, 'purple');
      filter: blur(50px);
    }
  }

  &__body-content {
    display: flex;
    position: absolute;
    inset: 0;
    flex-direction: column;
    padding: $default-padding;
  }

  &__generation-info {
    min-height: $min-footer-content-height;
    background-color: $var-subface;
    padding: $default-padding;

    #{$host}__action-card & {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
    }
  }

  &__footer {
    @include border-bottom-radius($card-border-radius);

    display: flex;
    position: relative;
    z-index: 1000;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    transform: translate(0, 0);
    transition: transform 300ms ease-in-out, border-bottom-right-radius $transition, border-bottom-left-radius $transition;
    background-color: $var-subface;

    &-content {
      min-height: $min-footer-content-height;
      padding: $default-padding;

      @include media-breakpoint-down(sm) {
        min-height: 0;
      }
    }
  }

  &__name {
    color: $body-color;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;

    @include media-breakpoint-only(sm) {
      font-size: .75rem;
      font-weight: 500;
      line-height: 1rem;
    }

    &--small {
      @include media-breakpoint-down(lg) {
        font-size: .75rem;
        font-weight: 500;
        line-height: 1rem;
      }
    }

    #{$host}--white & {
      color: $gray-800;
    }
  }

  &__actions {
    @include border-bottom-radius($card-border-radius);

    background-color: $var-subface;

    &:not(:empty) {
      padding: 0 $default-padding $default-padding $default-padding;
    }

    #{$host}__action-card & {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 0 $default-padding;
    }
  }

  &__running-discount {
    display: flex;
    align-items: center;
    width: 100%;
    height: $running-line-height;
    background-color: $red;
    color: $white;
    font-size: $font-size-xs;
  }

  &__running-discount-item {
    display: inline-flex;
    align-items: center;

    &::after {
      content: '';
      width: .25rem;
      height: .25rem;
      margin-right: map.get($spacers, 2);
      margin-left: map.get($spacers, 2);
      border-radius: 50%;
      background-color: $white;
    }
  }

  &:hover #{$card}, &:focus #{$card}, #{$card}--active {
    @include border-bottom-radius(0);

    transform: translate(0, calc(0px - var(--action-button-height)));
  }

  &__btn {
    --#{$prefix}btn-disabled-opacity: .8;
    --#{$prefix}btn-color: #{$link-color};
    --#{$prefix}btn-border-color: #{$var-surface-10};
    --#{$prefix}btn-hover-color: #{$link-color};
    --#{$prefix}btn-active-color: #{$link-color};
    --#{$prefix}btn-hover-border-color: #{$var-surface-10};
    --#{$prefix}btn-active-border-color: #{$var-surface-10};

    @include media-breakpoint-up(xl) {
      --#{$prefix}btn-color: #{$white};
      --#{$prefix}btn-disabled-color: #{$white};
      --#{$prefix}btn-hover-color: #{$white};
      --#{$prefix}btn-active-color: #{$white};
      --#{$prefix}btn-bg: #{rgba($black, .4)};
      --#{$prefix}btn-disabled-bg: #{rgba($black, .4)};
      --#{$prefix}btn-hover-bg: #{rgba($black, .6)};
      --#{$prefix}btn-active-bg: #{rgba($black, .8)};

      border-color: transparent !important;
    }
  }

  &__btn-bag {
    overflow: hidden;
  }

  &__btn-bag-icons {
    display: flex;
    flex-direction: row-reverse;
    margin-left: calc(-1rem - 19px);
    transition: transform 0.5s cubic-bezier(0, 0, 0, 1);

    #{$host}__btn-bag--active & {
      transform: translate3d(calc(100% - 19px), 0, 0);
    }
  }

  &__btn-bag-icon-check {
    margin-right: 1rem;
  }

  &__btn-bag-icon-cart,
  &__btn-bag-icon-check {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__tag {
    &-icon {
      margin-left: 2px;
    }
  }

  &__gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  }

  &__item-info {
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;
    padding: $default-padding;
    color: $surface-dark;
  }

  &--mine {
    &#{$host}--lg #{$host}__body-wrapper {
      width: 100%;
      height: 100%;
    }

    #{$host} {
      &__action-card {
        --action-button-height: #{$default-padding * 2 + map.get($spacers, 6)};
      }

      &__actions {
        padding: $default-padding;
      }
    }

  }
}
