@use 'sass:map';

.nft-upgrade-package {
  --padding: #{map.get($spacers, 3) * 1.25};
  --border-width: 1px;
  --border-color: #{$var-surface-10};
  --body-padding: #{map.get($spacers, 2) * 1.5} 0 0;
  --price-fs: #{$h2-font-size};
  --price-lh: 2.125rem;
  --price-mb: #{map.get($spacers, 3)};
  --summary-direction: column-reverse;
  --summary-align: flex-start;
  --level-visibility: visible;
  --divider-margin: #{map.get($spacers, 3)} 0;

  padding: var(--padding);
  transition: box-shadow $transition;
  border-radius: 1.25rem;
  box-shadow: inset 0 0 0 var(--border-width) var(--border-color);
  color: var(--surface);
  cursor: pointer;

  &--small {
    --padding: #{map.get($spacers, 3)};
    --body-padding: #{map.get($spacers, 2)} 0 0;
    --price-fs: #{$h4-font-size};
    --price-lh: 1.4;
    --price-mb: 0;
    --summary-direction: row;
    --summary-align: center;
    --divider-margin: #{map.get($spacers, 2) * 1.5} 0;
  }

  &--active {
    --border-width: 3px;
    --border-color: #{$primary};
  }

  &--nftEnergyEfficiencyUpgrade {
    &#{$host}--small {
      --level-visibility: hidden;
    }
  }

  &__body {
    padding: var(--body-padding);
  }

  &__price {
    margin-bottom: var(--price-mb);
    font-family: $font-family-headline;
    font-size: var(--price-fs);
    font-weight: $font-weight-medium;
    line-height: var(--price-lh);
  }

  &__summary {
    display: flex;
    flex-direction: var(--summary-direction);
    align-items: var(--summary-align);
    justify-content: space-between;
  }

  &__title {
    margin-bottom: 0;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
  }

  &__divider {
    margin: var(--divider-margin);
  }
}
