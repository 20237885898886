.social-share-links {
  display: flex;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $var-surface-accent;
    border-radius: $border-radius;
    color: $grey;
    margin: 0 map-get($spacers, 1);
    padding: map-get($spacers, 2) 0;
    flex-grow: 1;
    cursor: pointer;
    transition: opacity $transition;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}
