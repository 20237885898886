.icon-gmt {
  &__outer {
    //fill: $white;
    //
    //@include light-theme-content {
    //  fill: $primary;
    //}

    fill: $primary;
  }

  &__inner {
    //fill: $primary;
    //
    //@include light-theme-content {
    //  fill: $white;
    //}

    fill: $white;
  }

  &__inner--mono {
    fill: $dark;

    @include light-theme-content {
      fill: $white;
    }
  }
}
