@use "sass:map";

@include game-style {
  .form-switch {
    $width: 54px;
    $size-pin: 22px;
    $size-pin-offset: 4px;

    &__text {
      display: flex;
      align-items: center;
      position: absolute;
      padding: $size-pin-offset;
      left: 0;
      top: 0;
      height: 100%;
      width: $width;
      z-index: 1;
    }

    &__box {
      &::before {
        min-width: $width;
        border-radius: 10px;
      }

      &::after {
        background-image: url('../../../../assets/images/game/switch-indicator.svg');
        width: $size-pin;
        height: $size-pin;
        left: $size-pin-offset;
        top: $size-pin-offset;
        border-radius: 6px;
        z-index: 2;
      }
    }

    .form-switch__input:checked + .form-switch__box {
      &::before {
        background-color: $success-game;
      }

      &::after {
        transform: translate3d(#{$width - $size-pin - $size-pin-offset * 2}, 0, 0);
        background-color: $white;
      }
    }

  }
}
