@import "../vendors/bootstrap/type";

%text-link {
  text-decoration: $var-link-decoration !important;
  text-decoration-skip-ink: none;
}

%lead-headline {
  @include font-size($lead-font-size);
  font-weight: $lead-font-weight;
  font-family: $font-family-headline;
}

%small {
  font-size: $small-font-size !important;
}

%extra-small {
  font-size: $extra-small-font-size !important;
}


.headline {
  font-family: $font-family-headline;
}

.lead-headline {
  @extend %lead-headline;
}

.lead-sm {
  @include font-size($lead-font-size-sm);
}

.lead-xs {
  @include font-size($lead-font-size-xs);
}

.small {
  @extend %small;
}

.extra-small {
  @extend %extra-small;
}

.text-link {
  @extend %text-link;
}

.text-inherit {
  color: inherit !important;
}

.text-primary-theme {
  color: $var-primary-theme;
}
