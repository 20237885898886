// TODO Рефакторинг использования var
.image-logo {
  --color-1: #{ $var-primary-theme };

  &__color-1 {
    fill: var(--link-color);
  }


  &__letter {
    fill: var(--bs-body-color);
  }
}
